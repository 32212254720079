import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import PurchaseOrderDataTableFilter from 'src/components/parts/PurchaseOrderDataTableFilter/PurchaseOrderDataTableFilter';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import tableService from 'src/services/table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
}));

const defaultHeadCells = [
  {
    id: 'OrderNumber',
    numeric: false,
    disablePadding: true,
    label: 'Order No.',
  },
  {
    id: 'LineId',
    numeric: true,
    disablePadding: false,
    label: 'Line No.',
  },
  {
    id: 'OrderNumberSplit',
    numeric: true,
    disablePadding: false,
    label: 'Split No.',
  },
  {
    id: 'Code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'UnitPrice',
    numeric: true,
    disablePadding: false,
    label: 'Unit Price($)',
  },
  {
    id: 'QtyMet',
    numeric: true,
    disablePadding: false,
    label: 'Qty Receive',
  },
  {
    id: 'RemainingQty',
    numeric: true,
    disablePadding: false,
    label: 'Remaining Qty',
  },
  {
    id: 'CommercialValue',
    numeric: true,
    disablePadding: false,
    label: 'Shipped Commercial Value($)',
  },
  {
    id: 'RemainingCommercialValue',
    numeric: true,
    disablePadding: false,
    label: 'Remaining Commercial Value($)',
  },
  {
    id: 'ShippingDate',
    numeric: true,
    disablePadding: false,
    label: 'Shipping Date',
  },
  {
    id: 'RequiredInStoreDate',
    numeric: true,
    disablePadding: false,
    label: 'Required In Store',
  },
  {
    id: 'DeliveryDate',
    numeric: true,
    disablePadding: false,
    label: 'Delivery Date',
  },
  {
    id: 'RecentEventType',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'OriginETD',
    numeric: true,
    disablePadding: false,
    label: 'ETD',
  },
  {
    id: 'OriginATD',
    numeric: true,
    disablePadding: false,
    label: 'ATD',
  },
  {
    id: 'DestinationETA',
    numeric: true,
    disablePadding: false,
    label: 'ETA',
  },
  {
    id: 'DestinationATA',
    numeric: true,
    disablePadding: false,
    label: 'ATA',
  },
  {
    id: 'InStoreDelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'In Store Delay(D)',
    tooltip: 'Compare "Required In Store" and Shipment Arrival Date.',
  },
  {
    id: 'ArrivalDelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'Arrival Delay(D)',
    tooltip: 'Compare "Delivery Date" and Shipment Arrival Date.',
  },
  {
    id: 'DepartureDelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'Departure Delay(D)',
    tooltip: 'Compare "Shipping Date" and Shipment Departure Date.',
  },
  {
    id: 'TransitDelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'Transit Delay(D)',
    tooltip: 'Compare Expected Transit Time and Actual Transit Time.',
  },
  {
    id: 'VendorBookingDelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'Vendor Booking Delay(D)',
    tooltip: 'Compare "Shipping Date - 21 days" and Actual Vendor Booking Date.',
  },
];

function PurchaseOrderLineDataTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const { enabledPurchaseOrderLinesFeature } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();

  useEffect(() => {
    if (filter) {
      startProgress();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getPurchaseOrderLinesTableReport(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        data.buyerId
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.items);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        console.log(filteredTable.headCells);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    const createDelayCell = (delayInDays, maxLateDay) => {
      let delayInDaysCell = { text: delayInDays, className: '' };
      if (delayInDays !== null) {
        if (delayInDays > 0) {
          delayInDaysCell.text = `+${delayInDays}`;
          if (delayInDays <= maxLateDay) {
            delayInDaysCell.className = classes.arrivedLate;
          } else {
            delayInDaysCell.className = classes.arrivedVeryLate;
          }
        } else {
          delayInDaysCell.className = classes.arrivedOnTime;
        }
      }
      return delayInDaysCell;
    };

    items.map((item, index) => {
      let requiredInStoreDate = item.requiredInStoreDate ? moment(item.requiredInStoreDate) : null;
      // let orderDate = item.orderDate ? moment(item.orderDate) : null;
      let shippingDate = item.shippingDate ? moment(item.shippingDate) : null;
      let deliveryDate = item.deliveryDate ? moment(item.deliveryDate) : null;
      let originETD = item.originETD ? moment(item.originETD) : null;
      let originATD = item.originATD ? moment(item.originATD) : null;
      let destinationETA = item.destinationETA ? moment(item.destinationETA) : null;
      let destinationATA = item.destinationATA ? moment(item.destinationATA) : null;

      let unitPrice = '';
      if (item.unitPrice) {
        if (item.unitQty > 1) {
          unitPrice = `$${item.unitPrice} per ${item.unitQty}${item.unitUOM ?? ''}`;
        } else {
          unitPrice = `$${item.unitPrice} per ${item.unitUOM ?? ''}`;
        }
      }
      let inStoreDelayInDays = createDelayCell(item.inStoreDelayInDays, 3);
      let arrivalDelayInDays = createDelayCell(item.arrivalDelayInDays, 3);
      let departureDelayInDays = createDelayCell(item.departureDelayInDays, 3);
      let transitDelayInDays = createDelayCell(item.transitDelayInDays, 7);
      let vendorBookingDelayInDays = createDelayCell(item.vendorBookingDelayInDays, 3);

      var shipmentNumber = { component: 'linkList', links: [] };
      if (item.shipmentNumber) {
        shipmentNumber.links.push({
          text: item.shipmentNumber,
          path: '/shipments?s=' + item.shipmentNumber,
        });
      } else {
        shipmentNumber = '';
      }

      var shipmentStatus = item.recentEventType;
      if (item.recentEventType && item.recentEventType != 'Pending' && item.recentEventType != 'Closed' && item.shipmentNumber) {
        shipmentStatus = {
          component: 'linkList',
          links: [
            {
              text: item.recentEventType,
              path: '/trackandtrace?s=' + item.shipmentNumber,
            },
          ],
        };
      }

      let rowData = {
        id: index,
        OrderNumber: item.orderNumber,
        LineId: item.lineId,
        OrderNumberSplit: item.orderNumberSplit,
        Code: item.code,
        // Description: item.description,
        // Qty: item.qty ? item.qty.toLocaleString() : '',
        // QtyUOM: item.qtyUOM,
        UnitPrice: unitPrice,
        // LinePrice: item.linePrice ? item.linePrice.toLocaleString() : '',
        QtyMet: item.qtyMet ? item.qtyMet.toLocaleString() : '0',
        RemainingQty: item.remainingQty ? item.remainingQty.toLocaleString() : '0',
        CommercialValue: item.commercialValue ? item.commercialValue.toLocaleString() : '0',
        RemainingCommercialValue: item.remainingCommercialValue ? item.remainingCommercialValue.toLocaleString() : '0',
        // Buyer: item.buyer,
        RequiredInStoreDate: requiredInStoreDate ? requiredInStoreDate.format('L') : '',
        // OrderDate: orderDate ? orderDate.format('L') : '',
        ShippingDate: shippingDate ? shippingDate.format('L') : '',
        DeliveryDate: deliveryDate ? deliveryDate.format('L') : '',
        OriginETD: originETD ? originETD.format('L') : '',
        OriginATD: originATD ? originATD.format('L') : '',
        DestinationETA: destinationETA ? destinationETA.format('L') : '',
        DestinationATA: destinationATA ? destinationATA.format('L') : '',
        // ShipmentNumber: shipmentNumber,
        RecentEventType: shipmentStatus,
        InStoreDelayInDays: inStoreDelayInDays,
        ArrivalDelayInDays: arrivalDelayInDays,
        DepartureDelayInDays: departureDelayInDays,
        TransitDelayInDays: transitDelayInDays,
        VendorBookingDelayInDays: vendorBookingDelayInDays,
        // Supplier: item.supplier,
      };

      newArray.push(rowData);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLinesTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generatePurhcaseOrderEDILinesPDF(
            result.data.items,
            'Purchase Orders Lines     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Purchase Orders Lines Report',
            result.data.hiddenColumnNames
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderLinesTableReport(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generatePurchaseOrderLinesCsvData(
            result.data.items,
            filter.accountName + '_' + filter.supplierName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <EntityListFrame>
        {enabledPurchaseOrderLinesFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Purchase Order Lines</h4>
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
              </Grid>
              <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PurchaseOrderDataTableFilter
                sendFilterComponentData={filterCallback}
                sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                showSuppliers={true}
                freeTextFilterOn="Order Number, Code and Status."
              />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={newHeadCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(PurchaseOrderLineDataTable);
