import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Container, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 'calc(100vh - 150px)',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    '& h1': {
      fontSize: '7rem',
      fontWeight: 600,
      marginBottom: 0,
    },
  },
  subtitle: {
    '& p': {
      fontSize: '1.4rem',
      marginBottom: 0,
    },
  },
  description: {
    paddingBottom: '48px !important',
  },
}));

export default function PageNotFound(props) {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={clsx(classes.gridItem, classes.title)}>
          <h1>404</h1>
        </Grid>
        <Grid item xs={12} className={clsx(classes.gridItem, classes.subtitle)}>
          <p>Page not found</p>
        </Grid>
        <Grid item xs={12} className={clsx(classes.gridItem, classes.description)}>
          <p>The page your are looking for may have been moved or deleted.</p>
        </Grid>
      </Grid>
    </Container>
  );
}

PageNotFound.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};
