import apiHelperService from './api.helper.service';

class AccountApiService {
  constructor() {
    this.urlPrefix = '/api/v1/account';
  }
  getMyAccountSelectItems = () => {
    return apiHelperService.getCallAPIWrapper(`${this.urlPrefix}/query/my-account-select-items`);
  };

  getAccountIdByAlias = (alias) => {
    return apiHelperService.anonymousGet(`${this.urlPrefix}/query/get-account-id-by-alias?alias=${alias}`);
  };
}

export default new AccountApiService();
