import React, { useLayoutEffect, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../Card/Card.js';
import CardHeader from '../../../Card/CardHeader.js';
import CardBody from '../../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartLogo from '../../../parts/ChartLogo';
import { lightTheme } from '../../../../themes/lightTheme';
import ContentFont from '../../../Typography/ContentFont';
import LoadingOverlay from '../../../parts/LoadingOverlay.js';
import PropTypes from 'prop-types';
import ChartNoData from '../../../parts/ChartNoData.js';
import { format } from 'react-string-format';
import ChartTooltip from '../../../parts/ChartTooltip.js';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 355,
    paddingTop: 40,
  },
  chartTitle: {
    height: 70,
  },
  chartTitlePanel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    '& h4': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function LineChart(props) {
  const classes = useStyles();
  const { title, caption, chartData, isLoading, chartId, tooltipValueFormat } = props;
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShowNoData(false);
    } else {
      if (chartData && chartData.length > 0) {
        setShowNoData(false);
      } else {
        setShowNoData(true);
      }
    }
  }, [chartData, isLoading]);

  useLayoutEffect(() => {
    let chart = am4core.create(chartId, am4charts.XYChart);

    if (chartData) {
      chart.logo.disabled = true;
      chart.maskBullets = false;
      chart.paddingRight = 20;

      chart.data = chartData;

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 60;
      dateAxis.tooltipDateFormat = 'yyyy-MM-dd';

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.title.numberFormatter.numberFormat = '#,###.##';
      if (tooltipValueFormat) {
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
          return format(tooltipValueFormat, text);
        });
      }
      let valueYText = format(tooltipValueFormat + ' {labelSuffix}', '{valueY}');
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.strokeWidth = 2;
      series.stroke = am4core.color(lightTheme.chart.colors[0]);
      series.fill = am4core.color(lightTheme.chart.colors[0]);

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = valueYText;
      labelBullet.label.dy = -10;
      labelBullet.label.fontSize = '0.75rem';
      labelBullet.hidden = true;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color('#fff');
      bullet.circle.strokeWidth = 2;
      bullet.tooltipText = '[bold]{labelPrefix}: [/]' + valueYText;
      bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      bullet.events.on('hit', function (ev) {
        if (labelBullet.isHidden) {
          labelBullet.show();
        } else {
          labelBullet.hide();
        }
      });

      series.events.on('appeared', function (ev) {
        labelBullet.hide();
      });

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.opacity = 0;

      dateAxis.keepSelection = true;
    }

    return () => {
      chart.dispose();
    };
  }, [chartData, showNoData]);

  return (
    <Card chart>
      <LoadingOverlay IsLoading={isLoading} />
      <ChartTooltip
        contentHtml={
          <>
            <b>Show/Hide values above bullets:</b> Click on a bullet.
          </>
        }
      />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>
          {showNoData ? <ChartNoData text="There is no shipment data on this Product." /> : <div id={chartId} className={classes.chart}></div>}
        </ContentFont>
      </CardHeader>
      <CardBody className={classes.chartTitle}>
        <div className={classes.chartTitlePanel + ' titleFont'}>
          <h4> {title} </h4>
          {caption && <>({caption})</>}
        </div>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

LineChart.propTypes = {
  chartId: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.any,
      value: PropTypes.number,
      labelPrefix: PropTypes.string,
      labelSuffix: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  tooltipValueFormat: PropTypes.string,
};
