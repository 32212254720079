
import { Checkbox, withStyles } from '@material-ui/core';
import React from 'react';

const CustomCheckbox = withStyles((theme) => ({
    root: {
        '&$checked': {
            color: theme.palette.focusShipmentCheckbox.focused.color,
        },
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;