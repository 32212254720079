import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import GridContainer from 'src/components/Grid/GridContainer.js';
import GridItem from 'src/components/Grid/GridItem';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import apiService from 'src/services/api.service';

import AvgTransitTime from './AvgTransitTime';
import FCLTEU from './FCLTEU';
import PortPairsTrendFilter from './FilterControls/PortPairsTrendFilter';
import FreightSpendTrendChart from './FreightSpend';
import PercentOfOrderDelay from './PercentOfOrderDelay';
import PercentOfShipmentDelay from './PercentOfShipmentDelay';
import Shipments from './Shipments';
import Spend from './Spend';
import Volume from './Volume';

export default function PortPairChartsTabContainer(props) {
  const history = useHistory();
  const { baseQueryString, filterComponentData, addDataToTabsDataStorage, tabsDataStorage, trendOverTimeSettings, ShowTrendLine, ShowAvgLine } = props;

  const [accountId, setAccountId] = useState();
  const [supplierIds, setSupplierIds] = useState();
  const [portPairFilterItems, setPortPairFilterItems] = useState();
  const [selectedPortsValue, setSelectedPortsValue] = useState({
    originId: tabsDataStorage?.originId,
    destinationId: tabsDataStorage?.destinationId,
  });
  const [filterComponentDataWithPortPair, setFilterComponentDataWithPortPair] = useState({});
  const { enabledSpendPerSuppliers } = useCustomerAccountFeatures();

  useEffect(() => {
    if (filterComponentData && filterComponentData.accountId) {
      if (accountId !== filterComponentData.accountId || supplierIds !== filterComponentData.suppliers) {
        setAccountId(filterComponentData.accountId);
        setSupplierIds(filterComponentData.suppliers);
      } else {
        setFilterComponentDataWithPortPair({
          ...filterComponentData,
          ...selectedPortsValue,
        });
      }
    }
  }, [filterComponentData]);

  useEffect(() => {
    var cancelled = false;
    const LoadPortPairFilter = async (accountId, supplierIds) => {
      try {
        let result = await apiService.getTrendsPortPairFilterItems(accountId, supplierIds);
        if (!cancelled) {
          setPortPairFilterItems(result.data);
        }
      } catch (err) {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      }
    };

    if (accountId) {
      LoadPortPairFilter(accountId, supplierIds);
    }

    return () => {
      cancelled = true;
    };
  }, [accountId, supplierIds]);

  const isHidden = (chartName) => {
    if (trendOverTimeSettings) {
      if (trendOverTimeSettings.hiddenCharts && trendOverTimeSettings.hiddenCharts.length > 0) {
        let hiddenChartIndex = trendOverTimeSettings.hiddenCharts.findIndex((hiddenChart) => hiddenChart.toLowerCase() === chartName.toLowerCase());
        return hiddenChartIndex > -1;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  function setPortPairToTabsDataStorage(originId, destinationId) {
    if (addDataToTabsDataStorage) {
      addDataToTabsDataStorage({
        originId: originId,
        destinationId: destinationId,
      });
    }
  }

  const handlePortsChange = (ports) => {
    setPortPairToTabsDataStorage(ports.originId, ports.destinationId);
    setSelectedPortsValue(ports);

    setFilterComponentDataWithPortPair({
      ...filterComponentData,
      ...ports,
    });
  };

  return (
    <>
      {selectedPortsValue && (
        <PortPairsTrendFilter
          portPairFilterItems={portPairFilterItems}
          onSelectedPorts={handlePortsChange}
          originId={selectedPortsValue.originId}
          destinationId={selectedPortsValue.destinationId}
        />
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <Shipments
            FilterComponentData={filterComponentDataWithPortPair}
            baseQueryString={baseQueryString}
            ShowTrendLine={ShowTrendLine}
            ShowAvgLine={ShowAvgLine}
          />
        </GridItem>
        {!isHidden('PercentOfShipmentDelay') && (
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <PercentOfShipmentDelay
              FilterComponentData={filterComponentDataWithPortPair}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}
        {!isHidden('PercentOfOrderDelay') && (
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <PercentOfOrderDelay
              FilterComponentData={filterComponentDataWithPortPair}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <FCLTEU
            FilterComponentData={filterComponentDataWithPortPair}
            baseQueryString={baseQueryString}
            ShowTrendLine={ShowTrendLine}
            ShowAvgLine={ShowAvgLine}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <Volume
            FilterComponentData={filterComponentDataWithPortPair}
            baseQueryString={baseQueryString}
            ShowTrendLine={ShowTrendLine}
            ShowAvgLine={ShowAvgLine}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        {!isHidden('SpendPerSuppliers') && enabledSpendPerSuppliers && (
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Spend
              FilterComponentData={filterComponentDataWithPortPair}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}

        {!isHidden('FreightSpend') && (
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <FreightSpendTrendChart
              FilterComponentData={filterComponentDataWithPortPair}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <AvgTransitTime
            FilterComponentData={filterComponentDataWithPortPair}
            baseQueryString={baseQueryString}
            ShowTrendLine={ShowTrendLine}
            ShowAvgLine={ShowAvgLine}
          />
        </GridItem>
      </GridContainer>
    </>
  );
}
