import React, { useState, useRef } from "react";
import { Grid, TextField } from "@material-ui/core";
import Button from '../../wrappers/Button';
import { useHistory } from "react-router";
import LoadingBar from "react-top-loading-bar";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import apiService from "../../services/api.service";
import Typography from "@material-ui/core/Typography";
import { lightThemeAdmin } from '../../themes/lightTheme';
import { darkTheme } from '../../themes/darkTheme';

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [sentStatus, setSendStatus] = useState(false);
  const history = useHistory();
  const progressRef = useRef(null);
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValidEmailAddress(email)) {
      progressRef.current.continuousStart();
      apiService
        .forgotPassword(email)
        .then((result) => {
          setSendStatus(true);
          progressRef.current.complete();
        })
        .catch((err) => {
          console.log("error", err);
          setDisplayError(true);
        });
    } else setDisplayError(true);
  };

  const isValidEmailAddress = (address) => {
    return !!address.match(/.+@.+/);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex" ,
      height: "100vh",
      minHeight: "100%" ,
      clear: "both" ,     
     
    },
    buttons: {
      margin: theme.spacing(1),
      color: "#fff"
    },
    buttons2: {
      margin: theme.spacing(5),
     
    },
    mystyle :{
      background : "#e1214c"
    },
    description :{
     fontSize: "15px",
     margin: "20px 0 40px -22px"
    },
    titlecheckyouemail:{
    
    },
   
    propertyform : {
      background:"rgb(255, 255, 255)",
      width: "600px",
      maxWidth:"650px"
    
    },
    scopeIn:{
      margin:"0 0 0 0px",
      // border: "1px solid"
     
     
    }
  }));

  const classes = useStyles();

  const FinishedForm = (props) => {
    return (
      <div
        style={{
         
          maxWidth: "650px",
         
          position: "fixed",
          top: "50%",
          left: "50%",
          /* bring your own prefixes */
          transform: "translate(-50%, -50%)"
        }}
      >
          <div className={classes.propertyform} >
        <Grid  style={{ margin: "0 0 0 -33px"}}
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
          <Typography
                      component="h5"
                      variant="h5"
                      color="inherit"
                      noWrap
                    >
                    Please check your email for further instructions
                    </Typography>
       
          </Grid>
          <img  alt="email-sent" src="/images/email-sent.png" />
        </Grid>
        <Grid
          container
          spacing={8}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Button
              style={{ textTransform: "none"}}
              className={classes.buttons2}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                history.push("/login");
              }}
              variant="contained"
            >
              Back to login page
            </Button>
          </Grid>
        </Grid>
        </div>
        </div>
      
    );
  };

  const ForgotPassform = (props) => {
    return (
      <div
        style={{
          height: "580px",
          paddingTop: "100px",
          maxWidth: "650px",
        
          position: "fixed",
          top: "50%",
          left: "50%",
          /* bring your own prefixes */
          transform: "translate(-50%, -50%)"
       
        }}
      >
        <div className={classes.propertyform} >
        <form onSubmit={handleSubmit}>
          <Grid item xs={12} className={classes.scopeIn}
            container
            spacing={8}
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item xs={12} alignItems="right" >
              <div style={{ padding: "18px 0px 0px 38px", width:"100%"}}>
                <Grid
                  container
                  spacing={12}
                  xs={12}
                  md={12}
                  lg={12}
                  alignItems="right"
                >
                  <Grid item>
                    <Typography
                      component="h5"
                      variant="h5"
                      color="inherit"
                      noWrap
                    >
                      Forgot Password?
                    </Typography>
                    <Typography
                      component="h6"
                      variant="subtitle1"
                      color="inherit"
                      noWrap
                    >
                      <ol className={classes.description}>
                        <li>Enter your email address below.</li>
                        <li>Our system will send you a temporary link</li>
                        <li>Use the link to reset your password</li>
                      </ol>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={8}
                  xs={12}
                  alignItems="flex-end"
                  style={{ marginTop: "-50px"}}
                >
                  <Grid item md={true} sm={true} xs={true}>
                    <TextField
                      id="standard-basic"
                      label="Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      error={displayError}
                      helperText={
                        displayError ? "This is not a valid email address" : ""
                      }
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={12}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  alignItems="center"
                  justify="space-between"
                  style={{ margin: "0 auto" , padding: "35px 0 0px 165px"}}
                >
                  <Grid item>
                    <Button
                      style={{ textTransform: "none"}}
                      type="submit"
                      variant="contained"
                      color="primary"
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      Send Email
                    </Button>
                    <Button
                      style={{ textTransform: "none"  }}
                      className={classes.buttons}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/login");
                      }}
                      type="submit"
                      variant="contained"
                    >
                      Back to login page
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </form>
        </div>
      </div>
    );
  };
  return (
    <div  className={classes.mystyle}> 
    <div className={classes.root}>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Container maxWidth="sm">
        {sentStatus ? <FinishedForm /> : <ForgotPassform />}
      </Container>
    </div>
    </div>
  );
}
