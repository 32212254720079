import React from 'react';

import { Toolbar, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import MenuFont from 'src/components/Typography/MenuFont';
import MyButton from 'src/wrappers/Button';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginRight: 0,
  },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold',
  },
}));

const TableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, handleAdd } = props;

  return (
    <Toolbar className={classes.root}>
      {title && (
        <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
      {handleAdd && (
        <MyButton className={classes.addButton} onClick={handleAdd} startIcon={<AddCircleOutlineIcon />}>
          <MenuFont>ADD</MenuFont>
        </MyButton>
      )}
    </Toolbar>
  );
};

export default TableToolbar;
