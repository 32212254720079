import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import DeleteDialog from 'src/components/parts/DeleteDialog';
import EntityTable from 'src/components/parts/EntityTable';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import apiService from 'src/services/api.service';
import formErrorFactory from 'src/services/formError.factory';

const headCells = [
  {
    id: 'AccountNames',
    numeric: false,
    disablePadding: true,
    label: 'Account',
  },
  {
    id: 'AccountTypeNames',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'UserTypeName',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'Firstname',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'Lastname',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
  {
    id: 'AvatarExtension',
    numeric: false,
    disablePadding: false,
    label: 'Avatar',
  },
];

function Users(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [freeTextSearch, setFreeTextSearch] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
  });
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [reloadContext] = useContext(ReloadContext);
  const [userContext, setUserContext] = useContext(UserContext);
  const history = useHistory();

  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar, showErrorSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();

  useEffect(() => {
    startProgress();
    apiService
      .getUsers(page + 1, rowsPerPage, order, orderBy, freeTextSearch)
      .then((result) => {
        stopProgress();
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
      })
      .catch(catchApiError);
  }, [history, order, orderBy, rowsPerPage, page, dummyCounter, reloadContext, freeTextSearch]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      newArray.push([
        item.appId,
        item.accountNames,
        item.accountTypeNames,
        item.userTypeName,
        item.firstname,
        item.lastname,
        item.username,
        {
          avatar: item.avatarExtension
            ? `\\dynamic\\user\\images\\` + item.appId + '.' + item.avatarExtension + '?v=' + moment().format('YYYMMDDss')
            : '\\dynamic\\user\\images\\' + item.appId,
        },
      ]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
    } else {
      showErrorSnackBar("you don't have permission to delete users");
    }
  };

  const handleDelete = (id) => {
    apiService
      .deleteUser(id)
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          var errorString = formErrorFactory.createErrorText(result.errors);
          showErrorSnackBar(errorString);
        } else {
          setDummyCounter(dummyCounter + 1);
          showSuccessSnackBar('User was deleted successfully');
        }
      })
      .catch(catchApiError);
  };

  const handleAdd = () => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      history.push('/users/add');
    } else {
      showErrorSnackBar("you don't have permission to add users");
    }
  };

  const handleEdit = (event) => {
    if (userContext.isAdmin && userContext.permissions.canManageUsers) {
      history.push('/users/' + event.currentTarget.dataset.tag + '/edit');
    } else {
      showErrorSnackBar("you don't have permission to modify users");
    }
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleFilterChanged = (filter) => {
    setFreeTextSearch(filter.freeText);
  };

  return (
    <>
      {rows && (
        <EntityTable
          title="Users"
          headCells={headCells}
          totalNumberOfRows={totalNumberOfRows}
          rows={rows}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleAdd={userContext.isAdmin && userContext.permissions.canManageUsers && handleAdd}
          handleEdit={userContext.isAdmin && userContext.permissions.canManageUsers && handleEdit}
          deleteHandler={userContext.isAdmin && userContext.permissions.canManageUsers && handleDeleteRequest}
          handleFilterChanged={handleFilterChanged}
        />
      )}
      <DeleteDialog
        action={pendingDeleteAction}
        handleDelete={handleDelete}
        title="Delete User?"
        body="Are you sure you want to delete this user with all its data? Once deleted it cannot be undone."
      />
    </>
  );
}

export default Users;
