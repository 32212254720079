import React from 'react';
import PropTypes from 'prop-types';

export default function ContentFont(props) {
  const { children } = props;
  return <div className={'contentFont'}>{children}</div>;
}

ContentFont.propTypes = {
  children: PropTypes.node,
};
