import { makeStyles, MenuItem, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SmallSelect from '../../../wrappers/SmallSelect';
import SmallSearch from '../../../wrappers/SmallSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  searchBoxContainer: {
    display: 'flex',
  },
  searchInputControl: {
    maxWidth: '60%',
  },
}));

export default function FileSearchBox(props) {
  const { callbackSearchChanged } = props;
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [selectedTime, setSelectedTime] = useState(1);
  const now = new Date();
  const timeOptions = [
    { key: 1, name: 'Today', start: new Date(now.getFullYear(), now.getMonth(), now.getDate()), end: now },
    {
      key: 2,
      name: 'This week',
      start: new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)),
      end: now,
    },
    { key: 3, name: 'This month', start: new Date(now.getFullYear(), now.getMonth(), 1), end: now },
    { key: 4, name: 'Last month', start: new Date(now.getFullYear(), now.getMonth() - 1, 1), end: new Date(now.getFullYear(), now.getMonth(), 1) },
  ];

  useEffect(() => {
    if (callbackSearchChanged) {
      let selectedTimeOption = timeOptions.find((x) => x.key === selectedTime);
      callbackSearchChanged({
        searchText: searchText,
        start: selectedTimeOption.start,
        end: selectedTimeOption.end,
      });
    }
  }, [searchText, selectedTime]);

  const handleValueChange = (searchText) => {
    setSearchText(searchText);
    console.log(searchText);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  return (
    <Box className={classes.root}>
      <SmallSearch
        id="searchTextField"
        placeholder="Search for File Content"
        variant="outlined"
        value={searchText}
        className={classes.searchInputControl}
        onValueChange={handleValueChange}
      />
      <SmallSelect
        labelId="demo-simple-select-outlined-label"
        id="timeSelect"
        variant="outlined"
        value={selectedTime}
        onChange={handleTimeChange}
        className={classes.inputControl}
      >
        {timeOptions.map((timeOption) => {
          return (
            <MenuItem key={timeOption.key} value={timeOption.key}>
              {timeOption.name}
            </MenuItem>
          );
        })}
      </SmallSelect>
    </Box>
  );
}
