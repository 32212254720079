import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import LoadingOverlay from '../../parts/LoadingOverlay';
import apiService from '../../../services/api.service';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  news: {
    padding: theme.spacing(2),
    minHeight: 682,
  },
  hilightBox: {
    minHeight: 320,
    backgroundSize: 'cover',
    marginLeft: 12,
    marginRight: 12,
    color: 'White',
    '& a': {
      color: 'White',
    },
  },
  newsItems: {
    margin: 12,
    maxHeight: 250,
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    '& a': {
      color: theme.palette.news.titleColor,
    },
  },
  newsImageGrid: {
    paddingTop: theme.spacing(0.5),
  },
  newsImage: {
    backgroundSize: 'cover',
    height: 50,
  },
  contentGrid: {
    paddingLeft: theme.spacing(1),
  },
  authorAndPublishText: {
    color: '#AAAAAA',
  },
}));

function News(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [newsItems, setNewsItems] = useState([]);
  const [newsHighlight, setNewsHighlight] = useState();
  const [seeMoreUrl, setSeeMoreUr] = useState();

  useEffect(() => {
    setIsLoading(true);
    apiService
      .getWidgetNews(15)
      .then((result) => {
        setIsLoading(false);

        if (result.data && result.data.items.length > 0) {
          setSeeMoreUr(result.data.seeMoreUrl);

          let items = result.data.items;
          setNewsHighlight(items[0]);

          var newsItems = [];
          for (var i = 1; i < items.length; i++) {
            newsItems.push(items[i]);
          }
          setNewsItems(newsItems);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [history]);

  function getRelativeTimeText(dateTimeValue) {
    var momentTime = moment(dateTimeValue);
    return momentTime.fromNow();
  }

  return (
    <Paper className={classes.root}>
      <LoadingOverlay IsLoading={isLoading} />
      <Grid container direction="row" spacing={3} className={classes.news} alignContent="flex-start" alignItems="flex-start">
        {/* Header */}
        <Grid item xs={12} container justify="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="h6">News</Typography>
          </Grid>
          <Grid item>
            {seeMoreUrl ? (
              <a href={seeMoreUrl} target="_blank">
                <Typography variant="caption">See more</Typography>
              </a>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {/* Highligh */}
        {newsHighlight ? (
          <Grid
            item
            xs={12}
            container
            direction="column"
            justify="flex-end"
            alignItems="flex-start"
            className={classes.hilightBox}
            style={{ backgroundImage: 'url(' + newsHighlight.imageUrl + ')' }}
          >
            <Grid item>
              <a href={newsHighlight.sourceUrl} target="_blank">
                <Typography variant="h6">{newsHighlight.title}</Typography>
              </a>
            </Grid>
            <Grid item>
              <Typography variant="caption">{getRelativeTimeText(newsHighlight.publishedDate)}</Typography>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        {/* News items */}
        <Box className={classes.newsItems}>
          <Grid item xs={12} container direction="column" spacing={2}>
            {newsItems &&
              newsItems.map((item, index) => {
                return (
                  <Grid key={`news${index}`} item xs={12} container direction="row">
                    <Grid item xs={2} className={classes.newsImageGrid}>
                      <div className={classes.newsImage} style={{ backgroundImage: 'url(' + item.imageUrl + ')' }}></div>
                    </Grid>
                    <Grid item xs={10} className={classes.contentGrid} container direction="column" justify="flex-start" alignItems="flex-start">
                      <Grid item>
                        <a href={item.sourceUrl} target="_blank">
                          <Typography variant="subtitle2">{item.title}</Typography>
                        </a>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className={classes.authorAndPublishText}>
                          {getRelativeTimeText(item.publishedDate)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}

export default News;
