import React from 'react';
import StandardButton from '@material-ui/core/Button';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '200px',
    minWidth: '200px',
  },
}));

function ExportToPdfButton(props) {
  const classes = useStyles();
  let { className, ...otherProps } = props;
  let classNames = [classes.root, 'pdfButton', 'exclude', className].join(' ');

  return (
    <StandardButton variant="text" color="default" className={classNames} startIcon={<Avatar src={'/images/pdf-icon.png'} />} {...otherProps}>
      Export To PDF
    </StandardButton>
  );
}

export default ExportToPdfButton;
