import React, { useEffect, useState } from 'react';
import LineChart from './ChartTemplates/LineCharts.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function QtyPerMonthLineChart(props) {
  const [chartData, setChartData] = useState([]);
  const [total, setTotal] = useState('-');
  const [unit, setUnit] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { accountId, productCode } = props;
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      setIsLoading(true);
      apiService
        .getQtyPerShipmentProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.unit ? setUnit(result.data.unit) : setUnit('');
            result.data.total ? setTotal(result.data.total) : setTotal('-');

            setChartData(prepareStuff(result.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }
    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  const prepareStuff = (data) => {
    var tempArray = [];
    if (data && data.items) {
      data.items.forEach((item) => {
        let itemObj = {
          date: item.date,
          value: item.qty,
          labelPrefix: item.shipmentNumber,
          labelSuffix: data.unit ? '' : item.unit,
        };
        tempArray.push(itemObj);
      });
    }

    return tempArray;
  };

  return (
    <LineChart
      chartId="qtyLineChart"
      isLoading={isLoading}
      chartData={chartData}
      title="Quantity per Shipment"
      caption={`Total ${total.toLocaleString()} ${unit}`}
      tooltipValueFormat={`{0} ${unit}`}
    />
  );
}
