import React from 'react';
import { useParams } from 'react-router';

import { Grid } from '@material-ui/core';

import CustomerAccountEntry from './CustomerAccountEntry';

export default function CustomerAccountEntryContainer(props) {
  let { id } = useParams();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomerAccountEntry customerAccountId={id} />
      </Grid>
    </Grid>
  );
}
