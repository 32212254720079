import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import React, { useEffect, useRef, useState } from 'react';

import { Box, Hidden, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import GridContainer from 'src/components/Grid/GridContainer.js';
import GridItem from 'src/components/Grid/GridItem';
import ApplicationMenus from 'src/components/layouts/ApplicationMenus';
import TopRedBar from 'src/components/layouts/TopRedBar';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import apiService from 'src/services/api.service';
import PDFModule from 'src/services/pdf.service';
import { darkTheme } from 'src/themes/darkTheme';
import { lightThemeAdmin } from 'src/themes/lightTheme';
import ExportToPdfButton from 'src/wrappers/ExportToPdfButton';

import DataTableFilter from '../parts/DataTableFilter/DataTableFilter';
import AirFreightKGSPerSupplierChart from './Dashboard/AirFreightKGSPerSupplierChart';
import AvgTransitTimePerPortPairChart from './Dashboard/AvgTransitTimePerPortPairChart';
import CASAFreightSpendsPieChart from './Dashboard/CASAFreightSpendsPieChart';
import CBMPerSupplier from './Dashboard/CBMPerSupplierChart';
import CustomCharges from './Dashboard/CustomChargesChart';
import CustomsAndDutyPerSupplierChart from './Dashboard/CustomsAndDutyPerSupplierChart';
import FreightSpendsPieChart from './Dashboard/FreightSpendsPieChart';
import IcontermPerPortPairChart from './Dashboard/IcontermPerPortPairChart';
import IncontermCharts from './Dashboard/IncontermCharts';
import JobTypeSupplierChart from './Dashboard/JobTypePerSupplierChart';
import JobTypes from './Dashboard/JobTypesChart';
import LCLVolumePerPortPair from './Dashboard/LCLVolumePerPortPair';
import OrderDelaysChart from './Dashboard/OrderDelaysChart';
import ShipmentDelaysChart from './Dashboard/ShipmentDelaysChart';
import TEUPerPortPair from './Dashboard/TEUPerPortPair';
import TotalCBMCard from './Dashboard/TotalCBMCard';
import TotalDisbursementChargesChart from './Dashboard/TotalDisbursementCharges';
import TotalFCLTEUCard from './Dashboard/TotalFCLTEUCard';
import TotalShipmentCard from './Dashboard/TotalShipmentCard';
import VolumePerCountryChart from './Dashboard/VolumePerCountryChart';
import WeightPerPortal from './Dashboard/WeightPerPortalChart';
import DashboardChartGridItem from './Dashboard/parts/DashboardChartGridItem';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: 0,
    maxWidth: '1600px',
  },
  spacer: {
    flex: '1 1 auto',
  },
  footer: {
    margin: 0,
    padding: 0,
    maxWidth: '100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  noPeriodMessage: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function Dashboard(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;

  const childRef = useRef();
  const classes = useStyles();
  const customerAccountFeatures = useCustomerAccountFeatures();
  const [filterComponentData, setFilterComponentData] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [baseQueryString, setBaseQueryString] = useState();
  const [topBoxSize, setTopBoxSize] = useState({ sm: 6, md: 6, lg: 3 });
  const [accountId, setAccountId] = useState();
  const [overviewSettings, setOverviewSettings] = useState();
  const [chartBoxSizes, setChartBoxSizes] = useState({});

  const generatePdf = () => {
    setOpenBackdrop(true);
    am4core.unuseTheme(am4themes_animated);
    var returnValue = childRef.current
      .preparePDF()
      .then(() => {
        const element = document.querySelector('#dashboardPrint');
        const options = {
          filename: 'Report-' + new Date().toDateString() + '.pdf',
          excludeClassNames: ['exclude'],
          company: filterComponentData.companyName,
          period: filterComponentData.periodName,
          suppliers: filterComponentData.suppliers ? filterComponentData.supplierName : 'ALL',
        };

        return PDFModule(element, options, () => {
          am4core.useTheme(am4themes_animated);
          setOpenBackdrop(false);
        });
      })
      .then(() => {
        childRef.current.finishedPDF();
      });
  };

  useEffect(() => {
    const equalsTopBoxSize = (topBoxSize, compareWith) => {
      return topBoxSize.sm != compareWith.sm && topBoxSize.md != compareWith.md && topBoxSize.lg != compareWith.lg;
    };

    if (customerAccountFeatures) {
      let newSize;
      if (customerAccountFeatures.enabledCommercialInvoices) {
        newSize = { sm: 6, md: 6, lg: 3 };
      } else {
        newSize = { sm: 12, md: 4, lg: 4 };
      }

      if (!equalsTopBoxSize(topBoxSize, newSize)) {
        setTopBoxSize(newSize);
      }
    }
  }, [customerAccountFeatures]);

  useEffect(() => {
    const pieChartNames = ['CustomCharges', 'CASAFreightSpendsPieChart', 'FreightSpendsPieChart', 'JobTypes', 'ShipmentDelay', 'OrderDelay'];

    const isHidden = (chartName) => {
      if (overviewSettings) {
        if (overviewSettings.hiddenCharts && overviewSettings.hiddenCharts.length > 0) {
          let hiddenChartIndex = overviewSettings.hiddenCharts.findIndex((hiddenChart) => hiddenChart.toLowerCase() === chartName.toLowerCase());
          return hiddenChartIndex > -1;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    const getDisplayPieChartNames = () => {
      let displayChartNames = [];
      pieChartNames.forEach((x) => {
        if (!isHidden(x)) {
          displayChartNames.push(x);
        }
      });
      return displayChartNames;
    };

    const getPieChartsLayout = (totalDisplayCharts, currentChartIndex) => {
      switch (totalDisplayCharts) {
        case 6:
          return { xs: 12, sm: 6, md: 4, lg: 4 };
        case 5:
          if (currentChartIndex < 3) {
            return { xs: 12, sm: 6, md: 4, lg: 4 };
          } else {
            return { xs: 12, sm: true, md: 6, lg: 6 };
          }
        case 4:
          return { xs: 12, sm: 12, md: 6, lg: 3 };
        case 3:
          if (currentChartIndex < 2) {
            return { xs: 12, sm: 6, md: 4 };
          } else {
            return { xs: 12, sm: 12, md: 4 };
          }
        case 2:
          return { xs: 12, sm: 12, md: 6 };
        default:
          return { xs: 12 };
      }
    };

    if (overviewSettings) {
      let tempChartBoxSizes = {};
      let displayChartNames = getDisplayPieChartNames();
      displayChartNames.map((chartName, index) => {
        tempChartBoxSizes[chartName] = getPieChartsLayout(displayChartNames.length, index);
      });
      console.log(tempChartBoxSizes);
      setChartBoxSizes(tempChartBoxSizes);
    }
  }, [overviewSettings]);

  useEffect(() => {
    const loadOverviewSettings = async () => {
      let result = await apiService.getOverviewSettings(accountId);
      setOverviewSettings(result.data);
    };

    // setOverviewSettings(undefined);
    if (accountId) {
      loadOverviewSettings();
    }
  }, [accountId]);

  useEffect(() => {
    if (filterComponentData && filterComponentData.accountId) {
      setAccountId(filterComponentData.accountId);
    }
  }, [filterComponentData]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CssBaseline />
        <div className={classes.root}>
          <ApplicationMenus />
          <main className={classes.content}>
            <TopRedBar />
            <Container id="dashboardPrint" maxWidth="lg" className={classes.container}>
              <GridContainer>
                <Hidden lgUp>
                  <GridItem xs={12} sm={12} md={12}>
                    <ExportToPdfButton onClick={generatePdf}></ExportToPdfButton>
                  </GridItem>
                </Hidden>
                <GridItem xs={12} sm={12} md={12} lg={9} style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                  <DataTableFilter
                    sendFilterComponentData={setFilterComponentData}
                    showSuppliers={true}
                    showFreeText={false}
                    sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                  />
                </GridItem>
                <Hidden mdDown>
                  <GridItem xs={12} sm={12} md={12} lg={3} className="align-self-center">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      <ExportToPdfButton onClick={generatePdf}></ExportToPdfButton>
                    </div>
                  </GridItem>
                </Hidden>
                <div className={classes.spacer} />
                <GridItem xs={12} sm={12} md={12}>
                  <Divider />
                </GridItem>
              </GridContainer>
              {filterComponentData && (
                <>
                  {filterComponentData.start ? (
                    <>
                      <GridContainer style={{ paddingTop: '15px' }}>
                        <DashboardChartGridItem xs={12} {...topBoxSize}>
                          <TotalShipmentCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                        <DashboardChartGridItem xs={12} {...topBoxSize}>
                          <TotalFCLTEUCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                        <DashboardChartGridItem xs={12} {...topBoxSize}>
                          <TotalCBMCard FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                        {customerAccountFeatures.enabledCommercialInvoices && (
                          <DashboardChartGridItem xs={12} {...topBoxSize}>
                            <TotalDisbursementChargesChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                      </GridContainer>
                      <GridContainer>
                        {chartBoxSizes.CustomCharges && (
                          <DashboardChartGridItem {...chartBoxSizes.CustomCharges}>
                            <CustomCharges FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                        {chartBoxSizes.CASAFreightSpendsPieChart && (
                          <DashboardChartGridItem {...chartBoxSizes.CASAFreightSpendsPieChart}>
                            <CASAFreightSpendsPieChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                        {chartBoxSizes.FreightSpendsPieChart && (
                          <DashboardChartGridItem {...chartBoxSizes.FreightSpendsPieChart}>
                            <FreightSpendsPieChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                        {chartBoxSizes.JobTypes && (
                          <DashboardChartGridItem {...chartBoxSizes.JobTypes}>
                            <JobTypes FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                        {chartBoxSizes.ShipmentDelay && (
                          <DashboardChartGridItem {...chartBoxSizes.ShipmentDelay}>
                            <ShipmentDelaysChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                        {chartBoxSizes.OrderDelay && (
                          <DashboardChartGridItem {...chartBoxSizes.OrderDelay}>
                            <OrderDelaysChart FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        )}
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12}>
                          <AirFreightKGSPerSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      {customerAccountFeatures.enabledCommercialInvoices && (
                        <GridContainer>
                          <DashboardChartGridItem xs={12} sm={12} md={12}>
                            <CustomsAndDutyPerSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                          </DashboardChartGridItem>
                        </GridContainer>
                      )}
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12} lg={12}>
                          <IncontermCharts FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12}>
                          <AvgTransitTimePerPortPairChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12}>
                          <JobTypeSupplierChart FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12}>
                          <CBMPerSupplier FilterComponentData={filterComponentData} showDataItems="10" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                          <WeightPerPortal FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                        <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                          <LCLVolumePerPortPair FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                          <TEUPerPortPair FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                        <DashboardChartGridItem xs={12} sm={12} md={12} lg={6}>
                          <IcontermPerPortPairChart FilterComponentData={filterComponentData} showDataItems="5" baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                      <GridContainer>
                        <DashboardChartGridItem xs={12} sm={12} md={12}>
                          <VolumePerCountryChart ref={childRef} FilterComponentData={filterComponentData} baseQueryString={baseQueryString} />
                        </DashboardChartGridItem>
                      </GridContainer>
                    </>
                  ) : (
                    <GridContainer>
                      <Box className={classes.noPeriodMessage}>Please select start and end month.</Box>
                    </GridContainer>
                  )}
                </>
              )}
            </Container>
          </main>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Dashboard;
