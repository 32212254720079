import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import Card from '../../../components//Card/Card.js';
import CardFooter from '../../../components//Card/CardFooter.js';
import CardHeader from '../../../components//Card/CardHeader.js';
import CardIcon from '../../../components//Card/CardIcon.js';
import apiService from '../../../services/api.service';
import helperService from '../../../services/help.service';
import TitleFont from '../../Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
  },
  cardCategory: {
    color: 'rgba(0, 0, 0, 0.87)',
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

function TotalShipmentCard(props) {
  const classes = useStyles();
  const { FilterComponentData, baseQueryString } = props;
  const history = useHistory();
  const [totalShipments, setTotalShipments] = useState();

  function getDetailsPageUrl() {
    return '/shipments?' + baseQueryString;
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      apiService
        .getReportShipment(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            setTotalShipments(result.data.count);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (!isCancelled) {
            setTotalShipments(null);
          }
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else setTotalShipments(null);

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);
  return (
    <Card>
      <CardHeader color="warning" stats icon>
        <CardIcon color="warning">
          <LocalShippingIcon />
        </CardIcon>
        <TitleFont>
          <p className={classes.cardCategory}>
            <Link to={getDetailsPageUrl()}>Total Shipments</Link>
          </p>
        </TitleFont>
        <h3 className={classes.cardTitle}>{totalShipments ? helperService.thousands_separators(totalShipments) : '-'}</h3>
      </CardHeader>
      <CardFooter stats>
        <div style={{ height: '15px' }} className={classes.stats}>
          {' '}
        </div>
      </CardFooter>
    </Card>
  );
}

export default TotalShipmentCard;
