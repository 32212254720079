import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { StatusBox } from './components/StatusBox';
import useShipmentBookingEntry from './hooks/useShipmentBookingEntry';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  slashBox: {
    margin: '0 16px',
    marginTop: 3,
  },
  slash: {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'block',
    transform: 'rotate(15deg) scale(var(--ggs,1))',
    width: 3,
    height: 48,
    background: 'currentColor',
    borderRadius: 3,
  },
}));

export const StatesSection = (props) => {
  const [statusBoxes, setStatusBoxes] = useState();

  const { states } = useShipmentBookingEntry();

  useEffect(() => {
    if (states) {
      let viewModels = states.map((x) => {
        let caption = null;
        if (x.date) {
          var momentDate = moment(x.date);
          caption = momentDate.isValid() ? `date: ${momentDate.format('l')}` : null;
        }

        return { title: x.status, caption: caption };
      });

      setStatusBoxes(viewModels);
    } else {
      setStatusBoxes(null);
    }
  }, [states]);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {statusBoxes &&
        statusBoxes.map((x, index) => {
          let hasNext = statusBoxes.length - 1 > index;
          return (
            <>
              <StatusBox key={`statusBox${index}`} title={x.title} caption={x.caption} />
              {hasNext && (
                <Box key={`slash${index}`} className={classes.slashBox}>
                  <i className={classes.slash}></i>
                </Box>
              )}
            </>
          );
        })}
    </div>
  );
};
