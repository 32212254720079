import React, { useEffect, useState } from 'react';

import { TextField, Typography, makeStyles, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { AddIconButton } from '../components/AddIconButton';
import useShipmentBookingEntry from '../hooks/useShipmentBookingEntry';

const useStyles = makeStyles((theme) => ({
  error: {
    color: '#f44336',
  },
  warning: {
    color: theme.palette.inputControl.warning,
  },
}));

const StyledAutocomplete = withStyles({
  root: {
    maxWidth: 300,
  },
  inputRoot: {
    backgroundColor: 'white',
  },
})(Autocomplete);

export default function AddOrderControl(props) {
  const classes = useStyles();
  const [orderNumber, setOrderNumber] = useState();
  const { purchaseOrderSelectItems, addPurchaseOrder, messages } = useShipmentBookingEntry();
  const [messageStatusClass, setMessageStatusClass] = useState('');

  useEffect(() => {
    if (messages) {
      let status = messages[`Orders`]?.status;
      if (status == 'warning') {
        setMessageStatusClass(classes.warning);
      } else if (status == 'error') {
        setMessageStatusClass(classes.error);
      }
    } else {
      setMessageStatusClass('');
    }
  }, [messages]);

  return (
    <>
      <span>Order Number:</span>
      <StyledAutocomplete
        freeSolo
        fullWidth
        onInputChange={(inout, value) => {
          setOrderNumber(value);
        }}
        options={purchaseOrderSelectItems.map((selectItem) => selectItem.orderNumber)}
        renderInput={(params) => <TextField {...params}  margin="dense" variant="outlined" />}
        renderOption={(option) => <Typography noWrap>{option}</Typography>}
      />
      <AddIconButton
        onClick={() => {
          addPurchaseOrder(orderNumber);
        }}
      />
      <span className={messageStatusClass}>{messages[`Orders`]?.text}</span>
    </>
  );
}