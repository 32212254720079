import React, { useEffect, useCallback, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../../Grid/GridItem';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import { useState } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { ThemeProvider } from '@material-ui/styles';
import { lightTheme } from '../../../themes/lightTheme';
import MenuFont from '../../Typography/MenuFont';
import ContentFont from '../../Typography/ContentFont';
import ChartLogo from '../../parts/ChartLogo';

const useStyles = makeStyles((theme) => ({}));

const VolumePerCountryChart = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    showAlert() {
      alert('Child function called');
    },
    preparePDF() {
      return convertToImage();
    },
    finishedPDF() {
      convertBackToSVG();
    },
  }));
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const { FilterComponentData } = props;
  const history = useHistory();
  const [worldFilter, setWorldFilter] = React.useState(1);

  const chartDataArray = [];
  const originColor = lightTheme.chart.colors[0];
  const destinationColor = lightTheme.chart.colors[1];

  var chart;

  const handleChangeWorldFilter = (event, newFilter) => {
    if (newFilter !== null) {
      setWorldFilter(newFilter);
    }
  };

  const convertToImage = () => {
    chart.exporting.backgroundColor = am4core.color('white', 0);
    return chart.exporting.getImage('png').then((data) => {
      var img = new Image();
      img.src = data;
      document.querySelector('#VolumePerCountryChart').style.display = 'none';
      document.querySelector('#pdfExportDiv').appendChild(img);
    });
  };

  const convertBackToSVG = () => {
    document.querySelector('#VolumePerCountryChart').style.display = 'block';
    var node = document.querySelector('#pdfExportDiv');
    while (node.lastElementChild) {
      node.removeChild(node.lastElementChild);
    }
  };

  useLayoutEffect(() => {
    // am4core.useTheme(am4themes_animated);
    chart = am4core.create('VolumePerCountryChart', am4maps.MapChart);
    var mapData = worldFilter == 1 ? chartData.filter((x) => x.color == originColor) : chartData.filter((x) => x.color == destinationColor);
    chart.logo.disabled = true;
    var title = chart.titles.create();
    title.textAlign = 'middle';

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.exclude = ['AQ'];
    polygonSeries.useGeodata = true;
    polygonSeries.nonScalingStroke = true;
    polygonSeries.strokeWidth = 0.5;
    polygonSeries.calculateVisualCenter = true;

    var imageSeries = chart.series.push(new am4maps.MapImageSeries());
    imageSeries.data = mapData;
    imageSeries.dataFields.value = 'value';

    var imageTemplate = imageSeries.mapImages.template;
    imageTemplate.nonScaling = true;

    var circle = imageTemplate.createChild(am4core.Circle);
    circle.fillOpacity = 0.7;
    circle.propertyFields.fill = 'color';
    circle.tooltipText = '{name}: [bold]{value} m3[/]';

    imageSeries.heatRules.push({
      target: circle,
      property: 'radius',
      min: 4,
      max: 30,
      dataField: 'value',
    });

    imageTemplate.adapter.add('latitude', function (latitude, target) {
      var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
      if (polygon) {
        return polygon.visualLatitude;
      }
      return latitude;
    });

    imageTemplate.adapter.add('longitude', function (longitude, target) {
      var polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
      if (polygon) {
        return polygon.visualLongitude;
      }
      return longitude;
    });

    return () => {
      //Issue: Cannot export to pdf if dispose
      //chart.dispose();
    };
  }, [chartDataArray, worldFilter]);

  const prepareChartData = useCallback(
    (data) => {
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          var obj = {
            id: data[i].country,
            name: data[i].countryFullName,
            value: data[i].volume,
            color: data[i].direction == 'ORIGIN' ? originColor : destinationColor,
          };
          chartDataArray.push(obj);
        }
        setChartData(chartDataArray);
      } else setChartData([]);
    },
    [chartDataArray]
  );

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      apiService
        .getVolumePerCountryReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            prepareChartData(result.data.perCountryVolumeReportItems);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  return (
    <ThemeProvider theme={lightTheme}>
      <Card chart>
        <CardHeader>
          <div>
            <ContentFont>
              <div id="VolumePerCountryChart" style={{ width: '100%', height: '600px' }}></div>
              <div id="pdfExportDiv"></div>
            </ContentFont>
            <div
              style={{
                position: 'absolute',
                zIndex: '90',
                top: '20px',
                right: '20px',
              }}
            >
              <ToggleButtonGroup value={worldFilter.toString()} exclusive onChange={handleChangeWorldFilter} aria-label="world filter">
                <ToggleButton value="1" aria-label="Origin">
                  <MenuFont>Origin</MenuFont>
                </ToggleButton>
                <ToggleButton value="2" aria-label="Destination">
                  <MenuFont>Destination</MenuFont>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </CardHeader>
        <CardBody className={'dashboard-chart-title-area'}>
          <h4>Volume Per Country</h4>
          <ChartLogo />
        </CardBody>
      </Card>
    </ThemeProvider>
  );
});

export default VolumePerCountryChart;
