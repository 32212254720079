import React from 'react';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { withStyles } from '@material-ui/styles';

export const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primaryButton.backgroundColor,
    '&:hover': {
      color: theme.palette.primaryButton.hoverBackgroundColor,
    },
    height: 36,
    width: 36,
  },
}))((props) => (
  <IconButton aria-label="delete" {...props}>
    <DeleteIcon />
  </IconButton>
));
