import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flexGrow: 1,
  },
  select: {
    fontFamily: theme.font.menu,
  },
}));

export default function PeriodsControl(props) {
  const { id, title, periods, disabled, limitPeriodValue, selectedPeriodValue, handlePeriodChange, disablePeriodMenuItem } = props;

  const classes = useStyles();
  let labelId = id + '-label';

  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'} disabled={disabled}>
      <InputLabel id={labelId}>
        <TitleFont>{title}</TitleFont>
      </InputLabel>
      <Select
        className={classes.select}
        labelId={labelId}
        id={id}
        label={title}
        value={(periods && selectedPeriodValue) || ''}
        onChange={handlePeriodChange && handlePeriodChange}
      >
        <MenuItem value="" key={'none'}>
          <em>None</em>
        </MenuItem>
        {periods &&
          periods.map((item) => {
            return (
              <MenuItem key={`${id}${item.id}`} value={item.id} disabled={disablePeriodMenuItem && disablePeriodMenuItem(item, limitPeriodValue)}>
                {item.displayName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
