import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import EntityListFrame from 'src/components/layouts/EntityListFrame';

import ShipmentBookingEntry from './ShipmentBookingEntry';
import { ShipmentBookingEntryProvider } from './hooks/useShipmentBookingEntry';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

export function ShipmentBookingEntryContainer(props) {
  const classes = useStyles();

  return (
    <ShipmentBookingEntryProvider>
      <EntityListFrame inPaper={true}>
        <Box className={classes.boxContainer}>
          <ShipmentBookingEntry />
        </Box>
      </EntityListFrame>
    </ShipmentBookingEntryProvider>
  );
}
