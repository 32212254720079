import React, { createContext, useCallback, useContext, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { darkTheme } from 'src/themes/darkTheme';
import { lightThemeAdmin } from 'src/themes/lightTheme';

const GlobalLoadingBarContext = createContext({
  startProgress: () => {},
  stopProgress: () => {},
});

export const GlobalLoadingBarProvider = ({ children }) => {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const progressRef = useRef();

  const startProgress = useCallback(() => {
    if (progressRef && progressRef.current) progressRef.current.continuousStart();
  }, []);

  const stopProgress = useCallback(() => {
    if (progressRef && progressRef.current) progressRef.current.complete();
  }, []);

  const value = {
    startProgress,
    stopProgress,
  };

  return (
    <GlobalLoadingBarContext.Provider value={value}>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      {children}
    </GlobalLoadingBarContext.Provider>
  );
};

export default function useGlobalLoadingBar() {
  const context = useContext(GlobalLoadingBarContext);

  if (context === undefined) {
    throw new Error('useGlobalLoadingBar must be used within GlobalLoadingBarContext');
  }
  
  return context;
}
