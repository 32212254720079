// confirmemail
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import queryString from "query-string";
import apiService from "../../services/api.service";
import { useHistory  } from 'react-router'

export default function ConfirmEmail({ match, location }) {
  const [confirmResult, setConfirmResult] = useState(false);
  const parsedqs = queryString.parse(location.search);
  const history = useHistory();

  useEffect(() => {
      apiService.confirmEmail(parsedqs.email, parsedqs.code).then(() => {
          setConfirmResult(true);
      }).catch((error) => {
          setConfirmResult(false);
      })
    },[parsedqs.email, parsedqs.code])

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    alert: {
      margin: theme.spacing(1),
    },
  }));

  const handleClick = (event) =>
  {
        history.push("/login");
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {confirmResult ? (
          <Alert
            variant="outlined"
            severity="success"
            onClick={handleClick}
            className={classes.alert}
            action={
                <Button color="inherit" size="small">
                  Login
                </Button>}
          >
            Your email is confirm, go to the login page and login.
          </Alert>
        ) : (
          <Alert variant="outlined" severity="error" className={classes.alert}>
            The email confirmation failed, please contact support
          </Alert>
        )}
      </Container>
    </div>
  );
}

