import { useContext, useEffect, useState } from 'react';

import { UserContext } from 'src/contexts/UserContext';

let defaultPermissions = {
  isAdmin: false,
  hasFullPermissions: false,
  canManageUsers: false,
  canReadUsers: false,
  canManageCustomerAccounts: false,
  canReadCustomerAccounts: false,
  canManageVendorAccounts: false,
  canReadVendorAccounts: false,
  canManageExternalApplications: false,
  canReadExternalApplications: false,
  canReadFiles: false,
  canManageShipmentBookings: false,
  canReadShipmentBookings: false,
  canManageSuppliers: false,
  canReadSuppliers: false,
};

const usePermissions = () => {
  const [userContext] = useContext(UserContext);
  const [permissions, setPermissions] = useState(defaultPermissions);

  useEffect(() => {
    if (userContext) {
      let permissions = {
        isAdmin: userContext.isAdmin,
        hasFullPermissions: userContext.hasFullPermissions,
        canManageUsers: userContext.permissions?.canManageUsers,
        canReadUsers: userContext.permissions?.canReadUsers,
        canManageCustomerAccounts: userContext.permissions?.canManageCustomerAccounts,
        canReadCustomerAccounts: userContext.permissions?.canReadCustomerAccounts,
        canManageVendorAccounts: userContext.permissions?.canManageVendorAccounts,
        canReadVendorAccounts: userContext.permissions?.canReadVendorAccounts,
        canManageExternalApplications: userContext.permissions?.canManageExternalApplications,
        canReadExternalApplications: userContext.permissions?.canReadExternalApplications,
        canReadFiles: userContext.permissions?.canReadFiles,
        canManageShipmentBookings: userContext.permissions?.canManageShipmentBookings,
        canReadShipmentBookings: userContext.permissions?.canReadShipmentBookings,
        canManageSuppliers: userContext.permissions?.canManageSuppliers,
        canReadSuppliers: userContext.permissions?.canReadSuppliers,
      };

      setPermissions(permissions);
    } else {
      setPermissions(defaultPermissions);
    }
  }, [userContext]);

  return permissions;
};

export default usePermissions;
