import React from 'react';

import { Box, Card, CardContent, Grid, Hidden, Typography, makeStyles } from '@material-ui/core';

import NumberFormatCustom from 'src/wrappers/NumberFormatCustom';

import EntryDatePicker from './components/EntryDatePicker';
import { EntryFormSelect } from './components/EntryFormSelect';
import { EntryTextField } from './components/EntryTextField';
import useShipmentBookingEntry from './hooks/useShipmentBookingEntry';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
  },
  alignBottomGrid: {
    display: 'flex',
    alignItems: 'end',
  },
  containerBox: {
    borderRadius: 5,
    backgroundColor: '#efefef',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const ShippingDetailsEntrySection = (props) => {
  const classes = useStyles();

  const {
    messages,
    containerVolume,
    containerSize,
    containerSizeName,
    cargoReadyDate,
    incoterms,
    marksAndNumbers,
    goodsDescription,
    containerSizeSelectionItems,
    readOnly,
    setContainerVolume,
    setContainerSize,
    setCargoReadyDate,
    setIncoterms,
    setMarksAndNumbers,
    setGoodsDescription,
  } = useShipmentBookingEntry();

  const onSelectionChange = (selectedId) => {
    let selectedContainerSize = containerSizeSelectionItems.find((x) => x.id == selectedId);
    setContainerSize(selectedContainerSize?.id);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Shipping Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.containerBox}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="overline">Container</Typography>
                </Grid>
                <Grid item xs={6}>
                  <EntryTextField
                    label="Volume"
                    name="volume"
                    required
                    value={containerVolume}
                    onValueChange={(value) => setContainerVolume(value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    helperTextStatus={messages[`ContainerVolume`]?.status || messages[`ContainerCount`]?.status}
                    helperText={messages[`ContainerVolume`]?.text || messages[`ContainerCount`]?.text}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  {readOnly ? (
                    <EntryTextField label="Size" name="containerSize" required value={containerSizeName} readOnly={readOnly} />
                  ) : (
                    <EntryFormSelect
                      id={'containerSize'}
                      items={containerSizeSelectionItems}
                      key="companySelect"
                      label={'Size'}
                      onSelectionChange={onSelectionChange}
                      required={true}
                      helperTextStatus={messages[`ContainerSize`]?.status}
                      helperText={messages[`ContainerSize`]?.text}
                      value={containerSize}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1}>
            <Hidden smDown>
              <Grid item xs={12}>
                <Typography variant="overline">&nbsp;</Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={6}>
              {readOnly ? (
                <EntryTextField label="Cargo Ready Date" name="cargoReadyDate" required value={moment(cargoReadyDate).format('l')} readOnly={readOnly} />
              ) : (
                <EntryDatePicker
                  required
                  label="Cargo Ready Date"
                  value={cargoReadyDate}
                  onChange={(date) => setCargoReadyDate(date)}
                  helperTextStatus={messages[`CargoReadyDate`]?.status}
                  helperText={messages[`CargoReadyDate`]?.text}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <EntryTextField
                label="Incoterms"
                name="incoterms"
                helperTextStatus={messages[`Incoterms`]?.status}
                helperText={messages[`Incoterms`]?.text}
                value={incoterms}
                onValueChange={(value) => setIncoterms(value)}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField
              multiline
              rows={4}
              label="Marks and Numbers"
              name="marksAndNumbers"
              value={marksAndNumbers}
              onValueChange={(value) => setMarksAndNumbers(value)}
              helperTextStatus={messages[`MarksAndNumbers`]?.status}
              helperText={messages[`MarksAndNumbers`]?.text}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField
              multiline
              rows={4}
              label="Goods Description"
              name="goodsDescription"
              value={goodsDescription}
              onValueChange={(value) => setGoodsDescription(value)}
              helperTextStatus={messages[`GoodsDescription`]?.status}
              helperText={messages[`GoodsDescription`]?.text}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
