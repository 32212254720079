import axios from 'axios';

import authService from './auth.service';

class apiHelperService {
  getAuthClient = () => {
    let storedUser = JSON.parse(sessionStorage.getItem('user'));
    const defaultOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let instance = axios.create(defaultOptions);

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.request.use(function (config) {
      config.headers.Authorization = storedUser ? `Bearer ${storedUser.accessToken}` : '';
      return config;
    });

    return instance;
  };

  getAnonymousClient = () => {
    const defaultOptions = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let instance = axios.create(defaultOptions);

    return instance;
  };

  internalAPICall = (call, httpMethod, params) => {
    return call
      .then((response) => response.data)
      .catch((err) => {
        if (err.response.status === 401) {
          let storedUser = JSON.parse(sessionStorage.getItem('user'));
          if (storedUser && storedUser.refreshToken) {
            return new Promise((resolve, reject) => {
              authService.loginWithRefreshToken(storedUser.refreshToken).then(() => {
                let call;
                if (httpMethod === 'post') {
                  call = this.getAuthClient().post(...params);
                } else if (httpMethod === 'put') {
                  call = this.getAuthClient().put(...params);
                } else if (httpMethod === 'patch') {
                  call = this.getAuthClient().patch(...params);
                } else if (httpMethod === 'delete') {
                  call = this.getAuthClient().delete(...params);
                } else {
                  call = this.getAuthClient().get(...params);
                }

                resolve(
                  call
                    .then((response) => response.data)
                    .catch((err) => {
                      throw err;
                    })
                );
              });
            });
          }
        } else {
          console.log('error message was not 401 it was ' + err.response.status + ' .');
          throw err;
        }
      });
  };

  getCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().get(...params), 'get', params);
  }

  postCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().post(...params), 'post', params);
  }

  putCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().put(...params), 'put', params);
  }

  patchCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().patch(...params), 'patch', params);
  }

  deleteCallAPIWrapper(...params) {
    return this.internalAPICall(this.getAuthClient().delete(...params), 'delete', params);
  }

  anonymousGet(...params){
    return this.getAnonymousClient()
    .get(...params)
    .then((response) => response.data);
  }
}

export default new apiHelperService();
