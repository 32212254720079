import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';

import { UserContext } from 'src/contexts/UserContext';

const OneSignalContext = createContext({
  initialized: false,
  validDomain: null,
  notificationPermission: '',
  requestEnableWebPushNotificationResult: {
    enabled: false,
    requestedDate: null,
  },
  requestEnableWebPushNotification: () => {},
});

export const OneSignalProvider = ({ children }) => {
  const [userContext] = useContext(UserContext);
  const [initialized, setInitialized] = useState(false);
  const [validDomain, setValidDomain] = useState();
  const [notificationPermission, setNotificationPermission] = useState();
  const [requestEnableWebPushNotificationResult, setRequestEnableWebPushNotificationResult] = useState();

  useEffect(() => {
    const initialize = async (userId) => {
      try {
        await OneSignal.init({
          appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
          autoResubscribe: true,
        });

        await OneSignal.setSubscription(true);

        await OneSignal.setExternalUserId(userId);

        setInitialized(true);
        setNotificationPermission(Notification.permission);

        console.log('OneSignal initialized');
      } catch (ex) {
        console.log('Failed initialize OneSignal', ex);
      }
    };

    if (userContext && userContext.id) {
      let validDomain = window.location.origin == process.env.REACT_APP_ONESIGNAL_SITE_URL;
      setValidDomain(validDomain);
      if (validDomain) {
        initialize(userContext.id);
      }
    }
  }, [userContext]);

  const requestEnableWebPushNotification = useCallback(async () => {
    console.log('requestEnableWebPushNotification');
    if (initialized && validDomain) {
      if (Notification.permission !== 'granted') {
        await OneSignal.showNativePrompt();
        setNotificationPermission(Notification.permission);

        if (Notification.permission === 'granted') {
          setRequestEnableWebPushNotificationResult({ enabled: true, requestedDate: new Date() });
        } else {
          setRequestEnableWebPushNotificationResult({ enabled: false, requestedDate: new Date() });
        }
      } else {
        setRequestEnableWebPushNotificationResult({ enabled: true, requestedDate: new Date() });
      }
    } else {
      setRequestEnableWebPushNotificationResult({ enabled: false, requestedDate: new Date() });
    }
  }, [initialized, validDomain]);

  let contextValue = {
    initialized: initialized,
    validDomain: validDomain,
    notificationPermission: notificationPermission,
    requestEnableWebPushNotificationResult: requestEnableWebPushNotificationResult,
    requestEnableWebPushNotification,
  };

  return <OneSignalContext.Provider value={contextValue}>{children}</OneSignalContext.Provider>;
};

const useOneSignal = () => {
  const context = useContext(OneSignalContext);

  if (context === undefined) {
    throw new Error('useOneSignal must be used within OneSignalContext');
  }

  return context;
};

export default useOneSignal;
