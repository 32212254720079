import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import React from 'react';

import Grid from '@material-ui/core/Grid';

import useCustomerAccountFeatures from '../../hooks/useCustomerAccountFeatures';
import EntityListFrame from '../layouts/EntityListFrame';
import FeatureDisabledMessage from '../parts/FeatureDisabledMessage';
import SentReceivedChart from './EDIReport/SentReceivedChart';
import TabsContainer from './EDIReport/TabsContainer';

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function EDIReport(props) {
  const { enabledEDIReport } = useCustomerAccountFeatures();

  return (
    <EntityListFrame inPaper={!enabledEDIReport}>
      {enabledEDIReport ? (
        <>
          <Grid item xs={12} md={6} lg={7} xl={8}>
            <SentReceivedChart />
          </Grid>
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <TabsContainer />
          </Grid>
        </>
      ) : (
        <FeatureDisabledMessage />
      )}
    </EntityListFrame>
  );
}

export default EDIReport;
