export const themeConstant = {
  font: {
    title: '"Roboto", "Helvetica", "Arial", sans-serif', //'Biennale',
    tableHeader: '"Roboto", "Helvetica", "Arial", sans-serif', //'Biennale',
    button: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
    content: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
    menu: '"Roboto", "Helvetica", "Arial", sans-serif', //'OpenSauceSansLight',
  },
  loadingBar: {
    color: '#0000AA',
  },
  chart: {
    colors: ['#ed1848', '#a8a1a1', '#ad3636', '#000000', '#A858A0'],
    allSuppliersLineColor: '#909090',
    arrivedOnTimeColors: {
      early: '#ed1848',
      onTime: '#A858A0',
      late: '#ad3636',
      veryLate: '#a8a1a1',
    },
    trendLine:{
      lineColor: '#AD3636'
    },
    avgLine:{
      lineColor: '#A858A0'
    }
  },
  table: {
    shipments: {
      arrivedOnTimeColors: {
        late: '#FFB346',
        veryLate: '#FF6962',
        onTime: '#A9D099',
      },
    },
    tag: {
      color: '#A858A0',
    },
    filterTag: {
      color: '#ad3636',
    },
  },
  pdf: {
    table: {
      headerColor: '#ea2956',
    },
  },
  shipmentTracking: {
    map: {
      originColor: '#ed1848',
      destinationColor: '#ad3636',
      transhipmentColors: ['#a8a1a1', '#000000', '#A858A0'],
      geoLineColor: '#32342F',
      vessel: '#ed1848',
    },
    milestones: {
      lineColor: '#ea2956',
      completedText: '#FFFFFF',
    },
  },
};
