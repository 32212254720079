import MomentUtils from '@date-io/moment';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

import AccountProfileContainer from './components/pages/AccountProfile/AccountProfileContainer';
import Accounts from './components/pages/Accounts';
import ConfirmEmail from './components/pages/ConfirmEmail';
import Dashboard from './components/pages/Dashboard';
import CommercialInvoiceValuePerSupplierTable from './components/pages/DataTables/CommercialInvoiceValuePerSupplierTable';
import ConsolidatedShipmentsSpendsTable from './components/pages/DataTables/ConsolidatedShipmentsSpendsTable';
import DeliveriesTable from './components/pages/DataTables/DeliveriesTable';
import ExceptionsTable from './components/pages/DataTables/ExceptionsTable';
import PortPairDataTable from './components/pages/DataTables/PortPairDataTable';
import ProductsTable from './components/pages/DataTables/ProductsTable';
import ShipmentDetailTable from './components/pages/DataTables/ShipmentDetailTable';
import ShipmentSpendsTable from './components/pages/DataTables/ShipmentSpendsTable';
import ShipmentsTable from './components/pages/DataTables/ShipmentsTable';
import SpendPerSupplierTable from './components/pages/DataTables/SpendPerSupplierTable';
import EDIReport from './components/pages/EDIReport';
import ExternalApplications from './components/pages/ExternalApplication/ExternalApplications';
import ForgotPassword from './components/pages/ForgotPassword';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import PublicTrackAndTrace from './components/pages/PublicTrackAndTrace';
import PurchaseOrderDataTable from './components/pages/PurchaseOrder/PurchaseOrderDataTable';
import PurchaseOrderLineDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineDataTable';
import PurchaseOrderLineMilestonesDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineMilestonesDataTable';
import PurchaseOrderLineSpendsDataTable from './components/pages/PurchaseOrderLine/PurchaseOrderLineSpendsDataTable';
import ReportFiles from './components/pages/ReportFiles/ReportFiles';
import ResetPassword from './components/pages/ResetPassword';
import TrackAndTrace from './components/pages/TrackAndTrace';
import Trends from './components/pages/Trends';
import UserProfile from './components/pages/UserProfile/UserProfile';
import Users from './components/pages/Users';
import RequestBookingPurchaseOrders from './components/pages/Vendor/RequestBookingPurchaseOrders';
import ShipmentBookingEntry from './components/pages/Vendor/ShipmentBookingEntry';
import ShipmentBookings from './components/pages/Vendor/ShipmentBookings';
import { DrawerContext } from './contexts/DrawerContext';
import { ReloadContext } from './contexts/ReloadContext';
import { UserContext } from './contexts/UserContext';
import { AccountProvider } from './hooks/useAccounts';
import { CustomerAccountFeaturesProvider } from './hooks/useCustomerAccountFeatures';
import { GlobalLoadingBarProvider } from './hooks/useGlobalLoadingBar';
import { GlobalSnackBarProvider } from './hooks/useGlobalSnackBar';
import { OneSignalProvider } from './hooks/useOneSignal';
import AccountType from './models/AccountType';
import { ProtectedRoute } from './protected.route';
import apiService from './services/api.service';
import { lightThemeAdmin } from './themes/lightTheme';
import OpenOrdersTable from './components/pages/DataTables/OpenOrdersTable';
import BillingSummariesTable from './components/pages/DataTables/BillingSummariesTable';
import BillingDetailedTable from './components/pages/DataTables/BillingDetailedTable';
import PetbarnExceptionsTable from './components/pages/DataTables/PetbarnExceptionsTable';

let routes = (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route exact path="/forgotpassword" component={ForgotPassword} />
    <Route exact path="/resetpassword" component={ResetPassword} />
    <Route exact path="/confirmemail" component={ConfirmEmail} />
    <Route exact path="/:accountAlias/trackandtrace" component={PublicTrackAndTrace} />
    <ProtectedRoute exact path="/news" component={Home} />
    <ProtectedRoute exact path="/overview" component={Dashboard} />
    <ProtectedRoute path="/trends" component={Trends} />
    <ProtectedRoute path="/accounts" component={Accounts} />
    <ProtectedRoute path="/portpairdata" component={PortPairDataTable} />
    <ProtectedRoute path="/commercialInvoiceValuePerSupplier" component={CommercialInvoiceValuePerSupplierTable} />
    <ProtectedRoute path="/spendpersupplier" component={SpendPerSupplierTable} />
    <ProtectedRoute path="/shipments" component={ShipmentsTable} />
    <ProtectedRoute path="/shipmentDetails" component={ShipmentDetailTable} />
    <ProtectedRoute path="/products" component={ProductsTable} />
    <ProtectedRoute path="/users" component={Users} />
    <ProtectedRoute path="/profile" component={UserProfile} />
    <ProtectedRoute path="/trackandtrace" component={TrackAndTrace} />
    <ProtectedRoute path="/integrations" component={EDIReport} />
    <ProtectedRoute path="/external-applications" component={ExternalApplications} />
    <ProtectedRoute path="/purchase-order" component={PurchaseOrderDataTable} />
    <ProtectedRoute path="/purchase-order-lines" component={PurchaseOrderLineDataTable} />
    <ProtectedRoute path="/purchase-order-lines-milestones" component={PurchaseOrderLineMilestonesDataTable} />
    <ProtectedRoute path="/purchase-order-lines-spends" component={PurchaseOrderLineSpendsDataTable} />
    <ProtectedRoute path="/consolidated-shipments-spends" component={ConsolidatedShipmentsSpendsTable} />
    <ProtectedRoute path="/shipment-spends" component={ShipmentSpendsTable} />
    <ProtectedRoute path="/report-files" component={ReportFiles} />
    <ProtectedRoute path="/account-profile" component={AccountProfileContainer} />
    {/* <ProtectedRoute path="/exceptions" component={ExceptionsTable} /> */}
    <ProtectedRoute path="/deliveries" component={DeliveriesTable} />
    <ProtectedRoute path="/open-orders" component={OpenOrdersTable} />
    <ProtectedRoute path="/billing-summaries" component={BillingSummariesTable} />
    <ProtectedRoute path="/billing-detailed" component={BillingDetailedTable} />
    <ProtectedRoute path="/exceptions" component={PetbarnExceptionsTable} />
    <ProtectedRoute accountType={AccountType.Vendor} exact path="/shipment-bookings/add" component={ShipmentBookingEntry} />
    <ProtectedRoute accountType={AccountType.Vendor} exact path="/shipment-bookings/:id/edit" component={ShipmentBookingEntry} />
    <ProtectedRoute accountType={AccountType.Vendor} path="/shipment-bookings" component={ShipmentBookings} />
    <ProtectedRoute accountType={AccountType.Vendor} path="/request-booking-purchase-orders" component={RequestBookingPurchaseOrders} />
    <Route exact path="/">
      <Redirect to="/news" />
    </Route>
  </Switch>
);

function App() {
  const [userContext, setUserContext] = useState({});
  const [drawerContext, setDrawerContext] = useState(true);
  const [reloadContext, setReloadContext] = useState();
  const [ready, setReady] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const initialize = async () => {
      try {
        //Fetch UserContext
        if (userContext === undefined || userContext.id === undefined) {
          console.log('App > getMe');
          const result = await apiService.getMe();
          localStorage.setItem('theme', result.themeColor);
          setUserContext(result);
        } else {
          localStorage.setItem('theme', userContext.themeColor);
        }
      } catch (ex) {
        console.log('User does not login');
      } finally {
        setReady(true);
      }
    };

    initialize();
  }, []);

  return (
    <Router history={history}>
      <ThemeProvider theme={lightThemeAdmin}>
        <UserContext.Provider value={[userContext, setUserContext]}>
          <OneSignalProvider>
            <AccountProvider>
              <CustomerAccountFeaturesProvider>
                <ReloadContext.Provider value={[reloadContext, setReloadContext]}>
                  <DrawerContext.Provider value={[drawerContext, setDrawerContext]}>
                    <GlobalLoadingBarProvider>
                      <GlobalSnackBarProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils}>{ready && <div className="App">{routes}</div>}</MuiPickersUtilsProvider>
                      </GlobalSnackBarProvider>
                    </GlobalLoadingBarProvider>
                  </DrawerContext.Provider>
                </ReloadContext.Provider>
              </CustomerAccountFeaturesProvider>
            </AccountProvider>
          </OneSignalProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
