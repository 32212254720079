import React from 'react';

import { Card, CardActions, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

import useShipmentBookingEntry from '../hooks/useShipmentBookingEntry';
import AddOrderControl from './AddOrderControl';
import { AttachedOrderEntry } from './AttachedOrderEntry';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    backgroundColor: '#efefef',
  },
}));

export const AttachedOrdersSection = (props) => {
  const classes = useStyles();
  const { orders, readOnly } = useShipmentBookingEntry();

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Attached Orders
              </Typography>
            </Grid>
            {orders &&
              orders.map((order) => {
                return (
                  <Grid key={`AttachedOrderEntry${order.orderNumber}`} item xs={12}>
                    <AttachedOrderEntry order={order} />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
        {!readOnly && (
          <CardActions className={classes.cardActions}>
            <AddOrderControl />
          </CardActions>
        )}
      </Card>
    </>
  );
};
