import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  searchInputControl: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    '& div': {
      backgroundColor: theme.palette.inputControl.backgroundColor,
      borderRadius: 4,
    },
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 37,
    },
    '& fieldset': {
      borderColor: theme.palette.inputControl.borderColor,
    },
    '& :hover fieldset': {
      borderColor: theme.palette.inputControl.borderColor + ' !important',
    },
    '& .Mui-focused fieldset': {
      borderColor: theme.palette.inputControl.borderColor + ' !important',
    },
  },
  searchIcon: {
    color: '#ADADAD',
  },
}));

export default function SmallSearch(props) {
  const classes = useStyles();
  const { onValueChange, onChange, onKeyPress, InputProps, className, value, ...remains } = props;

  const [searchText, setSearchText] = useState(value);

  let timer;

  useEffect(() => {
    timer = setTimeout(() => {
      setSearchValues();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      setSearchValues();
    }
  };

  const setSearchValues = () => {
    clearTimeout(timer);
    if (onValueChange) {
      onValueChange(searchText);
    }
  };

  return (
    <TextField
      {...remains}
      value={searchText}
      className={clsx(classes.searchInputControl, className)}
      onChange={handleSearchTextChange}
      onKeyPress={handleOnKeyUp}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
    />
  );
}
