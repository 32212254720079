import React, { useEffect, useState } from 'react';
import LineChart from './ChartTemplates/LineCharts.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function UnitPricePerShipmentLineChart(props) {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [unit, setUnit] = useState('');
  const [min, setMin] = useState('-');
  const [max, setMax] = useState('-');
  const [avg, setAvg] = useState('-');
  const { accountId, productCode } = props;
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      setIsLoading(true);
      apiService
        .getUnitPricePerShipmentProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.unit ? setUnit(result.data.unit) : setUnit('');
            result.data.min ? setMin(result.data.min) : setMin('-');
            result.data.max ? setMax(result.data.max) : setMax('-');
            result.data.avg ? setAvg(result.data.avg) : setAvg('-');

            setChartData(prepareStuff(result.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }
    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  const prepareStuff = (data) => {
    var tempArray = [];
    if (data && data.items) {
      data.items.forEach((item) => {
        let itemObj = {
          date: item.date,
          value: item.unitValue,
          labelPrefix: item.shipmentNumber,
          labelSuffix: data.unit ? ` per ${data.unit}` : item.unit && ` per ${item.unit}`,
        };
        tempArray.push(itemObj);
      });
    }

    return tempArray;
  };

  return (
    <LineChart
      chartId="unitPriceLineChart"
      isLoading={isLoading}
      chartData={chartData}
      title="Unit Price per Shipment"
      caption={`Avg: $${avg.toLocaleString()} Min: $${min.toLocaleString()} Max: $${max.toLocaleString()}`}
      tooltipValueFormat="${0}"
    />
  );
}
