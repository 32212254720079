import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { withRouter } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';

import DeleteDialog from 'src/components/parts/DeleteDialog';
import EntityTable from 'src/components/parts/EntityTable';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import customerAccountApiService from 'src/services/customerAccount.api.service';

const headCells = [
  { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
  {
    id: 'ContactEmail',
    numeric: false,
    disablePadding: false,
    label: 'Contact Email',
  },
  {
    id: 'CreatedDate',
    numeric: true,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: 'UpdatedDate',
    numeric: true,
    disablePadding: false,
    label: 'Updated Date',
  },
];

function CustomerAccounts(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
  });
  const [reloadContext] = useContext(ReloadContext);
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();

  const history = useHistory();

  useEffect(() => {
    startProgress();
    customerAccountApiService
      .getCustomerAccounts(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
        stopProgress();
      })
      .catch(catchApiError);
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([item.id, item.name, item.contactEmail, c.format('L'), u.format('L')]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    customerAccountApiService
      .deleteCustomerAccount(id)
      .then((result) => {
        showSuccessSnackBar('The customer account was deleted successfully');
        setDummyCounter(dummyCounter + 1);
      })
      .catch(catchApiError);
  };

  const handleAdd = () => {
    history.push('/accounts/customer/add');
  };

  const handleEdit = (event) => {
    history.push('/accounts/customer/' + event.currentTarget.dataset.tag + '/edit');
  };

  const handleEditFeature = (event) => {
    history.push('/accounts/customer/' + event.currentTarget.parentNode.dataset.tag + '/features');
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      {rows && (
        <EntityTable
          title="Customer Accounts"
          headCells={headCells}
          totalNumberOfRows={totalNumberOfRows}
          rows={rows}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleAdd={userContext.isAdmin && userContext.permissions.canManageCustomerAccounts && handleAdd}
          handleEdit={userContext.isAdmin && userContext.permissions.canManageCustomerAccounts && handleEdit}
          deleteHandler={userContext.isAdmin && userContext.permissions.canManageCustomerAccounts && handleDeleteRequest}
          additionalRowAction={
            <IconButton aria-label="edit" onClick={handleEditFeature}>
              <BallotOutlinedIcon size="small" />
            </IconButton>
          }
        />
      )}
      <DeleteDialog
        action={pendingDeleteAction}
        handleDelete={handleDelete}
        title="Delete Customer?"
        body="Are you sure you want to delete this customer with all its data? Once deleted it cannot be undone."
      />
    </>
  );
}

export default withRouter(CustomerAccounts);
