import moment from 'moment';
import dateBaseOn from 'src/models/DateBaseOn';

const defaultPeriodMonths = 0;
const purchaseOrderDataTableFilterReducer = (state, action) => {
  switch (action.type) {
    case 'fieldWithTrigger': {
      return {
        ...state,
        [action.fieldName]: action.payload,
        filterFieldsUpdated: new Date(),
      };
    }
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'initialFields': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'loadSelectItems': {
      function getDefaultPeriodValue(periods) {
        let startPeriodValue, endPeriodValue;
        if (periods && periods.length > 0) {
          let currentDate = moment(new Date());
          let currentMonthPeriodId = currentDate.format('YYYY-MM');

          startPeriodValue = state.startPeriodValue || currentMonthPeriodId;
          endPeriodValue = state.endPeriodValue || currentMonthPeriodId;

          //Set Start Period
          let startPeriod = periods.find((x) => x.id === startPeriodValue);
          if (startPeriod == null) {
            var startPeriodIndex = defaultPeriodMonths;
            while (startPeriodIndex > periods.length - 1) {
              startPeriodIndex--;
            }
            startPeriodValue = periods[startPeriodIndex].id;
          } else {
            startPeriodValue = startPeriod.id;
          }

          //Set End Period
          let endPeriod = periods.find((x) => x.id === endPeriodValue);
          if (endPeriod == null) {
            endPeriodValue = periods[0].id;
          } else {
            endPeriodValue = endPeriod.id;
          }
        } else {
          startPeriodValue = '';
          endPeriodValue = '';
        }

        return {
          startPeriodValue: startPeriodValue,
          endPeriodValue: endPeriodValue,
        };
      }

      function getDefaultSupplierId(suppliers) {
        if (suppliers && suppliers.length) {
          let selectedSupplier = suppliers.find((x) => x.id === state.supplierId);
          if (selectedSupplier == null) {
            return { supplierId: 'All' };
          } else {
            return { supplierId: state.supplierId };
          }
        } else {
          return { supplierId: 'All' };
        }
      }

      let defaultPeriodValues = getDefaultPeriodValue(action.payload.periods);
      let defaultSupplierId = getDefaultSupplierId(action.payload.suppliers);

      return {
        ...state,
        ...action.payload,
        ...defaultPeriodValues,
        ...defaultSupplierId,
        filterFieldsUpdated: new Date(),
      };
    }
    case 'clearSpecialSearch': {
      return {
        ...state,
        specialSearch: {
          title: '',
          portOriginCode: '',
          portDestinationCode: '',
          trans: '',
          mode: '',
        },
        filterFieldsUpdated: new Date(),
      };
    }
    default:
      return state;
  }
};

const dataTableFilterInitialState = {
  startPeriodValue: '',
  endPeriodValue: '',
  freeTextSearch: '',
  supplierId: 'All',
  dateBasedOn: dateBaseOn.orderDate,
  specialSearch: {
    title: '',
    portOriginCode: '',
    portDestinationCode: '',
    trans: '',
    mode: '',
  },
  filterByMyBuyerId: false,
  filterFieldsUpdated: null,
  periods: null,
  suppliers: null,
  companies: null,
};

export { dataTableFilterInitialState, purchaseOrderDataTableFilterReducer };
