import React from 'react';

import GridContainer from 'src/components/Grid/GridContainer.js';
import GridItem from 'src/components/Grid/GridItem';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';

import NumberOfPurchaseOrder from './NumberOfPurchaseOrder';

export default function GeneralChartsTabContainer(props) {
  const { baseQueryString, filterComponentData, ShowTrendLine, ShowAvgLine } = props;
  const { enabledTrackAndTrace } = useCustomerAccountFeatures();
  return (
    <>
      <GridContainer>
        {enabledTrackAndTrace && (
          <GridItem xs={12}>
            <NumberOfPurchaseOrder
              FilterComponentData={filterComponentData}
              baseQueryString={baseQueryString}
              ShowTrendLine={ShowTrendLine}
              ShowAvgLine={ShowAvgLine}
            />
          </GridItem>
        )}
      </GridContainer>
    </>
  );
}
