import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import { IconButton, Modal, Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import PurchaseOrderDataTableFilter from 'src/components/parts/PurchaseOrderDataTableFilter/PurchaseOrderDataTableFilter';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

import PurchaseOrderDetails from './PurchaseOrderDetails';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentContainer: {
    margin: 60,
    width: '100%',
    maxHeight: 'calc(100% - 40px)',
    maxWidth: 'calc(100vw - 40px)',
    display: 'flex',
  },
  overviewIcon: {
    color: theme.palette.primaryButton.backgroundColor,
  },
}));

const headCells = [
  {
    id: 'orderNumber',
    numeric: false,
    disablePadding: true,
    label: 'Order Number',
  },
  {
    id: 'supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
  },
  {
    id: 'deliverTo',
    numeric: false,
    disablePadding: false,
    label: 'Deliver To',
  },
  {
    id: 'orderDate',
    numeric: true,
    disablePadding: false,
    label: 'Order Date',
  },
  {
    id: 'shipmentStatuses',
    numeric: false,
    disablePadding: false,
    label: 'Shipment Status',
  },
];

function PurchaseOrderDataTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('orderNumber');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const { enabledPurchaseOrder } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  useEffect(() => {
    if (filter) {
      startProgress();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const classes = useStyles();

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
    setSelectedAccountId(data.accountId);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getPurchaseOrderTableReport(
        data.accountId,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        data.buyerId
      )
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      var shipmentStatuses = 'Pending';
      if (item.shipmentStatuses && item.shipmentStatuses.length > 0) {
        if (item.shipmentStatuses[0].shipmentNumber) {
          shipmentStatuses = { component: 'linkList', links: [] };
          item.shipmentStatuses.map((shipmentStatus) => {
            shipmentStatuses.links.push({
              text: shipmentStatus.shipmentNumber + ': ' + shipmentStatus.status,
              path: '/trackandtrace?s=' + shipmentStatus.shipmentNumber,
            });
          });
        } else {
          shipmentStatuses = item.shipmentStatuses[0].status;
        }
      }

      var momentTime = moment(item.orderDate);

      let cells = [item.orderNumber, item.orderNumber, item.supplier, item.deliverTo, momentTime.format('l'), shipmentStatuses];

      let tmpCell = {};
      tmpCell['id'] = item.orderNumber;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });
      newArray.push(tmpCell);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderTableReport(
          filter.accountId,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generatePurhcaseOrderEDIPDF(
            result.data.items,
            'Purchase Orders     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Purchase Orders Report'
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getPurchaseOrderTableReport(
          filter.accountId,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          filter.buyerId
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generatePurhcaseOrderEDICsvData(
            result.data.items,
            filter.accountName + '_' + filter.supplierName + '_' + filter.periodName
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleOverviewClick = (event) => {
    let selectedOrderNumber = event.currentTarget.parentNode.dataset.tag;
    setSelectedOrderNumber(selectedOrderNumber);
    setModalOpen(true);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <EntityListFrame>
        {enabledPurchaseOrder ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Purchase Orders</h4>
              </Grid>
              <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PurchaseOrderDataTableFilter
                sendFilterComponentData={filterCallback}
                sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                showSuppliers={true}
                showDateBasedOn={false}
              />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowActionComponent={
                    <Tooltip title="Purchase Order Details" placement="left">
                      <IconButton aria-label="edit" onClick={handleOverviewClick}>
                        <ListAltIcon size="small" className={classes.overviewIcon} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
      <Modal className={classes.modalContainer} open={modalOpen} onClose={handleModalClose}>
        <div className={classes.modalContentContainer}>
          <PurchaseOrderDetails orderNumber={selectedOrderNumber} accountId={selectedAccountId} onClose={handleModalClose} />
        </div>
      </Modal>
    </>
  );
}

export default withRouter(PurchaseOrderDataTable);
