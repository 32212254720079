import clsx from 'clsx';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import red from '@material-ui/core/colors/red';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import { ReloadContext } from 'src/contexts/ReloadContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import usePermissions from 'src/hooks/usePermissions';
import formErrorFactory from 'src/services/formError.factory';
import vendorAccountApiService from 'src/services/vendorAccount.api.service';
import Button from 'src/wrappers/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    backgroundColor: theme.palette.modal.backgroundColor,
    position: 'relative',
    padding: theme.spacing(3),
    flexDirection: 'column',
    transition: 'visibility 0.1s linear,opacity 0.1s linear',
    maxWidth: '100%',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  rightTopCorner: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },

  input: {
    display: 'none',
  },
  actionButtonDelete: {
    color: red[500],
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  title: {
    paddingBottom: theme.spacing(2),
  },

  select: {
    // width: '100%',
  },
  closeIconButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#707070',
    padding: 0,
  },
  dialogTitle: {
    paddingBottom: 0,
    '& h2': {
      fontWeight: '700',
    },
  },
}));

const supplierEntryInitialState = {
  supplierId: '',
  name: '',
  companyName: '',
  streetAddress: '',
  city: '',
  state: '',
  country: '',
  countryCode: '',
  postCode: '',
  telephone: '',
  telephone2: '',
  fax: '',
  contract: '',
  email: '',
  errors: [],
  isEdit: false,
  isSubmitting: false,
  isSubmitted: false,
};

const supplierEntryReducer = (state, action) => {
  switch (action.type) {
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'loadExistingData': {
      return {
        ...state,
        ...action.payload,
        errors: [],
        isEdit: true,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'submit': {
      return {
        ...state,
        errors: [],
        isSubmitting: true,
      };
    }
    case 'success': {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case 'error': {
      return {
        ...state,
        errors: action.payload,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'clear': {
      return supplierEntryInitialState;
    }
    default:
      return state;
  }
};

const SupplierEntry = (props) => {
  const { onClose, open, supplierId, vendorAccountId } = props;
  const classes = useStyles();
  const [, setReloadContext] = useContext(ReloadContext);
  const [canManage, setCanManage] = useState(false);
  const [state, dispatch] = useReducer(supplierEntryReducer, supplierEntryInitialState);
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar, showErrorSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();
  const { canManageSuppliers } = usePermissions();

  useEffect(() => {
    console.log('canManageSuppliers', canManageSuppliers);
    // let canManage = userContext.isAdmin && userContext.permissions.canManageCustomerAccounts;
    setCanManage(canManageSuppliers);
  }, [canManageSuppliers]);

  useEffect(() => {
    if (vendorAccountId && supplierId && open) {
      vendorAccountApiService
        .getSupplierEntry(vendorAccountId, supplierId)
        .then((result) => {
          if (result.data) {
            let supplierEntry = {
              supplierId: result.data.id,
              name: result.data.name,
              companyName: result.data.companyName,
              streetAddress: result.data.streetAddress,
              city: result.data.city,
              state: result.data.state,
              country: result.data.country,
              countryCode: result.data.countryCode,
              postCode: result.data.postCode,
              telephone: result.data.telephone,
              telephone2: result.data.telephone2,
              fax: result.data.fax,
              contract: result.data.contract,
              email: result.data.email,
            };

            dispatch({
              type: 'loadExistingData',
              payload: supplierEntry,
            });
          }
        })
        .catch(catchApiError);
    }
  }, [supplierId, vendorAccountId, open]);

  const handleSubmit = (event) => {
    //internal functions
    const handleSubmitResult = (apiResult, successMessage) => {
      stopProgress();
      if (apiResult.errors && apiResult.errors.length > 0) {
        let { errors, errorString } = formErrorFactory.createErrors(apiResult.errors);
        dispatch({
          type: 'error',
          payload: errors,
        });
        if (errorString) {
          showErrorSnackBar(errorString);
        }
      } else {
        dispatch({ type: 'success' });
        setReloadContext(new Date());
        showSuccessSnackBar(successMessage);
        close();
      }
    };

    //Submitting
    startProgress();
    event.preventDefault();
    dispatch({ type: 'submit' });

    let supplierEntry = {
      vendorAccountId: vendorAccountId,
      supplierId: state.supplierId,
      name: state.name,
      companyName: state.companyName,
      streetAddress: state.streetAddress,
      city: state.city,
      state: state.state,
      country: state.country,
      countryCode: state.countryCode,
      postCode: state.postCode,
      telephone: state.telephone,
      telephone2: state.telephone2,
      fax: state.fax,
      contract: state.contract,
      email: state.email,
    };

    if (supplierId) {
      vendorAccountApiService
        .updateSupplier(supplierEntry)
        .then((result) => {
          handleSubmitResult(result, `The Supplier was updated successfully`);
        })
        .catch((err) => {
          catchApiError(err, () => {
            dispatch({
              type: 'error',
              payload: [],
            });
          });
        });
    } else {
      vendorAccountApiService
        .createSupplier(supplierEntry)
        .then((result) => {
          handleSubmitResult(result, `The Supplier was created successfully`);
        })
        .catch((err) => {
          catchApiError(err, () => {
            dispatch({
              type: 'error',
              payload: [],
            });
          });
        });
    }
  };

  const handleCancel = (event) => {
    close();
  };

  const close = () => {
    dispatch({
      type: 'clear',
    });
    if (onClose) {
      onClose();
    }
  };

  const getTitle = useCallback(() => {
    if (supplierId) {
      if (canManageSuppliers) {
        return 'Edit Supplier';
      } else {
        return 'View Supplier';
      }
    } else {
      return 'Create new Supplier';
    }
  }, [supplierId, canManageSuppliers]);

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth={true} maxWidth={'md'}>
      <IconButton color="default" className={classes.closeIconButton} onClick={handleCancel}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.dialogTitle }}>
        {getTitle()}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <form autoComplete="off" onSubmit={handleSubmit} noValidate>
          <FormGroup row>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'name',
                      payload: e.target.value,
                    })
                  }
                  required
                  error={state.errors['Name'] != null}
                  helperText={state.errors['Name']}
                  value={state.name || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Name"
                  margin="dense"
                  name="companyName"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'companyName',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['CompanyName'] != null}
                  helperText={state.errors['CompanyName']}
                  value={state.companyName || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Street Address"
                  margin="dense"
                  name="streetAddress"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'streetAddress',
                      payload: e.target.value,
                    })
                  }
                  required
                  error={state.errors['StreetAddress'] != null}
                  helperText={state.errors['StreetAddress']}
                  value={state.streetAddress || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="City"
                  margin="dense"
                  name="city"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'city',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['City'] != null}
                  helperText={state.errors['City']}
                  value={state.city || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="State"
                  margin="dense"
                  name="state"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'state',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['State'] != null}
                  helperText={state.errors['State']}
                  value={state.state || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Post Code"
                  margin="dense"
                  name="postCode"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'postCode',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['PostCode'] != null}
                  helperText={state.errors['PostCode']}
                  value={state.postCode || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Country"
                  margin="dense"
                  name="country"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'country',
                      payload: e.target.value,
                    })
                  }
                  required
                  error={state.errors['Country'] != null}
                  helperText={state.errors['Country']}
                  value={state.country || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Country Code"
                  margin="dense"
                  name="countryCode"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'countryCode',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['CountryCode'] != null}
                  helperText={state.errors['CountryCode']}
                  value={state.countryCode || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Telephone"
                  margin="dense"
                  name="telephone"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'telephone',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['Telephone'] != null}
                  helperText={state.errors['Telephone']}
                  value={state.telephone || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Telephone 2"
                  margin="dense"
                  name="telephone2"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'telephone2',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['Telephone2'] != null}
                  helperText={state.errors['Telephone2']}
                  value={state.telephone2 || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Fax"
                  margin="dense"
                  name="fax"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'fax',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['Fax'] != null}
                  helperText={state.errors['Fax']}
                  value={state.fax || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Contract Name"
                  margin="dense"
                  name="contract"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'contract',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['Contract'] != null}
                  helperText={state.errors['Contract']}
                  value={state.contract || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'email',
                      payload: e.target.value,
                    })
                  }
                  error={state.errors['Email'] != null}
                  helperText={state.errors['Email']}
                  value={state.email || ''}
                  disabled={!canManage || state.isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {canManage ? (
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button type="submit" buttonType="primary" disabled={state.isSubmitting} className={classes.buttons}>
                    Save
                  </Button>
                  <Button buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={handleCancel}>
                    Cancel
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.buttonsContainer}>
                  <Button buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={handleCancel}>
                    Back
                  </Button>
                </Grid>
              )}
            </Grid>
          </FormGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SupplierEntry;
