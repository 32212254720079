import React, { useState, useRef } from "react";
import { Grid, TextField } from "@material-ui/core";
import Button from '../../wrappers/Button';
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";
import apiService from "../../services/api.service";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";

export default function ResetPassword({ match, location }) {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [successAlertOpen, setSuccessAlertOpen] = useState(true);
    const [errorAlertOpen, setErrorAlertOpen] = useState(true);
    const [alertErrorMessage, setAlertErrorMessage] = useState(true);
    const [actionResult, setActionResult] = useState("");
    const [displayError, setDisplayError] = useState(false);
    const inputRef = useRef();
    const history = useHistory();

    const useStyles = makeStyles((theme) => ({
        root: {
            // padding: theme.spacing(3, 2),
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
            minHeight: "100%" ,
            clear: "both" ,
            padding: "0 0 310px 0"
        },
        alert: {
            margin: theme.spacing(1),
        },
        title: {
            fontStyle: "italic",
        },
        buttons: {
            margin: theme.spacing(1),
        
        },
        mystyle :{
            background : "#e1214c"
          },
          propertyform : {
            background:"rgb(255, 255, 255)",
            padding: "30px 0 20px 65px",
            position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"   ,
          width: "600px",
          maxWidth:"650px"   
          },
          forminner:{
              margin:"0 0 0 -45px" ,
              padding :"0 0 0 32px"
          }
    }));

    const classes = useStyles();
    const parsedqs = queryString.parse(location.search);

    const handleSubmit = (event) => {
        event.preventDefault();
        if ((password1.length < 1 && password2.length < 1) || password1 !== password2) {
            setDisplayError(true);
        }
        else {
            setDisplayError(false);

            apiService.resetPassword(password1, parsedqs.email, parsedqs.code).then((result) => {
                console.log("success:", result);
                setSuccessAlertOpen(true);
                setActionResult("success");
                setTimeout(() => {
                    history.push("/login");
                }, 3000)
            })
                .catch((errors) => {
                    setErrorAlertOpen(true);
                    setActionResult("failed");
                    setAlertErrorMessage(errors.response.data.errors.map(x => x.errorMessage).toString());

                });
        }
    };

    return (
        <div  className={classes.mystyle}> 
        <div className={classes.root}>
            <Container maxWidth="sm">
            <div className={classes.propertyform} >
                <form onSubmit={handleSubmit}>
                    <Grid className={classes.forminner}
                        container
                        spacing={3}
                        direction="row"
                        justify="flex-start"
                        alignItems="stretch">
                        <Grid item xs={12}>
                            <Typography component="h5" variant="h5" color="inherit" noWrap>
                                Reset Password
                            </Typography>
                        </Grid>
                        <Grid item xs={11} style={{}}>
                            <TextField
                                label="New Password"
                                name="password1"
                                value={password1}
                                onChange={(e) => setPassword1(e.target.value)}
                                type="password"
                                ref={inputRef}
                                fullWidth
                                autoFocus />
                        </Grid>

                        <Grid item xs={11}>
                            <TextField
                                label="Confirm New Password"
                                type="password"
                                name="password2"
                                value={password2}
                                onChange={(e) => setPassword2(e.target.value)}
                                error={displayError}
                                helperText={displayError ? "The two fields don't match" : ""}
                                fullWidth />
                        </Grid>

                        <Grid 
                         container
                         spacing={12}
                         xs={12}
                         sm={12}
                         md={12}
                         lg={12}
                         alignItems="center"
                         justify="space-between"
                         style={{ margin: "0 auto" , padding: "35px 0 0px 165px"}}>
                          <Grid item>   
                            <Button
                                style={{ textTransform: "none"}}
                                type="submit"
                                variant="contained"
                                color="primary"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                // color="primary"
                            >
                                Change Password
                </Button>
                            <Button
                                style={{ textTransform: "none" }}
                                className={classes.buttons}
                                type="button"
                                onClick={(e) => { e.preventDefault(); history.push("/login") }}
                                variant="contained"
                                color="yellow"
                                color="primary"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                            >
                                Back to login page
                </Button>
                </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            {actionResult === "success" ? (
                                <Collapse in={successAlertOpen}>
                                    <Alert
                                        variant="outlined"
                                        severity="success"
                                        onClose={() => {
                                            setSuccessAlertOpen(false);
                                        }}
                                        className={classes.alert}
                                    >
                                        The password was changed!
                      </Alert>
                                </Collapse>
                            ) : actionResult === "failed" ? (
                                <Collapse in={errorAlertOpen}>
                                    <Alert
                                        variant="outlined"
                                        severity="error"
                                        onClose={() => {
                                            setErrorAlertOpen(false);
                                        }}
                                        className={classes.alert}
                                    >
                                        The action failed. The resonse was: {alertErrorMessage}
                                    </Alert>
                                </Collapse>
                            ) : ("")}
                        </Grid>
                    </Grid>
                </form>
                </div>
            </Container>
        </div>
        </div>
    );
}
