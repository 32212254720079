import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import { MyTab, MyTabs, TabPanel } from 'src/components/parts/MyTabs';
import useAccounts from 'src/hooks/useAccounts';

import FileReceivedList from './FileReceivedList';
import FileSentList from './FileSentList';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanel: {
    borderBottomRightRadius: 8,
  },
}));

function TabsContainer(props) {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const { accountId } = useAccounts();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
  }, [accountId]);

  return (
    <div className={classes.root}>
      <MyTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
        <MyTab label="Files Received" />
        <MyTab label="Files Sent" />
      </MyTabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <FileReceivedList />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <FileSentList />
      </TabPanel>
    </div>
  );
}
export default TabsContainer;
