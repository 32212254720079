import { useCallback } from 'react';
import { useHistory } from 'react-router';

import useGlobalLoadingBar from './useGlobalLoadingBar';
import useGlobalSnackBar from './useGlobalSnackBar';

export default function useCatchAPIError() {
  const history = useHistory();
  const { stopProgress } = useGlobalLoadingBar();
  const { showErrorSnackBar } = useGlobalSnackBar();

  const catchApiError = useCallback(
    (error, callback) => {
      stopProgress();
      console.log('error: ', error);
      if (error.response && error.response.status === 401) {
        history.push('/login');
      } else {
        showErrorSnackBar();
      }
      if (callback) {
        callback();
      }
    },
    [history]
  );

  return { catchApiError };
}
