import React, { useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CircularProgress, IconButton, makeStyles, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    color: theme.palette.exportMenu.backgroundColor,
    width: 38,
    height: 38,
    padding: 6,
    borderRadius: '50%',
    marginLeft: 8,
    '&:hover': {
      boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 80%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 80%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.8);',
    },
  },
  indicatorContainer: {
    width: 38,
    height: 38,
    display: 'inline-flex',
    alignItems: 'center',
  },
  circularProgress: {
    width: '28px !important',
    height: '28px !important',
  },
}));

export default function DownloadButton(props) {
  let { onClick, isDownloading = false } = props;

  const handleOnClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };
  const classes = useStyles();

  return (
    <>
      {isDownloading ? (
        <Tooltip title="Downloading" placement="top" arrow>
          <div className={classes.indicatorContainer}>
            <CircularProgress className={classes.circularProgress} />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title="Download" placement="top" arrow>
          <IconButton onClick={handleOnClick} color="default" className={classes.downloadButton}>
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
