import React from 'react';
import { useParams } from 'react-router';

import { Grid } from '@material-ui/core';

import Suppliers from './Suppliers';
import VendorAccountEntry from './VendorAccountEntry';

export default function VendorAccountEntryContainer(props) {
  let { id } = useParams();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <VendorAccountEntry vendorAccountId={id} />
      </Grid>
      {id && (
        <Grid item xs={12}>
          <Suppliers vendorAccountId={id} />
        </Grid>
      )}
    </Grid>
  );
}
