import React from 'react';
import PropTypes from 'prop-types';

export default function MenuFont(props) {
  const { children } = props;
  return <div className={'menuFont'}>{children}</div>;
}

MenuFont.propTypes = {
  children: PropTypes.node,
};
