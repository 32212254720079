import apiHelperService from './api.helper.service';
import helpService from './help.service';

class ShipmentBookingApiService {
  getShipmentBookingsTable = (accountId, page, rowsPerPage, order, orderBy, periodStart, periodEnd, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/shipment-booking/query/get-shipment-bookings-table`, {
      params: {
        accountId: accountId,
        page: page,
        pageSize: rowsPerPage,
        periodStart: periodStart,
        periodEnd: periodEnd,
        sortColumn: orderBy,
        sortDirection: order,
        freeTextSearch,
      },
    });
  };

  deleteShipmentBooking = (shipmentBookingId) => {
    return apiHelperService.deleteCallAPIWrapper('/api/v1/shipment-booking/command/delete/' + shipmentBookingId);
  };

  getShipmentBookingEntrySettings = (vendorAccountId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-shipment-booking-entry-settings`, {
      params: {
        accountId: vendorAccountId,
      },
    });
  };

  getShipperSelectItems = (vendorAccountId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-shipper-select-items`, {
      params: {
        accountId: vendorAccountId,
      },
    });
  };

  getReceiverSelectItems = (vendorAccountId, orderNumbers) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-receiver-select-items`, {
      params: {
        accountId: vendorAccountId,
        orderNumbers: helpService.GuidToJoinedString(orderNumbers),
      },
    });
  };

  getShipmentBookingEntryPurchaseOrderSelectItems = (vendorAccountId, shipmentBookingId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-shipment-booking-entry-purchase-order-select-items`, {
      params: {
        accountId: vendorAccountId,
        shipmentBookingId: shipmentBookingId,
      },
    });
  };

  getShipmentBookingEntry = (vendorAccountId, shipmentBookingId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-shipment-booking-entry-by-id`, {
      params: {
        accountId: vendorAccountId,
        shipmentBookingId: shipmentBookingId,
      },
    });
  };

  getEntryPurchaseOrder = (vendorAccountId, orderNumber, shipmentBookingId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-booking/query/get-entry-purchase-order`, {
      params: {
        accountId: vendorAccountId,
        orderNumber: orderNumber,
        shipmentBookingId: shipmentBookingId,
      },
    });
  };

  createDraft = (shipmentBookingCommandModel) => {
    return apiHelperService.postCallAPIWrapper('api/v1/shipment-booking/command/create-draft', shipmentBookingCommandModel);
  };

  updateDraft = (shipmentBookingCommandModel) => {
    return apiHelperService.postCallAPIWrapper('api/v1/shipment-booking/command/update-draft', shipmentBookingCommandModel);
  };

  submitDraft = (id) => {
    return apiHelperService.postCallAPIWrapper('api/v1/shipment-booking/command/submit-draft', { id: id });
  };

  discardDraft = (id) => {
    return apiHelperService.postCallAPIWrapper('api/v1/shipment-booking/command/discard-draft', { shipmentBookingId: id });
  };

  getRequestBookingPurchaseOrdersTable = (accountId, page, rowsPerPage, order, orderBy, periodStart, periodEnd, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/shipment-booking/query/get-request-booking-purchase-orders-table`, {
      params: {
        accountId: accountId,
        page: page,
        pageSize: rowsPerPage,
        periodStart: periodStart,
        periodEnd: periodEnd,
        sortColumn: orderBy,
        sortDirection: order,
        freeTextSearch,
      },
    });
  };
}

export default new ShipmentBookingApiService();
