import React, { useEffect, useState } from 'react';
import PieChart from './ChartTemplates/PieChart.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import { format } from 'react-string-format';

export default function ShipmentDelayPieChart(props) {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accountId, productCode } = props;
  const history = useHistory();
  const veryLateDays = 3;

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      setIsLoading(true);
      apiService
        .getShipmentDelaysProductOverviewReport(accountId, productCode, veryLateDays)
        .then((result) => {
          if (!isCancelled) {
            setChartData(prepareStuff(result.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  const prepareStuff = (data) => {
    var tempArray = [];
    if (data.totalEarly || data.totalOnTime || data.totalLate || data.totalVeryLate) {
      tempArray.push({ name: 'Early', amount: data.totalEarly });
      tempArray.push({ name: 'On Time', amount: data.totalOnTime });
      tempArray.push({ name: 'Late', amount: data.totalLate });
      tempArray.push({ name: format('Late over {0} days', veryLateDays), amount: data.totalVeryLate });
    }
    return tempArray;
  };

  return <PieChart chartId="shipmentDelay" isLoading={isLoading} chartData={chartData} title="Shipment Delays" />;
}
