import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from 'src/contexts/UserContext';
import useAccounts from 'src/hooks/useAccounts';
import AccountType from 'src/models/AccountType';
import apiService from 'src/services/api.service';

import CustomerDrawer from './CustomerDrawer';
import TopMenuBar from './TopMenuBar';
import VendorDrawer from './VendorDrawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function ApplicationMenus({ props }) {
  const classes = useStyles();
  const [userContext, setUserContext] = useContext(UserContext);
  const { accountType } = useAccounts();

  const history = useHistory({ forceRefresh: true });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userContext === undefined || userContext.id === undefined) {
          const result = await apiService.getMe();
          localStorage.setItem('theme', result.themeColor);
          setUserContext(result);
        } else {
          localStorage.setItem('theme', userContext.themeColor);
        }
      } catch (ex) {
        history.push('/login');
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopMenuBar />
      {accountType == AccountType.Customer && <CustomerDrawer />}
      {accountType == AccountType.Vendor && <VendorDrawer />}
    </div>
  );
}
