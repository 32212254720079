import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import DataContentTableCell from './DataContentTableCell';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ScrollBar from 'react-perfect-scrollbar';

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={'tableHeader'}>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => {
          var textAlign = '';
          if (headCell.align) {
            textAlign = headCell.align;
          } else {
            textAlign = headCell.numeric ? 'right' : 'left';
          }
          var tooltip = '';
          if (headCell.tooltip !== undefined && headCell.tooltip !== null) {
            tooltip = headCell.tooltip;
          }
          return (
            <TableCell
              key={`tableCell${headCell.id}`}
              align={textAlign}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ minWidth: headCell.minWidth }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableSortLabel}
              >
                <div>
                  {headCell.label}
                  {tooltip ? (
                    <Tooltip arrow title={tooltip}>
                      <InfoIcon className={classes.tooltipIcon} />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
                {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {props.rowActionComponent && <TableCell align="right"></TableCell>}
        {props.canAdd && (
          <>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  pdfButton: {
    minWidth: '200px',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar className={clsx(classes.root, { [classes.highlight]: numSelected > 0 })}>
      <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
        {props.title}
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  actionButtonEdit: {
    color: green[500],
  },
  actionButtonDelete: {
    color: red[500],
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  scrollBar: {
    paddingTop: 15,
    maxHeight: 'calc(100vh - 200px)',
    '& .ps__rail-y': {
      marginTop: 15,
    },
    '& .ps__rail-x': {
      zIndex: 101,
    },
  },
  scrollXBackground: {
    background: theme.palette.dataTable.scrollBar.backgroundColor,
    height: 15,
    width: '100%',
    top: 0,
    position: 'absolute',
    zIndex: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionCell: {
    minWidth: '130px',
  },
  tableSortLabel: {
    whiteSpace: 'nowrap',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tooltipIcon: {
    fontSize: '1.25rem',
    marginLeft: 3,
  },
}));

export default function DataContentTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { rows, headCells } = props;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.handleRequestSort(isAsc ? 'desc' : 'asc', property);
  };

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    props.handleChangeRowsPerPage(parseInt(event.target.value));
  };

  const emptyRows = 0;

  const getTableCell = (cell, key, num, labelId) => {
    let block = {
      key: key + '' + num,
      className: '',
    };
    let cellClassName = '';

    if (cell && cell.image) {
      block.component = 'image';
      block.image = cell.image;
    } else if (cell && cell.avatar) {
      block.component = 'avatar';
      block.src = cell.avatar;
      block.variant = 'square';
      block.className = classes.avatar;
    } else if (cell && cell.component) {
      cell.key = block.key;
      block = cell;
    } else if (cell && cell.text !== undefined && cell.className !== undefined) {
      block.text = cell.text;
      cellClassName = cell.className;
    } else {
      block.text = cell;
    }
    if (num === 1) {
      return (
        <TableCell component="td" id={labelId} scope="row" padding="none" className={cellClassName} key={'cell' + block.key}>
          {DataContentTableCell(block)}
        </TableCell>
      );
    } else {
      return (
        <TableCell align={getTableRowAlign(num)} className={cellClassName} key={'cell' + block.key}>
          {DataContentTableCell(block)}
        </TableCell>
      );
    }
  };

  const getTableRowAlign = (num) => {
    if (headCells[num - 1]) {
      return headCells[num - 1].numeric ? 'right' : 'left';
    } else {
      return '';
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.scrollXBackground}></div>
      <ScrollBar className={classes.scrollBar} options={{ swipeEasing: false }}>
        <Table stickyHeader aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            rowActionComponent={props.rowActionComponent}
            canAdd={props.canAdd}
          />
          <TableBody>
            {rows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;
              let key = 'key' + index;
              let entityKey = row.id;
              return (
                <TableRow role="checkbox" tabIndex={-1} key={key}>
                  <TableCell padding="checkbox" key={'checkbox' + key}></TableCell>

                  {headCells.map((headCell, index) => {
                    return row[headCell.id] !== undefined ? getTableCell(row[headCell.id], key, index + 1, index === 0 ? labelId : null) : null;
                  })}
                  {props.rowActionComponent && (
                    <TableCell align="right" key={'action' + key} data-tag={entityKey}>
                      {typeof props.rowActionComponent === 'function' ? props.rowActionComponent(row, index) : props.rowActionComponent}
                    </TableCell>
                  )}
                  {props.canAdd && (
                    <>
                      <TableCell align="right" key={'actionedit' + key} data-tag={entityKey}>
                        <IconButton aria-label="edit" data-tag={entityKey} onClick={props.handleEdit}>
                          <EditIcon size="small" className={classes.actionButtonEdit} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right" key={'actiondelete' + key} data-tag={entityKey}>
                        <IconButton aria-label="delete" data-tag={entityKey} onClick={props.deleteHandler}>
                          <DeleteIcon size="small" className={classes.actionButtonDelete} />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollBar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={props.totalNumberOfRows}
        rowsPerPage={rowsPerPage}
        page={props.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
