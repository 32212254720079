import React from 'react';
import PropTypes from 'prop-types';

export default function TitleFont(props) {
  const { children } = props;
  return <div className={'titleFont'}>{children}</div>;
}

TitleFont.propTypes = {
  children: PropTypes.node,
};
