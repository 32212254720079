import React from 'react';
import PropTypes from 'prop-types';

function ChartNoData(props) {
  const { text = 'No Data on Specific Period', ...rest } = props;

  return (
    <div className={'no-data'} {...rest}>
      <span>{text}</span>
    </div>
  );
}

ChartNoData.propTypes = {
  text: PropTypes.string,
};

export default ChartNoData;
