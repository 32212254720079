import React, { useEffect, useState } from 'react';
import PieChart from './ChartTemplates/PieChart.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function AverageTransitPerSupplierPieChart(props) {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { productCode, accountId } = props;

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      setIsLoading(true);
      apiService
        .getAverageTransitTimePerSupplierProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            setChartData(prepareStuff(result.data.items));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (productCode && accountId) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  const prepareStuff = (data) => {
    var tempArray = [];
    data.forEach((item) => {
      let itemObj = {
        name: item.supplierName,
        amount: item.value,
      };
      tempArray.push(itemObj);
    });

    return tempArray;
  };

  return (
    <PieChart
      chartId="avgTransitPerSupplier"
      isLoading={isLoading}
      chartData={chartData}
      title="Avg. Transit Time per Supplier"
      tooltipValueFormat="{0} Days"
    />
  );
}
