import React, { useEffect, useState } from 'react';
import AmountBox from './ChartTemplates/AmountBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function TotalAmountBox(props) {
  const { accountId, productCode } = props;
  const [value, setValue] = useState('-');
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      apiService
        .getTotalAmountProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            setValue(result.data.value);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  return (
    <AmountBox
      title="Total Amount"
      valueText={`$ ${value.toLocaleString(undefined, { maximumFractionDigits: 0 })} `}
      color="danger"
      iconComponent={<FontAwesomeIcon icon={faCoins} size="lg" />}
    />
  );
}
