// https://codesandbox.io/p/sandbox/mui-date-range-picker-pq8nr?file=%2Fsrc%2Fpages%2FDemo.js%3A20%2C20
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';

import { IconButton, InputAdornment, Popover, TextField } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { withStyles } from '@material-ui/styles';

// theme css file

const styles = {
  calendarWrapper: {
    padding: '16px',
  },
};

class DateRangePicker extends React.Component {
  dateFormat = 'DD/MM/YYYY';
  currentDate = new Date();
  maxDate = new Date(this.currentDate.setMonth(this.currentDate.getMonth() + 4));

  state = {
    displayCalendar: false,
    inputValue: '',
    anchorEl: null,
    fromDate: undefined,
    toDate: undefined,
  };

  onAdornmentClick = (e) => {
    console.log('maxDate', this.maxDate, 'currentDate', this.currentDate);
    this.setState({ displayCalendar: true, anchorEl: e.currentTarget });
  };

  onInputChange = (e) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = this.processInputValue(inputValue);

    this.setState({ inputValue, fromDate, toDate });
  };

  onPopoverClose = (e, reason) => {
    console.log(reason);

    if (this.props.onChange) {
      this.props.onChange({
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });
    }
    this.setState({ displayCalendar: false, anchorEl: null });
  };

  onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = '';
    if (startDate) inputValue += moment(startDate).format(this.dateFormat);
    if (endDate) inputValue += ' - ' + moment(endDate).format(this.dateFormat);

    this.setState({ fromDate: startDate, toDate: endDate, inputValue });
  };

  processInputValue(value) {
    let [fromDate, toDate] = value.split('-').map((elm) => elm.trim());

    fromDate = moment(fromDate, this.dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, this.dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <TextField
          label={this.props.title}
          fullWidth={true}
          value={this.state.inputValue}
          // style={{ minWidth: 250 }}
          disabled
          InputProps={{
            readOnly: true,
            style: { color: 'rgba(0, 0, 0, 0.87)' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.onAdornmentClick}>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={this.onInputChange}
        />
        <Popover
          open={this.state.displayCalendar}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.onPopoverClose}
        >
          <div className={classes.calendarWrapper}>
            <DateRange
              ranges={[
                {
                  startDate: this.state.fromDate,
                  endDate: this.state.toDate,
                  key: 'selection',
                },
              ]}
              onChange={this.onSelectDateRanges}
              staticRanges={undefined}
              inputRanges={undefined}
              maxDate={this.maxDate}
              showMonthAndYearPickers={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={false}
              scroll={{ enabled: true }}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);

DateRangePicker.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
};
