import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import React, { useEffect, useState } from 'react';

import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import apiService from 'src/services/api.service';

import FocusShipmentCheckbox from './TrackAndTrace/FocusShipmentCheckbox';
import Map from './TrackAndTrace/Map';
import Milestones from './TrackAndTrace/MileStones';
import SearchBox from './TrackAndTrace/SearchBox';
import SubscribeButton from './TrackAndTrace/SubscribeButton';
import TabsContainer from './TrackAndTrace/TabsContainer';

const useStyles = makeStyles((theme) => ({
  mapAndMilestonePaper: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 590,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  subscribeButton: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
  },
  focusCheckbox: {
    padding: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
    top: 40,
  },
}));

am4core.useTheme(am4themes_material);
am4core.addLicense('255064348');
am4core.useTheme(am4themes_animated);

function TrackAndTrace(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [shipmentTrackingId, setShipmentTrackingId] = useState();
  const [shipmentTrackingModel, setShipmentTrackingModel] = useState();
  const { catchApiError } = useCatchAPIError();
  const { enabledTrackAndTrace } = useCustomerAccountFeatures();
  const { accountId } = useAccounts();

  useEffect(() => {
    setShipmentTrackingId(undefined);
  }, [accountId]);

  useEffect(() => {
    let isCancelled = false;

    if (accountId && shipmentTrackingId) {
      setIsLoading(true);
      apiService
        .getShipmentTrackingById(accountId, shipmentTrackingId)
        .then((result) => {
          if (!isCancelled) {
            setShipmentTrackingModel(result.data);
          }
          setIsLoading(false);
        })
        .catch(catchApiError);
    } else {
      setShipmentTrackingModel(undefined);
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, shipmentTrackingId]);

  const handleSelectedShipmentTrackingId = (shipmentTrackingId) => {
    setShipmentTrackingId(shipmentTrackingId);
  };

  return (
    <EntityListFrame inPaper={!enabledTrackAndTrace}>
      {enabledTrackAndTrace ? (
        <>
          <Grid item xs={12}>
            <SearchBox
              accountId={accountId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleSelectedShipmentTrackingId={handleSelectedShipmentTrackingId}
            ></SearchBox>
          </Grid>

          {shipmentTrackingModel ? (
            <>
              <Grid item xs={12} md={6}>
                <TabsContainer shipmentTrackingModel={shipmentTrackingModel} isLoading={isLoading}></TabsContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.mapAndMilestonePaper}>
                  <Grid container>
                    <Grid item xs>
                      <Milestones shipmentTrackingModel={shipmentTrackingModel}></Milestones>
                    </Grid>
                  </Grid>
                  <Map shipmentTrackingModel={shipmentTrackingModel}></Map>
                  <SubscribeButton shipmentTrackingModel={shipmentTrackingModel} className={classes.subscribeButton} />
                  <FocusShipmentCheckbox shipmentNumber={shipmentTrackingModel.shipmentNumber} className={classes.focusCheckbox} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}></Grid>
          )}
        </>
      ) : (
        <FeatureDisabledMessage />
      )}
    </EntityListFrame>
  );
}

export default TrackAndTrace;
