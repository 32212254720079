import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, makeStyles, Divider } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    fontFamily: theme.font.menu,
  },
  divider: {
    marginBottom: 10,
  },
}));

export default function SuppliersControl(props) {
  const { suppliers, selectedSupplierValues, handleSupplierValuesChange } = props;
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
      <InputLabel id="suppliers-label">
        <TitleFont>Suppliers</TitleFont>
      </InputLabel>
      <Select
        className={classes.select}
        labelId="suppliers-label"
        id="suppliers-select"
        label="Suppliers"
        value={(suppliers && selectedSupplierValues) || ''}
        onChange={handleSupplierValuesChange}
        autoWidth={true}
      >
        <MenuItem value="All" key="suppliers-select-all">
          ALL
        </MenuItem>
        <Divider className={classes.divider}></Divider>
        {suppliers &&
          suppliers.map((item) => (
            <MenuItem key={`suppliers-select${item.id}`} value={item.id}>
              {item.id}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
