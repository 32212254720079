import moment from 'moment';
import React, { useState } from 'react';

import { Card, CardContent, Collapse, Grid, makeStyles } from '@material-ui/core';

import { DeleteButton } from '../components/DeleteButton';
import { ExpandButton } from '../components/ExpandButton';
import useShipmentBookingEntry from '../hooks/useShipmentBookingEntry';
import { AttachedOrderLineEntry } from './AttachedOrderLineEntry';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
  },
  orderSelectionSection: {
    backgroundColor: 'lightgrey',
    borderRadius: 5,
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'end',
  },
}));

export const AttachedOrderEntry = (props) => {
  const { order } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const { readOnly, removePurchaseOrder } = useShipmentBookingEntry();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container className={classes.orderSelectionSection} spacing={1}>
            <Grid item xs={4} className={classes.infoSection}>
              <b>Order No.: </b>
              {order.orderNumber}
            </Grid>
            <Grid item xs={4} className={classes.infoSection}>
              <b>Ordered Date: </b>
              {moment(order.orderedDate).format('l')}
            </Grid>
            <Grid item xs className={classes.actionsSection}>
              {!readOnly && (
                <DeleteButton
                  onClick={() => {
                    removePurchaseOrder(order.orderNumber);
                  }}
                />
              )}
              <ExpandButton expanded={expanded} onExpandedChanged={setExpanded} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {order &&
                order.lines &&
                order.lines.map((line, index) => {
                  return (
                    <Grid item xs={12} key={`AttachedOrderLineEntry${order.orderNumber}${line.lineNumber}`}>
                      <AttachedOrderLineEntry orderNumber={order.orderNumber} orderLine={line} />
                    </Grid>
                  );
                })}
            </Collapse>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
