import React from 'react';

import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  select: {
    fontFamily: theme.font.menu,
  },
}));

export default function PeriodsControl(props) {
  const { id, title, periods, selectedPeriodValue, handlePeriodChange, disablePeriodMenuItem } = props;

  const classes = useStyles();
  let labelId = id + '-label';

  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
      <InputLabel id={labelId}>
        <TitleFont>{title}</TitleFont>
      </InputLabel>
      <Select
        className={classes.select}
        labelId={labelId}
        id={id}
        label={title}
        value={(periods && selectedPeriodValue) || ''}
        onChange={handlePeriodChange && handlePeriodChange}
      >
        {periods &&
          periods.map((item) => {
            return (
              <MenuItem key={`${id}${item.id}`} value={item.id} disabled={disablePeriodMenuItem && disablePeriodMenuItem(item)}>
                {item.displayName}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
