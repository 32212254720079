import apiHelperService from './api.helper.service';

class VendorAccountApiService {
  createVendorAccount = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/vendor-account/command/create', formData, params);
  };

  updateVendorAccount = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/vendor-account/command/update', formData, params);
  };

  deleteVendorAccount = (id) => {
    return apiHelperService.deleteCallAPIWrapper('/api/v1/vendor-account/command/delete/' + id);
  };

  getVendorAccount = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/account/${id}`);
  };

  getVendorAccounts = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/accounts`, {
      params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage },
    });
  };

  getSupplierEntry = (vendorAccountId, supplierId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/supplier/get-supplier-entry`, {
      params: {
        vendorAccountId: vendorAccountId,
        supplierId: supplierId,
      },
    });
  };

  getSupplierListItems = (vendorAccountId, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/supplier/get-supplier-list-items`, {
      params: { vendorAccountId: vendorAccountId, Page: 1, SortDirection: order, SortColumn: orderBy, PageSize: 500 },
    });
  };

  createSupplier = (params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/vendor-account/command/supplier/create', params);
  };

  updateSupplier = (params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/vendor-account/command/supplier/update', params);
  };

  deleteSupplier = (vendorAccountId, supplierId) => {
    return apiHelperService.deleteCallAPIWrapper('/api/v1/vendor-account/command/supplier/delete', {
      params: {
        vendorAccountId: vendorAccountId,
        supplierId: supplierId,
      },
    });
  };

  // getMappedSupplierOrganizations = (supplierAccountId, page, rowsPerPage, order, orderBy) => {
  //   return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/mapped-supplier-organizations`, {
  //     params: { supplierAccountId: supplierAccountId, Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage },
  //   });
  // };

  getMyVendorAccountSelectItems = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/my-vendor-account-select-items`);
  };

  // getMappingSupplierOrganizations = (customerAccountId, forSupplierAccountId) => {
  //   return apiHelperService.getCallAPIWrapper(`/api/v1/vendor-account/query/mapping-supplier-organizations`, {
  //     params: { customerAccountId: customerAccountId, forSupplierAccountId: forSupplierAccountId },
  //   });
  // };

  // mapSupplierOrganization = (customerAccountId, supplierAccountId, supplierOrganizationId) => {
  //   return apiHelperService.postCallAPIWrapper('/api/v1/vendor-account/command/map-supplier-organization', {
  //     customerAccountId: customerAccountId,
  //     supplierAccountId: supplierAccountId,
  //     supplierOrganizationId: supplierOrganizationId,
  //   });
  // };
}

export default new VendorAccountApiService();
