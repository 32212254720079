import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 180,
    width: '100%',
  },
  select: {
    fontFamily: theme.font.menu,
  },
}));

const SelectionControl = ({ id, title, items, onChange, showAll = true }) => {
  const labelId = id + '-label';
  const classes = useStyles();
  const [value, setValue] = useState('');

  const handleOnChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValue(newValue);
      onChange && onChange(newValue);
    },
    [onChange]
  );

  return (
    <FormControl variant="outlined" className={clsx(classes.formControl, 'exclude')}>
      <InputLabel id={labelId}>
        <TitleFont>{title}</TitleFont>
      </InputLabel>
      <Select labelId={labelId} id={`select-${labelId}`} label={title} value={value} onChange={handleOnChange}>
        {showAll && <MenuItem value={''}>All</MenuItem>}
        {items &&
          items.map((item, index) => (
            <MenuItem key={`${id}-item-${index}`} value={item}>
              {item}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectionControl;
