import React, { useState, useEffect } from 'react';
import SubscribeToggleButton from '../../parts/SubscribeToggleButton';
import apiService from '../../../services/api.service';


function SubscribeButton(props) {
  const { shipmentTrackingModel, className } = props;
  const [subscribe, setSubscribe] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const loadSubscribeStatusAsync = async (shipmentNumber) => {
    var result = await apiService.getShipmentStatteSubscribeStatus(shipmentNumber);
    if (result) {
      setSubscribe(result.data.isSubscribed);
      setEnabled(result.data.enabledNotification);
    }
  };

  const subscribeShipmentAsync = async (shipmentNumber) => {
    await apiService.subscribeShipmentState(shipmentNumber);
    loadSubscribeStatusAsync(shipmentNumber);
  };

  const unsubscribeShipmentAsync = async (shipmentNumber) => {
    await apiService.unsubscribeShipmentState(shipmentNumber);
    loadSubscribeStatusAsync(shipmentNumber);
  };

  useEffect(() => {
    if (shipmentTrackingModel && shipmentTrackingModel.shipmentNumber) {
      loadSubscribeStatusAsync(shipmentTrackingModel.shipmentNumber);
    }
  }, [shipmentTrackingModel]);

  const handleClickSubscribe = (event) => {
    if (shipmentTrackingModel && shipmentTrackingModel.shipmentNumber) {
      if (subscribe) {
        unsubscribeShipmentAsync(shipmentTrackingModel.shipmentNumber);
      } else {
        subscribeShipmentAsync(shipmentTrackingModel.shipmentNumber);
      }
    }
  };

  return (
    <div className={className}>
      <SubscribeToggleButton selected={subscribe} enabled={enabled} onChange={handleClickSubscribe}/>
    </div>
  );
}

export default SubscribeButton;
