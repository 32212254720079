import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../Card/Card';
import CardHeader from '../../../Card/CardHeader.js';
import CardIcon from '../../../Card/CardIcon.js';
import CardFooter from '../../../Card/CardFooter.js';
import TitleFont from '../../../Typography/TitleFont';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  value: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  footer: {
    height: 15,
  },
}));

export default function AmountBox(props) {
  const classes = useStyles();
  const { title, valueText, iconClassName, color = 'warning', iconComponent } = props;

  return (
    <Card>
      <CardHeader color={color} stats icon>
        <CardIcon color={color} className={iconClassName}>
          {iconComponent}
        </CardIcon>
        <TitleFont>
          <p className={classes.title}>{title}</p>
        </TitleFont>
        <h3 className={classes.value}>{valueText}</h3>
      </CardHeader>
      <CardFooter stats className={classes.footer} />
    </Card>
  );
}

AmountBox.propTypes = {
  title: PropTypes.string,
  valueText: PropTypes.string,
  iconClassName: PropTypes.string,
  color: PropTypes.oneOf(['warning', 'success', 'danger', 'info', 'primary', 'rose']),
  iconComponent: PropTypes.node,
};
