import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& img': {
      right: 10,
      width: 19,
      bottom: 26,
      position: 'absolute',
    },
  },
}));

export default function ChartLogo(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <a href="//www.fickletech.com" target="_blank">
        <img src="../../../images/iconSmallFickle.png" style={props.Style} title="Fickle Tech" />
      </a>
    </div>
  );
}
