import fileDownload from 'js-file-download';
import moment from 'moment';
import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';

import EDIList from './EDIList';
import FileSearchBox from './FileSearchBox';

const headCells = [
  {
    id: 'DocumentNumber',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'SentTime',
    numeric: true,
    disablePadding: false,
    label: 'Sent Time',
  },
];

function FileSentList(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('DocumentNumber');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [rows, setRows] = React.useState('');
  const [items, setItems] = React.useState([]);
  const [searchCriteria, setSearchCriteria] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);

  const { accountId } = useAccounts();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  useEffect(() => {
    if (searchCriteria && accountId) getData();
  }, [rowsPerPage, page, searchCriteria, accountId]);

  const handleSearchChanged = (data) => {
    setPage(0);
    setSearchCriteria(data);
  };

  const getData = (inData) => {
    startProgress();
    let data = inData !== undefined ? inData : searchCriteria;
    apiService
      .getEDIFilesSentTable(accountId, data.start, data.end, order, orderBy, page * rowsPerPage, rowsPerPage, data.searchText)
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setItems(result.data.items);
        setTotalNumberOfRows(result.data.count);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      var momentTime = moment(item.sentTime);
      newArray.push([item.ediId, item.documentNumber, momentTime.format('l LT')]);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const handleDownload = (event) => {
    var ediId = event.currentTarget.parentNode.dataset.tag;
    let ediItem = items.find((item) => item.ediId === ediId);
    var momentTime = moment(ediItem.sentTime);
    let fileName = `${ediItem.documentNumber}_sent_${momentTime.format('MM-DD-YYYY_HH-mm')}.xml`;
    apiService
      .downloadEDIFileById(ediId)
      .then((res) => {
        fileDownload(res, fileName);
      })
      .catch(catchApiError);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FileSearchBox callbackSearchChanged={handleSearchChanged} />
        </Grid>
        <Grid item xs={12}>
          {rows && (
            <EDIList
              title=""
              headCells={headCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              page={page}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              downloadHandler={handleDownload}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default FileSentList;
