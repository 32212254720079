import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import TableToolbar from 'src/components/parts/TableToolbar';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import usePermissions from 'src/hooks/usePermissions';
import shipmentBookingApiService from 'src/services/shipmentBooking.api.service';

import RequestBookingPurchaseOrdersDataTableFilter from './components/TableFilter';

const useStyles = makeStyles((theme) => ({
  addIcon: {
    color: theme.palette.primaryButton.backgroundColor,
  },
}));

const headCells = [
  {
    id: 'OrderNumber',
    numeric: false,
    disablePadding: true,
    label: 'Order No.',
  },
  {
    id: 'LinesDescriptions',
    numeric: false,
    disablePadding: false,
    label: 'Descriptions',
  },
  {
    id: 'ReceiverName',
    numeric: false,
    disablePadding: false,
    label: 'Receiver',
  },
  {
    id: 'OrderedDate',
    numeric: true,
    disablePadding: false,
    label: 'Ordered Date',
  },
  {
    id: 'NearestShippingDate',
    numeric: true,
    disablePadding: false,
    label: 'Shipping Date',
  },
  {
    id: 'TotalAvailableLines',
    numeric: true,
    disablePadding: false,
    label: 'Requested Lines',
  },
];

export function RequestBookingPurchaseOrdersContainer(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const history = useHistory();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { catchApiError } = useCatchAPIError();
  const { accountId } = useAccounts();
  const { canManageShipmentBookings, canReadShipmentBookings } = usePermissions();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    let cancelled = false;
    if (accountId) {
      startProgress();
      shipmentBookingApiService
        .getRequestBookingPurchaseOrdersTable(accountId, page + 1, rowsPerPage, order, orderBy, null, null, filter.freeTextSearch)
        .then((result) => {
          stopProgress();
          if (!cancelled) {
            setRows(createTableListData(result.data.list));
            setTotalNumberOfRows(result.data.totalItems);
          }
        })
        .catch(catchApiError);
    }

    return () => {
      cancelled = true;
    };
  }, [history, order, orderBy, rowsPerPage, page, accountId, filter]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let orderedDate = moment(item.orderedDate);
      let nearestShippingDate = moment(item.nearestShippingDate);

      var descriptions = { component: 'textList', texts: [] };
      if (item.linesDescriptions) {
        item.linesDescriptions.map((description) => {
          descriptions.texts.push({ text: description });
        });
      }

      let rowData = {
        id: item.orderNumber,
        OrderNumber: item.orderNumber,
        ReceiverName: item.receiverName,
        TotalAvailableLines: item.totalAvailableLines,
        OrderedDate: orderedDate.isValid() ? orderedDate.format('l') : '',
        NearestShippingDate: nearestShippingDate.isValid() ? nearestShippingDate.format('l') : '',
        LinesDescriptions: descriptions,
      };

      newArray.push(rowData);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleFilterChanged = (filterData) => {
    setFilter(filterData);
  };

  const handleCreateShipmentBookingClick = (event) => {
    let selectedOrderNumber = event.currentTarget.parentNode.dataset.tag;
    history.push(`/shipment-bookings/add?orderNumber=${selectedOrderNumber}`);
  };

  const createShipmentBookingRowActionComponent = useMemo(() => {
    if (canManageShipmentBookings) {
      return (
        <Tooltip title="Create Shipment Booking" placement="left">
          <IconButton aria-label="create" onClick={handleCreateShipmentBookingClick}>
            <NoteAddIcon size="small" className={classes.addIcon} />
          </IconButton>
        </Tooltip>
      );
    } else {
      return null;
    }
  }, [canManageShipmentBookings]);

  return (
    <>
      <EntityListFrame inPaper={true}>
        {canReadShipmentBookings ? (
          <>
            <Grid item xs={12}>
              <TableToolbar title="Request Booking Purchase Orders" />
              <Grid item xs={12}>
                <RequestBookingPurchaseOrdersDataTableFilter onFilterChanged={handleFilterChanged} />
              </Grid>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowActionComponent={createShipmentBookingRowActionComponent}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}
