import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { UserContext } from '../../../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.textFieldExternalApplicationKey.backgroundColor
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  title: {
    width: 115,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    justifyContent: 'flex-start',
    height: 44,
    color: '#FAFAFA',
    backgroundColor: theme.palette.textFieldExternalApplicationKey.titleBackgroundColor
  },
}));

export default function TextFieldExternalApplicationKey(props) {
  const classes = useStyles();
  const { externalApplicationKey, onRefreshButtonClick, title } = props;

  const [userContext] = useContext(UserContext);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
  });

  const onCopy = () => {
    setSnackbar({
      open: true,
      message: `Copied to clipboard.`,
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleRefreshButtonClick = () => {
    if (onRefreshButtonClick) onRefreshButtonClick();
  };

  const canManage = () => {
    return userContext && userContext.permissions && userContext.permissions.canManageExternalApplications;
  };

  return (
    <>
      <Paper className={classes.root} elevation={2}>
        <Box className={classes.title}>{title}</Box>
        <InputBase className={classes.input} inputProps={{ 'aria-label': 'key' }} value={externalApplicationKey} readOnly={true} />
        {canManage() && (
          <IconButton className={classes.iconButton} aria-label="regenerate key" onClick={handleRefreshButtonClick} title="Regenerate Key">
            <RefreshIcon />
          </IconButton>
        )}
        <CopyToClipboard onCopy={onCopy} text={externalApplicationKey}>
          <IconButton color="primary" className={classes.iconButton} aria-label="copy" title="Copy to clipboard">
            <FileCopyIcon />
          </IconButton>
        </CopyToClipboard>
      </Paper>
      <Snackbar open={snackbar.open} autoHideDuration={2000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={'info'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
