class ShipmentBookingValidation {
  createWarningMessage = (text) => {
    return {
      status: 'warning',
      text: text,
    };
  };

  createErrorMessage = (text) => {
    return {
      status: 'error',
      text: text,
    };
  };

  _validateOrderLineQty = (orderLine, overflowPercentageOnQty) => {
    if (orderLine.qty != '' && orderLine.qty > 0 && orderLine.active) {
      if (orderLine.qty < orderLine.remainingQty) {
        return this.createWarningMessage('Qty is less than Remaining Qty');
      } else if (orderLine.qty > orderLine.remainingQty) {
        if (overflowPercentageOnQty == 0) {
          return this.createErrorMessage('Cannot more than Remaining Qty');
        } else {
          let overflowQty = (orderLine.orderedQty * overflowPercentageOnQty) / 100;
          let limitQty = Math.floor(orderLine.remainingQty + overflowQty);
          if (orderLine.qty > limitQty) {
            return this.createErrorMessage(`Cannot more than ${limitQty}`);
          } else {
            return this.createWarningMessage('Qty is more than Remaining Qty');
          }
        }
      }
    } else {
      return null;
    }
  };

  validate = (entry, overflowPercentageOnQty) => {
    let messages = {};
    let totalErrors = 0;
    if (entry.orders) {
      entry.orders.forEach((order) => {
        order.lines.forEach((line) => {
          let messageKey = `Order#${order.orderNumber}_Line#${line.lineNumber}_Qty`;
          let message = this._validateOrderLineQty(line, overflowPercentageOnQty);
          messages[messageKey] = message;
          if (message) {
            totalErrors++;
          }
        });
      });
    }

    return {
      valid: totalErrors == 0,
      messages: messages,
    };
  };
}
export default new ShipmentBookingValidation();
