import clsx from 'clsx';
import fileDownload from 'js-file-download';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { Box, Grid, makeStyles } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import apiService from '../../../services/api.service';
import LoadingOverlay from '../../parts/LoadingOverlay';

const useDocumentsItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  overlayContainer: {
    position: 'relative',
  },
  documentRow: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '0.875rem',
    '& a': {
      color: theme.palette.shipmentTracking.documents.fontColor,
    },
  },
  documentIcon: {
    fontSize: '1rem',
    marginTop: -3,
  },
  hr: {
    marginBottom: 0,
    marginTop: 0,
  },
}));

function DocumentsItem(props) {
  const classes = useDocumentsItemStyles();
  const history = useHistory();
  const { shipmentNumber, model, index } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadLinkClicked = (event, shipmentTrackingDocumentId, filename) => {
    setIsDownloading(true);
    event.preventDefault();

    apiService
      .downloadShipmentTrackingDocumentById(shipmentNumber, shipmentTrackingDocumentId)
      .then((res) => {
        fileDownload(res, filename);
        setIsDownloading(false);
      })
      .catch((err) => {
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');

        setIsDownloading(false);
      });
  };

  return (
    <Grid container item xs={12} className={classes.root}>
      <Grid container item xs={12} className={classes.overlayContainer}>
        <LoadingOverlay IsLoading={isDownloading} Size={'small'} />
        <Grid item xs={12} className={classes.documentRow}>
          <a href="#" onClick={(e) => handleDownloadLinkClicked(e, model.shipmentTrackingDocumentId, model.filename)}>
            <DescriptionOutlinedIcon className={classes.documentIcon} /> {model.filename}
          </a>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.hr} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const useDocumentsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  scrollableList: {
    overflowY: 'auto',
  },
  emptyMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
}));

export default function Documents(props) {
  const { shipmentTrackingModel } = props;
  const classes = useDocumentsStyles();

  return (
    <Box className={clsx(props.className, classes.root)}>
      <Grid container className={classes.scrollableList}>
        {shipmentTrackingModel &&
          (shipmentTrackingModel.documents && shipmentTrackingModel.documents.length > 0 ? (
            shipmentTrackingModel.documents.map((item, index) => {
              return <DocumentsItem key={'documentItem' + index} shipmentNumber={shipmentTrackingModel.shipmentNumber} model={item} index={index} />;
            })
          ) : (
            <Grid item xs={12} className={classes.emptyMessage}>
              There is no document on this shipment.
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
