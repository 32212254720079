import apiHelperService from './api.helper.service';

class CustomerAccountApiService {
  getCustomerAccounts = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/customer-account/query/accounts`, {
      params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage },
    });
  };
  
  getCustomerAccountsOptions = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/customer-account/query/accounts-options`);
  };

  getCustomerAccount = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/customer-account/query/account/${id}`);
  };

  getCustomerAccountFeaturesEntry = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/customer-account/query/account-features-entry/${id}`);
  };

  getCustomerAccountFeatures = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/customer-account/query/account-features/${id}`);
  };

  createCustomerAccount = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/customer-account/command/create', formData, params);
  };

  updateCustomerAccount = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/customer-account/command/update', formData, params);
  };

  deleteCustomerAccount = (id) => {
    return apiHelperService.deleteCallAPIWrapper('/api/v1/customer-account/command/delete/' + id);
  };

  updateCustomerAccountFeatures = (formData) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/customer-account/command/update-account-features`, formData);
  };
}

export default new CustomerAccountApiService();
