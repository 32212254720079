import { faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '200px',
    minWidth: '200px',
    outline: '0 !important',
  },
  exportIcon: {
    color: theme.palette.exportMenu.backgroundColor,
    // color: theme.palette.exportMenu.fontColor,
    // boxShadow: '3px 4px 7px -1px rgb(0 0 0 / 48%)',
    width: 38,
    height: 38,
    padding: 6,
    borderRadius: '50%',
    marginLeft: 8,
    '&:hover': {
      boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 80%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 80%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.8);',
    },
  },
  menuItemIcon: {
    minWidth: 30,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:active': {
      backgroundColor: theme.palette.menuDrawer.main,
      '& div': {
        color: theme.palette.menuDrawer.text + ' !important',
      },
    },
  },
}))(MenuItem);

function ExportMenu(props) {
  const classes = useStyles();
  let { className, onPdfClick, onCsvClick, ...otherProps } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Tooltip title="Export Data" placement="top" arrow>
        <IconButton onClick={handleClick} color="default" className={classes.exportIcon}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {onPdfClick ? (
          <StyledMenuItem
            onClick={() => {
              onPdfClick();
              handleClose();
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <FontAwesomeIcon icon={faFilePdf} size="lg" />
            </ListItemIcon>
            <ListItemText primary="Export to PDF" />
          </StyledMenuItem>
        ) : (
          ''
        )}
        {onCsvClick ? (
          <StyledMenuItem
            onClick={() => {
              onCsvClick();
              handleClose();
            }}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <FontAwesomeIcon icon={faFileCsv} size="lg" />
            </ListItemIcon>
            <ListItemText primary="Export to CSV" />
          </StyledMenuItem>
        ) : (
          ''
        )}
      </StyledMenu>
    </>
  );
}

export default ExportMenu;
