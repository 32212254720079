import moment from 'moment';
import React from 'react';

import { Box, Grid, Switch, makeStyles } from '@material-ui/core';

import NumberFormatCustom from 'src/wrappers/NumberFormatCustom';

import { EntryTextField } from '../components/EntryTextField';
import useShipmentBookingEntry from '../hooks/useShipmentBookingEntry';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(17, 17, 26, 0.5) 0px 1px 0px;',
  },
  textField: {
    marginTop: 3,
  },
  qtyBox: {
    height: '100%',
    backgroundColor: theme.palette.inputControl.backgroundColor,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    marginTop: 2,
  },
  qtyTextField: {
    marginTop: 6,
  },
  actionsSection: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '20px !important',
  },
}));

export const AttachedOrderLineEntry = (props) => {
  const { orderNumber, orderLine } = props;

  const classes = useStyles();
  const { messages, readOnly, setActivePurchaseOrderLine, setPurchaseOrderLineQty, validateFormEntry } = useShipmentBookingEntry();

  return (
    <Grid container className={classes.root} spacing={1}>
      <Grid container item xs={12} md lg={6} spacing={1}>
        <Grid item xs={6} md={4} lg={2}>
          <EntryTextField label="Line No." name="lineNo" value={orderLine.lineNumber} readOnly={true} active={orderLine.active} />
        </Grid>
        <Grid item xs={6} md={8} lg={2}>
          <EntryTextField label="Code" name="code" value={orderLine.code} readOnly={true} active={orderLine.active} />
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <EntryTextField label="Description" name="description" value={orderLine.description} readOnly={true} active={orderLine.active} />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} lg={2} spacing={1}>
        <Grid item xs={6} md={12} lg={6}>
          <EntryTextField
            label="Shipping Date"
            name="orderedDate"
            value={moment(orderLine.shippingDate).format('l')}
            readOnly={true}
            active={orderLine.active}
          />
        </Grid>
        <Grid item xs={6} md={12} lg={6}>
          <EntryTextField
            label="Delivery Date"
            name="deliveryDate"
            value={moment(orderLine.deliveryDate).format('l')}
            readOnly={true}
            active={orderLine.active}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2} lg={2} spacing={1}>
        <Grid item xs={6} md={12} lg={6}>
          <EntryTextField
            label="Ordered Qty"
            name="orderedQty"
            value={orderLine.orderedQty}
            readOnly={true}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            active={orderLine.active}
          />
        </Grid>
        <Grid item xs={6} md={12} lg={6}>
          <EntryTextField
            label="Remaining Qty"
            name="remainingQty"
            value={orderLine.remainingQty}
            readOnly={true}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            active={orderLine.active}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={3} lg spacing={1}>
        <Grid item xs>
          <Box className={classes.qtyBox}>
            <EntryTextField
              className={classes.qtyTextField}
              label="Qty"
              name="qty"
              value={orderLine.active ? orderLine.qty : ''}
              readOnly={readOnly || !orderLine.active}
              onValueChange={(value) => {
                setPurchaseOrderLineQty(orderNumber, orderLine.lineNumber, value);
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              helperTextStatus={messages[`Order#${orderNumber}_Line#${orderLine.lineNumber}_Qty`]?.status}
              helperText={messages[`Order#${orderNumber}_Line#${orderLine.lineNumber}_Qty`]?.text}
              onDelayValueChange={() => {
                validateFormEntry();
              }}
            />
          </Box>
        </Grid>
        <Grid item xs="auto" className={classes.actionsSection}>
          {!readOnly && (
            <Switch
              checked={orderLine.active}
              size="small"
              onChange={(e) => {
                setActivePurchaseOrderLine(orderNumber, orderLine.lineNumber, e.target.checked);
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
