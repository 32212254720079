import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CustomSwitch from './CustomSwitch';

const Components = {
  linkList: 'LinkList',
  textList: 'TextList',
  avatar: 'Avatar',
  image: 'Image',
  checkbox: 'Checkbox',
  tags: TagsCell,
};

export default function DataContentTableCell(block) {
  if (Components[block.component] === 'Image') {
    return React.createElement(() => <img src={block.image} alt="logo" />, { key: block.key });
  } else if (Components[block.component] === 'Avatar') {
    return React.createElement(() => <Avatar variant={block.variant} src={block.src} className={block.className}></Avatar>, { key: block.key });
  } else if (Components[block.component] === 'LinkList') {
    return React.createElement(
      () =>
        block.links.map((link, index) => {
          return (
            <div key={'ll' + block.key + '' + index}>
              {index > 0 ? <br /> : ''}
              <Link to={link.path}>{link.text}</Link>
            </div>
          );
        }),
      { key: block.key }
    );
  } else if (Components[block.component] === 'TextList') {
    return React.createElement(
      () => (
        <>
          {block.texts.map((text, index) => {
            return (
              <div key={'tl' + block.key + '' + index}>
                {index > 0 ? <hr /> : ''}
                {text.text}
              </div>
            );
          })}
        </>
      ),
      { key: block.key }
    );
  } else if (Components[block.component] === 'Checkbox') {
    return React.createElement(
      () => (
        <>
          <div key={block.key} style={{ textAlign: 'left' }}>
            <CustomSwitch
              key={block.key}
              checked={block.checked}
              onChange={block.action}
            />
          </div>
        </>
      ),
      { key: block.key }
    );
  } else if (typeof Components[block.component] !== 'undefined') {
    return React.createElement(Components[block.component], {
      key: block.key,
      block: block,
    });
  }
  return block.text;
}

const useTagCellStyles = makeStyles((theme) => ({
  tagContainer: {
    maxWidth: 555,
    overflowWrap: 'break-word',
    lineHeight: '30px',
    margin: 0,
  },
  tag: {
    whiteSpace: 'nowrap',
    padding: 5,
    paddingLeft: 7,
    paddingRight: 7,
    backgroundColor: theme.table.tag.color,
    color: 'white',
    borderRadius: 16,
    margin: 2,
  },
}));

function TagsCell(props) {
  const classes = useTagCellStyles();
  const { block } = props;
  return (
    <p className={classes.tagContainer}>
      {block.texts.map((text, index) => {
        return (
          <span key={'tg' + block.key + '' + index} className={classes.tag}>
            {text.text}
          </span>
        );
      })}
    </p>
  );
}
