import React from 'react';

import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';
import dateBaseOn from 'src/models/DateBaseOn';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
}));

const dateBaseOns = [
  { title: 'Order Date', value: dateBaseOn.orderDate },
  { title: 'ETA', value: dateBaseOn.eta },
  { title: 'ETD', value: dateBaseOn.etd },
  { title: 'ATA', value: dateBaseOn.ata },
  { title: 'ATD', value: dateBaseOn.atd },
];

export default function DateBasedOnControl(props) {
  const { selectedDateBasedOn, handleDateBasedOnChange } = props;
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
      <InputLabel id="basedOnLabel">
        <TitleFont> Based On</TitleFont>
      </InputLabel>
      <Select labelId="basedOnLabel" id="dateBasedOnsSelect" label="Based On" value={selectedDateBasedOn} onChange={handleDateBasedOnChange}>
        {dateBaseOns.map((x, index) => {
          return (
            <MenuItem value={x.value} key={`dateBased-${index}`}>
              {x.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
