import React, { useEffect, useState } from 'react';
import LineChart from './ChartTemplates/LineCharts.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function TransitTimePerOrderLineChart(props) {
  
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [avg, setAvg] = useState('-');
  const { accountId, productCode } = props;
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      setIsLoading(true);
      apiService
        .getTransitTimePerOrderProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.avgTransitTime ? setAvg(result.data.avgTransitTime) : setAvg('-');

            setChartData(prepareStuff(result.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }
    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  const prepareStuff = (data) => {
    var tempArray = [];
    if (data && data.items) {
      data.items.forEach((item) => {
        let delaySign = '';
        if (item.delay > 0) {
          delaySign = '+';
        } else if (item.delay < 0) {
          delaySign = '';
        }

        let itemObj = {
          date: item.date,
          value: item.transitTime,
          labelPrefix: item.orderNumber,
          labelSuffix: `(${delaySign}${item.delay} days)`,
        };
        tempArray.push(itemObj);
      });
    }

    return tempArray;
  };

  return (
    <LineChart
      chartId="transitTimePerOrderLineChart"
      isLoading={isLoading}
      chartData={chartData}
      title="Transit Time per Order"
      caption={`Avg. ${avg.toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })} Days`}
      tooltipValueFormat="{0} Days"
    />
  );
}
