import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Box, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import useCatchAPIError from 'src/hooks/useCatchAPIError';
import accountApiService from 'src/services/account.api.service';
import publicShipmentTrackingApiService from 'src/services/publicShipmentTracking.api.service';

import PublicPageFrame from '../layouts/PublicPageFrame';
import PageNotFound from '../parts/PageNotFound';
import Map from './PublicTrackAndTrace/Map';
import Milestones from './PublicTrackAndTrace/MileStones';
import SearchBox from './PublicTrackAndTrace/SearchBox';
import TabsContainer from './PublicTrackAndTrace/TabsContainer';

const useStyles = makeStyles((theme) => ({
  mapAndMilestonePaper: {
    borderRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 590,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  informationBox: {
    display: 'flex',
    justifyContent: 'center',
    // '& span': {
    //   backgroundColor: 'antiquewhite',
    //   paddingLeft: 3,
    //   paddingRight: 3,
    //   borderRadius: 5,
    // },
  },
}));

function PublicTrackAndTrace(props) {
  const classes = useStyles();
  let { accountAlias } = useParams();

  const [accountId, setAccountId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [featureEnabled, setFeatureEnabled] = useState(false);
  const [shipmentTrackingId, setShipmentTrackingId] = useState();
  const [shipmentTrackingModel, setShipmentTrackingModel] = useState();
  const [ready, setReady] = useState(false);
  const { catchApiError } = useCatchAPIError();

  useEffect(()=>{
    
  })

  useEffect(() => {
    let isCancelled = false;

    let initialize = async () => {
      try {
        let accountIdResult = await accountApiService.getAccountIdByAlias(accountAlias);
        if (accountIdResult.data) {
          let accountId = accountIdResult.data;
          let featureEnabledResult = await publicShipmentTrackingApiService.hasPublicTrackAndTraceEnabled(accountId);
          if (!isCancelled) {
            setAccountId(accountId);
            setFeatureEnabled(featureEnabledResult.data);
          }
        }
      } catch (err) {
        setAccountId(undefined);
        setFeatureEnabled(undefined);
        catchApiError(err);
      } finally {
        setReady(true);
      }
    };

    if (accountAlias) {
      initialize();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountAlias]);

  useEffect(() => {
    let isCancelled = false;

    if (accountId && shipmentTrackingId) {
      setIsLoading(true);
      publicShipmentTrackingApiService
        .getPublicShipmentTrackingById(accountId, shipmentTrackingId)
        .then((result) => {
          if (!isCancelled) {
            setShipmentTrackingModel(result.data);
          }
          setIsLoading(false);
        })
        .catch(catchApiError);
    } else {
      setShipmentTrackingModel(undefined);
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, shipmentTrackingId]);

  const handleSelectedShipmentTrackingId = (shipmentTrackingId) => {
    setShipmentTrackingId(shipmentTrackingId);
  };

  return (
    <PublicPageFrame>
      {ready && (
        <>
          {featureEnabled ? (
            <>
              <Grid item xs={12}>
                <SearchBox
                  accountId={accountId}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  handleSelectedShipmentTrackingId={handleSelectedShipmentTrackingId}
                ></SearchBox>
              </Grid>

              {shipmentTrackingModel ? (
                <>
                  <Grid item xs={12}>
                    <Box className={classes.informationBox}>
                      <span>
                        You're currently viewing limited information. To access more details please{' '}
                        <a href={`/trackandtrace?s=${shipmentTrackingModel.shipmentNumber}`}>log in</a>.
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TabsContainer shipmentTrackingModel={shipmentTrackingModel} isLoading={isLoading}></TabsContainer>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.mapAndMilestonePaper}>
                      <Grid container>
                        <Grid item xs>
                          <Milestones shipmentTrackingModel={shipmentTrackingModel}></Milestones>
                        </Grid>
                      </Grid>
                      <Map shipmentTrackingModel={shipmentTrackingModel}></Map>
                    </Paper>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}></Grid>
              )}
            </>
          ) : (
            <PageNotFound />
          )}
        </>
      )}
    </PublicPageFrame>
  );
}

export default PublicTrackAndTrace;
