import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { UserContext } from '../../../contexts/UserContext';
import { lightTheme } from '../../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  chart: {
    position: 'relative',
    height: 430,
    marginBottom: 0,
    padding: 0,
    paddingRight: 10,
    borderRadius: 5,
    backgroundColor: '#FFF',
  },
  chartSuperAdmin: {
    height: 400,
  },
}));

function EDIChart(props) {
  const classes = useStyles();
  const [userContext] = useContext(UserContext);
  const { Title, ChartId, ChartData, IsLoading = true } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(ChartId, am4charts.XYChart);

    if (ChartData && ChartData.items) {
      chart.logo.disabled = true;
      chart.paddingRight = 20;

      chart.data = ChartData.items;

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = {
        timeUnit: 'month',
        count: 1,
      };
      dateAxis.tooltipDateFormat = 'MMMM';
      dateAxis.renderer.grid.template.location = 0;
      dateAxis.renderer.minGridDistance = 30;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;

      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.tooltipText = '[bold]{valueY}[/]';
      series.strokeWidth = 2;
      series.stroke = am4core.color(lightTheme.chart.colors[0]);
      series.fill = am4core.color(lightTheme.chart.colors[0]);

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color('#fff');
      bullet.circle.strokeWidth = 2;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.opacity = 0;
      chart.scrollbarX = new am4charts.XYChartScrollbar();
      chart.scrollbarX.height = 30;
      chart.scrollbarX.series.push(series);

      dateAxis.keepSelection = true;
    }

    return () => {
      chart.dispose();
    };
  }, [ChartData]);

  return (
    <Box className={classes.root}>
      <LoadingOverlay IsLoading={IsLoading} />
      <Typography variant="h6">{Title}</Typography>
      <ContentFont>
        <div id={ChartId} className={clsx(classes.chart, { [classes.chartSuperAdmin]: userContext.isAdmin })} />
      </ContentFont>
    </Box>
  );
}

export default EDIChart;
