import React, { useState, useContext, useRef } from 'react';
import { Grid, Divider, TextField, Card, CardContent, CardActions, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingBar from 'react-top-loading-bar';
import { useHistory } from 'react-router';
import apiService from '../../../services/api.service';
import { UserContext } from '../../../contexts/UserContext';
import clsx from 'clsx';
import Button from '../../../wrappers/Button';
import Alert from '../../../wrappers/Alert';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

function ChangePassword(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [displayPasswordError, setDisplayPasswordError] = useState('');
  const [displayConfirmPasswordError, setDisplayConfirmPasswordError] = useState('');
  const [displayOldPasswordError, setDisplayOldPasswordError] = useState('');
  const history = useHistory();
  const progressRef = useRef(null);
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    cleanErrors();
    let error = false;

    if (!oldPassword || oldPassword.trim() === '') {
      setDisplayOldPasswordError('Old password is a required field');
      error = true;
    }

    if (!password || password.trim() === '') {
      setDisplayPasswordError('Password is a required field');
      error = true;
    }
    if (!confirmPassword || confirmPassword.trim() === '') {
      setDisplayConfirmPasswordError('Confirm password is a reuqired field');
      error = true;
    }

    if (!error) {
      if (password.trim() !== confirmPassword.trim()) {
        setDisplayConfirmPasswordError('Password and Confirm password does not match');
        error = true;
      }
    }

    if (!error) {
      progressRef.current.continuousStart();
      apiService
        .changePassword(oldPassword, password, confirmPassword)
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            for (let error of result.errors) {
              if (error.propertyName === 'OldPassword') setDisplayOldPasswordError(error.errorMessage);
              else if (error.propertyName === 'NewPassword') setDisplayPasswordError(error.errorMessage);
              else if (error.propertyName === 'ConfirmPassword') setDisplayConfirmPasswordError(error.errorMessage);
            }
          } else {
            setSnackbar({
              open: true,
              isSuccess: true,
              message: `Your password changed successfully`,
            });
          }

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const cleanErrors = () => {
    setDisplayOldPasswordError('');
    setDisplayPasswordError('');
    setDisplayConfirmPasswordError('');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar };
    snackbartemp.open = false;
    setSnackbar(snackbartemp);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity="error" severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4>Change Password</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Old Password"
                  margin="dense"
                  type="password"
                  name="oldpassword"
                  error={!(displayOldPasswordError === '')}
                  helperText={displayOldPasswordError === '' ? '' : displayOldPasswordError}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                  value={oldPassword || ''}
                />
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <TextField
                  fullWidth
                  label="New Password"
                  margin="dense"
                  type="password"
                  name="name"
                  error={!(displayPasswordError === '')}
                  helperText={displayPasswordError === '' ? '' : displayPasswordError}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  value={password || ''}
                />
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <TextField
                  fullWidth
                  label="Confirm New Password"
                  margin="dense"
                  type="password"
                  name="confirm"
                  error={!(displayConfirmPasswordError === '')}
                  helperText={displayConfirmPasswordError === '' ? '' : displayConfirmPasswordError}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  value={confirmPassword || ''}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button theme={theme} buttonType="primary" type="submit" className={classes.buttons}>
              Save
            </Button>
            <Button theme={theme} buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={() => history.goBack()}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
}

export default ChangePassword;
