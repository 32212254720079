import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MyTab, MyTabs, TabPanel } from '../../parts/MyTabs';
import PortPairChartsTabContainer from './PortPairChartsTabContainer';
import GeneralChartsTabContainer from './GeneralChartsTabContainer';
import { lightThemeAdmin } from '../../../themes/lightTheme';
import { darkTheme } from '../../../themes/darkTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
    position: 'relative',
  },
  controls: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

function TabsContainer(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const { baseQueryString, filterComponentData, trendOverTimeSettings } = props;
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const [tabsDataStorage, setTabDataStorage] = useState({});
  const [showTrendLine, setShowTrendLine] = useState(false);
  const [showAvgLine, setShowAvgLine] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addDataToTabsDataStorage = (valueObject) => {
    let tmpTabsDataStorage = tabsDataStorage;
    if (tmpTabsDataStorage === undefined) {
      tmpTabsDataStorage = {};
    }
    setTabDataStorage({ ...tmpTabsDataStorage, ...valueObject });
  };
  return (
    <div className={classes.root}>
      <MyTabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
        <MyTab label="Port Pair" key="portPairChartsTab" tabBackgroundColor={theme.palette.trendsOverTime.tabBackgroundColor} />
        <MyTab label="General" key="generalChartsTab" tabBackgroundColor={theme.palette.trendsOverTime.tabBackgroundColor} />
      </MyTabs>
      <TabPanel value={value} index={0} key="portPairChartsTabPanel" tabBackgroundColor={theme.palette.trendsOverTime.tabBackgroundColor}>
        <PortPairChartsTabContainer
          filterComponentData={filterComponentData}
          baseQueryString={baseQueryString}
          addDataToTabsDataStorage={addDataToTabsDataStorage}
          tabsDataStorage={tabsDataStorage}
          trendOverTimeSettings={trendOverTimeSettings}
          ShowTrendLine={showTrendLine}
          ShowAvgLine={showAvgLine}
        />
      </TabPanel>
      <TabPanel value={value} index={1} key="generalChartsTabPanel" tabBackgroundColor={theme.palette.trendsOverTime.tabBackgroundColor}>
        <GeneralChartsTabContainer
          filterComponentData={filterComponentData}
          baseQueryString={baseQueryString}
          ShowTrendLine={showTrendLine}
          ShowAvgLine={showAvgLine}
        />
      </TabPanel>
      {!filterComponentData ||
        !filterComponentData.comparePeriodFilter ||
        (!filterComponentData.comparePeriodFilter.enabledCompare && (
          <div className={classes.controls}>
            <FormControlLabel
              checked={showTrendLine}
              onChange={() => {
                setShowTrendLine(!showTrendLine);
              }}
              control={<Checkbox name="checkedBoxShowTrendLine" color="primary" />}
              label="Trend Line"
            />
            <FormControlLabel
              checked={showAvgLine}
              onChange={() => {
                setShowAvgLine(!showAvgLine);
              }}
              control={<Checkbox name="checkedBoxShowAvgLine" color="primary" />}
              label="Avg. Line"
            />
          </div>
        ))}
    </div>
  );
}

export default TabsContainer;
