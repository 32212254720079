import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Tooltip, makeStyles, withStyles } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';

const MilestoneStepConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: theme.shipmentTracking.milestones.lineColor,
    },
  },
  completed: {
    '& $line': {
      backgroundColor: theme.shipmentTracking.milestones.lineColor,
    },
  },
  disabled: {
    backgroundImage: 'linear-gradient(90deg, #ccc, #ccc 50%, transparent 50%, transparent 100%)',
    backgroundSize: '8px 2px',
  },
  line: {
    height: 3,
    border: 0,
    borderRadius: 1,
  },
}))(StepConnector);

const useStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#999',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#ffffff',
    border: '3px solid ' + theme.shipmentTracking.milestones.lineColor,
    color: theme.shipmentTracking.milestones.lineColor,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: theme.shipmentTracking.milestones.lineColor,
    border: '3px solid ' + theme.shipmentTracking.milestones.lineColor,
    color: theme.shipmentTracking.milestones.completedText,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  nextStep: {
    border: '3px solid ' + theme.shipmentTracking.milestones.lineColor,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
}));

function StepIcon(props) {
  const classes = useStepIconStyles();
  const { active, completed, isNextStep } = props;

  const icons = {
    1: <ShoppingCartOutlinedIcon />,
    2: <SyncAltOutlinedIcon />,
    3: <ArchiveOutlinedIcon />,
    4: <DoneOutlinedIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.nextStep]: isNextStep,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  isNextStep: PropTypes.bool,
  icon: PropTypes.node,
};

const useMilestoneStepStyles = makeStyles((theme) => ({
  nextStep: {
    '& div.Mui-disabled span': {
      backgroundImage:
        'linear-gradient(90deg, ' +
        theme.shipmentTracking.milestones.lineColor +
        ', ' +
        theme.shipmentTracking.milestones.lineColor +
        ' 50%, transparent 50%, transparent 100%)',
      backgroundSize: '8px 2px',
    },
  },
  step: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));

function MilestoneStep(props) {
  const classes = useMilestoneStepStyles();
  const { isNextStep, children, tooltipText, ...remainProps } = props;

  return (
    <Tooltip title={tooltipText ? tooltipText : ''} key={remainProps.key}>
      <Step
        className={clsx(
          {
            [classes.nextStep]: isNextStep,
          },
          classes.step
        )}
        {...remainProps}
      >
        {children}
      </Step>
    </Tooltip>
  );
}

function getSteps() {
  return ['Order Placed', 'In Transit', 'Arrival', 'Completed'];
}

export default function Milestones(props) {
  const { shipmentTrackingModel } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [shipmentTrackingDates, setShipmentTrackingDates] = useState();
  const steps = getSteps();

  useEffect(() => {
    setActiveStep(shipmentTrackingModel.currentMilestoneStep);
    setShipmentTrackingDates(shipmentTrackingModel.shipmentTrackingDates);
  }, [shipmentTrackingModel]);

  function getDateTimeText(dateTimeValue) {
    if (dateTimeValue) {
      var momentTime = moment(dateTimeValue);

      if (momentTime.isValid()) {
        return momentTime.format('L');
      }
    }
    return dateTimeValue;
  }

  const getTooltipText = (label) => {
    if ((shipmentTrackingDates && label === 'In Transit') || (shipmentTrackingDates && label === 'Arrival')) {
      let date = '';
      let title = '';
      if (label === 'In Transit') {
        date = shipmentTrackingDates.actualDepartureDate ? shipmentTrackingDates.actualDepartureDate : shipmentTrackingDates.estimatedDepartureDate;
        title = shipmentTrackingDates.actualDepartureDate ? 'departure ' : 'est. departure ';
      } else if (label === 'Arrival') {
        date = shipmentTrackingDates.actualArrivalDate ? shipmentTrackingDates.actualArrivalDate : shipmentTrackingDates.estimatedArrivalDate;
        title = shipmentTrackingDates.actualArrivalDate ? 'arrival ' : 'est. arrival ';
      }

      const newDateText = getDateTimeText(date);
      const tooltipText = newDateText ? title + moment(newDateText, 'DDMMYYYY').fromNow() + ' ' + `(${newDateText})` : title + ' -';

      return tooltipText;
    } else {
      return null;
    }
  };

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<MilestoneStepConnector />}>
      {steps.map((label, index) => {
        let isNextStep = activeStep + 1 == index;
        let tooltipText = getTooltipText(label);
        return (
          <MilestoneStep key={label} isNextStep={isNextStep} tooltipText={tooltipText}>
            <StepLabel StepIconComponent={StepIcon} StepIconProps={{ isNextStep: isNextStep }}>
              {label}
            </StepLabel>
          </MilestoneStep>
        );
      })}
    </Stepper>
  );
}
