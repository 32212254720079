import PropTypes from 'prop-types';
import React from 'react';

import { IconButton, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const EDITableCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))(TableCell);

const EDITableHeaderCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderBottomWidth: 0,
  },
}))(TableCell);

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader + ' tableHeader'}>
      <TableRow>
        {props.headCells.map((headCell) => {
          var textAlign = '';
          if (headCell.align) {
            textAlign = headCell.align;
          } else {
            textAlign = headCell.numeric ? 'right' : 'left';
          }
          return (
            <EDITableHeaderCell
              key={headCell.id}
              align={textAlign}
              //   padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableSortLabel}
              >
                {headCell.label}
                {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
              </TableSortLabel>
            </EDITableHeaderCell>
          );
        })}
        <EDITableHeaderCell align="right"></EDITableHeaderCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  actionButtonDownload: {
    color: theme.palette.primaryButton.backgroundColor,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  actionButton: {
    padding: theme.spacing(1),
  },
  tableSortLabel: {
    whiteSpace: 'nowrap',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.inputControl.backgroundColor,
    '& th:nth-child(1)': {
      borderRadius: '10px 0px 0px 10px',
    },
    '& th:nth-last-child(1)': {
      borderRadius: '0px 10px 10px 0px',
    },
  },
}));

export default function EDIList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  // const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const rows = props.rows;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    props.handleRequestSort(isAsc ? 'desc' : 'asc', property);
  };

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  const getTableCell = (cell, key, index) => {
    return (
      <EDITableCell align={getTableRowAlign(index)} key={`cell${key}${index}`}>
        {cell}
      </EDITableCell>
    );
  };

  const getTableRowAlign = (index) => {
    return props.headCells[index - 1].numeric ? 'right' : 'left';
  };

  return (
    <>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={props.headCells}
          />
          <TableBody>
            {rows.map((row, index) => {
              let key = 'key' + index;
              let entityKey = row[0];
              return (
                <TableRow role="checkbox" tabIndex={-1} key={key}>
                  {row[1] !== undefined ? getTableCell(row[1], key, 1) : null}
                  {row[2] !== undefined ? getTableCell(row[2], key, 2) : null}
                  <EDITableCell align="right" data-tag={entityKey}>
                    <IconButton aria-label="delete" className={classes.actionButton} data-tag={entityKey} onClick={props.downloadHandler}>
                      <DescriptionOutlinedIcon size="small" className={classes.actionButtonDownload} />
                    </IconButton>
                  </EDITableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.totalNumberOfRows}
        rowsPerPage={props.rowsPerPage}
        rowsPerPageOptions={[props.rowsPerPage]}
        page={props.page}
        onChangePage={handleChangePage}
      />
    </>
  );
}
