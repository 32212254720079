import { Box, Divider, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    backgroundColor: theme.palette.modal.backgroundColor,
    position: 'relative',
    flexDirection: 'column',
    padding: theme.spacing(3),
    transition: 'visibility 0.1s linear,opacity 0.1s linear',
    opacity: 0,
  },
  ready: {
    opacity: 1,
  },
  contentContainer: {
    overflowY: 'auto',
    marginTop: '0px !important',
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  divider: {
    marginRight: 12,
  },
  tableHead: {
    '& th': {
      fontWeight: 'bold',
    },
  },
}));

export default function PurchaseOrderDetails(props) {
  const classes = useStyles();
  const { orderNumber, onClose } = props;
  const [purchaseOrderDetailsItems, setPurchaseOrderDetailsItems] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = (orderNumber) => {
      apiService
        .getShipmentTrackingPurchaseOrderDetails(orderNumber)
        .then((result) => {
          if (!isCancelled) {
            result.data.items ? setPurchaseOrderDetailsItems(result.data.items) : setPurchaseOrderDetailsItems([]);
            setIsReady(true);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (orderNumber) {
      loadData(orderNumber);
    }

    return () => {
      isCancelled = true;
      setIsReady(false);
    };
  }, [orderNumber]);

  const handleCloseModalClick = () => {
    setIsReady(false);
    onClose();
  };

  return (
    <Paper className={clsx(classes.root, isReady && classes.ready)}>
      <Box>
        <h4 style={{ fontWeight: 'normal' }}>Order Number: {orderNumber}</h4>
        <IconButton aria-label="edit" onClick={handleCloseModalClick} className={classes.closeButton}>
          <CloseIcon size="small" />
        </IconButton>
        <Divider className={classes.divider} />
      </Box>
      <Grid container spacing={2} className={classes.contentContainer}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Line No.</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Qty</TableCell>
                  <TableCell align="right">Qty Met</TableCell>
                  <TableCell>Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrderDetailsItems &&
                  purchaseOrderDetailsItems.length > 0 &&
                  purchaseOrderDetailsItems.map((row) => (
                    <TableRow key={row.lineNumber}>
                      <TableCell component="th" scope="row">
                        {row.lineNumber}
                      </TableCell>
                      <TableCell>{row.productCode}</TableCell>
                      <TableCell>{row.productName}</TableCell>
                      <TableCell align="right">{row.qty}</TableCell>
                      <TableCell align="right">{row.qtyMet}</TableCell>
                      <TableCell>{row.unitName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
}

PurchaseOrderDetails.propTypes = {
  orderNumber: PropTypes.string,
  onClose: PropTypes.func,
};
