import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router';

import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import shipmentBookingStatus from 'src/models/ShipmentBookingStatus';
import shipmentBookingApiService from 'src/services/shipmentBooking.api.service';

import ShipmentBookingEntryModelFactory from './ShipmentBookingEntryModelFactory';
import { shipmentBookingEntryInitialState, shipmentBookingEntryReducer } from './ShipmentBookingEntryReducer';
import ShipmentBookingValidation from './ShipmentBookingValidation';

const ShipmentBookingEntryContext = createContext({
  ...shipmentBookingEntryInitialState,
  initialize: async (vendorAccountId, shipmentBookingId) => {},
  loadExistingData: async (vendorAccountId, shipmentBookingId) => {},
  addPurchaseOrder: async (orderNumber) => {},
  removePurchaseOrder: async (orderNumber) => {},
  setActivePurchaseOrderLine: (orderNumber, lineNumber, active) => {},
  setPurchaseOrderLineQty: (orderNumber, lineNumber, qty) => {},
  setShipper: (addressId) => {},
  setReceiver: (addressId) => {},
  setContainerVolume: (value) => {},
  setContainerSize: (id) => {},
  setCargoReadyDate: (value) => {},
  setIncoterms: (value) => {},
  setMarksAndNumbers: (value) => {},
  setGoodsDescription: (value) => {},
  validateFormEntry: () => {},
  saveDraft: async () => {},
  submit: async () => {},
  changeFormToEditMode: async () => {},
  discardDraft: async () => {},
});

export const ShipmentBookingEntryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(shipmentBookingEntryReducer, shipmentBookingEntryInitialState);
  const { vendorAccountId, shipperSelectionItems, receiverSelectionItems, shipper, receiver, orders, initialized, statusName, errors } = state;
  const { catchApiError } = useCatchAPIError();
  const {} = useGlobalLoadingBar();
  const { showSuccessSnackBar } = useGlobalSnackBar();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const history = useHistory();

  const initialize = async (accountId, shipmentBookingId) => {
    if (vendorAccountId != accountId) {
      //ShipperSelectItems
      let shipperSelectItemsResult = await shipmentBookingApiService.getShipperSelectItems(accountId);

      //EntrySettings
      let entrySettingsResult = await shipmentBookingApiService.getShipmentBookingEntrySettings(accountId);
      let containerSizeSelectionItems = entrySettingsResult.data.containerSizes.map((x) => ({ id: x.value, name: x.name }));

      //ShipperSelectItems
      let purchaseOrderSelectItemsResult = await shipmentBookingApiService.getShipmentBookingEntryPurchaseOrderSelectItems(accountId, shipmentBookingId);
      dispatch({
        type: 'initialize',
        payload: {
          vendorAccountId: accountId,
          shipperSelectionItems: shipperSelectItemsResult.data,
          containerSizeSelectionItems: containerSizeSelectionItems,
          overflowPercentageOnQty: entrySettingsResult.data.overflowPercentageOnQty,
          purchaseOrderSelectItems: purchaseOrderSelectItemsResult.data,
        },
      });
    }
  };

  const initializeEditEntryMode = async (accountId, shipmentBookingEntryModel) => {
    let bookingEntry = { ...shipmentBookingEntryModel, readOnly: false, orders: [] };
    //ShipperSelectItems
    if (shipmentBookingEntryModel.shipper?.name) {
      let selectedShipper = shipperSelectionItems.find((x) => x.name == shipmentBookingEntryModel.shipper.name);
      if (selectedShipper) {
        bookingEntry.shipper = selectedShipper;
      }
    }

    if (shipmentBookingEntryModel.orders && shipmentBookingEntryModel.orders.length) {
      //ReceiverSelectItems
      let orderNumbers = shipmentBookingEntryModel.orders.map((x) => x.orderNumber);
      let receiverSelectItemsResult = await shipmentBookingApiService.getReceiverSelectItems(accountId, orderNumbers);
      bookingEntry.receiverSelectionItems = receiverSelectItemsResult.data;

      //Orders
      for (let i = 0; i < shipmentBookingEntryModel.orders.length; i++) {
        let loadedOrder = shipmentBookingEntryModel.orders[i];
        let refreshOrderResult = await shipmentBookingApiService.getEntryPurchaseOrder(accountId, loadedOrder.orderNumber, shipmentBookingEntryModel.id);
        if (refreshOrderResult.data) {
          let orderEntry = refreshOrderResult.data;
          orderEntry.lines = refreshOrderResult.data.lines.map((refreshLine) => {
            let loadedLine = loadedOrder.lines.find((x) => x.lineNumber == refreshLine.lineNumber);
            return {
              ...refreshLine,
              active: loadedLine ? true : false,
              qty: loadedLine?.qty,
            };
          });
          bookingEntry.orders.push(orderEntry);
        }
      }
    }

    //get validation messages
    let { messages } = ShipmentBookingValidation.validate(bookingEntry, state.overflowPercentageOnQty);
    bookingEntry.messages = messages;

    dispatch({
      type: 'loadExistingBooking',
      payload: bookingEntry,
    });
  };

  const loadExistingData = async (accountId, shipmentBookingId) => {
    if (!initialized) throw new Error('Shipment Booking Entry need to be Initialized first.');

    let shipmentBookingEntryResult = await shipmentBookingApiService.getShipmentBookingEntry(accountId, shipmentBookingId);

    if (shipmentBookingEntryResult.data) {
      let shipmentBookingEntryModel = ShipmentBookingEntryModelFactory.createShipmentBookingEntryModel(accountId, shipmentBookingEntryResult.data);

      if (shipmentBookingEntryModel.statusName == shipmentBookingStatus.draft) {
        await initializeEditEntryMode(accountId, shipmentBookingEntryModel);
      } else {
        dispatch({
          type: 'loadExistingBooking',
          payload: shipmentBookingEntryModel,
        });
      }
    }
  };

  const addPurchaseOrder = async (orderNumber) => {
    try {
      if (orders.findIndex((x) => x.orderNumber == orderNumber) < 0) {
        let apiResult = await shipmentBookingApiService.getEntryPurchaseOrder(vendorAccountId, orderNumber, state.id);
        if (apiResult.data) {
          let orderEntry = apiResult.data;
          orderEntry.lines = apiResult.data.lines.map((x) => ({ ...x, active: true, qty: null }));
          dispatch({
            type: 'addOrder',
            payload: orderEntry,
          });

          //reload receivers
          let orderNumbers = orders.map((x) => x.orderNumber);
          orderNumbers = [...orderNumbers, orderNumber];
          // _loadReceiverSelectItemsAsync(vendorAccountId, orderNumbers);
          let receiverSelectItemsResult = await shipmentBookingApiService.getReceiverSelectItems(vendorAccountId, orderNumbers);
          dispatch({
            type: 'loadReceiverSelection',
            payload: receiverSelectItemsResult.data,
          });
        } else {
          let errorMessage = ShipmentBookingValidation.createErrorMessage('Search order not found.');
          dispatch({
            type: 'message',
            fieldName: 'Orders',
            payload: errorMessage,
          });
        }
      } else {
        let errorMessage = ShipmentBookingValidation.createErrorMessage('This order has been added to the list');
        dispatch({
          type: 'message',
          fieldName: 'Orders',
          payload: errorMessage,
        });
      }
    } catch (err) {
      catchApiError(err);
    }
  };

  const removePurchaseOrder = async (orderNumber) => {
    const updateOrders = orders.filter((x) => x.orderNumber != orderNumber);
    dispatch({
      type: 'field',
      fieldName: 'orders',
      payload: updateOrders,
    });

    if (updateOrders.length > 0) {
      //reload receivers
      let orderNumbers = updateOrders.map((x) => x.orderNumber);
      orderNumbers = [...orderNumbers, orderNumber];
      // _loadReceiverSelectItemsAsync(vendorAccountId, orderNumbers);
      let receiverSelectItemsResult = await shipmentBookingApiService.getReceiverSelectItems(vendorAccountId, orderNumbers);
      dispatch({
        type: 'loadReceiverSelection',
        payload: receiverSelectItemsResult.data,
      });
    } else {
      dispatch({
        type: 'loadReceiverSelection',
        payload: [],
      });
    }
  };

  const setActivePurchaseOrderLine = (orderNumber, lineNumber, active) => {
    let order = orders.find((x) => x.orderNumber == orderNumber);
    if (order) {
      let orderLine = order.lines.find((x) => x.lineNumber == lineNumber);
      if (orderLine) {
        orderLine.active = active;
        dispatch({
          type: 'field',
          fieldName: 'orders',
          payload: orders,
        });
        validateFormEntry();
      }
    }
  };

  const setPurchaseOrderLineQty = (orderNumber, lineNumber, qty) => {
    let order = orders.find((x) => x.orderNumber == orderNumber);
    if (order) {
      let orderLine = order.lines.find((x) => x.lineNumber == lineNumber);
      if (orderLine) {
        orderLine.qty = qty;
        dispatch({
          type: 'field',
          fieldName: 'orders',
          payload: orders,
        });
      }
    }
  };

  const setShipper = (addressId) => {
    if (shipper.id != addressId) {
      let updateShipper = shipperSelectionItems.find((x) => x.id == addressId);
      if (updateShipper) {
        dispatch({
          type: 'field',
          fieldName: 'shipper',
          payload: updateShipper,
        });
      }
    }
  };

  const setReceiver = (addressId) => {
    if (receiver.id != addressId) {
      let updateReceiver = receiverSelectionItems.find((x) => x.id == addressId);
      if (updateReceiver) {
        dispatch({
          type: 'field',
          fieldName: 'receiver',
          payload: updateReceiver,
        });
      }
    }
  };

  const setContainerVolume = (value) => {
    dispatch({
      type: 'field',
      fieldName: 'containerVolume',
      payload: value,
    });
  };

  const setContainerSize = (id) => {
    dispatch({
      type: 'field',
      fieldName: 'containerSize',
      payload: id,
    });
  };

  const setCargoReadyDate = (value) => {
    dispatch({
      type: 'field',
      fieldName: 'cargoReadyDate',
      payload: value,
    });
  };

  const setIncoterms = (value) => {
    dispatch({
      type: 'field',
      fieldName: 'incoterms',
      payload: value,
    });
  };

  const setMarksAndNumbers = (value) => {
    dispatch({
      type: 'field',
      fieldName: 'marksAndNumbers',
      payload: value,
    });
  };

  const setGoodsDescription = (value) => {
    dispatch({
      type: 'field',
      fieldName: 'goodsDescription',
      payload: value,
    });
  };

  const validateFormEntry = () => {
    let { valid, messages } = ShipmentBookingValidation.validate(state, state.overflowPercentageOnQty);

    if (!valid) {
      dispatch({
        type: 'messages',
        payload: {
          messages: messages,
        },
      });
    }
  };

  const _processCreateOrUpdateDraft = async () => {
    let commandModel = ShipmentBookingEntryModelFactory.createShipmentBookingEntryCommandModel(state);
    if (commandModel.id) {
      //update draft
      await shipmentBookingApiService.updateDraft(commandModel);
    } else {
      //create draft
      let createDraftResult = await shipmentBookingApiService.createDraft(commandModel);
      commandModel.id = createDraftResult.data;
      dispatch({
        type: 'field',
        fieldName: 'id',
        payload: commandModel.id,
      });
      history.replace(`/shipment-bookings/${createDraftResult.data}/edit`);
    }
    return commandModel.id;
  };

  const saveDraft = async () => {
    dispatch({
      type: 'submit',
    });

    try {
      await _processCreateOrUpdateDraft();
      showSuccessSnackBar('Draft was saved successfully');
      dispatch({
        type: 'success',
      });

      if (statusName != shipmentBookingStatus.draft && state.id) {
        await loadExistingData(vendorAccountId, state.id);
      }
    } catch (ex) {
      catchApiError(ex);
      dispatch({
        type: 'apiError',
      });
    }
  };

  const submit = async () => {
    startProgress();
    dispatch({
      type: 'submit',
    });

    try {
      let id = await _processCreateOrUpdateDraft();
      let submitResult = await shipmentBookingApiService.submitDraft(id);

      stopProgress();

      if (submitResult.errors && submitResult.errors.length > 0) {
        showSuccessSnackBar('Draft was saved successfully');

        let { errors } = ShipmentBookingEntryModelFactory.createFormErrors(submitResult.errors);

        dispatch({
          type: 'messages',
          payload: {
            messages: errors,
          },
        });
      } else {
        dispatch({ type: 'success' });
        showSuccessSnackBar('Shipment Booking has submitted successfully');
        await loadExistingData(vendorAccountId, id);
      }
    } catch (ex) {
      catchApiError(ex);
      dispatch({
        type: 'apiError',
      });
    }
  };

  const changeFormToEditMode = async () => {
    await initializeEditEntryMode(vendorAccountId, state);
  };

  const discardDraft = async () => {
    startProgress();
    dispatch({
      type: 'submit',
    });

    try {
      let discardResult = await shipmentBookingApiService.discardDraft(state.id);

      stopProgress();

      if (discardResult.errors && discardResult.errors.length > 0) {
        let { errors } = ShipmentBookingEntryModelFactory.createFormErrors(discardResult.errors);
        dispatch({
          type: 'messages',
          payload: {
            messages: errors,
          },
        });
      } else {
        dispatch({ type: 'success' });
        showSuccessSnackBar('Draft has been discarded');
        await loadExistingData(vendorAccountId, state.id);
      }
    } catch (ex) {
      catchApiError(ex);
      dispatch({
        type: 'apiError',
      });
    }
  };

  const value = {
    ...state,
    initialize,
    loadExistingData,
    addPurchaseOrder,
    removePurchaseOrder,
    setActivePurchaseOrderLine,
    setPurchaseOrderLineQty,
    setShipper,
    setReceiver,
    setContainerVolume,
    setContainerSize,
    setCargoReadyDate,
    setIncoterms,
    setMarksAndNumbers,
    setGoodsDescription,
    validateFormEntry,
    saveDraft,
    submit,
    changeFormToEditMode,
    discardDraft,
  };

  return <ShipmentBookingEntryContext.Provider value={value}>{children}</ShipmentBookingEntryContext.Provider>;
};

const useShipmentBookingEntry = () => {
  const context = useContext(ShipmentBookingEntryContext);

  if (context === undefined) {
    throw new Error('useShipmentBookingEntry must be used within ShipmentBookingEntryContext');
  }

  return context;
};

export default useShipmentBookingEntry;
