import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import { FormControlLabel, Grid, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';

import TitleFont from 'src/components/Typography/TitleFont';
import { ReloadContext } from 'src/contexts/ReloadContext';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useFilterQuery from 'src/hooks/useFilterQuery';
import apiService from 'src/services/api.service';
import helpService from 'src/services/help.service';
import DelayChangedTextField2 from 'src/wrappers/DelayChangedTextField2';

import CustomCheckbox from '../CustomCheckbox';
import DataTableFilterModelFactory from './DataTableFilterModelFactory';
import { dataTableFilterInitialState, dataTableFilterReducer } from './DataTableFilterReducer';
import PeriodsControl from './PeriodsControl';
import SuppliersControl from './SuppliersControl';
import dateBaseOn from 'src/models/DateBaseOn';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  checkBlockControl: {
    paddingTop: '6px',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  checkBox: {
    marginRight: theme.spacing(1),
  },
  icon: {
    color: '#888888',
  },
  specialSearch: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    '& b': {
      paddingRight: theme.spacing(1),
    },
  },
  filterTag: {
    position: 'relative',
    padding: 5,
    paddingLeft: 12,
    paddingRight: 30,
    backgroundColor: theme.table.filterTag.color,
    color: 'white',
    borderRadius: 16,
    margin: 2,
    '& svg': {
      position: 'absolute',
      right: 5,
      top: 5,
      fontSize: '1.2rem',
      color: '#1c1c1c',
      cursor: 'pointer',
      backgroundImage: 'linear-gradient(#fff,#fff)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50% 50%',
      backgroundPosition: 'center',
      '&:hover': {
        color: '#636363',
      },
    },
  },
}));

const DataTableFilter = (props) => {
  const { showSuppliers = false, showFreeText = true, showDateBasedOn = true, showFocused, sendQueryStringsNoFreeTextSearch } = props;

  const classes = useStyles();
  const [reloadContext] = useContext(ReloadContext);
  const { filterQuery, queryString, updateAddressUrl } = useFilterQuery();
  const { accountId, accountName } = useAccounts();
  const [state, dispatch] = useReducer(dataTableFilterReducer, dataTableFilterInitialState);
  const { catchApiError } = useCatchAPIError();

  useEffect(() => {
    if (sendQueryStringsNoFreeTextSearch) {
      sendQueryStringsNoFreeTextSearch(queryString);
    }
  }, [queryString]);

  useEffect(() => {
    let initialReducerState = DataTableFilterModelFactory.createReducerStateFromFilterQueryModel(filterQuery, showDateBasedOn);
    dispatch({
      type: 'initialFields',
      payload: initialReducerState,
    });
  }, []);

  useEffect(() => {
    const resetFilters = (selectedAccountId, selectedDateBasedOn) => {
      apiService
        .getDashboardFilters(selectedAccountId, selectedDateBasedOn)
        .then((result) => {
          //Periods
          let periods = null;
          if (result.data.filterPeriods) {
            periods = result.data.filterPeriods;
          }

          //Suppliers
          let suppliers = null;
          if (result.data.reportSuppliers) {
            suppliers = result.data.reportSuppliers.sort(function (a, b) {
              var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });

            suppliers.forEach((item) => {
              item.name = helpService.truncateString(item.name, 40);
            });
          }

          dispatch({
            type: 'loadSelectItems',
            payload: {
              periods: periods,
              suppliers: suppliers,
            },
          });
        })
        .catch(catchApiError);
    };
    if (accountId && state.dateBasedOn) {
      resetFilters(accountId, state.dateBasedOn);
    }
  }, [reloadContext, accountId, state.dateBasedOn]);

  useEffect(() => {
    function ResendFilterComponentData() {
      const filterComponentData = DataTableFilterModelFactory.createReturnFilterComponentData(state, accountId, accountName);
      props.sendFilterComponentData(filterComponentData);
      console.log('ResendFilterComponentData', filterComponentData);
    }

    if (state.periods != null) {
      let filterQueryModel = DataTableFilterModelFactory.createFilterQueryModelFromReducerState(state, accountId);
      updateAddressUrl(filterQueryModel);
      ResendFilterComponentData();
    }
  }, [state.filterFieldsUpdated]);

  const clearSpecialSearch = useCallback((e) => {
    dispatch({
      type: 'clearSpecialSearch',
    });
  }, []);

  const handleStartPeriodChange = useCallback((e) => {
    dispatch({
      type: 'fieldWithTrigger',
      fieldName: 'startPeriodValue',
      payload: e.target.value,
    });
  }, []);

  const handleEndPeriodChange = useCallback((e) => {
    dispatch({
      type: 'fieldWithTrigger',
      fieldName: 'endPeriodValue',
      payload: e.target.value,
    });
  }, []);

  const handleSupplierValuesChange = useCallback((e) => {
    dispatch({
      type: 'fieldWithTrigger',
      fieldName: 'supplierId',
      payload: e.target.value,
    });
  }, []);

  const handleFreeTextValueChange = useCallback((text) => {
    dispatch({
      type: 'fieldWithTrigger',
      fieldName: 'freeTextSearch',
      payload: text,
    });
  }, []);

  const handleDateBasedOnChange = useCallback((e) => {
    dispatch({
      type: 'field',
      fieldName: 'dateBasedOn',
      payload: e.target.value,
    });
  }, []);

  const handleShowFocusedShipmentChange = useCallback(() => {
    dispatch({
      type: 'fieldWithTrigger',
      fieldName: 'showFocusedShipment',
      payload: !state.showFocusedShipment,
    });
  }, [state.showFocusedShipment]);

  const disableStartPeriodMenuItem = useCallback(
    (periodItem) => {
      if (state.endPeriodValue) {
        let selectedEndPeriod = state.periods.find((x) => x.id === state.endPeriodValue);
        if (selectedEndPeriod) {
          let endDate = new Date(selectedEndPeriod.periodEnd);
          let startDate = new Date(periodItem.periodStart);
          return startDate > endDate;
        }
      }
      return false;
    },
    [state.periods, state.endPeriodValue]
  );

  const disableEndPeriodMenuItem = useCallback(
    (periodItem) => {
      if (state.startPeriodValue) {
        let selectedStartPeriod = state.periods.find((x) => x.id === state.startPeriodValue);
        if (selectedStartPeriod) {
          let startDate = new Date(selectedStartPeriod.periodStart);
          let endDate = new Date(periodItem.periodEnd);
          return startDate > endDate;
        }
      }
      return false;
    },
    [state.periods, state.startPeriodValue]
  );

  return (
    <Grid container direction="row" spacing={2} className={classes.root}>
      {state.specialSearch?.title && (
        <Grid item xs={12} className="align-self-center">
          <h5 className={classes.specialSearch}>
            <b>Filter:</b>{' '}
            <div className={classes.filterTag}>
              {state.specialSearch?.title} <CancelIcon onClick={clearSpecialSearch} />
            </div>
          </h5>
        </Grid>
      )}

      {showFocused && (
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl + ' ' + classes.checkBlockControl + ' exclude'}>
            <FormControlLabel
              className={classes.checkBox}
              checked={state.showFocusedShipment}
              onChange={handleShowFocusedShipmentChange}
              control={<CustomCheckbox name="showFocusedShipment" />}
              label="Show only my focused shipments."
            />
            <Tooltip arrow placement="top-end" title="Filter by your focused shipment.">
              <InfoIcon className={classes.icon} />
            </Tooltip>
          </FormControl>
        </Grid>
      )}

      {showSuppliers && (
        <Grid item xs={12} sm={6} lg={3} xl>
          <SuppliersControl selectedSupplierValues={state.supplierId} handleSupplierValuesChange={handleSupplierValuesChange} suppliers={state.suppliers} />
        </Grid>
      )}

      {showDateBasedOn && (
        <Grid item xs={12} lg={1}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <InputLabel id="basedOnLabel">
              <TitleFont> Based On</TitleFont>
            </InputLabel>
            <Select labelId="groupByLabel" id="demo-simple-select-outlined" label="Based On" value={state.dateBasedOn} onChange={handleDateBasedOnChange}>
              <MenuItem value={dateBaseOn.eta}>{dateBaseOn.eta}</MenuItem>
              <MenuItem value={dateBaseOn.etd}>{dateBaseOn.etd}</MenuItem>
              <MenuItem value={dateBaseOn.ata}>{dateBaseOn.ata}</MenuItem>
              <MenuItem value={dateBaseOn.atd}>{dateBaseOn.atd}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12} sm={6} lg={2}>
        <PeriodsControl
          id={'period-start'}
          title={'Start'}
          selectedPeriodValue={state.startPeriodValue}
          handlePeriodChange={handleStartPeriodChange}
          periods={state.periods}
          disablePeriodMenuItem={disableStartPeriodMenuItem}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={2}>
        <PeriodsControl
          id={'period-end'}
          title={'End'}
          selectedPeriodValue={state.endPeriodValue}
          handlePeriodChange={handleEndPeriodChange}
          periods={state.periods}
          disablePeriodMenuItem={disableEndPeriodMenuItem}
        />
      </Grid>

      {showFreeText && (
        <Grid item xs={12} sm>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <DelayChangedTextField2
              id="free-text-filter"
              label="Free Text Filter"
              variant="outlined"
              value={state.freeTextSearch}
              onValueChange={(text) => {
                dispatch({
                  type: 'field',
                  fieldName: 'freeTextSearch',
                  payload: text,
                });
              }}
              onDelayValueChange={handleFreeTextValueChange}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default DataTableFilter;
