import { useRef } from 'react';

export default function useDelayChangedText(onDelayValueChange, onValueChange) {
  const textRef = useRef();
  const timerRef = useRef();

  const startDelayChange = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      triggerDelayValueChange();
    }, 500);
  };

  const triggerDelayValueChange = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (onDelayValueChange) {
      onDelayValueChange(textRef.current);
    }
  };

  const handleValueTextChange = (e) => {
    let text = e.target.value;
    textRef.current = text;
    onValueChange(e.target.value);
    startDelayChange();
  };

  const handleOnKeyPress = (event) => {
    if (event.key === 'Enter') {
      triggerDelayValueChange();
    }
  };

  return { handleValueTextChange, handleOnKeyPress };
}
