import React, { useLayoutEffect, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import ContentFont from '../../Typography/ContentFont';
import { format } from 'react-string-format';
import LoadingOverlay from '../../parts/LoadingOverlay.js';
import ChartNoData from '../../parts/ChartNoData.js';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 300,
    paddingTop: 40,
  },
  chartTitle: {
    height: 125,
  },
}));

function OrderDelaysChart(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData, baseQueryString } = props;
  const veryLateDays = 3;
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState();
  const history = useHistory();

  function getDetailsPageUrl() {
    return '/shipments?' + baseQueryString;
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getOrderDelaysReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          veryLateDays,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data && (result.data.totalEarly || result.data.totalOnTime || result.data.totalLate || result.data.totalVeryLate)) {
              setShowNoData(false);
              setChartDataInput(result.data);
            } else {
              setShowNoData(true);
              setChartDataInput([]);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    function hasChartData() {
      if (chartDataInput && (chartDataInput.totalEarly || chartDataInput.totalOnTime || chartDataInput.totalLate || chartDataInput.totalVeryLate)) {
        return true;
      } else {
        return false;
      }
    }

    // Create chart instance
    var chart;
    if (hasChartData()) {
      chart = am4core.create('OrderDelayChart', am4charts.PieChart);
      chart.logo.disabled = true;

      var colorList = [];
      colorList.push(am4core.color(lightTheme.chart.arrivedOnTimeColors.early));
      colorList.push(am4core.color(lightTheme.chart.arrivedOnTimeColors.onTime));
      colorList.push(am4core.color(lightTheme.chart.arrivedOnTimeColors.late));
      colorList.push(am4core.color(lightTheme.chart.arrivedOnTimeColors.veryLate));

      chart.data = prepareStuff(chartDataInput);

      // Add label
      chart.innerRadius = 50;
      chart.radius = 100;
      let label = chart.seriesContainer.createChild(am4core.Label);

      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = 11;

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.colors.list = colorList;
      pieSeries.dataFields.value = 'amount';
      pieSeries.dataFields.category = 'name';
      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);
      pieSeries.labels.template.adapter.add('text', function (text, target) {
        if (target.dataItem && target.dataItem.value === 0) {
          return '';
        } else {
          return text;
        }
      });
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.stroke = am4core.color('#fff');

      pieSeries.ticks.template.disabled = true;

      pieSeries.fontSize = 11;
    }
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray = [];
    tempArray.push({ name: 'Early', amount: data.totalEarly });
    tempArray.push({ name: 'On Time', amount: data.totalOnTime });
    tempArray.push({ name: 'Late', amount: data.totalLate });
    tempArray.push({ name: format('Late over {0} days', veryLateDays), amount: data.totalVeryLate });
    return tempArray;
  };

  return (
    <Card>
      <LoadingOverlay IsLoading={IsLoading} />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>{showNoData ? <ChartNoData /> : <div id="OrderDelayChart" className={classes.chart}></div>}</ContentFont>
      </CardHeader>
      <CardBody className={classes.chartTitle + ' ' + 'dashboard-chart-title-area'}>
        <Link to={getDetailsPageUrl()}>
          <h4>Order Delays</h4>
        </Link>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

export default OrderDelaysChart;
