import React from 'react';

import { IconButton, withStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export const AddIconButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primaryButton.backgroundColor,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primaryButton.hoverBackgroundColor,
    },
    width: 50,
  },
}))((props) => {
  return (
    <IconButton size="large" {...props}>
      <AddCircleIcon />
    </IconButton>
  );
});
