import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';

const useStyles = makeStyles((theme) => ({
  featureDisabledMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageArea: {
    paddingBottom: '48px !important',
  },
}));

export default function FeatureDisabledMessage(props) {
  const { title = 'Sorry.', message = 'This feature is not enabled for you. Please contact an administrator.' } = props;
  const classes = useStyles();
  const { featuresLoaded } = useCustomerAccountFeatures();
  return (
    <>
      {featuresLoaded && (
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.featureDisabledMessage}>
            <h4>{title}</h4>
          </Grid>
          <Grid item xs={12} className={clsx(classes.featureDisabledMessage, classes.messageArea)}>
            {message}
          </Grid>
        </Grid>
      )}
    </>
  );
}

FeatureDisabledMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};
