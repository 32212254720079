import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = {
  grid: {
    paddingLeft: 15,
    paddingRight: 15,
  },
};

const useStyles = makeStyles(styles);

export default function DashboardChartGridItem(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  let classNames = [classes.grid, className, 'd-flex', 'align-items-stretch'].join(' ');

  return (
    <Grid item {...rest} className={classNames}>
      {children}
    </Grid>
  );
}

DashboardChartGridItem.propTypes = {
  children: PropTypes.node,
};
