import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import DataContentTable from 'src/components/parts/DataContentTable';
import DeleteDialog from 'src/components/parts/DeleteDialog';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import LoadingOverlay from 'src/components/parts/LoadingOverlay';
import { ReloadContext } from 'src/contexts/ReloadContext';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import usePermissions from 'src/hooks/usePermissions';
import shipmentBookingStatus from 'src/models/ShipmentBookingStatus';
import formErrorFactory from 'src/services/formError.factory';
import shipmentBookingApiService from 'src/services/shipmentBooking.api.service';

import ShipmentBookingsDataTableFilter from './components/TableFilter';
import TableToolbar from 'src/components/parts/TableToolbar';

const headCells = [
  {
    id: 'BookingNumber',
    numeric: false,
    disablePadding: true,
    label: 'Booking No.',
  },
  {
    id: 'PurchaseOrderNumbers',
    numeric: false,
    disablePadding: false,
    label: 'PO No.(s)',
  },
  {
    id: 'Status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'CreatedDate',
    numeric: true,
    disablePadding: false,
    label: 'Created Date',
  },
  {
    id: 'SubmittedDate',
    numeric: true,
    disablePadding: false,
    label: 'Submitted Date',
  },
];

export function ShipmentBookingsContainer(props) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [pendingDeleteAction, setPendingDeleteAction] = useState({
    show: false,
    id: '0',
  });
  const [reloadContext, setReloadContext] = useContext(ReloadContext);
  const history = useHistory();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showErrorSnackBar, showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();
  const { accountId } = useAccounts();
  const { canReadShipmentBookings, canManageShipmentBookings } = usePermissions();
  const [isLoading, setIsLoading] = useState();
  const [filter, setFilter] = useState({});

  useEffect(() => {
    let cancelled = false;
    if (accountId) {
      startProgress();
      shipmentBookingApiService
        .getShipmentBookingsTable(accountId, page + 1, rowsPerPage, order, orderBy, null, null, filter.freeTextSearch)
        .then((result) => {
          stopProgress();
          if (!cancelled) {
            setRows(createTableListData(result.data.list));
            setTotalNumberOfRows(result.data.totalItems);
          }
        })
        .catch(catchApiError);
    }

    return () => {
      cancelled = true;
    };
  }, [history, order, orderBy, rowsPerPage, page, reloadContext, accountId, filter]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let createdDate = moment(item.createdDate);
      let submittedDate = moment(item.submittedDate);
      let statusText = item.statusText;
      if (statusText != shipmentBookingStatus.draft && item.hasDraft) {
        statusText = `${statusText} / ${shipmentBookingStatus.draft}`;
      }

      let rowData = {
        id: item.shipmentBookingId,
        BookingNumber: item.bookingNumber,
        PurchaseOrderNumbers: item.purchaseOrderNumbersText,
        Status: statusText,
        CreatedDate: createdDate.format('l'),
        SubmittedDate: submittedDate.isValid() ? submittedDate.format('l') : '',
      };

      newArray.push(rowData);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    if (canManageShipmentBookings) {
      setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
    } else {
      showErrorSnackBar("You don't have permission to delete shipment booking");
    }
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    shipmentBookingApiService
      .deleteShipmentBooking(id)
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          var errorString = formErrorFactory.createErrorText(result.errors);
          showErrorSnackBar(errorString);
        } else {
          setReloadContext(new Date());
          showSuccessSnackBar('Shipment Booking deleted successfully');
        }
      })
      .catch(catchApiError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAdd = () => {
    if (canManageShipmentBookings) {
      history.push('/shipment-bookings/add');
    } else {
      showErrorSnackBar("You don't have permission to add booking");
    }
  };

  const handleEdit = (event) => {
    if (canManageShipmentBookings) {
      history.push('/shipment-bookings/' + event.currentTarget.dataset.tag + '/edit');
    } else {
      showErrorSnackBar("You don't have permission to modify booking");
    }
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleFilterChanged = (filterData) => {
    setFilter(filterData);
  };

  return (
    <>
      <EntityListFrame inPaper={true}>
        {canReadShipmentBookings ? (
          <>
            <LoadingOverlay IsLoading={isLoading} />
            <Grid item xs={12}>
              <TableToolbar title="Shipment Bookings" handleAdd={canManageShipmentBookings && handleAdd} />
              <Grid item xs={12}>
                <ShipmentBookingsDataTableFilter onFilterChanged={handleFilterChanged} />
              </Grid>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  deleteHandler={handleDeleteRequest}
                  handleEdit={handleEdit}
                  canAdd={canManageShipmentBookings}
                />
              )}
            </Grid>
            <DeleteDialog
              action={pendingDeleteAction}
              handleDelete={handleDelete}
              title="Delete Shipment Booking?"
              body="Are you sure you want to delete this booking with all its data? Once deleted it cannot be undone."
            />
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}
