import React from 'react';

import { Grid } from '@material-ui/core';

import useAccounts from 'src/hooks/useAccounts';

import Suppliers from '../../Accounts/Suppliers';
import VendorProfile from './VendorProfile';

export default function VendorProfileContainer(props) {
  const { accountId } = useAccounts();
  return (
    <>
      <Grid item xs={12}>
        <VendorProfile />
      </Grid>
      {accountId && (
        <Grid item xs={12}>
          <Suppliers vendorAccountId={accountId} />
        </Grid>
      )}
    </>
  );
}
