import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import UserProfileMain from './UserProfileMain';
import LoadingBar from 'react-top-loading-bar';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import { Redirect } from 'react-router';
import EntityListFrame from '../../layouts/EntityListFrame';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';

function UserProfile(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const progressRef = useRef(null);
  let match = useRouteMatch();

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <EntityListFrame inPaper={false}>
        {/* <TitleCard title="User Profile" imgSrc="/images/container-1638068_1920.jpg"/> */}
        <Grid item xs={12}>
          <Switch>
            <Route exact path={`${match.path}/change-password`}>
              <ChangePassword />
            </Route>
            <Route exact path={`${match.path}`}>
              <UserProfileMain />
            </Route>
            <Redirect to="/dashboard" />
            <Route />
          </Switch>
        </Grid>
      </EntityListFrame>
    </>
  );
}

export default UserProfile;
