import { Box, Divider, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import AverageTransitBox from './AverageTransitBox';
import AverageTransitPerSupplierPieChart from './AverageTransitPerSupplierPieChart';
import AverageUnitPriceBox from './AverageUnitPriceBox';
import QtyPerMonthLineChart from './QtyPerShipmentLineChart';
import ShipmentDelayPieChart from './ShipmentDelayPieChart';
import TotalAmountBox from './TotalAmountBox';
import TotalQtyBox from './TotalQtyBox';
import TransitTimePerShipmentLineChart from './TransitTimePerShipmentLineChart';
import UnitPricePerShipmentLineChart from './UnitPricePerShipmentLineChart';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import OrderDelayPieChart from './OrderDelayPieChart';
import TransitTimePerOrderLineChart from './TransitTimePerOrderLineChart';
import UnitFreightSpendsPerShipmentLineChart from './UnitFreightSpendsPerShipmentLineChart';
import TotalSpendsPerShipmentLineChart from './TotalSpendsPerShipmentLineChart';
import TotalFreightSpendBox from './TotalFreightSpendBox';
import AverageUnitFreightSpendBox from './AverageUnitFreightSpendBox';
import TotalSpendBox from './TotalSpendBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    backgroundColor: theme.palette.modal.backgroundColor,
    position: 'relative',
    padding: theme.spacing(3),
    flexDirection: 'column',
  },
  contentContainer: {
    overflowY: 'auto',
    marginTop: '0px !important',
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  divider: {
    marginRight: 12,
  },
}));

export default function ProductOverview(props) {
  const classes = useStyles();
  const { accountId, productCode, productName, onClose, settings } = props;

  useEffect(() => {}, [productCode, productName]);

  const handleCloseModalClick = () => {
    onClose();
  };

  const isHidden = (chartName) => {
    if (settings && settings.hiddenCharts && settings.hiddenCharts.length > 0) {
      let hiddenChartIndex = settings.hiddenCharts.findIndex((hiddenChart) => hiddenChart.toLowerCase() === chartName.toLowerCase());
      return hiddenChartIndex > -1;
    } else {
      return false;
    }
  };

  return (
    <Paper className={classes.root}>
      <Box>
        <h4 style={{ fontWeight: 'normal' }}>
          {productCode}: {productName}
        </h4>

        <IconButton aria-label="edit" onClick={handleCloseModalClick} className={classes.closeButton}>
          <CloseIcon size="small" />
        </IconButton>
        <Divider className={classes.divider} />
      </Box>
      <Grid container spacing={2} className={classes.contentContainer}>
        <Grid item xs={12}></Grid>
        <Grid item xs={6} lg={3}>
          <AverageTransitBox productCode={productCode} accountId={accountId} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <AverageUnitPriceBox productCode={productCode} accountId={accountId} />
        </Grid>
        {!isHidden('AverageUnitFreightSpendBox') && (
          <Grid item xs={6} lg={3}>
            <AverageUnitFreightSpendBox productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        <Grid item xs={6} lg={3}>
          <TotalQtyBox productCode={productCode} accountId={accountId} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TotalAmountBox productCode={productCode} accountId={accountId} />
        </Grid>
        {!isHidden('TotalFreightSpendBox') && (
          <Grid item xs={6} lg={3}>
            <TotalFreightSpendBox productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('TotalSpendBox') && (
          <Grid item xs={12} lg={6}>
            <TotalSpendBox productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('AverageTransitPerSupplier') && (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <AverageTransitPerSupplierPieChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('ShipmentDelay') && (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <ShipmentDelayPieChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('OrderDelay') && (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <OrderDelayPieChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('TransitTimePerOrder') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TransitTimePerOrderLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('QtyPerMonth') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <QtyPerMonthLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('UnitPricePerShipment') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <UnitPricePerShipmentLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('TransitTimePerShipment') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TransitTimePerShipmentLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('UnitFreightSpendsPerShipment') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <UnitFreightSpendsPerShipmentLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
        {!isHidden('TotalSpendsPerShipment') && (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <TotalSpendsPerShipmentLineChart productCode={productCode} accountId={accountId} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

ProductOverview.propTypes = {
  accountId: PropTypes.string,
  productCode: PropTypes.string,
  productName: PropTypes.string,
  onClose: PropTypes.func,
  settings: PropTypes.shape({
    hiddenCharts: PropTypes.array,
  }),
};
