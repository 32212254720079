import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { IconButton, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const ExpandButton = (props) => {
  const useStyles = makeStyles((theme) => ({
    expand: {
      color: theme.palette.primaryButton.backgroundColor,
      '&:hover': {
        color: theme.palette.primaryButton.hoverBackgroundColor,
      },
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      height: 36,
      width: 36,
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));

  const classes = useStyles();
  const { expanded, onExpandedChanged } = props;
  const [isExpanded, setIsExpanded] = useState(expanded);
  const handleExpandClick = () => {
    setIsExpanded(!expanded);
    if (onExpandedChanged) {
      onExpandedChanged(!isExpanded);
    }
  };
  return (
    <IconButton
      className={clsx(classes.expand, {
        [classes.expandOpen]: isExpanded,
      })}
      onClick={handleExpandClick}
      aria-expanded={isExpanded}
      aria-label="show more"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

ExpandButton.propTypes = {
  expanded: PropTypes.bool,
  onExpandedChanged: PropTypes.func,
};
