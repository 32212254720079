import React, {useEffect, useState } from 'react';
import AmountBox from './ChartTemplates/AmountBox';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function AverageUnitPriceBox(props) {
  const [value, setValue] = useState('-');
  const { productCode, accountId } = props;
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      apiService
        .getAverageUnitPriceProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.value ? setValue(result.data.value) : setValue('-');
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (productCode && accountId) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [productCode, accountId]);

  return <AmountBox title="Avg. Unit Price" valueText={`$ ${value.toLocaleString()}`} color="success" iconComponent={<AttachMoneyIcon />} />;
}
