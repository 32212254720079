import clsx from 'clsx';
import React, { useCallback, useContext, useEffect } from 'react';
import { useMediaLayout } from 'use-media';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Group from '@material-ui/icons/Group';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PublicIcon from '@material-ui/icons/Public';

import DrawerMenuItem from 'src/components/parts/DrawerMenuItem';
import { DrawerContext } from 'src/contexts/DrawerContext';
import { UserContext } from 'src/contexts/UserContext';
import usePermissions from 'src/hooks/usePermissions';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.menuDrawer.main,
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.menuDrawer.button,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.menuDrawer.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100vh',
    overflowX: 'hidden',
    '& ::-webkit-scrollbar': {
      width: 8,
    },
  },
  drawerPaperClose: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  text: {
    color: theme.palette.menuDrawer.text,
  },
  divider: {
    background: theme.palette.menuDrawer.divider,
  },
  bottom: {
    borderTop: '1px solid #ffffff33',
    marginTop: 'auto',
  },
  menuScroll: {
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
}));

export default function VendorDrawer() {
  const classes = useStyles();
  const [userContext] = useContext(UserContext);
  const [drawerContext, setDrawerContext] = useContext(DrawerContext);
  const isWide = useMediaLayout({ maxWidth: '1000px' });
  const { hasFullPermissions, canReadUsers, canReadShipmentBookings } = usePermissions();

  useEffect(() => {
    if (isWide) {
      setDrawerContext(false);
    }
  }, [isWide]);

  const handleDrawerClose = useCallback(() => {
    setDrawerContext(false);
  }, []);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerContext && classes.drawerPaperClose),
      }}
      open={drawerContext}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose} className={clsx(classes.text, !drawerContext && classes.menuButtonHidden)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List className={clsx(classes.text, classes.menuScroll)}>
        <DrawerMenuItem title="News" linkTo="/news" iconComponent={<PublicIcon />} />
        <Divider className={classes.divider} />

        {canReadShipmentBookings && <DrawerMenuItem title="Shipment Bookings" linkTo="/shipment-bookings" iconComponent={<NoteAddIcon />} />}
        {canReadShipmentBookings && <DrawerMenuItem title="Request Booking POs" linkTo="/request-booking-purchase-orders" iconComponent={<ListAltIcon />} />}
      </List>

      <List className={classes.bottom}>
        {hasFullPermissions && <DrawerMenuItem title="Accounts" linkTo="/accounts" iconComponent={<AddBoxIcon />} />}
        {canReadUsers && <DrawerMenuItem title="Users" linkTo="/users" iconComponent={<Group />} />}
      </List>
    </Drawer>
  );
}
