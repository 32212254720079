import { createMuiTheme } from '@material-ui/core';
import { themeConstant } from './themeConstant';

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      // default: "#dddddd"
      default: '#FFFFFF',
    },
    primary: {
      main: '#4167B2',
    },
    primaryButton: {
      backgroundColor: '#ea2956',
      hoverBackgroundColor: '#ef5176',
      fontColor: '#fff',
    },
  },
  ...themeConstant,
});

export const lightThemeAdmin = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    menuDrawer: {
      main: '#ea2956',
      text: '#fff',
      button: '#fff',
      topButton: '#faa',
      drawer: '#ea2956',
      divider: '#aaa',
    },
    background: {
      default: '#f7f7f7',
    },
    exportMenu: {
      backgroundColor: '#ea2956',
      fontColor: '#fff',
    },
    primaryButton: {
      backgroundColor: '#ea2956',
      hoverBackgroundColor: '#ef5176',
      fontColor: '#fff',
    },
    myTab: {
      backgroundColor: '#ffffff',
      borderColor: '#e8e8e8',
      activeTabFontColor: 'rgba(0, 0, 0, 0.87)',
      tabs: {
        marginLeft: -1,
      },
    },
    inputControl: {
      backgroundColor: '#f1f1f1',
      borderColor: '#e7e7e7',
      warning: '#FF9A00',
    },
    modal: {
      backgroundColor: '#f4f4f4',
    },
    shipmentTracking: {
      shipmentDetails: {
        titleColor: '#666',
        hightlightRowBackgroundColor: '#f1f1f1',
      },
      documents: {
        fontColor: '#000',
      },
      searchResult: {
        productCodesContainer: {
          backgroundColor: '#E4E4E4',
        },
        productCodeItem: {
          backgroundColor: '#FFF',
        },
      },
      trackingEvents: {
        highlightColor: '#ea2956',
        unHighlightColor: 'rgba(0, 0, 0, 0.54)',
        iconColor: '#ffffff',
      },
    },
    topRedBar: {
      backgroundColor: '#EC2856',
      logoImgFilter: 'none',
    },
    trendsOverTime: {
      enabledComparePeriodBackgroundColor: '#efefef',
      moreFilterSection: '#efefef',
      mainBackgroundColor: '#fff',
      tabBackgroundColor: '#f7f7f7',
    },
    textFieldExternalApplicationKey: {
      backgroundColor: '#F5F5F5',
      titleBackgroundColor: '#424242',
    },
    externalEndpointListItem: {
      backgroundColor: '#F5F5F5',
    },
    currencyConverter: {
      convertedAmountTextBox: {
        backgroundColor: '#e7e7e7',
      },
      amountTextBox: {
        backgroundColor: '#e7e7e7',
      },
    },
    currentLocalTimes: {
      backgroundColor: '#e7e7e7',
    },
    stockIndexes: {
      priceColor: '#222',
      navigateButtonColor: '#ea2956',
    },
    news: {
      titleColor: '#000',
    },
    liveExchangeRates: {
      fromContainerBackgroundColor: '#e7e7e7',
    },
    fbxIndexes: {
      ticker: {
        backgroundColor: '#FFF',
      },
      lane: {
        color: '#999',
      },
    },
    subscribeToggleButton: {
      disabled: {
        color: '#ccc',
      },
      subscribed: {
        color: '#ea2956',
      },
      unsubscribed: {
        color: '#000',
      },
    },
    focusShipmentCheckbox: {
      focused: {
        color: '#ea2956',
      },
      unfocused: {
        color: '#000',
      },
    },
    dataTable: {
      scrollBar: {
        backgroundColor: '#FFF',
      },
    },
  },
  paletteChart: {
    type: 'dark',
    bg: {
      backgroundColor: 'rgb(255,255,255)',
      color: 'rgb(0,0,15)',
    },
    text: {
      color: 'rgb(0,0,15)',
    },
  },
  ...themeConstant,
});
