import helpService from './help.service';

class tableService {
  _LocalStorageKey = 'DataTableSettings';

  filterHiddenTableColumns(headCells, rows, hiddenColumnNames, rowCellsStartIndex = 1) {
    //rowCellsStartIndex, [0] is id, content start at 1
    let result = { headCells: headCells, contentRows: rows };
    if (headCells && hiddenColumnNames) {
      let filteredHeaderCells = [...headCells];
      let hiddenHeadCellsIndexes = [];
      hiddenColumnNames.forEach((name) => {
        let hiddenHeadCellIndex = headCells.findIndex((headCell) => headCell.id.toLowerCase() === name.toLowerCase());
        hiddenHeadCellsIndexes.push(hiddenHeadCellIndex);
      });

      hiddenHeadCellsIndexes = helpService.sortNumbersDesc(hiddenHeadCellsIndexes);

      hiddenHeadCellsIndexes.forEach((index) => {
        let hiddenHeadCells = filteredHeaderCells[index];
        if (hiddenHeadCells) {
          filteredHeaderCells.splice(index, 1);
          if (rows) {
            rows.forEach((row) => {
              if (row[hiddenHeadCells.id] !== undefined) {
                delete row[hiddenHeadCells.id];
              } else if (row[index + rowCellsStartIndex] !== undefined) {
                row.splice(index + rowCellsStartIndex, 1);
              }
            });
          }
        }
      });

      result.headCells = filteredHeaderCells;
      result.contentRows = rows;
    }

    return result;
  }

  getDataTableSettings() {
    let dataJsonString = localStorage.getItem(this._LocalStorageKey);
    if (dataJsonString) {
      return JSON.parse(dataJsonString);
    } else {
      return [];
    }
  }

  updateHiddenColumns(userId, accountId, tableName, hiddenColumns) {
    let dataTableSettings = this.getDataTableSettings();

    let existDataTableSetting = dataTableSettings.find((x) => x.userId == userId && x.accountId == accountId && x.tableName == tableName);

    if (existDataTableSetting) {
      existDataTableSetting.hiddenColumns = hiddenColumns;
    } else {
      dataTableSettings.push({
        userId: userId,
        accountId: accountId,
        tableName: tableName,
        hiddenColumns: hiddenColumns,
      });
    }
    localStorage.setItem(this._LocalStorageKey, JSON.stringify(dataTableSettings));
  }

  getHiddenColumnsById(userId, accountId, tableName) {
    let dataTableSettings = this.getDataTableSettings();
    let dataTableSetting = dataTableSettings.find((x) => x.userId == userId && x.accountId == accountId && x.tableName == tableName);
    if (dataTableSetting) {
      return dataTableSetting.hiddenColumns;
    } else {
      return [];
    }
  }
}
export default new tableService();
