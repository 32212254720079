import React, { useContext, useEffect, useReducer } from 'react';
import { withRouter } from 'react-router';

import { Card, CardActions, CardContent, Divider, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReloadContext } from 'src/contexts/ReloadContext';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import usePermissions from 'src/hooks/usePermissions';
import customerAccountApiService from 'src/services/customerAccount.api.service';
import formErrorFactory from 'src/services/formError.factory';
import MyButton from 'src/wrappers/Button';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  newSection: {
    paddingTop: '40px !important',
    paddingBottom: '0px !important',
  },
}));

const customerProfileReducer = (state, action) => {
  switch (action.type) {
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'loadExistingData': {
      return {
        ...state,
        ...action.payload,
        errors: [],
        isEdit: true,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'submit': {
      return {
        ...state,
        errors: [],
        isSubmitting: true,
      };
    }
    case 'success': {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case 'error': {
      return {
        ...state,
        errors: action.payload,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    default:
      return state;
  }
};

const customerEntryInitialState = {
  accountId: '',
  company: '',
  contactEmail: '',
  errors: [],
  isEdit: false,
  isSubmitting: false,
  isSubmitted: false,
};

const CustomerProfile = (props) => {
  const classes = useStyles();
  const [, setReloadContext] = useContext(ReloadContext);
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();
  const { accountId } = useAccounts();

  const [state, dispatch] = useReducer(customerProfileReducer, customerEntryInitialState);
  const { company, contactEmail, errors, isEdit, isSubmitting } = state;
  const { canManageCustomerAccounts } = usePermissions();

  useEffect(() => {
    const fetchData = async (accountId) => {
      try {
        startProgress();
        let accountResult = await customerAccountApiService.getCustomerAccount(accountId);
        dispatch({
          type: 'loadExistingData',
          payload: {
            accountId: accountId,
            company: accountResult.data.name,
            contactEmail: accountResult.data.contactEmail,
          },
        });
        stopProgress();
      } catch (err) {
        catchApiError(err);
      }
    };

    if (accountId) {
      fetchData(accountId);
    }
  }, [accountId]);

  const handleSubmit = (event) => {
    //internal functions
    const handleSubmitResult = (apiResult, successMessage, onSuccess) => {
      stopProgress();
      if (apiResult.errors && apiResult.errors.length > 0) {
        let { errors } = formErrorFactory.createErrors(apiResult.errors);
        dispatch({
          type: 'error',
          payload: errors,
        });
      } else {
        dispatch({ type: 'success' });
        setReloadContext(new Date());
        showSuccessSnackBar(successMessage);
        if (onSuccess) {
          onSuccess();
        }
      }
    };

    if (accountId && canManageCustomerAccounts) {
      //Submitting
      startProgress();
      event.preventDefault();
      dispatch({ type: 'submit' });
      const formData = new FormData();

      customerAccountApiService
        .updateCustomerAccount(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            id: accountId,
            name: company,
            contactEmail: contactEmail,
          },
        })
        .then((result) => {
          handleSubmitResult(result, `The Customer was updated successfully`);
        })
        .catch((err) => {
          catchApiError(err, () => {
            dispatch({
              type: 'error',
              payload: [],
            });
          });
        });
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4>Account Profile</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer's Name"
                  margin="dense"
                  name="company"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'company',
                      payload: e.target.value,
                    })
                  }
                  required
                  error={errors['Name'] != null}
                  helperText={errors['Name']}
                  value={company || ''}
                  disabled={!canManageCustomerAccounts || isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Contact Email"
                  margin="dense"
                  name="contactEmail"
                  type="email"
                  error={errors['ContactEmail'] != null}
                  helperText={errors['ContactEmail']}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'contactEmail',
                      payload: e.target.value,
                    })
                  }
                  required
                  value={contactEmail || ''}
                  disabled={!canManageCustomerAccounts || isSubmitting}
                />
              </Grid>
            </Grid>
          </CardContent>
          {canManageCustomerAccounts && (
            <>
              <Divider />
              <CardActions className={classes.cardActions}>
                <MyButton buttonType="primary" type="submit" disabled={isSubmitting} className={classes.buttons}>
                  Save
                </MyButton>
              </CardActions>
            </>
          )}
        </Card>
      </form>
    </>
  );
};

export default withRouter(CustomerProfile);
