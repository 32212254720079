import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import useFilterQuery from 'src/hooks/useFilterQuery';
import DelayChangedTextField2 from 'src/wrappers/DelayChangedTextField2';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));

const ShipmentBookingsDataTableFilter = (props) => {
  const { onFilterChanged } = props;

  const classes = useStyles();
  const { filterQuery, updateAddressUrl } = useFilterQuery();
  const [freeText, setFreeText] = useState(filterQuery.freeText);
  const [filterFieldsUpdated, setFilterFieldsUpdated] = useState();

  useEffect(() => {
    if (freeText) {
      setFilterFieldsUpdated(new Date());
    }
  }, []);

  useEffect(() => {
    function ResendFilterComponentData() {
      const filterComponentData = { freeTextSearch: freeText };
      onFilterChanged(filterComponentData);
      console.log('ResendFilterComponentData', filterComponentData);
    }

    if (filterFieldsUpdated) {
      let filterQueryModel = { freeText: freeText };
      updateAddressUrl(filterQueryModel);
      ResendFilterComponentData();
    }
  }, [filterFieldsUpdated]);

  const handleFreeTextValueChange = useCallback((text) => {
    setFreeText(text);
  }, []);

  const handleFreeTextDelayValueChange = useCallback((text) => {
    setFilterFieldsUpdated(new Date());
  }, []);

  return (
    <Grid container direction="row" spacing={2} className={classes.root}>
      <Grid item xs={12} sm>
        <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
          <DelayChangedTextField2
            id="free-text-filter"
            label="Free Text Filter"
            variant="outlined"
            value={freeText}
            onValueChange={handleFreeTextValueChange}
            onDelayValueChange={handleFreeTextDelayValueChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ShipmentBookingsDataTableFilter;
