import moment from 'moment';
import { format } from 'react-string-format';
import sanitize from 'sanitize-filename';

import helperService from '../services/help.service';
import tableService from './table.service';

class csvTableService {
  getFileName(filename, filterDescription) {
    const tmpName = filterDescription ? `${filename}_${filterDescription}` : filename;
    let csvFilename = sanitize(tmpName);
    return csvFilename + '.csv';
  }

  generatePortPairCsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { key: 'origin', label: 'Origin' },
        { key: 'destination', label: 'Destination' },
        { key: 'weight', label: 'Weight (Kg)' },
        { key: 'volume', label: 'Volume (m3)' },
        { key: 'teu', label: 'TEU' },
        { key: 'avgTransitTime', label: 'Avg. Transit Time (days)' },
      ];

      const pdfData = data.map((elt) => {
        return {
          origin: elt.origin,
          destination: elt.destination,
          weight: elt.weight ? elt.weight.toLocaleString() : '',
          volume: elt.volume ? elt.volume.toLocaleString() : '',
          teu: elt.teu,
          avgTransitTime: elt.avgTransitTime ? elt.avgTransitTime.toLocaleString() : '',
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('PortPair', filterDescription),
      };

      return content;
    }
  }

  generateCommercialInvoiceValuePerSupplierCsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { key: 'supplier', label: 'Supplier' },
        { key: 'customs', label: 'Customs ($)' },
        { key: 'duty', label: 'Duty ($)' },
        { key: 'fob', label: 'FOB ($)' },
        { key: 'fobCurrency', label: 'FOB Currency' },
        { key: 'cif', label: 'CIF ($)' },
        { key: 'cifCurrency', label: 'CIF Currency' },
        { key: 'invoiceValue', label: 'Commercial Invoice Value ($)' },
        { key: 'invoiceCurrency', label: 'Invoice Currency' },
      ];

      const pdfData = data.map((item) => {
        return {
          supplier: item.supplier,
          customs: item.customs ? item.customs.toLocaleString() : '',
          duty: item.duty ? item.duty.toLocaleString() : '',
          fob: item.fob ? item.fob.toLocaleString() : '',
          fobCurrency: 'AUD', //item.fobCurrency,//Feedback from Nicole(2021-03-18) FOB value to always AUD
          cif: item.cif ? item.cif.toLocaleString() : '',
          cifCurrency: item.cifCurrency,
          invoiceValue: item.invoiceValue ? item.invoiceValue.toLocaleString() : '',
          invoiceCurrency: item.invoiceCurrency,
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('CommercialInvoiceValuePerSupplier', filterDescription),
      };

      return content;
    }
  }

  generateShipmentsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        // { id: 'Vessel', key: 'vessel', label: 'Vessel' },
        { id: 'DepartureVessel', key: 'departureVessel', label: 'Departure Vessel' },
        { id: 'ArrivalVessel', key: 'arrivalVessel', label: 'Arrival Vessel' },
        { id: 'Origin', key: 'origin', label: 'Origin' },
        { id: 'Destination', key: 'destination', label: 'Destination' },
        { id: 'PODName', key: 'podName', label: 'Port of Destination' },
        { id: 'FDPName', key: 'fdpName', label: 'Final Delivery Point' },
        { id: 'Trans', key: 'trans', label: 'Trans' },
        { id: 'Mode', key: 'mode', label: 'Mode' },
        { id: 'Supplier', key: 'supplier', label: 'Supplier' },
        { id: 'Sellers', key: 'sellers', label: 'Sellers' },
        { id: 'Importer', key: 'importer', label: 'Importer' },
        { id: 'OriginETD', key: 'originETD', label: 'Origin ETD' },
        { id: 'OriginATD', key: 'originATD', label: 'Origin ATD' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'Destination ETA' },
        { id: 'DestinationATA', key: 'destinationATA', label: 'Destination ATA' },
        { id: 'PODETA', key: 'podeta', label: 'POD ETA' },
        { id: 'PODATA', key: 'podata', label: 'POD ATA' },
        { id: 'FDPETA', key: 'fdpeta', label: 'FDP ETA' },
        { id: 'FDPATA', key: 'fdpata', label: 'FDP ATA' },
        { id: 'DelayInDays', key: 'delayInDays', label: 'Delay (Day)' },
        { id: 'Weight', key: 'weight', label: 'Weight (Kg)' },
        { id: 'Volume', key: 'volume', label: 'Volume (m3)' },
        { id: 'TEU', key: 'teu', label: 'TEU' },
        { id: 'CarrierCode', key: 'carrierCode', label: 'Carrier Code' },
        { id: 'CarrierContractNumber', key: 'carrierContractNumber', label: 'Carrier Contract No.' },
        { id: 'ContainerCount', key: 'containerCount', label: 'Container Count' },
        { id: 'Incoterm', key: 'incoterm', label: 'Incoterm' },
        { id: 'SupplierBookingDate', key: 'supplierBookingDate', label: 'Supplier Booking Date' },
        { id: 'CargoReadyDate', key: 'cargoReadyDate', label: 'Cargo Ready Date' },
        { id: 'FreeTimeDays', key: 'freeTimeDays', label: 'Free Time (Day)' },
        { id: 'ContainerNumbers', key: 'containerNumbers', label: 'Container Numbers' },
        { id: 'OrderNumbers', key: 'orderNumbers', label: 'Order Numbers' },
      ];

      const csvData = data.map((elt) => {
        let etd = moment(elt.originETD);
        let atd = moment(elt.originATD);
        let eta = moment(elt.destinationETA);
        let ata = moment(elt.destinationATA);
        let podeta = moment(elt.podeta);
        let podata = moment(elt.podata);
        let fdpeta = moment(elt.fdpeta);
        let fdpata = moment(elt.fdpata);
        let supplierBookingDate = moment(elt.supplierBookingDate);
        let cargoReadyDate = moment(elt.cargoReadyDate);

        return {
          shipmentNumber: elt.shipmentNumber,
          // vessel: elt.vessel,
          departureVessel: elt.departureVessel,
          arrivalVessel: elt.arrivalVessel,
          origin: elt.origin,
          destination: elt.destination,
          podName: elt.podName,
          fdpName: elt.fdpName,
          trans: elt.trans,
          mode: elt.mode,
          supplier: elt.supplier,
          sellers: elt.orderSellers,
          importer: elt.importer,
          originETD: etd.isValid() ? etd.format('L') : '',
          originATD: atd.isValid() ? atd.format('L') : '',
          destinationETA: eta.isValid() ? eta.format('L') : '',
          destinationATA: ata.isValid() ? ata.format('L') : '',
          podeta: podeta.isValid() ? podeta.format('L') : '',
          podata: podata.isValid() ? podata.format('L') : '',
          fdpeta: fdpeta.isValid() ? fdpeta.format('L') : '',
          fdpata: fdpata.isValid() ? fdpata.format('L') : '',
          delayInDays: elt.delayInDays > 0 ? format('+{0}', elt.delayInDays) : elt.delayInDays,
          weight: elt.weight ? elt.weight.toLocaleString() : '',
          volume: elt.volume ? elt.volume.toLocaleString() : '',
          teu: elt.teu,
          carrierCode: elt.carrierCode,
          carrierContractNumber: elt.carrierContractNumber,
          containerCount: elt.containerCount,
          incoterm: elt.incoterm,
          supplierBookingDate: supplierBookingDate.isValid() ? supplierBookingDate.format('L') : '',
          cargoReadyDate: cargoReadyDate.isValid() ? cargoReadyDate.format('L') : '',
          freeTimeDays: elt.freeTimeDays,
          containerNumbers: elt.containerNumbers,
          orderNumbers: elt.orderNumbers,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Shipments', filterDescription),
      };

      return content;
    }
  }

  generateSpendPerSupplierCsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { key: 'supplier', label: 'Supplier' },
        { key: 'amount', label: 'Total Spend ($)' },
      ];

      const pdfData = data.map((elt) => {
        return {
          supplier: elt.supplier,
          amount: elt.amount ? elt.amount.toLocaleString() : '',
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('SpendPerSupplier', filterDescription),
      };

      return content;
    }
  }

  generateConsolidatedShipmentsSpendsCsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumbers', key: 'shipmentNumbers', label: 'Shipment No.(s)' },
        { id: 'Origin', key: 'origin', label: 'Origin' },
        { id: 'Destination', key: 'destination', label: 'Destination' },
        { id: 'Trans', key: 'trans', label: 'Trans' },
        { id: 'Mode', key: 'mode', label: 'Mode' },
        { id: 'OriginETD', key: 'originETD', label: 'Origin ETD' },
        { id: 'OriginATD', key: 'originATD', label: 'Origin ATD' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'Destination ETA' },
        { id: 'DestinationATA', key: 'destinationATA', label: 'Destination ATA' },
        { id: 'CarrierName', key: 'carrierName', label: 'Carrier Name' },
        { id: 'CarrierBookingReference', key: 'carrierBookingReference', label: 'Carrier Booking Ref.' },
        { id: 'TotalFreightSpend', key: 'totalFreightSpend', label: 'Freight Spend ($)' },
        { id: 'CommercialValue', key: 'commercialValue', label: 'Commercial Value ($)' },
        { id: 'TotalSpends', key: 'totalSpends', label: 'Total Spends ($)' },
        { id: 'Teu', key: 'teu', label: 'TEU' },
        { id: 'ContainerNumbers', key: 'containerNumbers', label: 'Container Numbers' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);

        return {
          shipmentNumbers: elt.shipmentNumbers,
          origin: elt.origin,
          destination: elt.destination,
          trans: elt.trans,
          mode: elt.mode,
          originETD: originETD.isValid() ? originETD.format('L') : '',
          originATD: originATD.isValid() ? originATD.format('L') : '',
          destinationETA: destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA: destinationATA.isValid() ? destinationATA.format('L') : '',
          carrierName: elt.carrierName,
          carrierBookingReference: elt.carrierBookingReference,
          totalFreightSpend: elt.totalFreightSpend ? elt.totalFreightSpend.toLocaleString() : '',
          commercialValue: elt.commercialValue ? elt.commercialValue.toLocaleString() : '',
          totalSpends: elt.totalSpends ? elt.totalSpends.toLocaleString() : '',
          teu: elt.teu,
          containerNumbers: elt.containerNumbers,
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('ShipmentsSpends', filterDescription),
      };

      return content;
    }
  }

  generateShipmentSpendsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'Origin', key: 'origin', label: 'Origin' },
        { id: 'Destination', key: 'destination', label: 'Destination' },
        { id: 'Trans', key: 'trans', label: 'Trans' },
        { id: 'Mode', key: 'mode', label: 'Mode' },
        { id: 'OriginETD', key: 'originETD', label: 'Origin ETD' },
        { id: 'OriginATD', key: 'originATD', label: 'Origin ATD' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'Destination ETA' },
        { id: 'DestinationATA', key: 'destinationATA', label: 'Destination ATA' },
        { id: 'CarrierName', key: 'carrierName', label: 'Carrier Name' },
        { id: 'CarrierBookingReference', key: 'carrierBookingReference', label: 'Carrier Booking Ref.' },
        { id: 'TotalFreightSpend', key: 'totalFreightSpend', label: 'Freight Spend ($)' },
        { id: 'CommercialValue', key: 'commercialValue', label: 'Commercial Value ($)' },
        { id: 'TotalSpends', key: 'totalSpends', label: 'Total Spends ($)' },
        { id: 'Teu', key: 'teu', label: 'TEU' },
        { id: 'ContainerNumbers', key: 'containerNumbers', label: 'Container Numbers' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);

        return {
          shipmentNumber: elt.shipmentNumber,
          origin: elt.origin,
          destination: elt.destination,
          trans: elt.trans,
          mode: elt.mode,
          originETD: originETD.isValid() ? originETD.format('L') : '',
          originATD: originATD.isValid() ? originATD.format('L') : '',
          destinationETA: destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA: destinationATA.isValid() ? destinationATA.format('L') : '',
          carrierName: elt.carrierName,
          carrierBookingReference: elt.carrierBookingReference,
          totalFreightSpend: elt.totalFreightSpend ? elt.totalFreightSpend.toLocaleString() : '',
          commercialValue: elt.commercialValue ? elt.commercialValue.toLocaleString() : '',
          totalSpends: elt.totalSpends ? elt.totalSpends.toLocaleString() : '',
          teu: elt.teu,
          containerNumbers: elt.containerNumbers,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: pdfData,
        filename: this.getFileName('ShipmentSpends', filterDescription),
      };

      return content;
    }
  }

  generateShipmentDetailsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'shipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'supplier', key: 'supplier', label: 'Supplier' },
        { id: 'productCode', key: 'productCode', label: 'Product Code' },
        { id: 'description', key: 'description', label: 'Description' },
        { id: 'invoiceQty', key: 'invoiceQty', label: 'Qty' },
        { id: 'invoiceUQ', key: 'invoiceUQ', label: 'UQ' },
        { id: 'unitValue', key: 'unitValue', label: 'Unit Value ($)' },
        { id: 'DelayInDays', key: 'delayInDays', label: 'Delay (Day)' },
      ];

      const pdfData = data.map((elt) => {
        return {
          shipmentNumber: elt.shipmentNumber,
          supplier: elt.supplier,
          productCode: elt.productCode,
          description: elt.description,
          invoiceQty: elt.invoiceQty ? elt.invoiceQty.toLocaleString() : '',
          invoiceUQ: elt.invoiceUQ,
          unitValue: elt.unitValue ? elt.unitValue.toLocaleString() : '',
          delayInDays: elt.delayInDays > 0 ? format('+{0}', elt.delayInDays) : elt.delayInDays,
        };
      });
      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: pdfData,
        filename: this.getFileName('ShipmentDetails', filterDescription),
      };

      return content;
    }
  }

  generateProductsCsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { key: 'productCode', label: 'Product Code' },
        { key: 'description', label: 'Description' },
        { key: 'totalQty', label: 'Total Qty.' },
        { key: 'avgUnitValue', label: 'Avg. ($)' },
        { key: 'minUnitValue', label: 'Min ($)' },
        { key: 'maxUnitValue', label: 'Max ($)' },
        { key: 'suppliers', label: 'Suppliers' },
      ];

      const pdfData = data.map((item) => {
        return {
          productCode: item.productCode,
          description: item.description,
          totalQty: item.totalQty ? item.totalQty.toLocaleString() : '',
          avgUnitValue: item.avgUnitValue ? item.avgUnitValue.toLocaleString() : '',
          minUnitValue: item.minUnitValue ? item.minUnitValue.toLocaleString() : '',
          maxUnitValue: item.maxUnitValue ? item.maxUnitValue.toLocaleString() : '',
          suppliers: item.suppliers,
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('Products', filterDescription),
      };

      return content;
    }
  }

  generatePurhcaseOrderEDICsvData(data, filterDescription) {
    if (data) {
      const headers = [
        { key: 'orderNumber', label: 'Order Number' },
        { key: 'supplier', label: 'Supplier' },
        { key: 'deliverTo', label: 'Deliver To' },
        { key: 'orderDate', label: 'Order Date' },
        { key: 'shipmentStatuses', label: 'Shipment Status' },
      ];

      const pdfData = data.map((item) => {
        let orderDate = moment(item.orderDate);

        var shipmentStatusesText = 'Pending';
        if (item.shipmentStatuses) {
          var shipmentStatuses = [];
          item.shipmentStatuses.map((shipmentStatus) => {
            shipmentStatuses.push(shipmentStatus.shipmentNumber + ': ' + shipmentStatus.status);
          });
          shipmentStatusesText = shipmentStatuses.join(', ');
        }

        return {
          orderNumber: item.orderNumber,
          supplier: item.supplier,
          deliverTo: item.deliverTo,
          orderDate: orderDate.isValid() ? orderDate.format('L') : '',
          shipmentStatuses: shipmentStatusesText,
        };
      });

      let content = {
        headers: headers,
        data: pdfData,
        filename: this.getFileName('Purchase Orders', filterDescription),
      };

      return content;
    }
  }

  generatePurchaseOrderLinesCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'LineId', key: 'lineId', label: 'Line No.' },
        { id: 'OrderNumberSplit', key: 'orderNumberSplit', label: 'Split No.' },
        { id: 'Code', key: 'code', label: 'Code' },
        // { id: 'Description', key: 'description', label: 'Description' },
        // { id: 'Qty', key: 'qty', label: 'Qty' },
        // { id: 'QtyUOM', key: 'qtyUOM', label: 'Qty UOM' },
        { id: 'UnitPrice', key: 'unitPrice', label: 'Unit Price($)' },
        { id: 'UnitQty', key: 'unitQty', label: 'Unit Qty' },
        { id: 'UnitUOM', key: 'unitUOM', label: 'Unit UOM' },
        // { id: 'LinePrice', key: 'linePrice', label: 'Line Price($)' },
        { id: 'QtyMet', key: 'qtyMet', label: 'Qty Receive' },
        { id: 'remainingQty', key: 'remainingQty', label: 'Remaining Qty' },
        { id: 'CommercialValue', key: 'commercialValue', label: 'Shipped Commercial Value($)' },
        { id: 'RemainingCommercialValue', key: 'remainingCommercialValue', label: 'Remaining Commercial Value($)' },
        // { id: 'Buyer', key: 'buyer', label: 'Buyer' },
        // { id: 'OrderDate', key: 'orderDate', label: 'Order Date' },
        { id: 'ShippingDate', key: 'shippingDate', label: 'Shipping Date' },
        { id: 'RequiredInStoreDate', key: 'requiredInStoreDate', label: 'Required In Store' },
        { id: 'DeliveryDate', key: 'deliveryDate', label: 'Delivery Date' },
        // { id: 'Supplier', key: 'supplier', label: 'Supplier' },
        // { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'RecentEventType', key: 'recentEventType', label: 'Status' },
        { id: 'OriginETD', key: 'originETD', label: 'ETD' },
        { id: 'OriginATD', key: 'originATD', label: 'ATD' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'ETA' },
        { id: 'DestinationATA', key: 'destinationATA', label: 'ATA' },
        { id: 'InStoreDelayInDays', key: 'inStoreDelayInDays', label: 'In Store Delay (Days)' },
        { id: 'ArrivalDelayInDays', key: 'arrivalDelayInDays', label: 'Arrival Delay (Days)' },
        { id: 'DepartureDelayInDays', key: 'departureDelayInDays', label: 'Departure Delay (Days)' },
        { id: 'TransitDelayInDays', key: 'transitDelayInDays', label: 'Transit Delay (Days)' },
        { id: 'VendorBookingDelayInDays', key: 'vendorBookingDelayInDays', label: 'Vendor Booking Delay (Days)' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);
        let requiredInStoreDate = moment(elt.requiredInStoreDate);
        let deliveryDate = moment(elt.deliveryDate);
        // let orderDate = moment(elt.orderDate);
        let shippingDate = moment(elt.shippingDate);

        let rowData = {
          orderNumber: elt.orderNumber,
          lineId: elt.lineId,
          orderNumberSplit: elt.orderNumberSplit,
          code: elt.code,
          // description: elt.description,
          // qty: elt.qty ? elt.qty.toLocaleString() : '',
          // qtyUOM: elt.qtyUOM,
          unitPrice: elt.unitPrice ? elt.unitPrice.toLocaleString() : '',
          unitQty: elt.unitQty ? elt.unitQty.toLocaleString() : '',
          unitUOM: elt.unitUOM,
          // linePrice: elt.linePrice ? elt.linePrice.toLocaleString() : '',
          qtyMet: elt.qtyMet ? elt.qtyMet.toLocaleString() : '0',
          remainingQty: elt.remainingQty ? elt.remainingQty.toLocaleString() : '0',
          commercialValue: elt.commercialValue ? elt.commercialValue.toLocaleString() : '0',
          remainingCommercialValue: elt.remainingCommercialValue ? elt.remainingCommercialValue.toLocaleString() : '0',
          // buyer: elt.buyer,
          requiredInStoreDate: requiredInStoreDate.isValid() ? requiredInStoreDate.format('L') : '',
          // orderDate: orderDate.isValid() ? orderDate.format('L') : '',
          shippingDate: shippingDate.isValid() ? shippingDate.format('L') : '',
          deliveryDate: deliveryDate.isValid() ? deliveryDate.format('L') : '',
          originETD: originETD.isValid() ? originETD.format('L') : '',
          originATD: originATD.isValid() ? originATD.format('L') : '',
          destinationETA: destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA: destinationATA.isValid() ? destinationATA.format('L') : '',
          // shipmentNumber: elt.shipmentNumber,
          recentEventType: elt.recentEventType,
          inStoreDelayInDays: elt.inStoreDelayInDays > 0 ? format('+{0}', elt.inStoreDelayInDays) : elt.inStoreDelayInDays,
          arrivalDelayInDays: elt.arrivalDelayInDays > 0 ? format('+{0}', elt.arrivalDelayInDays) : elt.arrivalDelayInDays,
          departureDelayInDays: elt.departureDelayInDays > 0 ? format('+{0}', elt.departureDelayInDays) : elt.departureDelayInDays,
          transitDelayInDays: elt.transitDelayInDays > 0 ? format('+{0}', elt.transitDelayInDays) : elt.transitDelayInDays,
          vendorBookingDelayInDays: elt.vendorBookingDelayInDays > 0 ? format('+{0}', elt.vendorBookingDelayInDays) : elt.vendorBookingDelayInDays,
          // supplier: elt.supplier,
        };

        return rowData;
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: pdfData,
        filename: this.getFileName('Purchase Order Lines', filterDescription),
      };

      return content;
    }
  }

  generatePurchaseOrderLineSpendsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'LineId', key: 'lineId', label: 'Line No.' },
        { id: 'OrderNumberSplit', key: 'orderNumberSplit', label: 'Split No.' },
        { id: 'Code', key: 'code', label: 'Code' },
        { id: 'UnitPrice', key: 'unitPrice', label: 'Unit Price($)' },
        { id: 'UnitQty', key: 'unitQty', label: 'Unit Qty' },
        { id: 'UnitUOM', key: 'unitUOM', label: 'Unit UOM' },
        { id: 'QtyMet', key: 'qtyMet', label: 'Qty Receive' },
        { id: 'remainingQty', key: 'remainingQty', label: 'Remaining Qty' },
        { id: 'CommercialValue', key: 'commercialValue', label: 'Shipped Commercial Value($)' },
        { id: 'RemainingCommercialValue', key: 'remainingCommercialValue', label: 'Remaining Commercial Value($)' },
        { id: 'FreightSpendPerUnit', key: 'freightSpendPerUnit', label: 'Freight per Unit($)' },
        { id: 'FreightSpendPerLine', key: 'freightSpendPerLine', label: 'Freight per Line($)' },
        { id: 'TotalSpendsPerLine', key: 'totalSpendsPerLine', label: 'Total Spends per Line($)' },
      ];

      const pdfData = data.map((elt) => {
        let rowData = {
          orderNumber: elt.orderNumber,
          lineId: elt.lineId,
          orderNumberSplit: elt.orderNumberSplit,
          code: elt.code,
          unitPrice: elt.unitPrice ? elt.unitPrice.toLocaleString() : '',
          unitQty: elt.unitQty ? elt.unitQty.toLocaleString() : '',
          unitUOM: elt.unitUOM,
          qtyMet: elt.qtyMet ? elt.qtyMet.toLocaleString() : '0',
          remainingQty: elt.remainingQty ? elt.remainingQty.toLocaleString() : '0',
          commercialValue: elt.commercialValue ? elt.commercialValue.toLocaleString() : '0',
          remainingCommercialValue: elt.remainingCommercialValue ? elt.remainingCommercialValue.toLocaleString() : '0',
          freightSpendPerUnit: elt.freightSpendPerUnit ? elt.freightSpendPerUnit.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
          freightSpendPerLine: elt.freightSpendPerLine ? elt.freightSpendPerLine.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
          totalSpendsPerLine: elt.totalSpendsPerLine ? elt.totalSpendsPerLine.toLocaleString(undefined, { maximumFractionDigits: 2 }) : '',
        };

        return rowData;
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: pdfData,
        filename: this.getFileName('Purchase Order Line Spends', filterDescription),
      };

      return content;
    }
  }

  generatePurchaseOrderLineMilestonesCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'LineId', key: 'lineId', label: 'Line No.' },
        { id: 'OrderNumberSplit', key: 'orderNumberSplit', label: 'Split No.' },
        { id: 'ShippingDate', key: 'shippingDate', label: 'Shipping Date' },
        { id: 'DeliveryDate', key: 'deliveryDate', label: 'Delivery Date' },
        { id: 'RequiredInStoreDate', key: 'requiredInStoreDate', label: 'Required In Store' },
        { id: 'RecentEventType', key: 'recentEventType', label: 'Status' },
        { id: 'OriginETD', key: 'originETD', label: 'ETD' },
        { id: 'OriginATD', key: 'originATD', label: 'ATD' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'ETA' },
        { id: 'DestinationATA', key: 'destinationATA', label: 'ATA' },
        { id: 'InStoreDelayInDays', key: 'inStoreDelayInDays', label: 'In Store Delay (Days)' },
        { id: 'ArrivalDelayInDays', key: 'arrivalDelayInDays', label: 'Arrival Delay (Days)' },
        { id: 'DepartureDelayInDays', key: 'departureDelayInDays', label: 'Departure Delay (Days)' },
        { id: 'TransitDelayInDays', key: 'transitDelayInDays', label: 'Transit Delay (Days)' },
        { id: 'VendorBookingDelayInDays', key: 'vendorBookingDelayInDays', label: 'Vendor Booking Delay (Days)' },
      ];

      const pdfData = data.map((elt) => {
        let originETD = moment(elt.originETD);
        let originATD = moment(elt.originATD);
        let destinationETA = moment(elt.destinationETA);
        let destinationATA = moment(elt.destinationATA);
        let requiredInStoreDate = moment(elt.requiredInStoreDate);
        let deliveryDate = moment(elt.deliveryDate);
        let shippingDate = moment(elt.shippingDate);

        let rowData = {
          orderNumber: elt.orderNumber,
          lineId: elt.lineId,
          orderNumberSplit: elt.orderNumberSplit,
          shippingDate: shippingDate.isValid() ? shippingDate.format('L') : '',
          deliveryDate: deliveryDate.isValid() ? deliveryDate.format('L') : '',
          requiredInStoreDate: requiredInStoreDate.isValid() ? requiredInStoreDate.format('L') : '',
          recentEventType: elt.recentEventType,
          originETD: originETD.isValid() ? originETD.format('L') : '',
          originATD: originATD.isValid() ? originATD.format('L') : '',
          destinationETA: destinationETA.isValid() ? destinationETA.format('L') : '',
          destinationATA: destinationATA.isValid() ? destinationATA.format('L') : '',
          inStoreDelayInDays: elt.inStoreDelayInDays > 0 ? format('+{0}', elt.inStoreDelayInDays) : elt.inStoreDelayInDays,
          arrivalDelayInDays: elt.arrivalDelayInDays > 0 ? format('+{0}', elt.arrivalDelayInDays) : elt.arrivalDelayInDays,
          departureDelayInDays: elt.departureDelayInDays > 0 ? format('+{0}', elt.departureDelayInDays) : elt.departureDelayInDays,
          transitDelayInDays: elt.transitDelayInDays > 0 ? format('+{0}', elt.transitDelayInDays) : elt.transitDelayInDays,
          vendorBookingDelayInDays: elt.vendorBookingDelayInDays > 0 ? format('+{0}', elt.vendorBookingDelayInDays) : elt.vendorBookingDelayInDays,
        };

        return rowData;
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: pdfData,
        filename: this.getFileName('Purchase Order Line Milestones', filterDescription),
      };

      return content;
    }
  }

  generateExceptionsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'Supplier', key: 'supplier', label: 'Supplier' },
        { id: 'LoadAt', key: 'loadAt', label: 'Load At' },
        { id: 'DischargeAt', key: 'dischargeAt', label: 'Discharge At' },
        { id: 'Vessel', key: 'vessel', label: 'Vessel' },
        { id: 'Voyage', key: 'voyage', label: 'Voyage' },
        { id: 'Carrier', key: 'carrier', label: 'Carrier' },
        { id: 'Containers', key: 'containers', label: 'Containers' },
        { id: 'RequiredExWorksDate', key: 'requiredExWorksDate', label: 'Req. Ex Works' },
        { id: 'RequiredDeliveryDate', key: 'requiredDeliveryDate', label: 'Req. Delivery' },
        { id: 'EstimatedDeliveryDate', key: 'estimatedDeliveryDate', label: 'Est. Delivery' },
        { id: 'ActualDeliveryDate', key: 'actualDeliveryDate', label: 'Act. Delivery' },
        { id: 'EstimatedExFactoryDate', key: 'estimatedExFactoryDate', label: 'Est. Ex Factory' },
        { id: 'ActualExFactoryDate', key: 'actualExFactoryDate', label: 'Act Ex Factory' },
        { id: 'ETD', key: 'etd', label: 'ETD' },
        { id: 'ATD', key: 'atd', label: 'ATD' },
        { id: 'ETA', key: 'eta', label: 'ETA' },
        { id: 'ATA', key: 'ata', label: 'ATA' },
        { id: 'EstimatedCustomsClearedDate', key: 'estimatedCustomsClearedDate', label: 'Est. Customs Cleared' },
        { id: 'ActualCustomsClearedDate', key: 'actualCustomsClearedDate', label: 'Act. Customs Cleared' },
        { id: 'CTOAvailability', key: 'ctoAvailability', label: 'CTO Availability' },
        { id: 'DelayDeliveryInDays', key: 'delayDeliveryInDays', label: 'Delay Delivery (Day)' },
        { id: 'DelayExFactoryInDays', key: 'delayExFactoryInDays', label: 'Delay Ex Factory (Day)' },
        { id: 'DelayDepartureInDays', key: 'delayDepartureInDays', label: 'Delay Departure (Day)' },
        { id: 'DelayArrivalInDays', key: 'delayArrivalInDays', label: 'Delay Arrival (Day)' },
      ];

      const csvData = data.map((item) => {
        let etd = moment(item.etd);
        let atd = moment(item.atd);
        let eta = moment(item.eta);
        let ata = moment(item.ata);
        let requiredExWorksDate = moment(item.requiredExWorksDate);
        let requiredDeliveryDate = moment(item.requiredDeliveryDate);
        let estimatedDeliveryDate = moment(item.estimatedDeliveryDate);
        let actualDeliveryDate = moment(item.actualDeliveryDate);
        let estimatedExFactoryDate = moment(item.estimatedExFactoryDate);
        let actualExFactoryDate = moment(item.actualExFactoryDate);
        let estimatedCustomsClearedDate = moment(item.estimatedCustomsClearedDate);
        let actualCustomsClearedDate = moment(item.actualCustomsClearedDate);
        let ctoAvailability = moment(item.ctoAvailability);

        return {
          shipmentNumber: item.shipmentNumber,
          orderNumber: item.orderNumber,
          supplier: item.supplierName,
          loadAt: item.loadAt,
          dischargeAt: item.dischargeAt,
          vessel: item.vessel,
          voyage: item.voyage,
          carrier: item.carrier,
          containers: item.containers,
          requiredExWorksDate: requiredExWorksDate.isValid() ? requiredExWorksDate.format('L') : '',
          requiredDeliveryDate: requiredDeliveryDate.isValid() ? requiredDeliveryDate.format('L') : '',
          estimatedDeliveryDate: estimatedDeliveryDate.isValid() ? estimatedDeliveryDate.format('L') : '',
          actualDeliveryDate: actualDeliveryDate.isValid() ? actualDeliveryDate.format('L') : '',
          estimatedExFactoryDate: estimatedExFactoryDate.isValid() ? estimatedExFactoryDate.format('L') : '',
          actualExFactoryDate: actualExFactoryDate.isValid() ? actualExFactoryDate.format('L') : '',
          etd: etd.isValid() ? etd.format('L') : '',
          atd: atd.isValid() ? atd.format('L') : '',
          eta: eta.isValid() ? eta.format('L') : '',
          ata: ata.isValid() ? ata.format('L') : '',
          estimatedCustomsClearedDate: estimatedCustomsClearedDate.isValid() ? estimatedCustomsClearedDate.format('L') : '',
          actualCustomsClearedDate: actualCustomsClearedDate.isValid() ? actualCustomsClearedDate.format('L') : '',
          ctoAvailability: ctoAvailability.isValid() ? ctoAvailability.format('L') : '',
          delayDeliveryInDays: item.delayDeliveryInDays > 0 ? `+${item.delayDeliveryInDays}` : item.delayDeliveryInDays,
          delayExFactoryInDays: item.delayExFactoryInDays > 0 ? `+${item.delayExFactoryInDays}` : item.delayExFactoryInDays,
          delayDepartureInDays: item.delayDepartureInDays > 0 ? `+${item.delayDepartureInDays}` : item.delayDepartureInDays,
          delayArrivalInDays: item.delayArrivalInDays > 0 ? `+${item.delayArrivalInDays}` : item.delayArrivalInDays,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Exceptions', filterDescription),
      };

      return content;
    }
  }

  generateDeliveriesCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'OrderNumbers', key: 'orderNumbers', label: 'Order No.' },
        { id: 'ContainerNumber', key: 'containerNumber', label: 'Container No.' },
        { id: 'ContainerType', key: 'containerType', label: 'Type' },
        { id: 'SupplierName', key: 'supplierName', label: 'Supplier' },
        { id: 'GoodsDescription', key: 'goodsDescription', label: 'Goods Description' },
        { id: 'VesselVoyage', key: 'vesselVoyage', label: 'Vessel/Voyage' },
        { id: 'Destination', key: 'destination', label: 'Destination' },
        { id: 'DestinationETA', key: 'destinationETA', label: 'ETA' },
        { id: 'AvailableFromDate', key: 'availableFromDate', label: 'Available From' },
        { id: 'EstimatedDeliveryDate', key: 'estimatedDeliveryDate', label: 'Est. Delivery' },
        { id: 'DeliveryMode', key: 'deliveryMode', label: 'Delivery Mode' },
        { id: 'DeliveryAddress', key: 'deliveryAddress', label: 'Delivery Address' },
        { id: 'ContainerDetentionStartsDate', key: 'containerDetentionStartsDate', label: 'Container Detention Starts' },
        { id: 'EmptyReadyDate', key: 'emptyReadyDate', label: 'Empty Ready Date' },
        { id: 'ContainerDehireDate', key: 'containerDehireDate', label: 'Container Dehire Date' },
      ];

      const csvData = data.map((elt) => {
        let destinationETA = moment(elt.destinationETA);
        let availableFromDate = moment(elt.availableFromDate);
        let estimatedDeliveryDate = moment(elt.estimatedDeliveryDate);
        let containerDetentionStartsDate = moment(elt.containerDetentionStartsDate);
        let emptyReadyDate = moment(elt.emptyReadyDate);
        let containerDehireDate = moment(elt.containerDehireDate);

        return {
          shipmentNumber: elt.shipmentNumber,
          orderNumbers: elt.orderNumbers,
          containerNumber: elt.containerNumber,
          containerType: elt.containerType,
          supplierName: elt.supplierName,
          goodsDescription: elt.goodsDescription,
          vesselVoyage: elt.vesselVoyage,
          destination: elt.destination,
          destinationETA: destinationETA.isValid() ? destinationETA.format('L') : '',
          availableFromDate: availableFromDate.isValid() ? availableFromDate.format('L') : '',
          estimatedDeliveryDate: estimatedDeliveryDate.isValid() ? estimatedDeliveryDate.format('L') : '',
          deliveryMode: elt.deliveryMode,
          deliveryAddress: elt.deliveryAddress,
          containerDetentionStartsDate: containerDetentionStartsDate.isValid() ? containerDetentionStartsDate.format('L') : '',
          emptyReadyDate: emptyReadyDate.isValid() ? emptyReadyDate.format('L') : '',
          containerDehireDate: containerDehireDate.isValid() ? containerDehireDate.format('L') : '',
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Deliveries', filterDescription),
      };

      return content;
    }
  }

  generateOpenOrdersCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'OrderDate', key: 'orderDate', label: 'Order Date' },
        { id: 'OrderStatus', key: 'orderStatus', label: 'Order Status' },
        { id: 'Supplier', key: 'supplier', label: 'Supplier' },
        { id: 'BookingDate', key: 'bookingDate', label: 'Booking Date' },
        { id: 'GoodsDescription', key: 'goodsDescription', label: 'Goods Description' },
        { id: 'EstimatedExFactory', key: 'estimatedExFactory', label: 'Est. Ex Factory' },
        { id: 'OriginName', key: 'originName', label: 'Origin' },
        { id: 'DestinationName', key: 'destinationName', label: 'Destination' },
        { id: 'ETD', key: 'etd', label: 'ETD' },
        { id: 'ETA', key: 'eta', label: 'ETA' },
        { id: 'ArrivalVessel', key: 'arrivalVessel', label: 'Arrival Vessel' },
        { id: 'ArrivalVoyage', key: 'arrivalVoyage', label: 'Arrival Voyage' },
        { id: 'Containers', key: 'containers', label: 'Containers' },
        { id: 'EstimatedCCleared', key: 'estimatedCCleared', label: 'Est. C Cleared' },
        { id: 'EstimatedDelivered', key: 'estimatedDelivered', label: 'Est. Delivered' },
      ];

      const csvData = data.map((elt) => {
        let orderDate = moment(elt.orderDate);
        let bookingDate = moment(elt.bookingDate);
        let estimatedExFactory = moment(elt.estimatedExFactory);
        let etd = moment(elt.etd);
        let eta = moment(elt.eta);
        let estimatedCCleared = moment(elt.estimatedCCleared);
        let estimatedDelivered = moment(elt.estimatedDelivered);

        return {
          shipmentNumber: elt.shipmentNumber,
          orderNumber: elt.orderNumber,
          orderDate: orderDate.isValid() ? orderDate.format('L') : '',
          orderStatus: elt.orderStatus,
          supplier: elt.supplier,
          bookingDate: bookingDate.isValid() ? bookingDate.format('L') : '',
          goodsDescription: elt.goodsDescription,
          estimatedExFactory: estimatedExFactory.isValid() ? estimatedExFactory.format('L') : '',
          originName: elt.originName,
          destinationName: elt.destinationName,
          etd: etd.isValid() ? etd.format('L') : '',
          eta: eta.isValid() ? eta.format('L') : '',
          arrivalVessel: elt.arrivalVessel,
          arrivalVoyage: elt.arrivalVoyage,
          containers: elt.containers,
          estimatedCCleared: estimatedCCleared.isValid() ? estimatedCCleared.format('L') : '',
          estimatedDelivered: estimatedDelivered.isValid() ? estimatedDelivered.format('L') : '',
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Deliveries', filterDescription),
      };

      return content;
    }
  }

  generateBillingSummariesCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'InvoiceNumber', key: 'invoiceNumber', label: 'Invoice No.' },
        { id: 'InvoiceType', key: 'invoiceType', label: 'Type' },
        { id: 'InvoiceDate', key: 'invoiceDate', label: 'Invoice Date' },
        { id: 'LocalExcludingTax', key: 'localExcludingTax', label: 'Local Excluding Tax' },
        { id: 'LocalTax', key: 'localTax', label: 'Local Tax' },
        { id: 'InvoiceAmount', key: 'invoiceAmount', label: 'Invoice Amount' },
        { id: 'Currency', key: 'currency', label: 'Currency' },
        { id: 'Mode', key: 'mode', label: 'Mode' },
        { id: 'ContainerNumbers', key: 'containerNumbers', label: 'Container No.' },
        { id: 'OrderNumbers', key: 'orderNumbers', label: 'Order No.' },
        { id: 'OriginCode', key: 'originCode', label: 'Origin' },
        { id: 'Destination', key: 'destination', label: 'Destination' },
        { id: 'ETD', key: 'etd', label: 'ETD' },
        { id: 'ETA', key: 'eta', label: 'ETA' },
        { id: 'Vessel', key: 'vessel', label: 'Vessel' },
        { id: 'VoyageFlight', key: 'voyageFlight', label: 'Voyage/Flight' },
      ];

      const csvData = data.map((elt) => {
        let invoiceDate = moment(elt.invoiceDate);
        let etd = moment(elt.etd);
        let eta = moment(elt.eta);

        return {
          shipmentNumber: elt.shipmentNumber,
          invoiceNumber: elt.invoiceNumber,
          invoiceType: elt.invoiceType,
          invoiceDate: invoiceDate.isValid() ? invoiceDate.format('L') : '',
          localExcludingTax: elt.localExcludingTax?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          localTax: elt.localTax?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          invoiceAmount: elt.invoiceAmount?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          currency: elt.currency,
          mode: elt.mode,
          containerNumbers: elt.containerNumbers,
          orderNumbers: elt.orderNumbers,
          originCode: elt.originCode,
          destination: elt.destination,
          etd: etd.isValid() ? etd.format('L') : '',
          eta: eta.isValid() ? eta.format('L') : '',
          vessel: elt.vessel,
          voyageFlight: elt.voyageFlight,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Billing Summaries', filterDescription),
      };

      return content;
    }
  }

  generateBillingDetailedCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'InvoiceNumber', key: 'invoiceNumber', label: 'Invoice No.' },
        { id: 'InvoiceType', key: 'invoiceType', label: 'Type' },
        { id: 'InvoiceDate', key: 'invoiceDate', label: 'Invoice Date' },
        { id: 'ChargeCode', key: 'chargeCode', label: 'Charge Code' },
        { id: 'LineDescription', key: 'lineDescription', label: 'Line Description' },
        { id: 'Local', key: 'local', label: 'Local' },
        { id: 'Tax', key: 'tax', label: 'Tax' },
        { id: 'TotalInvoiced', key: 'totalInvoiced', label: 'Total Invoiced' },
        { id: 'Currency', key: 'currency', label: 'Currency' },
        { id: 'ChargeGroup', key: 'chargeGroup', label: 'Charge Group' },
        { id: 'OrderNumbers', key: 'orderNumbers', label: 'Order No.' },
      ];

      const csvData = data.map((elt) => {
        let invoiceDate = moment(elt.invoiceDate);
        const lineDescriptions = elt.lineDescription?.split('#NL#');

        return {
          shipmentNumber: elt.shipmentNumber,
          invoiceNumber: elt.invoiceNumber,
          invoiceType: elt.invoiceType,
          invoiceDate: invoiceDate.isValid() ? invoiceDate.format('L') : '',
          chargeCode: elt.chargeCode,
          lineDescription: lineDescriptions,
          local: elt.local?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          tax: elt.tax?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          totalInvoiced: elt.totalInvoiced?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          currency: elt.currency,
          chargeGroup: elt.chargeGroup,
          orderNumbers: elt.orderNumbers,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Billing Detailed', filterDescription),
      };

      return content;
    }
  }

  generatePetbarnExceptionsCsvData(data, filterDescription, hiddenColumnNames) {
    if (data) {
      const headers = [
        { id: 'ShipmentNumber', key: 'shipmentNumber', label: 'Shipment No.' },
        { id: 'OrderNumber', key: 'orderNumber', label: 'Order No.' },
        { id: 'Supplier', key: 'supplier', label: 'Supplier' },
        { id: 'LoadAt', key: 'loadAt', label: 'Load At' },
        { id: 'DischargeAt', key: 'dischargeAt', label: 'Discharge At' },
        { id: 'Vessel', key: 'vessel', label: 'Vessel' },
        { id: 'Voyage', key: 'voyage', label: 'Voyage' },
        { id: 'Containers', key: 'containers', label: 'Container No.' },
        { id: 'RequiredExWorks', key: 'requiredExWorks', label: 'Req. Ex Works' },
        { id: 'EstimatedExFactory', key: 'estimatedExFactory', label: 'Est. Ex Factory' },
        { id: 'ActualExFactory', key: 'actualExFactory', label: 'Act Ex Factory' },
        { id: 'ETD', key: 'etd', label: 'ETD' },
        { id: 'ATD', key: 'atd', label: 'ATD' },
        { id: 'ETA', key: 'eta', label: 'ETA' },
        { id: 'ATA', key: 'ata', label: 'ATA' },
        { id: 'CTOAvailability', key: 'ctoAvailability', label: 'CTO Availability' },
        { id: 'RequiredDelivery', key: 'requiredDelivery', label: 'Req. Delivery' },
        { id: 'ActualDelivery', key: 'actualDelivery', label: 'Act. Delivery' },
        { id: 'DelayDeliveryDay', key: 'delayDeliveryDay', label: 'Delay Delivery (Day)' },
      ];

      const csvData = data.map((elt) => {
        const requiredExWorks = moment(elt.requiredExWorks);
        const estimatedExFactory = moment(elt.estimatedExFactory);
        const actualExFactory = moment(elt.actualExFactory);
        const etd = moment(elt.etd);
        const atd = moment(elt.atd);
        const eta = moment(elt.eta);
        const ata = moment(elt.ata);
        const ctoAvailability = moment(elt.ctoAvailability);
        const requiredDelivery = moment(elt.requiredDelivery);
        const actualDelivery = moment(elt.actualDelivery);

        return {
          shipmentNumber: elt.shipmentNumber,
          orderNumber: elt.orderNumber,
          supplier: elt.supplier,
          loadAt: elt.loadAt,
          dischargeAt: elt.dischargeAt,
          vessel: elt.vessel,
          voyage: elt.voyage,
          containers: elt.containers,
          requiredExWorks: requiredExWorks.isValid() ? requiredExWorks.format('L') : '',
          estimatedExFactory: estimatedExFactory.isValid() ? estimatedExFactory.format('L') : '',
          actualExFactory: actualExFactory.isValid() ? actualExFactory.format('L') : '',
          etd: etd.isValid() ? etd.format('L') : '',
          atd: atd.isValid() ? atd.format('L') : '',
          eta: eta.isValid() ? eta.format('L') : '',
          ata: ata.isValid() ? ata.format('L') : '',
          ctoAvailability: ctoAvailability.isValid() ? ctoAvailability.format('L') : '',
          requiredDelivery: requiredDelivery.isValid() ? requiredDelivery.format('L') : '',
          actualDelivery: actualDelivery.isValid() ? actualDelivery.format('L') : '',
          delayDeliveryDay: elt.delayDeliveryDay,
        };
      });

      let filteredTable = tableService.filterHiddenTableColumns(headers, null, hiddenColumnNames, 0);

      let content = {
        headers: filteredTable.headCells,
        data: csvData,
        filename: this.getFileName('Exceptions', filterDescription),
      };

      return content;
    }
  }
}
export default new csvTableService();
