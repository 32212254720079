import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

import Card from '../../../components//Card/Card.js';
import CardFooter from '../../../components//Card/CardFooter.js';
import CardHeader from '../../../components//Card/CardHeader.js';
import CardIcon from '../../../components//Card/CardIcon.js';
import apiService from '../../../services/api.service';
import helperService from '../../../services/help.service';
import TitleFont from '../../Typography/TitleFont.js';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
  },
  cardCategory: {
    color: 'rgba(0, 0, 0, 0.87)',
    '& a': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));

function TotalDisbursementChargesChart(props) {
  const classes = useStyles();
  const { FilterComponentData, baseQueryString } = props;
  const history = useHistory();
  const [totalSpent, setTotalSpent] = useState();

  function getDetailsPageUrl() {
    return '/commercialInvoiceValuePerSupplier?' + baseQueryString;
  }

  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      apiService
        .getTotalDisbursementChargesReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            setTotalSpent(result.data.cost);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (!isCancelled) {
            setTotalSpent(null);
          }
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else {
      setTotalSpent(null);
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  return (
    <Card>
      <CardHeader color="info" stats icon>
        <CardIcon color="danger">
          <AttachMoneyOutlinedIcon style={{ color: '#ffffff' }} />
        </CardIcon>
        <TitleFont>
          <p className={classes.cardCategory}>
            <Link to={getDetailsPageUrl()}>Total Disbursement</Link>
          </p>
        </TitleFont>
        <h3 className={classes.cardTitle}>$ {totalSpent ? helperService.thousands_separators(totalSpent) : '-'}</h3>
      </CardHeader>
      <CardFooter stats>
        <div style={{ height: '15px' }} className={classes.stats} />
      </CardFooter>
    </Card>
  );
}

export default TotalDisbursementChargesChart;
