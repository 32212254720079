import React, { useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridItem from '../../Grid/GridItem';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import CardBody from '../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import { format } from 'react-string-format';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Link } from 'react-router-dom';
import ChartTooltip from '../../parts/ChartTooltip';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  card: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: 'none',
    position: 'relative',
  },
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  chartProperties: {
    backgroundColor: theme.paletteChart.bg.backgroundColor,
    color: theme.paletteChart.bg.color,
  },
  textProperties: {
    color: theme.paletteChart.text.color,
  },
  chartTitlePanel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    '& h4': {
      marginRight: theme.spacing(1),
    },
    '& span': {
      marginRight: theme.spacing(1),
    },
  },
  gainText: {
    color: 'green',
  },
  loseText: {
    color: 'red',
  },
}));
const AllSuppliersName = 'All Suppliers';
const AllSelectedSuppliersName = 'All Selected Suppliers';

function ComparePeriodLineChart(props) {
  const classes = useStyles();
  const [noChartData, setNoChartData] = useState(false);
  const [noChartDataText, setNoChartDataText] = useState('No Supplier Specific Data for This Chart');

  const [subTitle, setSubTitle] = useState({
    label: '',
    valueText: '',
    valueChange: '',
    valueChangePercentage: '',
    valueChangeStatus: '',
  });

  const {
    Title,
    ChartId,
    TrendsOverTimeReportDatas,
    GroupBy,
    TooltipValueFormat,
    NoDataText,
    IsLoading = true,
    TitleLinkUrl,
    ValuePrefix = '',
    ValueSuffix = '',
    SubTitle,
    InverseGainAndLoseStatus = false,
    ForceShowNoData = false,
  } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(ChartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chart.maskBullets = false;

    let supplierDatas = PrepareSupplierDatas(TrendsOverTimeReportDatas);
    let chartDatas = PrepareChartData(TrendsOverTimeReportDatas, supplierDatas);

    const createDateAxis = () => {
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;

      dateAxis.baseInterval = {
        timeUnit: 'month',
        count: 1,
      };
      if (GroupBy && GroupBy.toString().toLowerCase() === 'year') {
        dateAxis.baseInterval = {
          timeUnit: 'year',
          count: 1,
        };
      }

      return dateAxis;
    };

    const createLineSeries = (supplierData, hexColor, tooltipHTML, style) => {
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = supplierData.valuePropertyName;
      series.dataFields.dateX = 'date';
      series.strokeWidth = style.strokeWidth;
      series.minBulletDistance = 10;
      series.tooltipHTML = tooltipHTML;
      series.tooltip.getFillFromObject = false;
      series.stroke = am4core.color(hexColor);
      series.fill = am4core.color(hexColor);
      series.strokeDasharray = style.strokeDasharray;

      let valuePropertyName = format(ValuePrefix + '{{0}}' + ValueSuffix, supplierData.valuePropertyName);
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = valuePropertyName;
      labelBullet.label.dy = -10;
      labelBullet.label.fontSize = '0.75rem';
      labelBullet.hidden = true;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color('#fff');
      bullet.circle.strokeWidth = 2;
      bullet.circle.radius = style.bulletRadius;
      bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

      bullet.events.on('hit', function (ev) {
        if (labelBullet.isHidden) {
          labelBullet.show();
        } else {
          labelBullet.hide();
        }
      });

      series.events.on('appeared', function (ev) {
        labelBullet.hide();
      });

      return series;
    };

    setSubTitleText(TrendsOverTimeReportDatas, supplierDatas);
    if (supplierDatas && chartDatas && supplierDatas.main && chartDatas.length > 0) {
      setNoChartData(false);

      chart.data = chartDatas;

      //Axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.numberFormatter.numberFormat = '#,###.##';
      if (TooltipValueFormat) {
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
          return format(TooltipValueFormat, text);
        });
      }

      var dateAxis1 = createDateAxis();

      //Series
      var dateFormatTooltip = 'yyyy-MMM';
      if (GroupBy && GroupBy.toString().toLowerCase() === 'year') {
        dateFormatTooltip = 'yyyy';
      }
      let mainValuePropertyName = `${ValuePrefix}{${supplierDatas.main.valuePropertyName}}${ValueSuffix}`;
      let tooltipHTML;
      if (supplierDatas.compare) {
        var compareSeries = createLineSeries(supplierDatas.compare, lightTheme.chart.allSuppliersLineColor, '', {
          strokeWidth: 1,
          strokeDasharray: '8,4',
          bulletRadius: 4,
        });

        let compareValuePropertyName = `${ValuePrefix}{${supplierDatas.compare.valuePropertyName}}${ValueSuffix}`;
        tooltipHTML = `
        <table class="compareChartTooltip">
        <tr>
          <th align="left">{date.formatDate("${dateFormatTooltip}")}:</th>
          <td><span> ${mainValuePropertyName} </span> <span class="{valueChangeStatus}"> {valueChange} ({valueChangePercentage}%)
        </span></td>
        </tr>
        <tr class="compareRow">
          <th align="left">{compareDate.formatDate("${dateFormatTooltip}")}:</th>
          <td><span> ${compareValuePropertyName} </span></td>
        </tr>
        </table>`;
        var mainSeries = createLineSeries(supplierDatas.main, lightTheme.chart.colors[0], tooltipHTML, {
          strokeWidth: 2,
          strokeDasharray: '',
          bulletRadius: 5,
        });
      } else {
        tooltipHTML = `
        <table class="compareChartTooltip">
        <tr>
          <th align="left">{date.formatDate("${dateFormatTooltip}")}:</th>
          <td><span> ${mainValuePropertyName} </span> <span class="{valueChangeStatus}"> {valueChange} ({valueChangePercentage}%)
        </span></td>
        </tr>
        </table>`;
        var mainSeries = createLineSeries(supplierDatas.main, lightTheme.chart.colors[0], tooltipHTML, {
          strokeWidth: 2,
          strokeDasharray: '',
          bulletRadius: 5,
        });
      }

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis1;
    } else {
      setNoChartData(true);
      if (NoDataText) {
        setNoChartDataText(NoDataText);
      }
    }

    return () => {
      chart.dispose();
    };
  }, [TrendsOverTimeReportDatas]);

  const PrepareSupplierDatas = (trendsOverTimeReportDatas) => {
    const removeAllSuppliersData = (trendsOverTimeReportData) => {
      if (trendsOverTimeReportData && trendsOverTimeReportData.supplierDatas && trendsOverTimeReportData.supplierDatas.length > 0) {
        let supplierDatas = trendsOverTimeReportData.supplierDatas;
        if (supplierDatas.length > 1) {
          supplierDatas = supplierDatas.filter((supplier) => supplier.name !== AllSuppliersName);
        }
        return supplierDatas;
      } else {
        return null;
      }
    };

    let supplierDatas = { main: undefined, compare: undefined };
    if (trendsOverTimeReportDatas) {
      let mainSupplierData = removeAllSuppliersData(trendsOverTimeReportDatas.main);
      let compareSupplierData = removeAllSuppliersData(trendsOverTimeReportDatas.compare);
      if (mainSupplierData) {
        supplierDatas.main = mainSupplierData[0];
        supplierDatas.compare = compareSupplierData && compareSupplierData[0];
      }
    }
    return supplierDatas;
  };

  const PrepareChartData = (trendsOverTimeReportDatas, supplierDatas) => {
    let chartDatas = [];
    if (trendsOverTimeReportDatas && supplierDatas.main) {
      let mainChartDatas = trendsOverTimeReportDatas.main.trendDatas;
      let compareChartDatas = trendsOverTimeReportDatas.compare.trendDatas;
      if (mainChartDatas && compareChartDatas) {
        let totalData = mainChartDatas.length;
        if (totalData < compareChartDatas.length) {
          totalData = compareChartDatas.length;
        }

        let latestDate;
        for (let i = 0; i < totalData; i++) {
          let mainChartData = mainChartDatas[i];
          let compareChartData = compareChartDatas[i];
          let mainChartRest = {};
          if (mainChartData) {
            let { date, ...rest } = mainChartData;
            latestDate = new Date(date);
            mainChartRest = rest;
          } else {
            if (GroupBy && GroupBy.toString().toLowerCase() === 'year') {
              latestDate = new Date(latestDate.getFullYear() + 1, latestDate.getMonth(), 1);
            } else {
              latestDate = new Date(latestDate.getFullYear(), latestDate.getMonth() + 1, 1);
            }
          }

          if (compareChartData) {
            let { date, ...rest } = compareChartData;
            let compareChartValue, valueChange, valueChangePercentage, valueChangeStatus;

            let mainChartValue = mainChartRest[supplierDatas.main.valuePropertyName];
            if (supplierDatas.compare) compareChartValue = rest[supplierDatas.compare.valuePropertyName];

            if (mainChartValue && compareChartValue) {
              valueChange = mainChartValue - compareChartValue;
              valueChangePercentage = (valueChange / compareChartValue) * 100.0;
              valueChangeStatus = 'normal';

              if (valueChange < 0) {
                valueChangeStatus = InverseGainAndLoseStatus ? 'gain' : 'lose';
                valueChange = `${valueChange.toLocaleString()}`;
                valueChangePercentage = valueChangePercentage.toLocaleString();
              } else if (valueChange > 0) {
                valueChangeStatus = InverseGainAndLoseStatus ? 'lose' : 'gain';
                valueChange = `+${valueChange.toLocaleString()}`;
                valueChangePercentage = `+${valueChangePercentage.toLocaleString()}`;
              }
            }

            chartDatas.push({
              date: latestDate,
              ...mainChartRest,
              compareDate: date,
              ...rest,
              valueChange: valueChange,
              valueChangePercentage: valueChangePercentage,
              valueChangeStatus: valueChangeStatus,
            });
          } else {
            chartDatas.push({ date: latestDate, ...mainChartRest });
          }
        }
      }
    }
    return chartDatas;
  };

  const setSubTitleText = (trendsOverTimeReportDatas, supplierDatas) => {
    if (SubTitle) {
      setSubTitle(SubTitle);
    } else {
      if (trendsOverTimeReportDatas && supplierDatas && supplierDatas.main) {
        let mainTotalAmountPerSupplier = trendsOverTimeReportDatas.main.totalAmountPerSupplier;
        let compareTotalAmountPerSupplier = trendsOverTimeReportDatas.compare.totalAmountPerSupplier;
        if (mainTotalAmountPerSupplier && compareTotalAmountPerSupplier) {
          let mainTotalAmount = mainTotalAmountPerSupplier[supplierDatas.main.valuePropertyName];
          let compareTotalAmount = 0,
            valueChangePercentage = 0;
          if (supplierDatas.compare) compareTotalAmount = compareTotalAmountPerSupplier[supplierDatas.compare.valuePropertyName];

          let valueChange = mainTotalAmount - compareTotalAmount;
          if (compareTotalAmount > 0) valueChangePercentage = (valueChange / compareTotalAmount) * 100.0;
          let valueChangeStatus = 'normal';

          if (valueChange < 0) {
            valueChangeStatus = 'lose';
            valueChange = valueChange.toLocaleString();
            valueChangePercentage = valueChangePercentage.toLocaleString();
          } else if (valueChange > 0) {
            valueChangeStatus = 'gain';
            valueChange = `+${valueChange.toLocaleString()}`;
            valueChangePercentage = `+${valueChangePercentage.toLocaleString()}`;
          }

          setSubTitle({
            label: 'Total',
            valueText: mainTotalAmount,
            valueChange: valueChange,
            valueChangePercentage: valueChangePercentage,
            valueChangeStatus: valueChangeStatus,
          });
        }
      } else {
        setSubTitle({});
      }
    }
  };

  const getSubTitleClassName = () => {
    if (subTitle) {
      if (subTitle.valueChangeStatus === 'gain') {
        return classes.gainText;
      } else if (subTitle.valueChangeStatus === 'lose') {
        return classes.loseText;
      }
    } else {
      return '';
    }
  };

  return (
    <Card className={classes.chartProperties}>
      <LoadingOverlay IsLoading={IsLoading} />
      <ChartTooltip />
      <CardHeader>
        <ContentFont>
          {ForceShowNoData || (noChartData && !IsLoading) ? (
            <div style={{ position: 'absolute', marginBottom: '0px', fontSize: '13px', color: '#bbbbbb' }}>{noChartDataText}</div>
          ) : (
            ''
          )}
          <div id={ChartId} style={{ position: 'relative', height: '400px', marginTop: '20px', marginBottom: '0px', padding: '0 10px 0 0' }} />
        </ContentFont>
      </CardHeader>
      <CardBody>
        <div className={classes.chartTitlePanel + ' titleFont'}>
          {TitleLinkUrl ? (
            <Link to={TitleLinkUrl}>
              <h4 className={classes.textProperties}> {Title} </h4>
            </Link>
          ) : (
            <h4 className={classes.textProperties}> {Title} </h4>
          )}
          {subTitle && subTitle.label && (
            <>
              <span>
                {subTitle.label} {TooltipValueFormat ? format(TooltipValueFormat, subTitle.valueText) : subTitle.valueText}
              </span>
              <span className={getSubTitleClassName()}>
                {subTitle.valueChange} ({subTitle.valueChangePercentage}%)
              </span>
            </>
          )}
        </div>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

export default ComparePeriodLineChart;

ComparePeriodLineChart.propTypes = {
  Title: PropTypes.string,
  ChartId: PropTypes.string,
  TrendsOverTimeReportDatas: PropTypes.shape({
    main: PropTypes.shape({
      supplierDatas: PropTypes.array,
      trendDatas: PropTypes.array,
      totalAmount: PropTypes.number,
      totalAmountPerSupplier: PropTypes.any,
    }),
    compare: PropTypes.shape({
      supplierDatas: PropTypes.array,
      trendDatas: PropTypes.array,
      totalAmount: PropTypes.number,
      totalAmountPerSupplier: PropTypes.any,
    }),
  }),
  GroupBy: PropTypes.oneOf(['Month', 'Year']),
  TooltipValueFormat: PropTypes.string,
  NoDataText: PropTypes.string,
  IsLoading: PropTypes.bool,
  TitleLinkUrl: PropTypes.string,
  ValuePrefix: PropTypes.string,
  ValueSuffix: PropTypes.string,
  SubTitle: PropTypes.shape({
    label: PropTypes.string,
    valueText: PropTypes.string,
    valueChange: PropTypes.string,
    valueChangePercentage: PropTypes.string,
    valueChangeStatus: PropTypes.oneOf(['normal', 'lose', 'gain']),
  }),
  InverseGainAndLoseStatus: PropTypes.bool,
};
