import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { darkTheme } from 'src/themes/darkTheme';
import { lightThemeAdmin } from 'src/themes/lightTheme';

import ApplicationMenus from './ApplicationMenus';
import TopRedBar from './TopRedBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // maxWidth: '1600px',
  },
  paper: {
    width: '100%',
    padding: '50px',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  nonePaper: {
    padding: 0,
    boxShadow: 'none',
    background: 'none',
    transition: 'none',
  },
  childrenGridContainer: {
    position: 'relative',
  },
}));

function EntityListFrame(props) {
  const classes = useStyles();
  const { inPaper = true, showApplicationMenus = true } = props;
  return (
    <ThemeProvider theme={parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme}>
      <CssBaseline />
      <div className={classes.root}>
        {showApplicationMenus && <ApplicationMenus />}
        <main className={classes.content}>
          <TopRedBar />
          <Container maxWidth="xl" className={classes.container}>
            <Paper elevation={12} className={clsx(classes.paper, { [classes.nonePaper]: !inPaper })}>
              <Grid container spacing={3} className={classes.childrenGridContainer}>
                {props.children}
              </Grid>
            </Paper>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default EntityListFrame;
EntityListFrame.propTypes = {
  inPaper: PropTypes.bool,
};
