import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';

import { Grid, makeStyles } from '@material-ui/core';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import useAccounts from 'src/hooks/useAccounts';
import AccountType from 'src/models/AccountType';
import MyButton from 'src/wrappers/Button';

import CustomerProfileContainer from './CustomerProfile/CustomerProfileContainer';
import VendorProfileContainer from './VendorProfile/VendorProfileContainer';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
}));

export default function AccountProfileContainer(props) {
  const { accountType } = useAccounts();
  const classes = useStyles();
  const history = useHistory();
  return (
    <EntityListFrame inPaper={false}>
      {accountType == AccountType.Vendor && <VendorProfileContainer />}
      {accountType == AccountType.Customer && <CustomerProfileContainer />}
      <Grid item xs={12} className={classes.buttonsContainer}>
        <MyButton buttonType="cancel" className={clsx(classes.button, classes.buttonRight)} onClick={() => history.goBack()}>
          Back
        </MyButton>
      </Grid>
    </EntityListFrame>
  );
}
