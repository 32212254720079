import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { Checkbox, Tooltip, withStyles } from '@material-ui/core';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import apiService from 'src/services/api.service';

const ColorCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.focusShipmentCheckbox.unfocused.color,
    '&$checked': {
      color: theme.palette.focusShipmentCheckbox.focused.color,
    },
  },
  checked: {},
}))(forwardRef((props, ref) => <Checkbox color="default" {...props} ref={ref} />));

const FocusShipmentCheckbox = (props) => {
  const { shipmentNumber, className } = props;
  const [focus, setFocus] = useState(false);
  const ref = useRef();
  const { showSuccessSnackBar, showErrorSnackBar } = useGlobalSnackBar();

  const loadFocusedShipmentAsync = async (shipmentNumber) => {
    let result = await apiService.getFocusedShipmentState(shipmentNumber);
    if (result) {
      setFocus(result.data);
    }
  };

  useEffect(() => {
    if (shipmentNumber) {
      loadFocusedShipmentAsync(shipmentNumber);
    }
  }, [shipmentNumber]);

  const FocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.focusShipment(shipmentNumber);
    loadFocusedShipmentAsync(shipmentNumber);
    if (res && res.errors.length === 0) {
      showSuccessSnackBar(`Shipment No.${shipmentNumber} has been focus.`);
    } else {
      showErrorSnackBar(`Could not set focus for Shipment No.${shipmentNumber}`);
    }
  };

  const UnfocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.unfocusShipment(shipmentNumber);
    loadFocusedShipmentAsync(shipmentNumber);
    if (res && res.errors.length === 0) {
      showSuccessSnackBar(`Shipment No. ${shipmentNumber} has been unfocus.`);
    } else {
      showErrorSnackBar(`Could not set unfocus for Shipment No.${shipmentNumber}`);
    }
  };

  const handleClickFocusCheckbox = (event) => {
    if (shipmentNumber) {
      if (focus) {
        UnfocusShipmentAsync(shipmentNumber);
      } else {
        FocusShipmentAsync(shipmentNumber);
      }
    }
  };

  return (
    <div className={className}>
      <Tooltip title={focus ? 'Click to Unfocus' : 'Click to Focus'}>
        <ColorCheckbox ref={ref} icon={<StarOutlineRoundedIcon />} checkedIcon={<StarRoundedIcon />} checked={focus} onChange={handleClickFocusCheckbox} />
      </Tooltip>
    </div>
  );
};

export default FocusShipmentCheckbox;
