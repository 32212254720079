import Parser from 'html-react-parser';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';
import { format } from 'react-string-format';

import { Backdrop, CircularProgress } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import DataTableFilterNew from 'src/components/parts/DataTableFilter/DataTableFilter';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import helperService from 'src/services/help.service';
import pdfTableService from 'src/services/pdf.table.service';
import tableService from 'src/services/table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  arrivedOnTime: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.onTime,
    color: 'white',
  },
  arrivedLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.late,
    color: 'white',
  },
  arrivedVeryLate: {
    textAlign: 'center',
    backgroundColor: theme.table.shipments.arrivedOnTimeColors.veryLate,
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgb(0 0 0 / 25%)',
  },
}));

const defaultHeadCells = [
  {
    id: 'ShipmentNumber',
    numeric: false,
    disablePadding: true,
    label: 'Shipment No.',
  },
  // {
  //   id: 'Vessel',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Vessel',
  // },
  {
    id: 'DepartureVessel',
    numeric: false,
    disablePadding: false,
    label: 'Departure Vessel',
  },
  {
    id: 'ArrivalVessel',
    numeric: false,
    disablePadding: false,
    label: 'Arrival Vessel',
  },
  {
    id: 'Origin',
    numeric: false,
    disablePadding: false,
    label: 'Origin',
  },
  {
    id: 'Destination',
    numeric: false,
    disablePadding: false,
    label: 'Destination',
  },
  {
    id: 'PODName',
    numeric: false,
    disablePadding: false,
    label: 'POD',
    tooltip: 'Port of Destination',
  },
  {
    id: 'FDPName',
    numeric: false,
    disablePadding: false,
    label: 'FDP',
    tooltip: 'Final Delivery Point',
  },
  {
    id: 'Trans',
    numeric: false,
    disablePadding: false,
    label: 'Trans',
  },
  {
    id: 'Mode',
    numeric: false,
    disablePadding: false,
    label: 'Mode',
  },
  {
    id: 'Supplier',
    numeric: false,
    disablePadding: false,
    label: 'Supplier',
    minWidth: 200,
  },
  {
    id: 'OrderSellersText',
    numeric: false,
    disablePadding: false,
    label: 'Sellers',
    minWidth: 200,
  },
  {
    id: 'Importer',
    numeric: false,
    disablePadding: false,
    label: 'Importer',
  },
  {
    id: 'OriginETD',
    numeric: false,
    disablePadding: false,
    label: 'Origin ETD',
  },
  {
    id: 'OriginATD',
    numeric: false,
    disablePadding: false,
    label: 'Origin ATD',
  },
  {
    id: 'DestinationETA',
    numeric: false,
    disablePadding: false,
    label: 'Destination ETA',
  },
  {
    id: 'DestinationATA',
    numeric: false,
    disablePadding: false,
    label: 'Destination ATA',
  },
  {
    id: 'PODETA',
    numeric: false,
    disablePadding: false,
    label: 'POD ETA',
  },
  {
    id: 'PODATA',
    numeric: false,
    disablePadding: false,
    label: 'POD ATA',
  },
  {
    id: 'FDPETA',
    numeric: false,
    disablePadding: false,
    label: 'FDP ETA',
  },
  {
    id: 'FDPATA',
    numeric: false,
    disablePadding: false,
    label: 'FDP ATA',
  },
  {
    id: 'DelayInDays',
    numeric: true,
    disablePadding: false,
    label: 'Delay (Day)',
  },
  {
    id: 'Weight',
    numeric: true,
    disablePadding: false,
    label: 'Weight (Kg)',
  },
  {
    id: 'Volume',
    numeric: true,
    disablePadding: false,
    label: Parser('<span>Volume (m<sup>3</sup>)</span>'),
  },
  {
    id: 'TEU',
    numeric: true,
    disablePadding: false,
    label: 'TEU',
  },
  {
    id: 'CarrierCode',
    numeric: false,
    disablePadding: false,
    label: 'Carrier Code',
  },
  {
    id: 'CarrierContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Carrier Contract No.',
  },
  {
    id: 'ContainerCount',
    numeric: true,
    disablePadding: false,
    label: 'Container Count',
  },
  {
    id: 'Incoterm',
    numeric: true,
    disablePadding: false,
    label: 'Incoterm',
  },
  {
    id: 'SupplierBookingDate',
    numeric: false,
    disablePadding: false,
    label: 'Supplier Booking Date',
  },
  {
    id: 'CargoReadyDate',
    numeric: false,
    disablePadding: false,
    label: 'Cargo Ready Date',
  },
  {
    id: 'CustomsClearedDate',
    numeric: false,
    disablePadding: false,
    label: 'Customs Cleared Date',
  },
  {
    id: 'RequiredDeliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Required Delivery Date',
  },
  {
    id: 'ActualDeliveryDate',
    numeric: false,
    disablePadding: false,
    label: 'Actual Delivery Date',
  },
  {
    id: 'FreeTimeDays',
    numeric: true,
    disablePadding: false,
    label: 'Free Time (Day)',
  },
  {
    id: 'ContainerNumbersText',
    numeric: false,
    disablePadding: false,
    label: 'Container Numbers',
  },
  {
    id: 'OrderNumbersText',
    numeric: false,
    disablePadding: false,
    label: 'Order Numbers',
  },
  {
    id: 'FocusedShipments',
    numeric: false,
    disablePadding: false,
    label: 'Focused',
  },
];

function ShipmentsTable(props) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('ShipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [baseQueryString, setBaseQueryString] = useState();
  const classes = useStyles();
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const { enabledShipmentsFeature, enabledTrackAndTrace } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar, showErrorSnackBar } = useGlobalSnackBar();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    if (filter) {
      startProgress();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    var portOrigin = (data && data.specialSearch && data.specialSearch.portOriginCode) || '';
    var portDestination = (data && data.specialSearch && data.specialSearch.portDestinationCode) || '';
    var trans = (data && data.specialSearch && data.specialSearch.trans) || '';
    var mode = (data && data.specialSearch && data.specialSearch.mode) || '';

    apiService
      .getShipments(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers,
        portOrigin,
        portDestination,
        trans,
        mode,
        data.showFocusedShipment
      )
      .then((result) => {
        let contentRows = createTableListData(result.data.shipmentsReportItems);
        let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
        setHeadCells(filteredTable.headCells);
        setRows(filteredTable.contentRows);
        setTotalNumberOfRows(result.data.count);
        setHiddenColumnNames(result.data.hiddenColumnNames);
        stopProgress();
        handleBackdropClose();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      let etd = moment(item.originETD);
      let atd = moment(item.originATD);
      let eta = moment(item.destinationETA);
      let ata = moment(item.destinationATA);
      let podeta = moment(item.podeta);
      let podata = moment(item.podata);
      let fdpeta = moment(item.fdpeta);
      let fdpata = moment(item.fdpata);

      let supplierBookingDate = item.supplierBookingDate ? moment(item.supplierBookingDate) : null;
      let cargoReadyDate = item.cargoReadyDate ? moment(item.cargoReadyDate) : null;
      let customsClearedDate = item.customsClearedDate ? moment(item.customsClearedDate) : null;
      let requiredDeliveryDate = item.requiredDeliveryDate ? moment(item.requiredDeliveryDate) : null;
      let actualDeliveryDate = item.actualDeliveryDate ? moment(item.actualDeliveryDate) : null;

      var shipmentNumberLinks = item.shipmentNumber;

      if (enabledTrackAndTrace && item.hasShipmentTracking) {
        shipmentNumberLinks = { component: 'linkList', links: [] };
        shipmentNumberLinks.links.push({
          text: item.shipmentNumber,
          path: '/trackandtrace?s=' + item.shipmentNumber,
        });
      } else if (item.hasDetails == true) {
        shipmentNumberLinks = { component: 'linkList', links: [] };
        shipmentNumberLinks.links.push({
          text: item.shipmentNumber,
          path: '/shipmentDetails?s=' + item.shipmentNumber + otherQueryString,
        });
      }

      var delayInDays = { text: item.delayInDays, className: '' };
      if (item.delayInDays !== null) {
        if (item.delayInDays > 0) {
          delayInDays.text = format('+{0}', item.delayInDays);
          if (item.delayInDays <= 3) {
            delayInDays.className = classes.arrivedLate;
          } else {
            delayInDays.className = classes.arrivedVeryLate;
          }
        } else {
          delayInDays.className = classes.arrivedOnTime;
        }
      }

      var orderNumbers = { component: 'tags', texts: [] };
      if (item.orderNumbers) {
        item.orderNumbers.map((orderNumber) => {
          orderNumbers.texts.push({ text: orderNumber });
        });
      }

      var containerNumbers = { component: 'tags', texts: [] };
      if (item.containerNumbers) {
        item.containerNumbers.map((number) => {
          containerNumbers.texts.push({ text: number });
        });
      }

      var sellerNames = { component: 'textList', texts: [] };
      if (item.orderSellers) {
        item.orderSellers.map((sellerName) => {
          sellerNames.texts.push({ text: sellerName });
        });
      }

      var focusedShipments = {
        component: 'checkbox',
        checked: item.hasFocusedShipment,
        action: () => handleCheckboxChange(item.shipmentNumber, item.hasFocusedShipment),
      };

      let rowData = {
        id: item.shipmentNumber,
        ShipmentNumber: shipmentNumberLinks,
        // Vessel: item.vessel,
        DepartureVessel: item.departureVessel,
        ArrivalVessel: item.arrivalVessel,
        Origin: item.origin,
        Destination: item.destination,
        PODName: item.podName,
        FDPName: item.fdpName,
        Trans: item.trans,
        Mode: item.mode,
        Supplier: item.supplier,
        OrderSellersText: sellerNames,
        Importer: item.importer,
        OriginETD: etd.isValid() ? etd.format('L') : '',
        OriginATD: atd.isValid() ? atd.format('L') : '',
        DestinationETA: eta.isValid() ? eta.format('L') : '',
        DestinationATA: ata.isValid() ? ata.format('L') : '',
        PODETA: podeta.isValid() ? podeta.format('L') : '',
        PODATA: podata.isValid() ? podata.format('L') : '',
        FDPETA: fdpeta.isValid() ? fdpeta.format('L') : '',
        FDPATA: fdpata.isValid() ? fdpata.format('L') : '',
        DelayInDays: delayInDays,
        Weight: helperService.thousands_separators(item.weight),
        Volume: helperService.thousands_separators(item.volume),
        TEU: helperService.thousands_separators(item.teu),
        CarrierCode: item.carrierCode,
        CarrierContractNumber: item.carrierContractNumber,
        ContainerCount: item.containerCount,
        Incoterm: item.incoterm,
        SupplierBookingDate: supplierBookingDate ? supplierBookingDate.format('L') : '',
        CargoReadyDate: cargoReadyDate ? cargoReadyDate.format('L') : '',
        CustomsClearedDate: customsClearedDate ? customsClearedDate.format('L') : '',
        RequiredDeliveryDate: requiredDeliveryDate ? requiredDeliveryDate.format('L') : '',
        ActualDeliveryDate: actualDeliveryDate ? actualDeliveryDate.format('L') : '',
        FreeTimeDays: item.freeTimeDays,
        ContainerNumbersText: containerNumbers,
        OrderNumbersText: orderNumbers,
        FocusedShipments: focusedShipments,
      };

      newArray.push(rowData);
    });

    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      var trans = (filter && filter.specialSearch && filter.specialSearch.trans) || '';
      var mode = (filter && filter.specialSearch && filter.specialSearch.mode) || '';
      apiService
        .getShipments(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          portOrigin,
          portDestination,
          trans,
          mode
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generateShipmentPDF(
            result.data.shipmentsReportItems,
            'Shipments     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Shipment Report',
            result.data.hiddenColumnNames
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      var portOrigin = (filter && filter.specialSearch && filter.specialSearch.portOriginCode) || '';
      var portDestination = (filter && filter.specialSearch && filter.specialSearch.portDestinationCode) || '';
      var trans = (filter && filter.specialSearch && filter.specialSearch.trans) || '';
      var mode = (filter && filter.specialSearch && filter.specialSearch.mode) || '';
      apiService
        .getShipments(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers,
          portOrigin,
          portDestination,
          trans,
          mode
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateShipmentsCsvData(
            result.data.shipmentsReportItems,
            filter.accountName + '_' + filter.periodName,
            result.data.hiddenColumnNames
          );
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleColumnSelected = (headCells) => {
    setNewHeadCells(headCells);
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  const FocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.focusShipment(shipmentNumber);
    if (res && res.errors.length === 0) {
      showSuccessSnackBar(`Shipment No.${shipmentNumber} has been focus.`);
      startProgress();
      getData();
    } else {
      showErrorSnackBar(`Failed to set focus on Shipment No.${shipmentNumber}.`);
    }
  };

  const UnfocusShipmentAsync = async (shipmentNumber) => {
    let res = await apiService.unfocusShipment(shipmentNumber);
    if (res && res.errors.length === 0) {
      showSuccessSnackBar(`Shipment No.${shipmentNumber} has been unfocus.`);
      startProgress();
      getData();
    } else {
      showErrorSnackBar(`Failed to set unfocus on Shipment No.${shipmentNumber}.`);
    }
  };

  const handleCheckboxChange = (shipmentNumber, focused) => {
    if (shipmentNumber) {
      setOpenBackdrop(true);
      if (focused) {
        UnfocusShipmentAsync(shipmentNumber);
      } else {
        FocusShipmentAsync(shipmentNumber);
      }
    }
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        {enabledShipmentsFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Shipments</h4>
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableFilterNew
                sendFilterComponentData={filterCallback}
                sendQueryStringsNoFreeTextSearch={setBaseQueryString}
                showSuppliers={true}
                showFocused={true}
              />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={newHeadCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(ShipmentsTable);
