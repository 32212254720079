import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  statusBox: {
    backgroundColor: '#0000001f',
    borderRadius: 5,
    padding: theme.spacing(1.5),
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;',
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: '0.75rem',
    textAlign: 'end',
    paddingTop: 5,
  },
}));

export const StatusBox = (props) => {
  const { title, caption } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.statusBox}>
        <span className={classes.title}>{title}</span>
      </Box>
      {caption && <span className={classes.caption}>{caption}</span>}
    </div>
  );
};
