import React from 'react';

import { TextField } from '@material-ui/core';

import TitleFont from 'src/components/Typography/TitleFont';
import useDelayChangedText from 'src/hooks/useDelayChangedText';

export default function DelayChangedTextField2(props) {
  const { onDelayValueChange, onValueChange, value, label, ...remains } = props;

  const { handleValueTextChange, handleOnKeyPress } = useDelayChangedText(onDelayValueChange, onValueChange);

  return <TextField {...remains} value={value} label={<TitleFont>{label}</TitleFont>} onChange={handleValueTextChange} onKeyPress={handleOnKeyPress} />;
}
