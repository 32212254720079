import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import useQuery from './useQuery';

const useFilterQuery = () => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const [queryString, setQueryString] = useState(location.search ? location.search.slice(1) : '');

  let supplierId = query.get('supId');
  let dateBasedOn = query.get('b');
  let startPeriod = query.get('from');
  let endPeriod = query.get('to');
  let freeText = query.get('s');
  let specialSearchTitle = query.get('sst');
  let portOriginCode = query.get('pOri');
  let portDestinationCode = query.get('pDest');
  let trans = query.get('trans');
  let mode = query.get('mode');
  let companyId = query.get('cId');

  let filterQuery = {
    supplierId: supplierId,
    dateBasedOn: dateBasedOn,
    startPeriod: startPeriod,
    endPeriod: endPeriod,
    freeText: freeText,
    specialSearchTitle: specialSearchTitle,
    portOriginCode: portOriginCode,
    portDestinationCode: portDestinationCode,
    trans: trans,
    mode: mode,
    companyId: companyId,
  };

  function updateAddressUrl(filter, includeFreeTextSearch = true) {
    let pathName = history.location.pathname;
    var queryString = getQueryStrings(filter, includeFreeTextSearch);
    setQueryString(queryString);
    if (queryString) {
      queryString = '?' + queryString;
    }
    window.history.replaceState(null, null, pathName + queryString);
  }

  function getQueryStrings(filter, includeFreeTextSearch = true) {
    var searches = [];
    if (includeFreeTextSearch && filter.freeText) {
      searches.push('s=' + filter.freeText);
    }
    if (filter.startPeriod) {
      searches.push('from=' + filter.startPeriod);
    }
    if (filter.endPeriod) {
      searches.push('to=' + filter.endPeriod);
    }
    if (filter.supplierId) {
      searches.push('supId=' + filter.supplierId);
    }
    if (filter.dateBasedOn) {
      searches.push('b=' + filter.dateBasedOn);
    }
    if (filter.companyId) {
      searches.push('cId=' + filter.companyId);
    }
    var queryString = searches.map((item) => item).join('&');

    return queryString;
  }

  return { filterQuery, queryString, updateAddressUrl };
};

export default useFilterQuery;
