import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import apiService from '../../../services/api.service';
import '../../../util/extensions';
import ComparePeriodLineChart from './ComparePeriodLineChart';
import XYChartTrends from './XYChartTrends';

// "[bold]{date.formatDate()}:[/] ${value1}"

function Spend(props) {
  const { FilterComponentData, baseQueryString, ShowTrendLine, ShowAvgLine } = props;
  const [trendsOverTimeReportData, setTrendsOverTimeReportData] = useState([]);
  const [mainAndCompareTrendData, setMainAndCompareTrendData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;
    const LoadTrendReport = async (filterData) => {
      try {
        let result = await apiService.getSpentTrendsReport(
          filterData.accountId,
          filterData.start,
          filterData.end,
          filterData.suppliers,
          filterData.groupBy,
          filterData.showSuppliersIndividually,
          filterData.originId,
          filterData.destinationId,
          filterData.carrierContractNumber
        );
        if (!isCancelled) {
          setTrendsOverTimeReportData(result.data);
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      }
    };

    const LoadCompareTrendReports = async (filterData) => {
      try {
        let mainReport = await apiService.getSpentTrendsReport(
          filterData.accountId,
          filterData.start,
          filterData.end,
          filterData.suppliers,
          filterData.groupBy,
          filterData.showSuppliersIndividually,
          filterData.originId,
          filterData.destinationId,
          filterData.carrierContractNumber
        );

        let compareReport = await apiService.getSpentTrendsReport(
          filterData.accountId,
          filterData.comparePeriodFilter.start,
          filterData.comparePeriodFilter.end,
          filterData.suppliers,
          filterData.groupBy,
          filterData.showSuppliersIndividually,
          filterData.originId,
          filterData.destinationId,
          filterData.carrierContractNumber
        );

        if (!isCancelled) {
          setIsLoading(false);
          setMainAndCompareTrendData({ main: mainReport.data, compare: compareReport.data });
        }
      } catch (err) {
        setIsLoading(false);
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      }
    };

    if (FilterComponentData.accountId && (FilterComponentData.showAllSuppliers === true || FilterComponentData.suppliers.length > 0)) {
      setIsLoading(true);
      if (FilterComponentData.comparePeriodFilter.enabledCompare) {
        LoadCompareTrendReports(FilterComponentData);
      } else {
        LoadTrendReport(FilterComponentData);
      }
    } else {
      setIsLoading(false);
      setTrendsOverTimeReportData([]);
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  function getDetailsPageUrl() {
    return '/spendpersupplier?' + baseQueryString;
  }

  return (
    <>
      {FilterComponentData && FilterComponentData.comparePeriodFilter && FilterComponentData.comparePeriodFilter.enabledCompare ? (
        <ComparePeriodLineChart
          Title="Spend"
          ChartId="CompareSpentChart"
          TrendsOverTimeReportDatas={mainAndCompareTrendData}
          GroupBy={FilterComponentData.groupBy}
          TooltipValueFormat="${0}"
          IsLoading={isLoading}
          HiddenAllSuppliersSeriesOnInit={FilterComponentData.showSuppliersIndividually}
          TitleLinkUrl={getDetailsPageUrl()}
          ValuePrefix="$"
        ></ComparePeriodLineChart>
      ) : (
        <XYChartTrends
          Title="Spend"
          ChartId="SpentChart"
          TrendsOverTimeReportData={trendsOverTimeReportData}
          GroupBy={FilterComponentData.groupBy}
          TooltipValueFormat="${0}"
          IsLoading={isLoading}
          HiddenAllSuppliersSeriesOnInit={FilterComponentData.showSuppliersIndividually}
          TitleLinkUrl={getDetailsPageUrl()}
          ValuePrefix="$"
          ShowTrendLine={ShowTrendLine}
          ShowAvgLine={ShowAvgLine}
        ></XYChartTrends>
      )}
    </>
  );
}

export default Spend;
