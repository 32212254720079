import clsx from 'clsx';
import React, { useContext, useEffect, useReducer } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router';

import { Card, CardActions, CardContent, Divider, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ReloadContext } from 'src/contexts/ReloadContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import customerAccountApiService from 'src/services/customerAccount.api.service';
import formErrorFactory from 'src/services/formError.factory';
import Button from 'src/wrappers/Button';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  newSection: {
    paddingTop: '40px !important',
    paddingBottom: '0px !important',
  },
}));

const customerAccountEntryReducer = (state, action) => {
  switch (action.type) {
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'loadExistingData': {
      return {
        ...state,
        ...action.payload,
        errors: [],
        isEdit: true,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'submit': {
      return {
        ...state,
        errors: [],
        isSubmitting: true,
      };
    }
    case 'success': {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case 'error': {
      return {
        ...state,
        errors: action.payload,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    default:
      return state;
  }
};

const customerEntryInitialState = {
  accountId: '',
  company: '',
  contactEmail: '',
  username: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  errors: [],
  isEdit: false,
  isSubmitting: false,
  isSubmitted: false,
};

const CustomerAccountEntry = ({ customerAccountId, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const [, setReloadContext] = useContext(ReloadContext);
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();

  const [state, dispatch] = useReducer(customerAccountEntryReducer, customerEntryInitialState);
  const { accountId, company, username, firstName, lastName, phoneNumber, contactEmail, errors, isEdit, isSubmitting } = state;

  useEffect(() => {
    const fetchData = async (accountId) => {
      try {
        startProgress();
        let accountResult = await customerAccountApiService.getCustomerAccount(accountId);
        dispatch({
          type: 'loadExistingData',
          payload: {
            accountId: accountId,
            company: accountResult.data.name,
            contactEmail: accountResult.data.contactEmail,
          },
        });
        stopProgress();
      } catch (err) {
        catchApiError(err);
      }
    };

    if (customerAccountId) {
      fetchData(customerAccountId);
    }
  }, [customerAccountId]);

  const handleSubmit = (event) => {
    //internal functions
    const handleSubmitResult = (apiResult, successMessage, onSuccess) => {
      stopProgress();
      if (apiResult.errors && apiResult.errors.length > 0) {
        let { errors } = formErrorFactory.createErrors(apiResult.errors);
        dispatch({
          type: 'error',
          payload: errors,
        });
      } else {
        dispatch({ type: 'success' });
        setReloadContext(new Date());
        showSuccessSnackBar(successMessage);
        if (onSuccess) {
          onSuccess();
        }
      }
    };

    //Submitting
    startProgress();
    event.preventDefault();
    dispatch({ type: 'submit' });
    const formData = new FormData();

    if (!isEdit) {
      customerAccountApiService
        .createCustomerAccount(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            name: company,
            contactEmail: contactEmail,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            username: username,
          },
        })
        .then((result) => {
          handleSubmitResult(result, `The Customer was created successfully`, () => {
            history.goBack();
          });
        })
        .catch((err) => {
          catchApiError(err, () => {
            dispatch({
              type: 'error',
              payload: [],
            });
          });
        });
    } else {
      customerAccountApiService
        .updateCustomerAccount(formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            id: accountId,
            name: company,
            contactEmail: contactEmail,
          },
        })
        .then((result) => {
          handleSubmitResult(result, `The Customer was updated successfully`);
        })
        .catch((err) => {
          catchApiError(err, () => {
            dispatch({
              type: 'error',
              payload: [],
            });
          });
        });
    }
  };

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {customerAccountId ? <h4>Edit Customer Account</h4> : <h4>Create New Customer Account</h4>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Customer's Name"
                  margin="dense"
                  name="company"
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'company',
                      payload: e.target.value,
                    })
                  }
                  required
                  error={errors['Name'] != null}
                  helperText={errors['Name']}
                  value={company || ''}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Contact Email"
                  margin="dense"
                  name="contactEmail"
                  type="email"
                  error={errors['ContactEmail'] != null}
                  helperText={errors['ContactEmail']}
                  onChange={(e) =>
                    dispatch({
                      type: 'field',
                      fieldName: 'contactEmail',
                      payload: e.target.value,
                    })
                  }
                  required
                  value={contactEmail || ''}
                  disabled={isSubmitting}
                />
              </Grid>

              {!isEdit && (
                <>
                  <Grid item xs={12} className={classes.newSection}>
                    <strong>User:</strong>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Username (Email)"
                      margin="dense"
                      name="username"
                      type="email"
                      error={errors['Username'] != null}
                      helperText={errors['Username']}
                      onChange={(e) =>
                        dispatch({
                          type: 'field',
                          fieldName: 'username',
                          payload: e.target.value,
                        })
                      }
                      required
                      value={username || ''}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="First Name"
                      margin="dense"
                      name="firstName"
                      error={errors['FirstName'] != null}
                      helperText={errors['FirstName']}
                      onChange={(e) =>
                        dispatch({
                          type: 'field',
                          fieldName: 'firstName',
                          payload: e.target.value,
                        })
                      }
                      value={firstName || ''}
                      required
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      margin="dense"
                      name="lastName"
                      error={errors['LastName'] != null}
                      helperText={errors['LastName']}
                      onChange={(e) =>
                        dispatch({
                          type: 'field',
                          fieldName: 'lastName',
                          payload: e.target.value,
                        })
                      }
                      value={lastName || ''}
                      required
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      type="tel"
                      name="phoneNumber"
                      error={errors['PhoneNumber'] != null}
                      helperText={errors['PhoneNumber']}
                      onChange={(e) =>
                        dispatch({
                          type: 'field',
                          fieldName: 'phoneNumber',
                          payload: e.target.value,
                        })
                      }
                      value={phoneNumber || ''}
                      required
                      disabled={isSubmitting}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button buttonType="primary" type="submit" disabled={isSubmitting} className={classes.buttons}>
              Save
            </Button>
            <Button buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} disabled={isSubmitting} onClick={() => props.history.goBack()}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
};

export default withRouter(CustomerAccountEntry);
