import React from 'react';

import Grid from '@material-ui/core/Grid';

import EntityListFrame from '../layouts/EntityListFrame';
import Articles from './Home/Articles';
import CurrencyConverter from './Home/CurrencyConverter';
import CurrentLocalTimes from './Home/CurrentLocalTimes';
import FBXIndexes from './Home/FBXIndexes';
import LiveExchangeRates from './Home/LiveExchangeRates';
import News from './Home/News';
import StockIndexes from './Home/StockIndexes';

function Home(props) {
  return (
    <EntityListFrame inPaper={false}>
      <Grid item xs={12}>
        <FBXIndexes></FBXIndexes>
      </Grid>
      <Grid item xs={12}>
        <StockIndexes></StockIndexes>
      </Grid>
      <Grid item xs={12} md={12} lg={5}>
        <News></News>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Articles></Articles>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <CurrentLocalTimes></CurrentLocalTimes>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CurrencyConverter></CurrencyConverter>
          </Grid>
          <Grid item xs={12}>
            <LiveExchangeRates></LiveExchangeRates>
          </Grid>
        </Grid>
      </Grid>
    </EntityListFrame>
  );
}

export default Home;
