import axios from 'axios';

import apiHelperService from './api.helper.service';
import helpService from './help.service';

class apiService {
  forgotPassword(email) {
    return axios
      .post(`/api/v1/user/command/forgot-password-restore`, { email: email })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(password, email, code) {
    return axios
      .post(`/api/v1/user/command/restore-password-by-code`, {
        code: code,
        email: email,
        newPassword: password,
      })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  confirmEmail(email, code) {
    return axios
      .patch(`/api/v1/user/command/confirm-email`, { Email: email, Code: code })
      .then((res) => {
        Promise.resolve(res);
      })
      .catch((error) => {
        throw error;
      });
  }

  changePassword = (oldPassword, password, confirmPassword) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/user/command/change-password`, {
      OldPassword: oldPassword,
      NewPassword: password,
      ConfirmPassword: confirmPassword,
    });
  };

  setUserPassword = (applicationUserId, password, confirmPassword) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/user/command/set-user-password`, {
      ApplicationUserId: applicationUserId,
      Password: password,
      ConfirmPassword: confirmPassword,
    });
  };

  getShipments = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    portOrigin,
    portDestination,
    trans,
    mode,
    showFocusedShipment
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-shipments-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        portOrigin: portOrigin,
        portDestination: portDestination,
        trans: trans,
        mode: mode,
        dateBasedOn: dateBasedOn,
        showFocusedShipment: showFocusedShipment,
      },
    });
  };

  getPortPairData = (accountId, dateBasedOn, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch, portOrigin, portDestination) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-portpair-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        portOrigin: portOrigin,
        portDestination: portDestination,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getCommercialInvoiceValuePerSupplierTable = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-commercial-invoice-value-per-supplier-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getSpendPerSupplierTable = (accountId, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-spend-per-supplier-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
      },
    });
  };

  getConsolidatedShipmentsSpendsTable = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    portOrigin,
    portDestination,
    trans,
    mode
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-consolidated-shipments-spends-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        portOrigin: portOrigin,
        portDestination: portDestination,
        trans: trans,
        mode: mode,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getShipmentSpendsTable = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    portOrigin,
    portDestination,
    trans,
    mode
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-shipment-spends-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        portOrigin: portOrigin,
        portDestination: portDestination,
        trans: trans,
        mode: mode,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getReportShipment = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-total-shipments-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getTotalTeuReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-total-teu-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getTotaSpent = (accountId, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/get-total-spent-report` +
        '?supplierIds=' +
        helpService.GuidToJoinedString(supplierIds) +
        `&accountId=` +
        accountId +
        `&startDate=` +
        startDate +
        `&endDate=` +
        endDate
    );
  };

  getTotalDisbursementChargesReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-total-disbursement-charges-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getIndividualChargedReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-individual-charges-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getCASAFreightSpendsReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-casa-freight-spends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getFreightSpendsReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-freight-spends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getTotalCbmReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-total-cbm-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getJobTypesReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-job-types-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getCustomsChargesReport = (accountId, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/get-customs-charges-report` +
        '?supplierIds=' +
        helpService.GuidToJoinedString(supplierIds) +
        `&accountId=` +
        accountId +
        `&startDate=` +
        startDate +
        `&endDate=` +
        endDate
    );
  };

  getCBMSupplierReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-cbm-supplier-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getVolumePerCountryReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-per-country-volume-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getWeightPerPortPairReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-weight-per-port-pair-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getLclVolumePerPortPairReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-lcl-volume-per-port-pair-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getTuePerPortPairReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-tue-per-port-pair-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getIncotermPerPortPairReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-incoterm-per-port-pair-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getJobTypePerSupplierReport = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-job-type-per-supplier-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getChargeCodeReport = (accountId, startDate, endDate) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/get-charge-code-report?accountId=` + accountId + `&startDate=` + startDate + `&endDate=` + endDate
    );
  };

  getJobIncotermPerCountry = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-job-incoterm-per-country`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };
  getAvgTransitPerPortPair = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-avg-transit-per-port-pair-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getCustomsAndDutyPerSupplier = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-customs-and-duty-per-supplier-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getAirFreightKgsPerSupplier = (accountId, dateBasedOn, startDate, endDate, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-air-freight-kgs-per-supplier-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
      },
    });
  };

  getUsers = (page, rowsPerPage, order, orderBy, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/users`, {
      params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage, Search: freeTextSearch },
    });
  };

  getUser = (id) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/user/${id}`);
  };

  getDashboard = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/dashboard/query/dashboard`);
  };

  getMe = () => {
    console.log('GetMe()');
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/me`);
  };

  getMyFeatures = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/my-features`);
  };

  getUserEntryAccountSelectItems = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/user-entry-account-select-items`);
  };

  getUserProfile = () => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/user/query/user-profile`);
  };

  postUserProfile = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/user/command/update-profile', formData, params);
  };

  createUser = (formData, params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/user/command/create', formData, params);
  };

  updateUser = (formData, params) => {
    return apiHelperService.putCallAPIWrapper('/api/v1/user/command/update', formData, params);
  };

  deleteUser = (id) => {
    return apiHelperService.deleteCallAPIWrapper('/api/v1/user/command/delete/' + id);
  };

  setMessageAsRead = (messages) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/message/command/set-as-read`, { messageIds: messages });
  };

  removeGoogle = (params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/authentication/remove-login-google', params);
  };

  addLoginGoogle = (params) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/authentication/add-login-google', params);
  };

  getDashboardFilters = (accountId, dateBasedOn) => {
    return apiHelperService.getCallAPIWrapper('/api/v1/report/query/get-report-dashboard-filter', {
      params: {
        accountId: accountId,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getShipmentsTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-shipments-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getFCLTEUTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-fclteu-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getVolumeTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-volume-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getSpentTrendsReport = (accountId, startDate, endDate, supplierIds, groupBy, showSuppliersIndividually, originId, destinationId, carrierContractNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-spent-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getFreightSpendTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-freight-spend-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getNumberOfPurchaseOrderTrendsReport = (accountId, startDate, endDate, supplierIds, groupBy, showSuppliersIndividually, carrierContractNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-number-of-purchase-order-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getPercentOfShipmentDelayTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-percent-of-shipment-delay-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getAvgPercentOfShipmentDelay = (accountId, dateBasedOn, startDate, endDate, supplierIds, originId, destinationId, carrierContractNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-avg-percent-of-shipment-delay`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getPercentOfOrderDelayTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-percent-of-order-delay-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getAvgPercentOfOrderDelay = (accountId, dateBasedOn, startDate, endDate, supplierIds, originId, destinationId, carrierContractNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-avg-percent-of-order-delay`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getSpecialSuppliersTrendFilters = () => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-special-suppliers-trend-filters`);
  };

  getSuppliersBySpecialSuppliersTrendFilterOption = (accountId, dateBasedOn, startDate, endDate, filterOption) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-suppliers-by-special-suppliers-trend-filter-option`, {
      params: {
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        filterOption: filterOption,
      },
    });
  };

  getTrendsPortPairFilterItems = (accountId, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/get-trends-port-pair-filter-items` + '?supplierIds=' + helpService.GuidToJoinedString(supplierIds) + `&accountId=` + accountId
    );
  };

  getAvgTransitTimeTrendsReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    supplierIds,
    groupBy,
    showSuppliersIndividually,
    originId,
    destinationId,
    carrierContractNumber
  ) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-avg-transit-time-trends-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        groupBy: groupBy,
        showSuppliersIndividually: showSuppliersIndividually,
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getTrendOverTimeSettings = (accountId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-trend-over-time-settings`, {
      params: {
        accountId: accountId,
      },
    });
  };

  getAvgTransitTime = (accountId, dateBasedOn, startDate, endDate, supplierIds, originId, destinationId, carrierContractNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-avg-transit-time`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        originId: originId,
        destinationId: destinationId,
        carrierContractNumber: carrierContractNumber,
      },
    });
  };

  getShipmentDetailTableReport = (accountId, dateBasedOn, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-shipment-detail-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getProductsTable = (accountId, dateBasedOn, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-products-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getWidgetNews = (total) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/news/query/widget-news?total=` + total);
  };

  getWidgetArticle = (total) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/article/query/widget-article?total=` + total);
  };

  getShipmentDelaysReport = (accountId, dateBasedOn, startDate, endDate, veryLateDays, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-shipment-delays-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        veryLateDays: veryLateDays,
      },
    });
  };

  getOrderDelaysReport = (accountId, dateBasedOn, startDate, endDate, veryLateDays, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/get-order-delays-report`, {
      params: {
        supplierIds: helpService.GuidToJoinedString(supplierIds),
        accountId: accountId,
        dateBasedOn: dateBasedOn,
        startDate: startDate ? startDate : '',
        endDate: endDate ? endDate : '',
        veryLateDays: veryLateDays,
      },
    });
  };

  searchShipmentTrackings = (accountId, searchText, skip, take) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-tracking/query/search`, {
      params: {
        accountId: accountId,
        searchText: searchText,
        skip: skip,
        take: take,
      },
    });
  };
  getShipmentTrackingById = (accountId, shipmentTrackingId) => {
    return apiHelperService.getCallAPIWrapper(
      `/api/v1/shipment-tracking/query/get-shipment-tracking-by-id?accountId=${accountId}&shipmentTrackingId=${shipmentTrackingId}`
    );
  };

  downloadShipmentTrackingDocumentById = (shipmentNumber, shipmentTrackingDocumentId) => {
    return apiHelperService.getCallAPIWrapper(
      `/api/v1/shipment-tracking/download-document?shipmentNumber=${shipmentNumber}&documentId=${shipmentTrackingDocumentId}`,
      {
        responseType: 'blob',
      }
    );
  };

  getVesselPositionByShipmentTrackingId = (shipmentTrackingId) => {
    return apiHelperService.getCallAPIWrapper(
      `/api/v1/shipment-tracking/query/get-vessel-position-by-shipment-tracking-id` + '?ShipmentTrackingId=' + shipmentTrackingId
    );
  };

  getEDIReportFilter = () => {
    return apiHelperService.getCallAPIWrapper(`api/v1/edi-report/query/get-report-filter`);
  };

  getEDIFilesReceivedChart = (companyAccountId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/edi-report/query/get-report-files-received-chart?AccountId=${companyAccountId}`);
  };

  getEDIFilesSentChart = (companyAccountId) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/edi-report/query/get-report-files-sent-chart?AccountId=${companyAccountId}`);
  };

  getEDIFilesReceivedTable = (accountId, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/edi-report/query/get-report-files-received-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
      },
    });
  };

  getEDIFilesSentTable = (accountId, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/edi-report/query/get-report-files-sent-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
      },
    });
  };

  downloadEDIFileById = (id) => {
    return apiHelperService.getCallAPIWrapper('/api/v1/edi-report/download-file/' + id, {
      responseType: 'blob',
    });
  };

  getAverageTransitTimePerSupplierProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-average-transit-time-per-supplier-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getAverageTransitTimeProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-average-transit-time-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getAverageUnitPriceProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-average-unit-price-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getShipmentDelaysProductOverviewReport = (accountId, productCode, veryLateDays) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-shipment-delays-report?accountId=${accountId}&productCode=${productCode}&veryLateDays=${veryLateDays}`
    );
  };

  getOrderDelaysProductOverviewReport = (accountId, productCode, veryLateDays) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-order-delays-report?accountId=${accountId}&productCode=${productCode}&veryLateDays=${veryLateDays}`
    );
  };

  getTotalAmountProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/product-overview/get-total-amount-report?accountId=${accountId}&productCode=${productCode}`);
  };

  getTotalQtyProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/product-overview/get-total-qty-report?accountId=${accountId}&productCode=${productCode}`);
  };

  getQtyPerShipmentProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-qty-per-shipment-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getUnitPricePerShipmentProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-unit-price-per-shipment-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getUnitFreightSpendsPerShipmentProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-unit-freight-spends-per-shipment-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getTotalSpendsPerShipmentProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-total-spends-per-shipment-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getTransitTimePerShipmentProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-transit-time-per-shipment-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getTransitTimePerOrderProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-transit-time-per-order-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getTotalFreightSpendProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-total-freight-spend-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getAverageUnitFreightSpendProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/report/query/product-overview/get-average-unit-freight-spend-report?accountId=${accountId}&productCode=${productCode}`
    );
  };

  getTotalSpendProductOverviewReport = (accountId, productCode) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/report/query/product-overview/get-total-spend-report?accountId=${accountId}&productCode=${productCode}`);
  };

  getProductOverviewSettings = (accountId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/product-overview/get-settings`, {
      params: {
        accountId: accountId,
      },
    });
  };

  getShipmentTrackingPurchaseOrderDetails = (orderNumber) => {
    return apiHelperService.getCallAPIWrapper(`api/v1/shipment-tracking/query/get-shipment-tracking-purchase-order-details?orderNumber=${orderNumber}`);
  };

  getNotEmptyPurchaseNumbersInShipmentTracking = (shipmentTrackingId) => {
    return apiHelperService.getCallAPIWrapper(
      `api/v1/shipment-tracking/query/get-not-empty-purchase-order-numbers-in-shipment-tracking?shipmentTrackingId=${shipmentTrackingId}`
    );
  };

  getExternalApplications = (page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/external-application/query/get`, {
      params: { Page: page, SortDirection: order, SortColumn: orderBy, PageSize: rowsPerPage },
    });
  };

  getExternalApplicationById = (externalApplicationId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/external-application/query/get/${externalApplicationId}`);
  };

  getExternalApplicationAssignableUserByAccountId = (accountId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/external-application/query/get/assignable-users-by-account-id?accountId=${accountId}`);
  };

  getExternalApplicationSupplierFilterItemsByAccountId = (accountId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/external-application/query/get/supplier-filter-items-by-account-id?accountId=${accountId}`);
  };

  createExternalApplication = (externalApplication) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/external-application/command/create', externalApplication);
  };

  updateExternalApplication = (externalApplication) => {
    return apiHelperService.putCallAPIWrapper('/api/v1/external-application/command/update', externalApplication);
  };

  deleteExternalApplication = (externalApplicationId) => {
    return apiHelperService.deleteCallAPIWrapper(`/api/v1/external-application/command/delete/${externalApplicationId}`);
  };

  generateExternalApplicationPrimaryKey = (externalApplicationId) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/external-application/command/regenerate-primary-key/${externalApplicationId}`);
  };

  generateExternalApplicationSecondaryKey = (externalApplicationId) => {
    return apiHelperService.patchCallAPIWrapper(`/api/v1/external-application/command/regenerate-secondary-key/${externalApplicationId}`);
  };

  getPurchaseOrderTableFilters = (accountId, dateBasedOn) => {
    console.log('getPurchaseOrderTableFilters', accountId, dateBasedOn);
    return apiHelperService.getCallAPIWrapper('/api/v1/purchase-order/query/get-purchase-order-table-filter', {
      params: {
        accountId: accountId,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  getPurchaseOrderTableReport = (accountId, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch, supplierIds, buyerId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/purchase-order/query/get-purchase-order-table`, {
      params: {
        AccountId: accountId,
        PeriodStart: startDate,
        PeriodEnd: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        SupplierName: supplierIds,
        BuyerId: buyerId,
      },
    });
  };

  getPurchaseOrderLinesTableReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    buyerId
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/purchase-order/query/get-purchase-order-lines-table`, {
      params: {
        AccountId: accountId,
        DateBasedOn: dateBasedOn,
        PeriodStart: startDate,
        PeriodEnd: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        SupplierName: supplierIds,
        BuyerId: buyerId,
      },
    });
  };

  getPurchaseOrderReport = (accountId, orderNumber) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/purchase-order/query/get-purchase-order`, {
      params: {
        AccountId: accountId,
        OrderNumber: orderNumber,
      },
    });
  };

  getAvailableCurrencies = () => {
    return apiHelperService.getCallAPIWrapper(`api/v1/finance/query/get-available-currencies`);
  };

  getConvertedCurrencyAmount = (fromCurrencyCode, toCurrencyCode, amount) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/finance/query/get-converted-currency-amount`, {
      params: {
        fromCurrencyCode: fromCurrencyCode,
        toCurrencyCode: toCurrencyCode,
        amount: amount,
      },
    });
  };

  getLiveCurrencyExchangeRates = (baseCurrencySymbol, currencySymbols) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/finance/query/get-live-currency-exchange-rates`, {
      params: {
        baseCurrencySymbol: baseCurrencySymbol,
        currencySymbols: currencySymbols,
      },
    });
  };

  getIndiceChartDatas = (symbols) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/finance/query/get-indice-chart-datas`, {
      params: {
        symbols: symbols,
      },
    });
  };

  getOverviewSettings = (accountId) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-overview-settings`, {
      params: {
        accountId: accountId,
      },
    });
  };

  subscribeShipmentState = (shipmentNumber) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/notification/command/subscribe-shipment-state', {
      shipmentNumber: shipmentNumber,
    });
  };

  unsubscribeShipmentState = (shipmentNumber) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/notification/command/unsubscribe-shipment-state', { shipmentNumber: shipmentNumber });
  };

  getShipmentStatteSubscribeStatus = (shipmentNumber) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/notification/query/shipment-state-subscribe-status`, {
      params: {
        shipmentNumber: shipmentNumber,
      },
    });
  };

  getCarrierContractNumberFilterItems = (accountId) => {
    return apiHelperService.getCallAPIWrapper('/api/v1/report/query/get-carrier-contact-number-filter-items?accountId=' + accountId);
  };

  getPurchaseOrderLineSpendsTableReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    buyerId
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/purchase-order/query/get-purchase-order-lines-spends-table`, {
      params: {
        AccountId: accountId,
        DateBasedOn: dateBasedOn,
        PeriodStart: startDate,
        PeriodEnd: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        SupplierName: supplierIds,
        BuyerId: buyerId,
      },
    });
  };

  getPurchaseOrderLineMilestonesTableReport = (
    accountId,
    dateBasedOn,
    startDate,
    endDate,
    sortDirection,
    sortColumn,
    skip,
    take,
    freeTextSearch,
    supplierIds,
    buyerId
  ) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/purchase-order/query/get-purchase-order-lines-milestones-table`, {
      params: {
        AccountId: accountId,
        DateBasedOn: dateBasedOn,
        PeriodStart: startDate,
        PeriodEnd: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        SupplierName: supplierIds,
        BuyerId: buyerId,
      },
    });
  };

  getReportFilesTable = (accountId, page, rowsPerPage, order, orderBy) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/file/query/report-files`, {
      params: {
        accountId: accountId,
        Page: page,
        SortDirection: order,
        SortColumn: orderBy,
        PageSize: rowsPerPage,
      },
    });
  };

  downloadReportFileById = (fileId) => {
    return apiHelperService.getCallAPIWrapper('/api/v1/file/download-report-file/' + fileId, {
      responseType: 'blob',
    });
  };

  focusShipment = (shipmentNumber) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/shipment/command/focus-shipment', {
      shipmentNumber: shipmentNumber,
    });
  };

  unfocusShipment = (shipmentNumber) => {
    return apiHelperService.postCallAPIWrapper('/api/v1/shipment/command/unfocus-shipment', {
      shipmentNumber: shipmentNumber,
    });
  };

  getFocusedShipmentState = (shipmentNumber) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/shipment/query/get-focused-shipment-state`, {
      params: {
        shipmentNumber: shipmentNumber,
      },
    });
  };

  getExceptionsTableReport = (accountId, dateBasedOn, startDate, endDate, sortDirection, sortColumn, skip, take, freeTextSearch, supplierIds) => {
    return apiHelperService.getCallAPIWrapper(`/api/v1/report/query/get-report-exception-table`, {
      params: {
        AccountId: accountId,
        StartDate: startDate,
        EndDate: endDate,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Skip: skip,
        Take: take,
        FreeTextSearch: freeTextSearch,
        supplierIds: supplierIds,
        dateBasedOn: dateBasedOn,
      },
    });
  };

  // string SortColumn
  // string SortDirection
  // int Skip
  // int Take
  // string ShipmentNumber
  // string OrderNumber
  // string ContainerNumber
  // string Destination
  // DateFilter Eta
  // DateFilter EstimatedDelivery
  // DateFilter ContainerDetentionStart
  // DateFilter EmptyReadyDate
  // DateFilter ContainerDehireDate
  getDeliveriesTableReport = (deliveryTableReportFilter) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/report/query/get-report-deliveries-table`, deliveryTableReportFilter);
  };

  // string SortColumn
  // string SortDirection
  // int Skip
  // int Take
  // string ShipmentNumber
  // string OrderNumber
  // string Supplier
  // string Destination
  // DateFilter Eta
  // DateFilter EstimatedExFactory
  // DateFilter BookingDate
  getOpenOrdersTableReport = (openOrdersTableReportFilter) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/report/query/get-report-open-orders-table`, openOrdersTableReportFilter);
  };

  // string SortColumn
  // string SortDirection
  // int Skip
  // int Take
  // string ShipmentNumber
  // string OrderNumber
  // string InvoiceNumber
  // string Destination
  // DateFilter InvoiceDate
  getBillingSummariesTableReport = (billingSummariesTableReportFilter) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/report/query/get-report-billing-summaries-table`, billingSummariesTableReportFilter);
  };

  // string SortColumn 
  // string SortDirection 
  // int Skip 
  // int Take 
  // string ShipmentNumber 
  // string OrderNumber 
  // string InvoiceNumber 
  // DateFilter InvoiceDate 
  // string ChargeCode 
  // string ChargeGroup 
  getBillingDetailedTableReport = (billingDetailedTableReportFilter) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/report/query/get-report-billing-detailed-table`, billingDetailedTableReportFilter);
  };

  // string SortColumn 
  // string SortDirection 
  // int Skip 
  // int Take 
  // string ShipmentNumber 
  // string OrderNumber 
  // string InvoiceNumber 
  // DateFilter InvoiceDate 
  // string ChargeCode 
  // string ChargeGroup 
  getPetbarnExceptionsTableReport = (petbarnExceptionsTableReportFilter) => {
    return apiHelperService.postCallAPIWrapper(`/api/v1/report/query/get-report-petbarn-exceptions-table`, petbarnExceptionsTableReportFilter);
  };
}
export default new apiService();
