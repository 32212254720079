import { InputBase, Select, withStyles } from '@material-ui/core';
import React from 'react';

const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.inputControl.backgroundColor,
    padding: theme.spacing(1),
    paddingRight: '26px',
    border: '1px solid ' + theme.palette.inputControl.borderColor,
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      backgroundColor: theme.palette.inputControl.backgroundColor,
    },
  },
}))(InputBase);

export default function SmallSelect(props) {
  const { children, ...remains } = props;

  return (
    <Select {...remains} input={<CustomInput />}>
      {children}
    </Select>
  );
}
