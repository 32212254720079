import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { Route } from 'react-router-dom';

import { UserContext } from './contexts/UserContext';
import authService from './services/auth.service';

const ProtectedRoute = ({ component: Component, allowAccountType, ...rest }) => {
  const history = useHistory();
  const [userContext] = useContext(UserContext);

  const isAuthorized = useCallback(() => {
    if (authService.getAuthenticationContext() && authService.getAuthenticationContext().user) {
      if (allowAccountType && userContext.accountTypes && userContext.accountTypes.length) {
        return userContext.accountTypes.some((x) => x == allowAccountType);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [userContext, allowAccountType]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthorized()) {
          return <Component {...props} />;
        } else {
          var pathname = rest?.location?.pathname;
          var search = rest?.location?.search;
          console.log(`redirect pathname: ${pathname}, search: ${search}`);
          const loginLocation = {
            pathname: '/login',
            state: { returnToLocation: rest?.location },
          };
          history.push(loginLocation);
        }
      }}
    />
  );
};

export { ProtectedRoute };
