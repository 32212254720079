import fileDownload from 'js-file-download';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import EntityTable from 'src/components/parts/EntityTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import { ReloadContext } from 'src/contexts/ReloadContext';
import useAccounts from 'src/hooks/useAccounts';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import apiService from 'src/services/api.service';
import DownloadButton from 'src/wrappers/DownloadButton';

const headCells = [
  {
    id: 'fileName',
    numeric: false,
    disablePadding: true,
    label: 'File Name',
  },
];

function ReportFiles(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [reloadContext] = useContext(ReloadContext);
  const history = useHistory();
  const [reportFiles, setReportFiles] = useState();

  const { accountId } = useAccounts();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar } = useGlobalSnackBar();
  const { catchApiError } = useCatchAPIError();
  const { enabledReportFilesFeature } = useCustomerAccountFeatures();

  useEffect(() => {
    let cancelled = false;
    if (accountId) {
      startProgress();
      apiService
        .getReportFilesTable(accountId, page + 1, rowsPerPage, order, orderBy)
        .then((result) => {
          if (!cancelled) {
            stopProgress();
            setReportFiles(result.data.list);
            setRows(createTableListData(result.data.list));
            setTotalNumberOfRows(result.data.totalItems);
          }
        })
        .catch(catchApiError);
    }

    return () => {
      cancelled = true;
    };
  }, [history, order, orderBy, rowsPerPage, page, reloadContext, accountId]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      newArray.push([item.id, item.fileName]);
    });
    return newArray;
  };
  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const downloadReportFileById = async (fileId) => {
    const setDownloadingStatus = (isDownloading) => {
      var tmpReportFiles = reportFiles.slice();
      let file = tmpReportFiles.find((x) => x.id === fileId);
      file.isDownloading = isDownloading;
      setReportFiles(tmpReportFiles);
    };

    let file = reportFiles.find((x) => x.id === fileId);
    let fileName = file.fileName;

    setDownloadingStatus(true);
    try {
      var result = await apiService.downloadReportFileById(fileId);
      fileDownload(result, fileName);
      setDownloadingStatus(false);
      showSuccessSnackBar('File was downloaded successfully');
    } catch (err) {
      catchApiError(err, () => {
        setDownloadingStatus(false);
      });
    }
  };

  const handleDownloadClick = (row, index) => {
    let fileId = row[0];
    downloadReportFileById(fileId);
  };

  return (
    <EntityListFrame>
      {enabledReportFilesFeature ? (
        <Grid item xs={12}>
          {rows && (
            <EntityTable
              title="Report Files"
              headCells={headCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              additionalRowAction={(row, index) => {
                return <DownloadButton onClick={() => handleDownloadClick(row, index)} isDownloading={reportFiles[index]?.isDownloading} />;
              }}
            />
          )}
        </Grid>
      ) : (
        <FeatureDisabledMessage />
      )}
    </EntityListFrame>
  );
}

export default ReportFiles;
