import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import { MyTab, MyTabs, TabPanel } from 'src/components/parts/MyTabs';
import { UserContext } from 'src/contexts/UserContext';
import { ProtectedRoute } from 'src/protected.route';

import CustomerAccountEntryContainer from './CustomerAccountEntryContainer';
import CustomerAccounts from './CustomerAccounts';
import FeaturesSettingEntry from './FeaturesSettingEntry';
import VendorAccountEntryContainer from './VendorAccountEntryContainer';
import VendorAccounts from './VendorAccounts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    maxWidth: '1600px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  featureDisabledMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function AccountsContainer(props) {
  let match = useRouteMatch();
  let location = useLocation();
  let classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showFrameAsPaper, setShowFrameAsPaper] = useState(false);
  const [userContext] = useContext(UserContext);

  const handleTabChange = useCallback((event, newValue) => {
    setCurrentTabIndex(newValue);
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf('features') > -1) {
      setShowFrameAsPaper(true);
    } else if ((location.pathname = '/accounts')) {
      if (userContext.permissions?.canReadCustomerAccounts && userContext.permissions?.canReadVendorAccounts) {
        setShowFrameAsPaper(false);
      } else {
        setShowFrameAsPaper(true);
      }
    } else {
      setShowFrameAsPaper(false);
    }
  }, [location, userContext]);

  const getAccountContainers = () => {
    if (userContext.permissions?.canReadCustomerAccounts && userContext.permissions?.canReadVendorAccounts) {
      return (
        <>
          <MyTabs value={currentTabIndex} indicatorColor="primary" textColor="primary" onChange={handleTabChange}>
            <MyTab label="Customers" />
            <MyTab label="Vendors" />
          </MyTabs>
          <TabPanel value={currentTabIndex} index={0} className={classes.tabPanel}>
            <CustomerAccounts />
          </TabPanel>
          <TabPanel value={currentTabIndex} index={1} className={classes.tabPanel}>
            <VendorAccounts />
          </TabPanel>
        </>
      );
    } else if (userContext.permissions?.canReadCustomerAccounts) {
      return <CustomerAccounts />;
    } else if (userContext.permissions?.canReadVendorAccounts) {
      return <VendorAccounts />;
    } else {
      return null;
    }
  };

  const accountContainer = useMemo(() => getAccountContainers(), [userContext, currentTabIndex]);

  return (
    <>
      <EntityListFrame inPaper={showFrameAsPaper}>
        <Grid item xs={12}>
          <Switch>
            {userContext.isAdmin && userContext.permissions.canManageCustomerAccounts && (
              <ProtectedRoute exact path={`${match.path}/customer/add`}>
                <CustomerAccountEntryContainer />
              </ProtectedRoute>
            )}
            {userContext.isAdmin && userContext.permissions.canManageCustomerAccounts && (
              <ProtectedRoute exact path={`${match.path}/vendor/add`}>
                <VendorAccountEntryContainer />
              </ProtectedRoute>
            )}
            <ProtectedRoute exact path={`${match.path}/customer/:id/edit`}>
              <CustomerAccountEntryContainer />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}/customer/:id/features`}>
              <FeaturesSettingEntry />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}/vendor/:id/edit`}>
              <VendorAccountEntryContainer />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}`}>
              {accountContainer}
            </ProtectedRoute>
          </Switch>
        </Grid>
      </EntityListFrame>
    </>
  );
}
