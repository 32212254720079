import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Card, CardContent, makeStyles } from '@material-ui/core';

import DeleteDialog from 'src/components/parts/DeleteDialog';
import EntityTable from 'src/components/parts/EntityTable';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import vendorAccountApiService from 'src/services/vendorAccount.api.service';

import SupplierEntry from './SupplierEntry';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
}));

const headCells = [
  {
    id: 'Name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },

  {
    id: 'CompanyName',
    numeric: false,
    disablePadding: false,
    label: 'Company Name',
  },

  {
    id: 'StreetAddress',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
];

function Suppliers({ vendorAccountId, ...props }) {
  const classes = useStyles();
  const [accountName, setAccountName] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [dummyCounter, setDummyCounter] = useState(0);
  const [userContext] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = useState({
    show: false,
    id: '0',
  });
  const [reloadContext] = useContext(ReloadContext);
  const [tableCaptionText, setTableCaptionText] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');
  const { catchApiError } = useCatchAPIError();
  const { showSuccessSnackBar } = useGlobalSnackBar();

  useEffect(() => {}, [vendorAccountId, reloadContext]);

  useEffect(() => {
    vendorAccountApiService
      .getSupplierListItems(vendorAccountId, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);

        if (result.data.list && result.data.list.length > 0) {
          setTableCaptionText('');
        } else {
          setTableCaptionText(`There is no 'Supplier' in this Vendor yet.`);
        }
      })
      .catch(catchApiError);
  }, [vendorAccountId, order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      newArray.push([item.supplierId, item.name, item.companyName, item.streetAddress]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    vendorAccountApiService
      .deleteSupplier(vendorAccountId, id)
      .then((result) => {
        showSuccessSnackBar('Supplier was deleted successfully');
        setDummyCounter(dummyCounter + 1);
      })
      .catch(catchApiError);
  };

  const handleAdd = () => {
    setSelectedSupplierId('');
    setModalOpen(true);
  };

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleEdit = (event) => {
    setSelectedSupplierId(event.currentTarget.dataset.tag);
    setModalOpen(true);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          {rows && (
            <EntityTable
              title="Suppliers"
              headCells={headCells}
              totalNumberOfRows={totalNumberOfRows}
              rows={rows}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleAdd={userContext.isAdmin && userContext.permissions.canManageSuppliers && handleAdd}
              handleEdit={userContext.permissions.canReadSuppliers && handleEdit}
              deleteHandler={userContext.isAdmin && userContext.permissions.canManageSuppliers && handleDeleteRequest}
              captionText={tableCaptionText}
              showPagination={false}
            />
          )}
        </CardContent>
      </Card>
      <DeleteDialog action={pendingDeleteAction} handleDelete={handleDelete} title="Delete Supplier?" body="Are you sure you want to delete this supplier?" />

      <SupplierEntry open={modalOpen} accountName={accountName} onClose={handleModalClose} supplierId={selectedSupplierId} vendorAccountId={vendorAccountId} />
    </>
  );
}

export default Suppliers;
