import React, { createContext, useCallback, useContext, useState } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const snackBarInitialState = {
  open: false,
  severity: 'info', //["error","info","success","warning"]
  message: '',
};

const GlobalSnackBarContext = createContext({
  showSuccessSnackBar: (message) => {},
  showErrorSnackBar: (message) => {},
  closeSnackBar: () => {},
});

export const GlobalSnackBarProvider = ({ children }) => {
  const [snackBar, setSnackBar] = useState(snackBarInitialState);

  const showSuccessSnackBar = useCallback((message) => {
    setSnackBar({
      open: true,
      severity: 'success',
      message: message,
    });
  }, []);

  const showErrorSnackBar = useCallback((message = 'An Error Occurred Please Try Again Later.') => {
    setSnackBar({
      open: true,
      severity: 'error',
      message: message,
    });
  }, []);

  const closeSnackBar = useCallback(() => {
    setSnackBar(snackBarInitialState);
  }, []);

  const handleSnackbarClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackBar();
  }, []);

  const value = {
    showSuccessSnackBar,
    showErrorSnackBar,
    closeSnackBar,
  };

  return (
    <GlobalSnackBarContext.Provider value={value}>
      <Snackbar open={snackBar?.open} autoHideDuration={4000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} variant="filled" severity={snackBar?.severity}>
          {snackBar?.message}
        </Alert>
      </Snackbar>
      {children}
    </GlobalSnackBarContext.Provider>
  );
};

export default function useGlobalSnackBar() {
  const context = useContext(GlobalSnackBarContext);

  if (context === undefined) {
    throw new Error('useGlobalSnackBar must be used within GlobalSnackBarContext');
  }

  return context;
}
