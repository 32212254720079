import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { UserContext } from 'src/contexts/UserContext';
import accountApiService from 'src/services/account.api.service';

const accountInitialState = {
  accountId: '',
  accountType: '',
  accountName: '',
};

const AccountContext = createContext({
  accountId: '',
  accountType: '',
  accountName: '',
  accounts: [],
  setCurrentAccount: (accountId) => {},
  clearCurrentAccount: () => {},
});

const LocalStorageKey_currentAccountId = 'currentAccountId';

export const AccountProvider = ({ children }) => {
  const [userContext] = useContext(UserContext);
  const [account, setAccount] = useState(accountInitialState);
  const [accounts, setAccounts] = useState([]);
  const history = useHistory();
  const currentUserId = useRef();
  const currentAccountType = useRef();

  const createAccountContextModel = (accountSelectItem) => {
    let model = {
      accountId: accountSelectItem.id,
      accountType: accountSelectItem.accountTypeName,
      accountName: accountSelectItem.name,
    };
    return model;
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        let apiResult = await accountApiService.getMyAccountSelectItems();
        setAccounts(apiResult.data);
      } catch (err) {
        currentUserId.current = null;
      }
    };

    if (userContext && currentUserId.current != userContext.id) {
      currentUserId.current = userContext.id;
      loadData();
    }
  }, [userContext]);

  useEffect(() => {
    if (accounts && accounts.length) {
      let currentAccountId = localStorage.getItem(LocalStorageKey_currentAccountId);
      let currentAccount = accounts.find((x) => x.id == currentAccountId);
      if (!currentAccount) {
        //Not found current account
        let accountContextModel = createAccountContextModel(accounts[0]);
        setAccount(accountContextModel);
      } else {
        //found current account
        if (account.accountId != currentAccount) {
          //reset account
          let accountContextModel = createAccountContextModel(currentAccount);
          setAccount(accountContextModel);
        }
      }
    }
  }, [accounts]);

  useEffect(() => {
    currentAccountType.current = account.accountType;
    if (account.accountId) {
      localStorage.setItem(LocalStorageKey_currentAccountId, account.accountId);
    }
  }, [account]);

  const setCurrentAccount = useCallback(
    (accountId) => {
      if (accounts && accounts.length && accountId) {
        let selectedAccount = accounts.find((x) => x.id == accountId);
        if (selectedAccount) {
          let accountContextModel = createAccountContextModel(selectedAccount);

          if (currentAccountType.current && currentAccountType.current != accountContextModel.accountType) {
            history.push('/');
          }

          setAccount(accountContextModel);
        }
      }
    },
    [accounts]
  );

  const clearCurrentAccount = useCallback(() => {
    setAccount(accountInitialState);
    currentUserId.current = null;
    currentAccountType.current = null;
    localStorage.setItem(LocalStorageKey_currentAccountId, '');
  }, []);

  let contextValue = {
    ...account,
    accounts: accounts,
    setCurrentAccount,
    clearCurrentAccount,
  };

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>;
};

const useAccounts = () => {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error('useAccounts must be used within AccountContext');
  }

  return context;
};

export default useAccounts;
