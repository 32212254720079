import React, { useEffect, useState } from 'react';

import { FormControl, InputBase, ListSubheader, MenuItem, Select, makeStyles, withStyles } from '@material-ui/core';

import useAccounts from 'src/hooks/useAccounts';
import AccountType from 'src/models/AccountType';

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    paddingRight: theme.spacing(1),
  },
  select: {
    fontFamily: theme.font.menu,
  },
  listSubheader: {
    lineHeight: '30px',
    paddingTop: theme.spacing(1),
    borderBottom: '1px solid #e1e1e1',
  },
}));

const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    padding: theme.spacing(1),
    paddingRight: '26px',
    backgroundColor: 'rgba(0,0,0,0.05)',
    backdropFilter: 'blur(4px)',
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

export default function AccountsSelection() {
  const classes = useStyles();
  const { accountId, accounts, setCurrentAccount } = useAccounts();
  const [selectData, setSelectData] = useState({
    customers: [],
    vendors: [],
  });

  useEffect(() => {
    console.log(accounts);
    if (accounts) {
      let customers = accounts.filter((x) => x.accountTypeName == AccountType.Customer);
      let vendors = accounts.filter((x) => x.accountTypeName == AccountType.Vendor);
      setSelectData({
        customers: customers,
        vendors: vendors,
      });
    }
  }, [accounts]);

  if (selectData.customers.length > 1 || selectData.vendors.length > 1) {
    return (
      <>
        <span className={classes.label}>Account:</span>
        <FormControl variant="standard" className={classes.root + ' exclude'} size="small">
          <Select
            className={classes.select}
            labelId="select-account-label"
            id="select-account"
            label="Account"
            value={(accounts && accountId) || ''}
            onChange={(e) => {
              setCurrentAccount(e.target.value);
            }}
            input={<CustomInput />}
          >
            {selectData.customers && selectData.customers.length && <ListSubheader className={classes.listSubheader}>Customers</ListSubheader>}
            {selectData.customers &&
              selectData.customers.length &&
              selectData.customers.map((item) => {
                return (
                  <MenuItem key={`account${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            {selectData.vendors && selectData.vendors.length && <ListSubheader className={classes.listSubheader}>Vendors</ListSubheader>}
            {selectData.vendors &&
              selectData.vendors.length &&
              selectData.vendors.map((item) => {
                return (
                  <MenuItem key={`account${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </>
    );
  } else {
    return null;
  }
}
