import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import { Backdrop, CircularProgress, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import tableService from 'src/services/table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

import PetbarnExceptionsTableFilter from './PetbarnExceptionsTableFilter';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  late1: {
    textAlign: 'center',
    backgroundColor: '#FFBE6D',
    color: 'white',
  },
  late2: {
    textAlign: 'center',
    backgroundColor: '#FF9C38',
    color: 'white',
  },
  late3: {
    textAlign: 'center',
    backgroundColor: '#FF8100',
    color: 'white',
  },
  late4: {
    textAlign: 'center',
    backgroundColor: '#98280D',
    color: 'white',
  },
}));

const defaultHeadCells = [
  { id: 'ShipmentNumber', numeric: false, disablePadding: true, label: 'Shipment No.' },
  { id: 'OrderNumber', numeric: false, disablePadding: false, label: 'Order No.' },
  { id: 'Supplier', numeric: false, disablePadding: false, label: 'Supplier' },
  { id: 'LoadAt', numeric: false, disablePadding: false, label: 'Load At' },
  { id: 'DischargeAt', numeric: false, disablePadding: false, label: 'Discharge At' },
  { id: 'Vessel', numeric: false, disablePadding: false, label: 'Vessel' },
  { id: 'Voyage', numeric: false, disablePadding: false, label: 'Voyage' },
  { id: 'Containers', numeric: false, disablePadding: false, label: 'Container No.' },
  { id: 'RequiredExWorks', numeric: false, disablePadding: false, label: 'Req. Ex Works' },
  { id: 'EstimatedExFactory', numeric: false, disablePadding: false, label: 'Est. Ex Factory' },
  { id: 'ActualExFactory', numeric: false, disablePadding: false, label: 'Act Ex Factory' },
  { id: 'ETD', numeric: false, disablePadding: false, label: 'ETD' },
  { id: 'ATD', numeric: false, disablePadding: false, label: 'ATD' },
  { id: 'ETA', numeric: false, disablePadding: false, label: 'ETA' },
  { id: 'ATA', numeric: false, disablePadding: false, label: 'ATA' },
  { id: 'CTOAvailability', numeric: false, disablePadding: false, label: 'CTO Availability' },
  { id: 'RequiredDelivery', numeric: false, disablePadding: false, label: 'Req. Delivery' },
  { id: 'ActualDelivery', numeric: false, disablePadding: false, label: 'Act. Delivery' },
  { id: 'DelayDeliveryDay', numeric: true, disablePadding: false, label: 'Delay Delivery (Day)' },
];

function PetbarnExceptionsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('ShipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState({});
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const { enabledShipmentsFeature, enabledTrackAndTrace } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const [dischargeAts, setDischargeAts] = useState([]);

  const previousReload = useRef();
  const [reload, setReload] = useState(new Date());

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const createPetbarnExceptionsTableReportFilter = useCallback(() => {
    const petbarnExceptionsTableReportFilter = {
      ...filter,
      skip: page * rowsPerPage,
      take: rowsPerPage,
      sortColumn: orderBy,
      sortDirection: order,
    };

    return petbarnExceptionsTableReportFilter;
  }, [filter, order, orderBy, page, rowsPerPage]);

  const createTableListData = useCallback(
    (items) => {
      const createDelayCell = (value) => {
        var delayCell = { text: value, className: '' };
        if (value !== null) {
          if (value > 0) {
            delayCell.text = `+${value}`;
            if (value <= 10) {
              delayCell.className = classes.late1;
            } else if (value <= 20) {
              delayCell.className = classes.late2;
            } else if (value <= 100) {
              delayCell.className = classes.late3;
            } else {
              delayCell.className = classes.late4;
            }
          }
        }
        return delayCell;
      };

      let newArray = [];

      items.forEach((item) => {
        const requiredExWorks = moment(item.requiredExWorks);
        const estimatedExFactory = moment(item.estimatedExFactory);
        const actualExFactory = moment(item.actualExFactory);
        const etd = moment(item.etd);
        const atd = moment(item.atd);
        const eta = moment(item.eta);
        const ata = moment(item.ata);
        const ctoAvailability = moment(item.ctoAvailability);
        const requiredDelivery = moment(item.requiredDelivery);
        const actualDelivery = moment(item.actualDelivery);

        var delayDeliveryDay = createDelayCell(item.delayDeliveryDay);

        var shipmentNumberLinks = item.shipmentNumber;

        if (enabledTrackAndTrace) {
          shipmentNumberLinks = { component: 'linkList', links: [] };
          shipmentNumberLinks.links.push({
            text: item.shipmentNumber,
            path: '/trackandtrace?s=' + item.shipmentNumber,
          });
        }

        var containers = { component: 'tags', texts: [] };
        if (item.containers) {
          item.containers.split(',').forEach((container) => {
            containers.texts.push({ text: container });
          });
        }

        const rowData = {
          ShipmentNumber: shipmentNumberLinks,
          OrderNumber: item.orderNumber,
          Supplier: item.supplier,
          LoadAt: item.loadAt,
          DischargeAt: item.dischargeAt,
          Vessel: item.vessel,
          Voyage: item.voyage,
          Containers: containers,
          RequiredExWorks: requiredExWorks.isValid() ? requiredExWorks.format('L') : '',
          EstimatedExFactory: estimatedExFactory.isValid() ? estimatedExFactory.format('L') : '',
          ActualExFactory: actualExFactory.isValid() ? actualExFactory.format('L') : '',
          ETD: etd.isValid() ? etd.format('L') : '',
          ATD: atd.isValid() ? atd.format('L') : '',
          ETA: eta.isValid() ? eta.format('L') : '',
          ATA: ata.isValid() ? ata.format('L') : '',
          CTOAvailability: ctoAvailability.isValid() ? ctoAvailability.format('L') : '',
          RequiredDelivery: requiredDelivery.isValid() ? requiredDelivery.format('L') : '',
          ActualDelivery: actualDelivery.isValid() ? actualDelivery.format('L') : '',
          DelayDeliveryDay: delayDeliveryDay,
        };

        newArray.push(rowData);
      });

      return newArray;
    },
    [enabledTrackAndTrace]
  );

  const handleOnFilterChange = useCallback((data) => {
    setPage(0);
    setFilter((f) => ({ ...f, ...data }));
    setReload(new Date());
  }, []);

  const handleRequestSort = useCallback((sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    setReload(new Date());
  }, []);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
    setReload(new Date());
  }, []);

  const handleChangeRowsPerPage = useCallback((newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
    setReload(new Date());
  }, []);

  const generatePdf = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const petbarnExceptionsTableReportFilter = createPetbarnExceptionsTableReportFilter();
      const filterForPdf = {
        ...petbarnExceptionsTableReportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getPetbarnExceptionsTableReport(filterForPdf)
        .then((result) => {
          stopProgress();
          pdfTableService.generatePetbarnExceptionsPDF(result.data.items, 'Exceptions', 'Exceptions Report', result.data.hiddenColumnNames);
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createPetbarnExceptionsTableReportFilter, stopProgress, totalNumberOfRows]);

  const generateCSV = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const petbarnExceptionsTableReportFilter = createPetbarnExceptionsTableReportFilter();
      const filterForCsv = {
        ...petbarnExceptionsTableReportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getPetbarnExceptionsTableReport(filterForCsv)
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generatePetbarnExceptionsCsvData(result.data.items, '', result.data.hiddenColumnNames);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createPetbarnExceptionsTableReportFilter, stopProgress, totalNumberOfRows]);

  const handleColumnSelected = useCallback((headCells) => {
    setNewHeadCells(headCells);
  }, []);

  const handleBackdropClose = useCallback(() => {
    setOpenBackdrop(false);
  }, []);

  useEffect(() => {
    const getData = (petbarnExceptionsTableReportFilter) => {
      apiService
        .getPetbarnExceptionsTableReport(petbarnExceptionsTableReportFilter)
        .then((result) => {
          let contentRows = createTableListData(result.data.items);
          let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
          setHeadCells(filteredTable.headCells);
          setRows(filteredTable.contentRows);
          setTotalNumberOfRows(result.data.count);
          setHiddenColumnNames(result.data.hiddenColumnNames);
          setDischargeAts(result.data.dischargeAts);
          stopProgress();
          handleBackdropClose();
        })
        .catch(catchApiError);
    };

    if (previousReload.current !== reload) {
      previousReload.current = reload;
      startProgress();
      const petbarnExceptionsTableReportFilter = createPetbarnExceptionsTableReportFilter();
      getData(petbarnExceptionsTableReportFilter);
    }
  }, [reload, createPetbarnExceptionsTableReportFilter, startProgress, catchApiError, createTableListData, stopProgress, handleBackdropClose]);

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        {enabledShipmentsFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Exceptions</h4>
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PetbarnExceptionsTableFilter onChange={handleOnFilterChange} dischargeAts={dischargeAts} />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={newHeadCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(PetbarnExceptionsTable);
