import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { Grid, InputAdornment, Modal, Paper, TextField, Typography, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import useQuery from 'src/hooks/useQuery';
import publicShipmentTrackingApiService from 'src/services/publicShipmentTracking.api.service';
import MyButton from 'src/wrappers/Button';

import SearchResultModal from './SearchResultModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  searchBoxContainer: {
    display: 'flex',
  },
  searchInputControl: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    '& div': {
      backgroundColor: theme.palette.inputControl.backgroundColor,
      borderRadius: 4,
    },
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
    },
    '& fieldset': {
      borderColor: theme.palette.inputControl.borderColor,
    },
    '& :hover fieldset': {
      borderColor: theme.palette.inputControl.borderColor + ' !important',
    },
    '& .Mui-focused fieldset': {
      borderColor: theme.palette.inputControl.borderColor + ' !important',
    },
  },
  searchIcon: {
    color: '#ADADAD',
  },
  findButton: {
    height: 40,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function SearchBox(props) {
  const { accountId, handleSelectedShipmentTrackingId, isLoading, setIsLoading } = props;
  const history = useHistory();
  const query = useQuery();
  const takeItems = 200;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState(query.get('s') ? query.get('s') : '');
  const [shipmentTrackingItems, setShipmentTrackingItems] = useState();
  const [totalShipmentTrackingItems, setTotalShipmentTrackingItems] = useState(0);
  const [totalLoadedItems, setTotalLoadedItems] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  useEffect(() => {
    if (accountId && searchText) {
      handleFindButtonClicked();
    }
  }, [accountId]);

  useEffect(() => {
    if (shipmentTrackingItems) {
      if (shipmentTrackingItems.length > 1) {
        setOpen(true);
      } else if (shipmentTrackingItems.length == 1) {
        handleSelectedShipmentTrackingId(shipmentTrackingItems[0].id);
      } else {
        handleSelectedShipmentTrackingId(null);
      }
    }
  }, [shipmentTrackingItems]);

  const loadMoreItems = async (refresh) => {
    let tmpTotalLoadedItems = 0;
    let tmpLoadedItems = [];
    if (!refresh) {
      tmpTotalLoadedItems = totalLoadedItems;
      tmpLoadedItems = shipmentTrackingItems;
    }

    try {
      const apiResult = await publicShipmentTrackingApiService.searchPublicShipmentTrackings(accountId, searchText, tmpTotalLoadedItems, takeItems);
      if (apiResult && apiResult.data) {
        if (apiResult.data.items.length) {
          tmpLoadedItems = tmpLoadedItems.concat(apiResult.data.items);
          tmpTotalLoadedItems = tmpTotalLoadedItems + apiResult.data.items.length;

          if (tmpTotalLoadedItems < apiResult.data.totalItems) {
            setHasMoreItems(true);
          } else {
            setHasMoreItems(false);
          }
        } else {
          setHasMoreItems(false);
        }

        setTotalLoadedItems(tmpTotalLoadedItems);
        setTotalShipmentTrackingItems(apiResult.data.totalItems);
        setShipmentTrackingItems(tmpLoadedItems);
      } else {
        setTotalLoadedItems(0);
        setTotalShipmentTrackingItems(0);
        setShipmentTrackingItems([]);
      }
    } catch (err) {
      setIsLoading(false);
      console.log('error: ', err);
      if (err.response && err.response.status === 401) history.push('/login');
    }
  };

  const handleFindButtonClicked = async () => {
    setIsLoading(true);
    await loadMoreItems(true);
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleOnKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleFindButtonClicked();
    }
  };

  return (
    <>
      <Paper className={classes.root}>
        <Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start">
          {/* Header */}
          <Grid item xs={12}>
            <Typography variant="h6">Track Your Shipment</Typography>
          </Grid>
          <Grid item xs={12} className={classes.searchBoxContainer}>
            <TextField
              id="widthField"
              label=""
              variant="outlined"
              value={searchText}
              className={classes.searchInputControl}
              onChange={handleSearchTextChange}
              onKeyPress={handleOnKeyUp}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                ),
              }}
              helperText={
                <span>
                  You can search by <b>Shipment Number</b> or <b>House Waybill Number</b>.
                </span>
              }
              disabled={isLoading}
            />
            <MyButton className={classes.findButton} onClick={handleFindButtonClicked} isLoading={isLoading}>
              Find
            </MyButton>
          </Grid>
        </Grid>
      </Paper>
      <Modal className={classes.modalContainer} open={open} onClose={handleModalClose}>
        <div style={{ margin: 60 }}>
          <SearchResultModal
            isLoading={isLoading}
            onClose={handleModalClose}
            items={shipmentTrackingItems}
            searchText={searchText}
            handleSelectedShipmentTrackingId={handleSelectedShipmentTrackingId}
            totalItems={totalShipmentTrackingItems}
            hasMoreItems={hasMoreItems}
            loadMoreItems={loadMoreItems}
          />
        </div>
      </Modal>
    </>
  );
}
