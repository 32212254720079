import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Collapse, FormControlLabel, Grid, Switch } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import DateFilterControl from 'src/components/parts/DataTableFilter/DateFilterControl';
import SelectionControl from 'src/components/parts/DataTableFilter/SelectionControl';
import DelayChangedTextField2 from 'src/wrappers/DelayChangedTextField2';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  container: {
    paddingTop: 6,
  },
}));

const OpenOrdersTableFilter = ({ onChange, dischargeAts }) => {
  const classes = useStyles();
  const [filterInputs, setFilterInputs] = useState({
    shipmentNumber: '',
    orderNumber: '',
    containerNumber: '',
  });
  const [filter, setFilter] = useState({});
  const previousReload = useRef();
  const [reload, setReload] = useState(new Date());
  const [showMoreFilter, setShowMoreFilter] = useState(false);

  const handleOnFilterValueChanged = useCallback((key, value) => {
    setFilter((f) => {
      let result = { ...f };
      result[key] = value;
      return result;
    });
    setReload(new Date());
  }, []);

  const handleChangeShowMoreFilter = useCallback(() => {
    setShowMoreFilter((prev) => !prev);
  }, []);

  useEffect(() => {
    if (previousReload.current !== reload) {
      previousReload.current = reload;
      onChange && onChange(filter);
      console.log('Deliveries Table Filter', filter);
    }
  }, [reload, filter, onChange]);

  return (
    <div className={classes.root}>
      <Collapse in={showMoreFilter} collapsedHeight={66}>
        <Grid container direction="row" spacing={2} className={classes.container}>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
              <DelayChangedTextField2
                id="shipment-number-filter"
                label="Shipment Number"
                variant="outlined"
                value={filterInputs.shipmentNumber}
                onValueChange={(text) => {
                  setFilterInputs((f) => ({
                    ...f,
                    shipmentNumber: text,
                  }));
                }}
                onDelayValueChange={(text) => {
                  handleOnFilterValueChanged('shipmentNumber', text);
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
              <DelayChangedTextField2
                id="order-number-filter"
                label="Order Number"
                variant="outlined"
                value={filterInputs.orderNumber}
                onValueChange={(text) => {
                  setFilterInputs((f) => ({
                    ...f,
                    orderNumber: text,
                  }));
                }}
                onDelayValueChange={(text) => {
                  handleOnFilterValueChanged('orderNumber', text);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
              <DelayChangedTextField2
                id="container-number-filter"
                label="Container Number"
                variant="outlined"
                value={filterInputs.containerNumber}
                onValueChange={(text) => {
                  setFilterInputs((f) => ({
                    ...f,
                    containerNumber: text,
                  }));
                }}
                onDelayValueChange={(text) => {
                  handleOnFilterValueChanged('containerNumber', text);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectionControl
              id={'dischargeAt-filter'}
              items={dischargeAts}
              title={'Discharge At'}
              onChange={(text) => {
                handleOnFilterValueChanged('dischargeAt', text);
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DateFilterControl
              id="eta-filter"
              title="ETA"
              onChange={(dateFilter) => {
                handleOnFilterValueChanged('eta', dateFilter);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFilterControl
              id="ata-filter"
              title="ATA"
              onChange={(dateFilter) => {
                handleOnFilterValueChanged('ata', dateFilter);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFilterControl
              id="requiredDelivery-filter"
              title="Required Delivery"
              onChange={(dateFilter) => {
                handleOnFilterValueChanged('requiredDelivery', dateFilter);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateFilterControl
              id="actualDelivery-filter"
              title="Actual Delivery"
              onChange={(dateFilter) => {
                handleOnFilterValueChanged('actualDelivery', dateFilter);
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
      <FormControlLabel control={<Switch checked={showMoreFilter} onChange={handleChangeShowMoreFilter} />} label="Show more filters" />
    </div>
  );
};

export default OpenOrdersTableFilter;
