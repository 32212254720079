import { Tooltip, withStyles } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import React from 'react';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNoneRounded';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActiveRounded';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

const SubscribeToggleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.subscribeToggleButton.unsubscribed.color,
    backgroundColor: 'transparent !important',
    border: 'none',
    width: 40,
    height: 40,
    '&.Mui-selected': {
      color: theme.palette.subscribeToggleButton.subscribed.color,
    },
    '&.Mui-disabled': {
      color: theme.palette.subscribeToggleButton.disabled.color,
    },
  },
}))((props) => (
  <>
    {props.enabled ? (
      <Tooltip title={props.selected ? 'Click to Unsubscribe' : 'Click to Subscribe'}>
        <ToggleButton {...props} value="check" selected={props.selected} onChange={props.onChange}>
          {props.selected ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />}
        </ToggleButton>
      </Tooltip>
    ) : (
      <Tooltip title="To Subscibe, please enable notification on Edit User Profile page.">
        <span>
          <ToggleButton {...props} disabled>
            <NotificationsOffIcon />
          </ToggleButton>
        </span>
      </Tooltip>
    )}
  </>
));

export default SubscribeToggleButton;
