import moment from 'moment';

class ShipmentBookingEntryModelFactory {
  createShipmentBookingEntryCommandModel = (entryState) => {
    let orders = entryState.orders?.map((orderEntryState) => {
      let orderLines = orderEntryState.lines
        ?.filter((x) => x.active)
        .map((lineEntryState) => ({
          lineNumber: lineEntryState.lineNumber,
          qty: lineEntryState.qty == '' ? null : lineEntryState.qty,
        }));

      return {
        orderNumber: orderEntryState.orderNumber,
        lines: orderLines,
      };
    });

    let commandModel = {
      id: entryState.id,
      accountId: entryState.vendorAccountId,
      shipper: { ...entryState.shipper },
      receiver: { ...entryState.receiver },
      containerVolume: entryState.containerVolume == '' ? null : entryState.containerVolume,
      containerSize: !entryState.containerSize ? '' : entryState.containerSize,
      cargoReadyDate: entryState.cargoReadyDate == '' ? null : entryState.cargoReadyDate,
      incoterms: entryState.incoterms,
      marksAndNumbers: entryState.marksAndNumbers,
      goodsDescription: entryState.goodsDescription,
      orders: orders,
    };
    console.log(entryState, commandModel);
    return commandModel;
  };

  createShipmentBookingEntryModel = (accountId, loadedBooking) => {
    let bookingEntry = {};
    bookingEntry.vendorAccountId = accountId;
    bookingEntry.id = loadedBooking.id;
    bookingEntry.bookingNumber = loadedBooking.bookingNumber;
    bookingEntry.shipper = loadedBooking.shipper;
    bookingEntry.receiver = loadedBooking.receiver;
    if (loadedBooking.cargoReadyDate) {
      bookingEntry.cargoReadyDate = moment(loadedBooking.cargoReadyDate).toDate();
    }
    bookingEntry.containerSize = loadedBooking.containerSize ? `${loadedBooking.containerSize}` : null;
    bookingEntry.containerSizeName = loadedBooking.containerSizeName;
    bookingEntry.containerVolume = loadedBooking.containerVolume;
    bookingEntry.incoterms = loadedBooking.incoterms;
    bookingEntry.note = loadedBooking.note;
    bookingEntry.statusName = loadedBooking.status;
    bookingEntry.goodsDescription = loadedBooking.goodsDescription;
    bookingEntry.marksAndNumbers = loadedBooking.marksAndNumbers;
    bookingEntry.submittedDate = loadedBooking.submittedDate;
    bookingEntry.orders = loadedBooking.orders;
    bookingEntry.readOnly = true;
    bookingEntry.states = loadedBooking.states;

    if (bookingEntry.orders) {
      bookingEntry.orders.forEach((order) => {
        order.lines.forEach((line) => {
          line.active = true;
        });
      });
    }

    return bookingEntry;
  };

  createFormErrors = (apiErrors) => {
    if (apiErrors && apiErrors.length) {
      let errors = {};
      let errorString = '';
      for (let error of apiErrors) {
        if (error.propertyName) {
          errors[error.propertyName] = { status: 'error', text: error.errorMessage };
        } else {
          errorString += error.errorMessage;
        }
      }
      return { errors, errorString };
    }
    return null;
  };
}

export default new ShipmentBookingEntryModelFactory();
