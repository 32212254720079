import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MyButton from 'src/wrappers/Button';

function ConfirmDialog(props) {
  let { title, body, action, handleConfirm } = props;
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (action) {
      setOpenDialog(action.show);
    }
  }, [action]);

  const onCancelClick = () => {
    setOpenDialog(false);
  };

  const onConfirmClick = () => {
    handleConfirm(action.referenceId);
    setOpenDialog(false);
  };
  return (
    <Dialog open={openDialog} onClose={onCancelClick} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MyButton buttonType="primary" onClick={onConfirmClick}>
          Confirm
        </MyButton>
        <MyButton buttonType="cancel" onClick={onCancelClick}>
          Cancel
        </MyButton>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  action: PropTypes.shape({ show: PropTypes.bool, referenceId: PropTypes.string.isRequired }),
};
