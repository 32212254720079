import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import CardBody from '../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import { format } from 'react-string-format';
import helpService from '../../../services/help.service';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Link } from 'react-router-dom';
import ChartTooltip from '../../parts/ChartTooltip';
import createTrend from 'trendline';

const useStyles = makeStyles((theme) => ({
  card: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    boxShadow: 'none',
    position: 'relative',
  },
  cardTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  chartProperties: {
    backgroundColor: theme.paletteChart.bg.backgroundColor,
    color: theme.paletteChart.bg.color,
  },
  textProperties: {
    color: theme.paletteChart.text.color,
  },
  chartTitlePanel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    '& h4': {
      marginRight: theme.spacing(1),
    },
  },
}));
const AllSuppliersName = 'All Suppliers';
const AllSelectedSuppliersName = 'All Selected Suppliers';

function XYChartTrends(props) {
  const classes = useStyles();
  const [noChartData, setNoChartData] = useState(false);
  const [noChartDataText, setNoChartDataText] = useState('No Supplier Specific Data for This Chart');
  const [totalAmountText, setTotalAmountText] = useState('');
  const [chartData, setChartData] = useState({ supplierDatas: {}, data: {}, trendLineData: {}, avgLineData: {} });
  const {
    Title,
    ChartId,
    TrendsOverTimeReportData,
    GroupBy,
    TooltipValueFormat,
    NoDataText,
    IsLoading = true,
    HiddenAllSuppliersSeriesOnInit = false,
    TitleLinkUrl,
    ValuePrefix = '',
    ValueSuffix = '',
    SubTitle,
    ShowTrendLine = false,
    ShowAvgLine = false,
    AvgValue,
    ForceShowNoData = false,
  } = props;

  useEffect(() => {
    const createTrendLineData = () => {
      if (
        ShowTrendLine &&
        TrendsOverTimeReportData.supplierDatas &&
        TrendsOverTimeReportData.trendDatas &&
        TrendsOverTimeReportData.supplierDatas.length > 0 &&
        TrendsOverTimeReportData.trendDatas.length > 0
      ) {
        let supplierData = TrendsOverTimeReportData.supplierDatas[0];

        let trendDatas = TrendsOverTimeReportData.trendDatas.map((x) => {
          let time = new Date(x.date).getTime();
          return { ...x, time: time };
        });
        let trend = createTrend(trendDatas, 'time', supplierData.valuePropertyName);
        let lastIndex = trendDatas.length - 1;
        let trendLineData = [
          { data: trend.calcY(trendDatas[0].time), date: trendDatas[0].date },
          { data: trend.calcY(trendDatas[lastIndex].time), date: trendDatas[lastIndex].date },
        ];

        return trendLineData;
      }
      return null;
    };

    const createAvgLineData = () => {
      if (
        ShowAvgLine &&
        TrendsOverTimeReportData.supplierDatas &&
        TrendsOverTimeReportData.trendDatas &&
        TrendsOverTimeReportData.supplierDatas.length > 0 &&
        TrendsOverTimeReportData.trendDatas.length > 0
      ) {
        let supplierData = TrendsOverTimeReportData.supplierDatas[0];
        let trendDatas = TrendsOverTimeReportData.trendDatas;

        let total = 0;
        trendDatas.forEach((data) => {
          total = total + data[supplierData.valuePropertyName];
        });

        let lastIndex = trendDatas.length - 1;
        let avgValue = total / trendDatas.length;
        let trendLineData = [
          { data: avgValue, date: trendDatas[0].date },
          { data: avgValue, date: trendDatas[lastIndex].date },
        ];

        return trendLineData;
      }
      return null;
    };

    if (TrendsOverTimeReportData) {
      let trendLineData = createTrendLineData();
      let avgLineData = createAvgLineData();

      let chartData = {
        supplierDatas: TrendsOverTimeReportData.supplierDatas,
        data: TrendsOverTimeReportData.trendDatas,
        trendLineData: trendLineData,
        avgLineData: avgLineData,
      };

      setChartData(chartData);
    }
  }, [TrendsOverTimeReportData, ShowTrendLine, ShowAvgLine]);

  useLayoutEffect(() => {
    let supplierDatas = chartData.supplierDatas;
    let chartDatas = chartData.data;
    let trendLineData = chartData.trendLineData;
    let avgLineData = chartData.avgLineData;
    let chart = am4core.create(ChartId, am4charts.XYChart);
    chart.logo.disabled = true;
    chart.maskBullets = false;

    function createTrendLine(trendLineData) {
      var trend = chart.series.push(new am4charts.LineSeries());
      trend.dataFields.valueY = 'data';
      trend.dataFields.dateX = 'date';
      trend.strokeWidth = 2;
      trend.strokeDasharray = '10,4,4,4';
      trend.stroke = trend.fill = am4core.color(lightTheme.chart.trendLine.lineColor);
      trend.data = trendLineData;
      trend.name = 'Trend';

      return trend;
    }

    function createAvgLine(avgLineData) {
      var trend = chart.series.push(new am4charts.LineSeries());
      trend.dataFields.valueY = 'data';
      trend.dataFields.dateX = 'date';
      trend.strokeWidth = 2;
      trend.strokeDasharray = '10,4,4,4';
      trend.stroke = trend.fill = am4core.color(lightTheme.chart.avgLine.lineColor);
      trend.data = avgLineData;
      trend.name = 'Average';
      return trend;
    }

    if (supplierDatas && chartDatas && supplierDatas.length > 0 && chartDatas.length > 0) {
      setNoChartData(false);

      chart.data = chartDatas;

      //Legend
      if (supplierDatas.length > 1) {
        chart.legend = new am4charts.Legend();
        chart.legend.position = 'top';
        chart.legend.paddingBottom = 20;
        chart.legend.marginTop = 0;
        chart.legend.paddingTop = 0;
      }

      //Axis
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.numberFormatter.numberFormat = '#,###.##';
      if (TooltipValueFormat) {
        valueAxis.renderer.labels.template.adapter.add('text', function (text) {
          return format(TooltipValueFormat, text);
        });
      }

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;

      var dateFormatTooltip = 'yyyy-MMM';
      dateAxis.baseInterval = {
        timeUnit: 'month',
        count: 1,
      };
      if (GroupBy && GroupBy.toString().toLowerCase() === 'year') {
        dateFormatTooltip = 'yyyy';
        dateAxis.baseInterval = {
          timeUnit: 'year',
          count: 1,
        };
      }

      //Series
      // let totalColors = lightTheme.chart.colors.length;
      let totalColors = lightTheme.chart.colors.length;
      var colorIndex = 0;
      for (var i = 0; i < supplierDatas.length; i++) {
        var supplierName = '';

        if (supplierDatas[i].name) {
          supplierName = supplierDatas[i].name;
        }

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = supplierDatas[i].valuePropertyName;
        series.dataFields.dateX = 'date';
        series.strokeWidth = 2;
        series.minBulletDistance = 10;
        if (supplierName !== AllSuppliersName && supplierName !== AllSelectedSuppliersName) {
          series.name = helpService.truncateString(supplierName, 15);
        } else {
          series.name = supplierName;
        }

        let valuePropertyName = format(ValuePrefix + '{{0}}' + ValueSuffix, supplierDatas[i].valuePropertyName);
        series.tooltipText = '[bold]' + supplierName + ': {date.formatDate("' + dateFormatTooltip + '")}:[/] ' + valuePropertyName;

        if (supplierName === AllSuppliersName && supplierDatas.length > 1) {
          series.strokeDasharray = '8,4';
          series.stroke = am4core.color(lightTheme.chart.allSuppliersLineColor);
          series.fill = am4core.color(lightTheme.chart.allSuppliersLineColor);
          series.hidden = HiddenAllSuppliersSeriesOnInit;
        } else {
          series.stroke = am4core.color(lightTheme.chart.colors[colorIndex % totalColors]);
          series.fill = am4core.color(lightTheme.chart.colors[colorIndex % totalColors]);
          colorIndex++;
        }

        let labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = valuePropertyName;
        labelBullet.label.dy = -10;
        labelBullet.label.fontSize = '0.75rem';
        labelBullet.hidden = true;

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = am4core.color('#fff');
        bullet.circle.strokeWidth = 2;
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;

        bullet.events.on('hit', function (ev) {
          if (labelBullet.isHidden) {
            labelBullet.show();
          } else {
            labelBullet.hide();
          }
        });

        series.events.on('appeared', function (ev) {
          labelBullet.hide();
        });
      }

      //TrendLine
      if (trendLineData) {
        createTrendLine(trendLineData);
      }

      //TrendLine
      if (avgLineData) {
        createAvgLine(avgLineData);
      }

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = dateAxis;
    } else {
      setNoChartData(true);
      if (NoDataText) {
        setNoChartDataText(NoDataText);
      }
    }

    return () => {
      chart.dispose();
    };
  }, [chartData]);

  useEffect(() => {
    if (SubTitle || SubTitle === '') {
      setTotalAmountText(SubTitle);
    } else if (TrendsOverTimeReportData.totalAmount) {
      var totalAmountText = TrendsOverTimeReportData.totalAmount.toLocaleString();
      if (TooltipValueFormat) {
        totalAmountText = format(TooltipValueFormat, totalAmountText);
      }

      setTotalAmountText(format('(Total {0})', totalAmountText));
    } else {
      setTotalAmountText('');
    }
  }, [TrendsOverTimeReportData, TooltipValueFormat, SubTitle]);

  return (
    <Card className={classes.chartProperties}>
      <LoadingOverlay IsLoading={IsLoading} />
      <ChartTooltip />
      <CardHeader>
        <ContentFont>
          {ForceShowNoData || (noChartData && !IsLoading) ? (
            <div style={{ position: 'absolute', marginBottom: '0px', fontSize: '13px', color: '#bbbbbb' }}>{noChartDataText}</div>
          ) : (
            ''
          )}
          <div id={ChartId} style={{ position: 'relative', height: '400px', marginTop: '20px', marginBottom: '0px', padding: '0 10px 0 0' }} />
        </ContentFont>
      </CardHeader>
      <CardBody>
        <div className={classes.chartTitlePanel + ' titleFont'}>
          {TitleLinkUrl ? (
            <Link to={TitleLinkUrl}>
              <h4 className={classes.textProperties}> {Title} </h4>
            </Link>
          ) : (
            <h4 className={classes.textProperties}> {Title} </h4>
          )}
          {totalAmountText}
        </div>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

export default XYChartTrends;
