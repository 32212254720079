class helpService {
  truncateString(str, num) {
    if (!str || str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  GuidToJoinedString(input) {
    var output = '';
    if (Array.isArray(input)) {
      output = input.map((n, index) => `${n}`).join(',');
    } else {
      output = input;
    }
    return output;
  }

  thousands_separators(num) {
    var num_parts = num.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return num_parts.join('.');
  }

  max_value(arrarylist) {
    var max = arrarylist[1];
    for (var i = 1; i < arrarylist.length; i++) {
      if (arrarylist[i] > max) {
        max = arrarylist[i];
      }
    }
    return max;
  }

  cloneObject(sourceObject) {
    return Object.assign({}, sourceObject);
  }

  sortNumbersDesc(numbersArray) {
    return numbersArray.sort(function (a, b) {
      return b - a;
    });
  }

  sortStringsAsc(stringArray) {
    return stringArray.sort(function (a, b) {
      var nameA = a.toLowerCase(),
        nameB = b.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
}
export default new helpService();
