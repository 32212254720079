import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import { IconButton, Modal, Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import DataContentTable from 'src/components/parts/DataContentTable';
import DataTableFilter from 'src/components/parts/DataTableFilter/DataTableFilter';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

import ProductOverview from '../ProductOverview/ProductOverview';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentContainer: {
    margin: 60,
    width: '100%',
    maxHeight: 'calc(100% - 40px)',
    display: 'flex',
  },
  overviewIcon: {
    color: theme.palette.primaryButton.backgroundColor,
  },
}));

const headCells = [
  {
    id: 'productCode',
    numeric: false,
    disablePadding: true,
    label: 'Product Code',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'suppliers',
    numeric: false,
    disablePadding: false,
    label: 'Suppliers',
  },
  {
    id: 'totalQty',
    numeric: true,
    disablePadding: false,
    label: 'Total Qty.',
  },
  {
    id: 'avgUnitValue',
    numeric: true,
    disablePadding: false,
    label: 'Avg. ($)',
    tooltip: 'Average unit price per commercial invoice',
  },
  {
    id: 'minUnitValue',
    numeric: true,
    disablePadding: false,
    label: 'Min ($)',
    tooltip: 'Minimum unit price per commercial invoice',
  },
  {
    id: 'maxUnitValue',
    numeric: true,
    disablePadding: false,
    label: 'Max ($)',
    tooltip: 'Maximum unit price per commercial invoice',
  },
  {
    id: 'units',
    numeric: false,
    disablePadding: false,
    label: 'Units',
  },
];

function ProductsTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productCode');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [baseQueryString, setBaseQueryString] = React.useState();
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [accountId, setAccountId] = useState();
  const [productOverviewSettings, setProductOverviewSettings] = useState({});
  const { enabledProducts } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  useEffect(() => {
    if (filter) {
      startProgress();
      setAccountId(filter.accountId);
      getData();
    }
  }, [rowsPerPage, page, filter]);

  useEffect(() => {
    const loadProductOverviewSettings = async () => {
      let result = await apiService.getProductOverviewSettings(accountId);
      setProductOverviewSettings(result.data);
    };

    if (accountId) {
      loadProductOverviewSettings();
    }
  }, [accountId]);

  const classes = useStyles();

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getProductsTable(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers
      )
      .then((result) => {
        setProducts(result.data.items);
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    var index = 0;
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item) => {
      var suppliers = { component: 'textList', texts: [] };
      item.suppliers.map((supplier) => {
        suppliers.texts.push({ text: supplier });
      });
      let cells = [
        item.productCode,
        item.productCode,
        item.description,
        suppliers,
        item.totalQty.toLocaleString(),
        item.avgUnitValue.toLocaleString(),
        item.minUnitValue.toLocaleString(),
        item.maxUnitValue.toLocaleString(),
        item.units,
      ];

      let tmpCell = {};
      tmpCell['id'] = item.productCode;
      headCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getProductsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generateProductsPDF(
            result.data.items,
            'Products     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Products Report'
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getProductsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateProductsCsvData(result.data.items, filter.accountName + '_' + filter.supplierName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleOverviewClick = (event) => {
    let selectedProductCode = event.currentTarget.parentNode.dataset.tag;
    let selectedProduct = products.find((x) => x.productCode === selectedProductCode);
    setSelectedProductCode(selectedProductCode);
    setSelectedProductName(selectedProduct.description);
    setModalOpen(true);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <EntityListFrame>
        {enabledProducts ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Products</h4>
              </Grid>
              <Grid item xs={12} sm className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableFilter sendFilterComponentData={filterCallback} sendQueryStringsNoFreeTextSearch={setBaseQueryString} showSuppliers={true} />
            </Grid>
            <Divider />
            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  rowActionComponent={
                    <Tooltip title="Product Overview" placement="left">
                      <IconButton aria-label="edit" onClick={handleOverviewClick}>
                        <DonutLargeIcon size="small" className={classes.overviewIcon} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
      <Modal className={classes.modalContainer} open={modalOpen} onClose={handleModalClose}>
        <div className={classes.modalContentContainer}>
          <ProductOverview
            accountId={accountId}
            productCode={selectedProductCode}
            productName={selectedProductName}
            onClose={handleModalClose}
            settings={productOverviewSettings}
          />
        </div>
      </Modal>
    </>
  );
}

export default withRouter(ProductsTable);
