import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid, Divider, TextField, Card, CardContent, Snackbar, CardActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LoadingBar from 'react-top-loading-bar';
import { useHistory, useParams } from 'react-router';
import apiService from '../../../services/api.service';
import { UserContext } from '../../../contexts/UserContext';
import clsx from 'clsx';
import Button from '../../../wrappers/Button';
import Alert from '../../../wrappers/Alert';
import { darkTheme } from '../../../themes/darkTheme';
import { lightThemeAdmin } from '../../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: '-8px',
    marginBottom: theme.spacing(2),
  },
  card: {
    boxShadow: '0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)',
    padding: theme.spacing(3),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
}));

function SetUserPassword(props) {
  let theme = parseInt(localStorage.getItem('theme')) === 1 ? lightThemeAdmin : darkTheme;
  const [username, setUsername] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [displayPasswordError, setDisplayPasswordError] = useState('');
  const [displayConfirmPasswordError, setDisplayConfirmPasswordError] = useState('');
  const [userContext] = useContext(UserContext);
  const history = useHistory();
  const progressRef = useRef(null);
  const classes = useStyles();
  const { id } = useParams();
  const [snackbar, setSnackbar] = useState({
    open: false,
    isSuccess: false,
    message: '',
  });

  useEffect(() => {
    if (id && userContext.isAdmin && userContext.permissions.canManageUsers) {
      progressRef.current.continuousStart();
      apiService
        .getUser(id)
        .then((userResult) => {
          setUsername(userResult.data.username);
          setUserFullName(userResult.data.firstname + ' ' + userResult.data.lastname);
          if (progressRef && progressRef.current) progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else {
      history.push('/users');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    cleanErrors();
    let error = false;

    if (!password || password.trim() === '') {
      setDisplayPasswordError('Password is a required field');
      error = true;
    }
    if (!confirmPassword || confirmPassword.trim() === '') {
      setDisplayConfirmPasswordError('Confirm password is a reuqired field');
      error = true;
    }

    if (!error) {
      if (password.trim() !== confirmPassword.trim()) {
        setDisplayConfirmPasswordError('Password and Confirm password does not match');
        error = true;
      }
    }

    if (!error) {
      progressRef.current.continuousStart();
      apiService
        .setUserPassword(id, password, confirmPassword)
        .then((result) => {
          if (result.errors && result.errors.length > 0) {
            var errorString = '';
            for (let error of result.errors) {
              if (error.propertyName === 'Password') {
                setDisplayPasswordError(error.errorMessage);
              } else if (error.propertyName === 'ConfirmPassword') {
                setDisplayConfirmPasswordError(error.errorMessage);
              } else {
                if (error.errorMessage) {
                  errorString += ' ' + error.errorMessage + ',';
                } else {
                  errorString += ' ' + error + ',';
                }
              }
            }
            if (errorString) {
              setSnackbar({
                open: true,
                isSuccess: false,
                message: errorString,
              });
            }
          } else {
            setSnackbar({
              open: true,
              isSuccess: true,
              message: `Password updated successfully`,
            });
          }

          progressRef.current.complete();
        })
        .catch((err) => {
          console.log('error: ', err);
          if (progressRef && progressRef.current) progressRef.current.complete();
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }
  };

  const cleanErrors = () => {
    setDisplayPasswordError('');
    setDisplayConfirmPasswordError('');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    let snackbartemp = { ...snackbar };
    snackbartemp.open = false;
    setSnackbar(snackbartemp);
  };

  return (
    <>
      <LoadingBar color={theme.loadingBar.color} ref={progressRef} height="4px" />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert theme={theme} onClose={handleSnackbarClose} severity="error" severity={snackbar.isSuccess ? 'success' : 'error'}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form autoComplete="off" onSubmit={handleSubmit} noValidate>
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4>Set password to '{userFullName}'</h4>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Username (Email)" margin="dense" type="email" name="email" value={username || ''} disabled={true} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  margin="dense"
                  type="password"
                  name="name"
                  error={!(displayPasswordError === '')}
                  helperText={displayPasswordError === '' ? '' : displayPasswordError}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  value={password || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  margin="dense"
                  type="password"
                  name="confirm"
                  error={!(displayConfirmPasswordError === '')}
                  helperText={displayConfirmPasswordError === '' ? '' : displayConfirmPasswordError}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  value={confirmPassword || ''}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions className={classes.cardActions}>
            <Button theme={theme} buttonType="primary" type="submit" className={classes.buttons}>
              Save
            </Button>
            <Button theme={theme} buttonType="cancel" className={clsx(classes.buttons, classes.buttonRight)} onClick={() => history.goBack()}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
}

export default SetUserPassword;
