import { Checkbox, Divider, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import apiService from '../../../../services/api.service';
import TitleFont from '../../../Typography/TitleFont';

export default function SuppliersTrendFilter(props) {
  const { Suppliers, Title, OnSelectedSuppliers, RootClassName } = props;

  const supplierFilterSections = { all: 0, specialFilter: 1, suppliers: 2 };
  const maximumSelectedSuppliers = 10;

  const [changesSelectedSupplierValues, setChangesSelectedSupplierValues] = useState(false);
  const [specialSuppliersFilters, setSpecialSuppliersFilters] = useState();
  const [selectedSupplierValues, setSelectedSupplierValues] = useState(['All']);
  const [selectedAllSuppliers, setSelectedAllSuppliers] = useState(true);

  const [sendBackSelectedSupplierValue, setSendBackSelectedSupplierValue] = useState();

  useEffect(() => {
    const loadData = async () => {
      var specialSupplierFilters = await apiService.getSpecialSuppliersTrendFilters();
      setSpecialSuppliersFilters(specialSupplierFilters.data);
    };

    if (Suppliers) {
      loadData();
      setSelectedSupplierValues(['All']);
      setSelectedAllSuppliers(true);
      setSendBackSelectedSupplierValue({
        supplierIds: '',
        supplierNames: 'All Suppliers',
        showAllSuppliers: true,
        specialFilterOption: undefined,
      });
    }
  }, [Suppliers]);

  useEffect(() => {
    if (sendBackSelectedSupplierValue && OnSelectedSuppliers) {
      OnSelectedSuppliers(sendBackSelectedSupplierValue);
    }
  }, [sendBackSelectedSupplierValue]);

  const handleSupplierValuesChange = (e) => {
    var values = e.target.value;
    var selectedSuppliers = [];
    if (values.indexOf('Reset') > -1 || values.length == 0) {
      setSelectedAllSuppliers(false);
    } else {
      var lastValue = values[values.length - 1];
      if (lastValue.filterOption !== undefined) {
        //specialFilter type
        //select 1 at the time
        setSelectedAllSuppliers(false);
        selectedSuppliers = [lastValue];
      } else if (lastValue.id !== undefined) {
        //supplier type
        setSelectedAllSuppliers(false);
        selectedSuppliers = values.filter((x) => x.id !== undefined);
      } else if (lastValue === 'All') {
        setSelectedAllSuppliers(true);
        selectedSuppliers.push('All');
      }
    }

    setSelectedSupplierValues(selectedSuppliers);
    setChangesSelectedSupplierValues(true);
  };

  const handleSupplierValuesClose = (e) => {
    if (changesSelectedSupplierValues === true) {
      setChangesSelectedSupplierValues(false);

      let selectedSupplierIds = GetSelectedSupplierIds();
      let supplierNames = GetSelectedSupplierNames();
      let supplierNamesArray = GetSelectedSupplierNamesArray();
      let specialFilterOption = GetSelectedSpecialFilterOption();

      var sendBackValue = {
        supplierIds: selectedSupplierIds,
        supplierNames: supplierNames,
        supplierNamesArray: supplierNamesArray,
        showAllSuppliers: selectedAllSuppliers,
        specialFilterOption: specialFilterOption,
      };

      setSendBackSelectedSupplierValue(sendBackValue);
    }
  };

  function GetSelectedSupplierNames() {
    return GetSelectedSupplierNamesArray().join(', ');
  }

  function GetSelectedSupplierNamesArray() {
    var supplierNames = [];
    if (selectedAllSuppliers == true) {
      supplierNames.push('All Suppliers');
    } else {
      selectedSupplierValues.forEach((supplier) => {
        supplierNames.push(supplier.name);
      });
    }
    return supplierNames;
  }

  function GetSelectedSupplierIds() {
    var supplierIds = [];
    selectedSupplierValues.forEach((item) => {
      if (item.id !== undefined) {
        supplierIds.push(item.id);
      }
    });
    return supplierIds.join(', ');
  }

  function GetSelectedSpecialFilterOption() {
    let selectedSpecialFilter = selectedSupplierValues.find((x) => x.filterOption !== undefined);
    if (selectedSpecialFilter) {
      return selectedSpecialFilter.filterOption;
    } else {
      return undefined;
    }
  }

  function disableMenuItem(supplierItem) {
    return selectedSupplierValues.length >= maximumSelectedSuppliers && selectedMenuItem(supplierItem) == false;
  }

  function selectedMenuItem(menuItem, supplierFilterSection) {
    if (supplierFilterSection && supplierFilterSection === supplierFilterSections.specialFilter) {
      return selectedSupplierValues.find((x) => x.filterOption === menuItem.filterOption) != null;
    } else {
      return selectedSupplierValues.find((x) => x.id === menuItem.id) != null;
    }
  }

  return (
    <FormControl variant="outlined" className={RootClassName}>
      <InputLabel id="suppliers-select-label">
        <TitleFont>{Title}</TitleFont>
      </InputLabel>
      <Select
        labelId="suppliers-select-label"
        id="suppliers-select"
        multiple
        value={selectedSupplierValues}
        onChange={handleSupplierValuesChange}
        onClose={handleSupplierValuesClose}
        input={<Input />}
        renderValue={GetSelectedSupplierNames}
        autoWidth={true}
      >
        <MenuItem value="Reset">Reset</MenuItem>
        <Divider style={{ marginBottom: '10px' }}></Divider>
        <MenuItem value="All">
          <Checkbox checked={selectedAllSuppliers} />
          <ListItemText primary="All Suppliers" />
        </MenuItem>
        <Divider style={{ marginBottom: '10px' }}></Divider>
        <Typography variant="caption" align="center" spacing={2} className="pl-2">
          fixed filters
        </Typography>
        {specialSuppliersFilters &&
          specialSuppliersFilters.map((item) => (
            <MenuItem key={item.filterOption} value={item}>
              <Checkbox checked={selectedMenuItem(item, supplierFilterSections.specialFilter)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        <Divider style={{ marginBottom: '10px' }}></Divider>
        <Typography variant="caption" align="center" spacing={2} className="pl-2">
          maximum {maximumSelectedSuppliers} suppliers
        </Typography>
        {Suppliers &&
          Suppliers.map((item) => (
            <MenuItem key={item.id} value={item} disabled={disableMenuItem(item)}>
              <Checkbox checked={selectedMenuItem(item, supplierFilterSections.suppliers)} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
