import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0),
      width: theme.spacing(36),
      padding: theme.spacing(1),
      //height: theme.spacing(24),
    },
  },
  inline: {
    display: 'inline',
  },
  pussy: {
    messagetext: {
      color: '#ff0000',
    },
  },
  contentNotificationHeight: {
    height: '100%',
    maxHeight: '600px',
    overflow: 'auto',
  },
}));

const NotificationItem = ({ header, body, classes, showDivider }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText className={classes.pussy} primary={header} secondary={<span dangerouslySetInnerHTML={{ __html: body }}></span>} />
      </ListItem>
      {showDivider && <Divider component="li" />}
    </>
  );
};

export default function NotificationPopup(props) {
  const classes = useStyles();

  const messLength = props.messages.length;

  return (
    <div>
      <Paper elevation={3} className={classes.root}>
        <List className={classes.contentNotificationHeight}>
          {props.messages.map((item, i) => {
            return (
              <NotificationItem
                key={`notificationItem${i}`}
                header={item.messageHeader}
                body={item.messageBody}
                classes={classes}
                showDivider={i + 1 < messLength}
              />
            );
          })}
        </List>
      </Paper>
    </div>
  );
}
