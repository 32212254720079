import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Box } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';
import { lightTheme } from '../../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  chart: {
    position: 'relative',
    height: 80,
    maxWidth: 200,
    marginBottom: 0,
    padding: 0,
  },
}));

function LiveExchangeRateChart(props) {
  const classes = useStyles();
  const [userContext] = useContext(UserContext);
  const { ChartId, ChartData } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(ChartId, am4charts.XYChart);

    if (ChartData) {
      chart.logo.disabled = true;
      chart.paddingTop = 5;
      chart.paddingRight = 0;
      chart.paddingBottom = 5;
      chart.paddingLeft = 0;

      chart.data = prepareChartData(ChartData);

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = {
        timeUnit: 'day',
        count: 1,
      };
      dateAxis.renderer.labels.template.disabled = true;
      dateAxis.renderer.grid.template.disabled = true;
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.title.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;

      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.tooltipText = '[bold]{valueY}[/]';
      series.strokeWidth = 2;
      series.stroke = am4core.color(lightTheme.chart.colors[0]);
      series.fill = am4core.color(lightTheme.chart.colors[0]);

      dateAxis.keepSelection = true;
    }

    return () => {
      chart.dispose();
    };
  }, [ChartData]);

  

  const prepareChartData = (chartDataItems) => {
    let preparedChartData = chartDataItems.map((x) => {
      return {
        date: new Date(x.date),
        value: x.value,
      };
    });

    return preparedChartData;
  };

  return (
    <Box className={classes.root}>
      <div id={ChartId} className={classes.chart} />
    </Box>
  );
}

export default LiveExchangeRateChart;
