import React, { createContext, useEffect, useState } from 'react';

import apiService from 'src/services/api.service';

const userContextInitialState = {
  id: '',
  accountId: '',
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  hasFullPermissions: false,
  avatarExtension: '',
  themeColor: null,
  topBarColor: 0,
  permissions: {
    canManageUsers: false,
    canReadUsers: false,
    canManageCustomerAccounts: false,
    canReadCustomerAccounts: false,
    canManageVendorAccounts: false,
    canReadVendorAccounts: false,
    canManageExternalApplications: false,
    canReadExternalApplications: false,
    canReadFiles: false,
    canManageShipmentBookings: false,
    canReadShipmentBookings: false,
    canManageSuppliers: false,
    canReadSuppliers: false,
  },
  messages: [],
  buyerId: '',
  accountTypes: [],
};

const setUserContext = () => {};
const loaded = false;

export const UserContext = createContext([userContextInitialState, setUserContext, loaded]);

export const UserContextProvider = ({ children }) => {
  const [userContext, setUserContext] = useState(userContextInitialState);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        //Fetch UserContext
        if (!userContext || !userContext.id) {
          const result = await apiService.getMe();
          if (result) {
            setUserContext(result);
            localStorage.setItem('theme', result.themeColor);
          } else {
            setUserContext(...userContextInitialState);
            localStorage.setItem('theme', 0);
          }
        } else {
          localStorage.setItem('theme', 0);
        }
      } catch (ex) {
        console.log('User does not login');
      } finally {
        setLoaded(true);
      }
    };

    initialize();
  }, []);

  return <UserContext.Provider value={[userContext, setUserContext, loaded]}>{children}</UserContext.Provider>;
};
