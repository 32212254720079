import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Box, makeStyles } from '@material-ui/core';

import { lightTheme } from 'src/themes/lightTheme';

var targetSVG =
  'M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
  },
  map: {
    width: '100%',
    flexGrow: 1,
    borderBottomRightRadius: 8,
  },
  tips: {
    position: 'absolute',
    color: '#666',
    fontSize: '0.75rem',
    left: 5,
    bottom: 5,
  },
}));

export default function Map(props) {
  const { shipmentTrackingModel } = props;
  const [vesselPosition, setVesselPosition] = useState(null);
  const history = useHistory();
  const classes = useStyles();

  useLayoutEffect(() => {
    let chart = am4core.create('TrackAndTraceMap', am4maps.MapChart);

    if (shipmentTrackingModel && shipmentTrackingModel.ports) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      chart.logo.disabled = true;
      var title = chart.titles.create();
      title.textAlign = 'middle';

      // Set map definition
      chart.geodata = am4geodata_worldLow;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
      polygonSeries.exclude = ['AQ'];
      polygonSeries.useGeodata = true;
      polygonSeries.nonScalingStroke = true;
      polygonSeries.strokeWidth = 0.5;
      polygonSeries.calculateVisualCenter = true;

      let blinkPort = prepareBlinkDestinationPort(shipmentTrackingModel.ports);
      if (blinkPort && blinkPort.length > 0) {
        var blinkImageSeries = chart.series.push(new am4maps.MapImageSeries());
        var blinkTemplate = blinkImageSeries.mapImages.template;
        blinkTemplate.nonScaling = true;
        blinkTemplate.propertyFields.latitude = 'latitude';
        blinkTemplate.propertyFields.longitude = 'longitude';
        blinkTemplate.propertyFields.fill = 'color';
        blinkImageSeries.data = blinkPort;

        var portBlink = blinkImageSeries.mapImages.template.createChild(am4core.Circle);
        portBlink.radius = 3;
        portBlink.propertyFields.fill = 'color';

        portBlink.events.on('inited', function (event) {
          animateBullet(event.target);
        });
      }

      var imageSeries = chart.series.push(new am4maps.MapImageSeries());
      var imageTemplate = imageSeries.mapImages.template;
      imageTemplate.nonScaling = true;
      imageTemplate.tooltipText = '{title}';

      var marker = imageTemplate.createChild(am4core.Sprite);
      marker.path = targetSVG;
      marker.horizontalCenter = 'middle';
      marker.verticalCenter = 'middle';
      marker.scale = 1;

      imageTemplate.propertyFields.latitude = 'latitude';
      imageTemplate.propertyFields.longitude = 'longitude';
      imageTemplate.propertyFields.fill = 'color';
      imageSeries.data = prepareChartData(shipmentTrackingModel.ports);

      // Add lines
      var lineSeries = chart.series.push(new am4maps.MapLineSeries());
      lineSeries.dataFields.multiGeoLine = 'multiGeoLine';
      let geoLineColor = am4core.color(lightTheme.shipmentTracking.map.geoLineColor);

      var lineTemplate = lineSeries.mapLines.template;
      lineTemplate.nonScalingStroke = true;
      lineTemplate.arrow.nonScaling = true;
      lineTemplate.arrow.width = 4;
      lineTemplate.arrow.height = 6;
      lineTemplate.stroke = geoLineColor;
      lineTemplate.fill = geoLineColor;
      lineTemplate.line.strokeOpacity = 0.4;
      lineSeries.data = prepareGeoLineChartData(shipmentTrackingModel.ports);

      //Vessel
      if (vesselPosition) {
        var vesselImageSeries = chart.series.push(new am4maps.MapImageSeries());
        var vesselImageTemplate = vesselImageSeries.mapImages.template;
        vesselImageTemplate.nonScaling = true;
        vesselImageTemplate.tooltipText = `[bold]Status:[/] {status}
        [bold]Updated:[/] {timestampText}`;
        vesselImageTemplate.propertyFields.latitude = 'latitude';
        vesselImageTemplate.propertyFields.longitude = 'longitude';
        vesselImageTemplate.propertyFields.fill = 'color';
        vesselImageSeries.data = prepareVesselChartData(vesselPosition);

        var vesselImage = vesselImageTemplate.createChild(am4core.Image);
        vesselImage.propertyFields.href = 'imageURL';
        vesselImage.width = 20;
        vesselImage.height = 20;
        vesselImage.horizontalCenter = 'middle';
        vesselImage.verticalCenter = 'middle';
        vesselImage.moveTo(undefined, vesselPosition.headingDeg);

        var circleBlink = vesselImageSeries.mapImages.template.createChild(am4core.Circle);
        circleBlink.radius = 3;
        circleBlink.propertyFields.fill = 'color';

        circleBlink.events.on('inited', function (event) {
          animateBullet(event.target);
        });
      }

      function animateBullet(circle) {
        var animation = circle.animate(
          [
            { property: 'scale', from: 1, to: 6 },
            { property: 'opacity', from: 1, to: 0 },
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on('animationended', function (event) {
          animateBullet(event.target.object);
        });
      }
    }

    return () => {
      chart.dispose();
    };
  }, [shipmentTrackingModel, vesselPosition]);

  function prepareChartData(ports) {
    let originColor = am4core.color(lightTheme.shipmentTracking.map.originColor);
    let destinationColor = am4core.color(lightTheme.shipmentTracking.map.destinationColor);
    var transhipmentColors = [];
    for (var i = 0; i < lightTheme.shipmentTracking.map.transhipmentColors.length; i++) {
      transhipmentColors.push(am4core.color(lightTheme.shipmentTracking.map.transhipmentColors[i]));
    }

    let portsAsChartData = ports.map((port, index) => {
      let color;
      if (port.order == 1) {
        color = originColor;
      } else if (port.order == ports.length) {
        color = destinationColor;
      } else {
        color = transhipmentColors[index % transhipmentColors.length];
      }

      let blink = false;
      //Arrival
      if (port.order == ports.length && shipmentTrackingModel.currentMilestoneStep == 2) {
        blink = true;
      }

      return {
        svgPath: targetSVG,
        title: port.fullName,
        latitude: port.lat,
        longitude: port.long,
        scale: 0.5,
        color: color,
        blink: blink,
      };
    });

    return portsAsChartData;
  }

  function prepareVesselChartData(vesselPosition) {
    let timestamp = moment(vesselPosition.timestamp);
    return [
      {
        latitude: parseFloat(vesselPosition.lat),
        longitude: parseFloat(vesselPosition.lon),
        imageURL: '/images/boat.png',
        color: am4core.color(lightTheme.shipmentTracking.map.vessel),
        status: vesselPosition.status,
        timestampText: timestamp.fromNow(),
        speed: vesselPosition.speed,
      },
    ];
  }

  function prepareGeoLineChartData(ports) {
    let geoLines = [];
    for (let i = 1; i < ports.length; i++) {
      let origin = ports[i - 1];
      let destination = ports[i];
      let geoLine = {
        multiGeoLine: [
          [
            { latitude: origin.lat, longitude: origin.long },
            { latitude: destination.lat, longitude: destination.long },
          ],
        ],
      };
      geoLines.push(geoLine);
    }
    return geoLines;
  }

  function prepareBlinkDestinationPort(ports) {
    let destinationColor = am4core.color(lightTheme.shipmentTracking.map.destinationColor);

    if (shipmentTrackingModel.currentMilestoneStep == 2) {
      let destinationPort = ports[ports.length - 1];
      return [
        {
          latitude: destinationPort.lat,
          longitude: destinationPort.long,
          color: destinationColor,
        },
      ];
    } else {
      return [];
    }
  }

  return (
    <Box className={classes.root}>
      <div id="TrackAndTraceMap" className={classes.map}></div>
      <Box className={classes.tips}>To zoom in/out, use the mouse scroll wheel</Box>
    </Box>
  );
}
