import { ListItem, ListItemIcon, makeStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import MenuFont from '../Typography/MenuFont';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: '#fff',
    paddingLeft: theme.spacing(3),
    '&:hover': {
      color: '#fff',
    },
  },
  listItemIcon: {
    color: theme.palette.menuDrawer.text,
  },
  menuFont: {
    fontSize: '1rem',
  },
}));

const DrawerMenuItem = (props) => {
  const classes = useStyles();
  const { title, tooltipTitle, linkTo, iconComponent } = props;
  return (
    <Tooltip title={tooltipTitle ? tooltipTitle : title} placement="left">
      <ListItem button component={Link} to={linkTo} className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>{iconComponent}</ListItemIcon>
        <MenuFont className={classes.menuFont}>{title}</MenuFont>
      </ListItem>
    </Tooltip>
  );
};

export default DrawerMenuItem;
