import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import DeleteDialog from 'src/components/parts/DeleteDialog';
import EntityTable from 'src/components/parts/EntityTable';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import useGlobalSnackBar from 'src/hooks/useGlobalSnackBar';
import vendorAccountApiService from 'src/services/vendorAccount.api.service';

const headCells = [
  { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'VendorNumbers', numeric: false, disablePadding: false, label: 'Vendor Numbers' },
  { id: 'ContactEmail', numeric: false, disablePadding: false, label: 'Contact Email' },
  { id: 'CreatedDate', numeric: true, disablePadding: false, label: 'Created Date' },
  { id: 'UpdatedDate', numeric: true, disablePadding: false, label: 'Updated Date' },
];

function VendorAccounts(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState('');
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [dummyCounter, setDummyCounter] = React.useState(0);
  const [userContext] = useContext(UserContext);
  const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
    show: false,
    id: '0',
  });
  const [reloadContext] = useContext(ReloadContext);

  const history = useHistory();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const { showSuccessSnackBar, showErrorSnackBar } = useGlobalSnackBar();

  useEffect(() => {
    startProgress();
    vendorAccountApiService
      .getVendorAccounts(page + 1, rowsPerPage, order, orderBy)
      .then((result) => {
        setRows(createTableListData(result.data.list));
        setTotalNumberOfRows(result.data.totalItems);
        stopProgress();
      })
      .catch(catchApiError);
  }, [order, orderBy, rowsPerPage, page, dummyCounter, reloadContext]);

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let c = moment(item.createdDate);
      let u = moment(item.updatedDate);
      newArray.push([item.id, item.name, item.vendorNumbers, item.contactEmail, c.format('L'), u.format('L')]);
    });
    return newArray;
  };

  const handleDeleteRequest = (event) => {
    setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
  };

  const handleDelete = (id) => {
    vendorAccountApiService
      .deleteVendorAccount(id)
      .then((result) => {
        showSuccessSnackBar('The vendor account was deleted successfully');
        setDummyCounter(dummyCounter + 1);
      })
      .catch(catchApiError);
  };

  const catchApiError = (error) => {
    stopProgress();
    console.log('error: ', error);
    if (error.response && error.response.status === 401) {
      history.push('/login');
    } else {
      showErrorSnackBar();
    }
  };

  const handleAdd = () => {
    history.push('/accounts/vendor/add');
  };

  const handleEdit = (event) => {
    history.push('/accounts/vendor/' + event.currentTarget.dataset.tag + '/edit');
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setRowsPerPage(newValue);
    setPage(0);
  };

  return (
    <>
      {rows && (
        <EntityTable
          title="Vendor Accounts"
          headCells={headCells}
          totalNumberOfRows={totalNumberOfRows}
          rows={rows}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleAdd={userContext.isAdmin && userContext.permissions.canManageVendorAccounts && handleAdd}
          handleEdit={userContext.isAdmin && userContext.permissions.canManageVendorAccounts && handleEdit}
          deleteHandler={userContext.isAdmin && userContext.permissions.canManageVendorAccounts && handleDeleteRequest}
        />
      )}
      <DeleteDialog
        action={pendingDeleteAction}
        handleDelete={handleDelete}
        title="Delete Vendor?"
        body="Are you sure you want to delete this vendor with all its data? Once deleted it cannot be undone."
      />
    </>
  );
}

export default VendorAccounts;
