import clsx from 'clsx';
import React from 'react';

import { TextField, withStyles } from '@material-ui/core';

import useDelayChangedText from 'src/hooks/useDelayChangedText';

export const EntryTextField = withStyles((theme) => ({
  root: {
    '&.readOnly': {
      marginTop: 3,
      '& input': {
        backgroundColor: 'transparent',
      },
    },
    '&.warning': {
      '& label': {
        color: theme.palette.inputControl.warning,
      },
      '& p': {
        color: theme.palette.inputControl.warning,
      },
      '& input + fieldset, & textarea + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 1,
      },
      '& input:hover + fieldset, & textarea:hover + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 1,
      },
      '& input:focus + fieldset, & textarea:focus + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 2,
      },
    },
    '&.active': {
      '& input': {
        color: 'rgba(0,0,0,0.8) !important',
      },
    },
    '& label': {
      fontSize: '0.875rem',
    },
    '& input': {
      backgroundColor: 'white',
    },
  },
}))((props) => {
  const { readOnly, active = true, className, helperTextStatus, error, onDelayValueChange, onValueChange, ...remainingProps } = props;
  let isWarning = false;
  let isError = error;
  if (helperTextStatus == 'warning') {
    isWarning = true;
  } else if (helperTextStatus == 'error') {
    isError = true;
  }

  const { handleValueTextChange, handleOnKeyPress } = useDelayChangedText(onDelayValueChange, onValueChange);

  return readOnly ? (
    <TextField
      className={clsx('readOnly', className, active && 'active', !isError && isWarning && 'warning')}
      fullWidth
      margin="dense"
      disabled={true}
      error={isError}
      {...remainingProps}
    />
  ) : (
    <TextField
      className={clsx(className, !isError && isWarning && 'warning')}
      fullWidth
      variant="outlined"
      margin="dense"
      disabled={false}
      error={isError}
      onChange={handleValueTextChange}
      onKeyPress={handleOnKeyPress}
      {...remainingProps}
    />
  );
});
