import React, { useEffect, useState } from 'react';
import AmountBox from './ChartTemplates/AmountBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function TotalQtyBox(props) {
  const { accountId, productCode } = props;
  const [value, setValue] = useState('-');
  const [unit, setUnit] = useState('');
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      apiService
        .getTotalQtyProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.unit ? setUnit(result.data.unit) : setUnit('');
            result.data.value ? setValue(result.data.value) : setValue('-');
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  return (
    <AmountBox title="Total Qty" valueText={`${value.toLocaleString()} ${unit}`} color="info" iconComponent={<FontAwesomeIcon icon={faCubes} size="lg" />} />
  );
}
