import React from 'react';

import { Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
  },
}));

export const ImportantNoticeSection = () => {
  const classes = useStyles();

  const importantNotice =
    'If the goods contain any unconventional goods, or goods containing liquid, battery, powder, gas, and other chemical products, it must be mentioned in detail in your booking and provide relevant MSDS to further confirm whether they are suitable for loading. Otherwise, all consequences will be borne by the shipper. Thank you for your understanding and cooperation!';

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="overline">Important Notice</Typography>
            <Typography variant="body2">
              <i>{importantNotice}</i>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
