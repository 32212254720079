import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '../../../util/extensions';
import ContentFont from '../../Typography/ContentFont';
import LoadingOverlay from '../../parts/LoadingOverlay';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { UserContext } from '../../../contexts/UserContext';
import { lightTheme } from '../../../themes/lightTheme';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  chart: {
    position: 'relative',
    height: 80,
    maxWidth: 200,
    marginBottom: 0,
    padding: 0,
    // borderRadius: 5,
    // backgroundColor: '#FFF',
  },
}));

function StockIndexesChart(props) {
  const classes = useStyles();
  const { ChartId, ChartData } = props;

  useLayoutEffect(() => {
    let chart = am4core.create(ChartId, am4charts.XYChart);

    if (ChartData && ChartData.prices) {
      chart.logo.disabled = true;
      //chart.paddingRight = 20;

      chart.paddingTop = 0;
      chart.paddingRight = 0;
      chart.paddingBottom = 0;
      chart.paddingLeft = 0;

      chart.data = prepareChartData(ChartData.prices, ChartData.startDate, ChartData.endDate, ChartData.previousClosePrice);

      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.baseInterval = {
        timeUnit: 'minute',
        count: 15,
      };
      dateAxis.renderer.labels.template.disabled = true;
      dateAxis.renderer.grid.template.disabled = true;

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.title.disabled = true;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.baseValue = ChartData.previousClosePrice;
      valueAxis.min = ChartData.minPrice;
      valueAxis.max = ChartData.maxPrice;

      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'date';
      series.dataFields.valueY = 'value';
      series.strokeWidth = 2;
      series.stroke = am4core.color('#26A69A');
      series.fill = am4core.color('#26A69A');
      // series.fill = am4core.color(lightTheme.chart.colors[0]);
      series.fillOpacity = 0.2;

      var baseLine = valueAxis.axisRanges.create();
      baseLine.value = ChartData.previousClosePrice;
      baseLine.grid.stroke = am4core.color('#396478');
      baseLine.grid.strokeWidth = 1;
      baseLine.grid.strokeOpacity = 1;

      // Create a range to change stroke for values below 0
      var range = valueAxis.createSeriesRange(series);
      range.value = ChartData.previousClosePrice;
      range.endValue = 0;
      range.contents.stroke = am4core.color('#f22b2b');
      range.contents.fill = range.contents.stroke;
      range.contents.strokeOpacity = 0.7;
      range.contents.fillOpacity = 0.2;

      dateAxis.keepSelection = true;
    }

    return () => {
      chart.dispose();
    };
  }, [ChartData]);

  const prepareChartData = (chartDataItems, startDate, endDate, previousClosePrice) => {
    let preparedChartData = chartDataItems.map((x) => {
      return {
        date: new Date(x.date),
        value: x.value,
      };
    });

    preparedChartData = [
      { date: new Date(startDate), value: undefined },
      ...preparedChartData,
      { date: new Date(endDate), value: undefined },
    ];


    return preparedChartData;
  };

  return (
    <Box className={classes.root}>
      <div id={ChartId} className={classes.chart} />
    </Box>
  );
}

export default StockIndexesChart;
