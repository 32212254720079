class FormErrorFactory {
  createErrors = (apiErrors) => {
    if (apiErrors && apiErrors.length) {
      let errors = {};
      let errorString = '';
      for (let error of apiErrors) {
        if (error.propertyName) {
          errors[error.propertyName] = error.errorMessage;
        } else {
          errorString += error.errorMessage;
        }
      }
      return { errors, errorString };
    }
    return null;
  };

  createErrorText = (apiErrors) => {
    if (apiErrors && apiErrors.length) {
      var errorString = '';
      for (var i = 0; i < apiErrors.length; i++) {
        if (apiErrors[i].errorMessage) {
          errorString += ' ' + apiErrors[i].errorMessage + ',';
        } else {
          errorString += ' ' + apiErrors[i] + ',';
        }
      }
      return errorString;
    }
    return null;
  };
}

export default new FormErrorFactory();
