import clsx from 'clsx';
import React from 'react';

import { createMuiTheme, makeStyles } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '0.875rem',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    '&.warning': {
      '& label': {
        color: theme.palette.inputControl.warning,
      },
      '& p': {
        color: theme.palette.inputControl.warning,
      },
      '& input + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 1,
      },
      '& input:hover + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 1,
      },
      '& input:focus + fieldset': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 2,
      },
    },
  },
}));

function EntryDatePicker(props) {
  const { helperTextStatus, ...remainingProps } = props;
  const classes = useStyles();

  let isWarning = false;
  let isError = false;
  if (helperTextStatus == 'warning') {
    isWarning = true;
  } else if (helperTextStatus == 'error') {
    isError = true;
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <DatePicker
        className={clsx(classes.root, !isError && isWarning && 'warning')}
        fullWidth
        autoOk
        margin="dense"
        format="L"
        variant="inline"
        inputVariant="outlined"
        error={isError}
        {...remainingProps}
      />
    </ThemeProvider>
  );
}

export default EntryDatePicker;
