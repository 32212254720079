import React, { useLayoutEffect, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../Card/Card.js';
import CardHeader from '../../../Card/CardHeader.js';
import CardBody from '../../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartLogo from '../../../parts/ChartLogo';
import { lightTheme } from '../../../../themes/lightTheme';
import ContentFont from '../../../Typography/ContentFont';
import LoadingOverlay from '../../../parts/LoadingOverlay.js';
import PropTypes from 'prop-types';
import ChartNoData from '../../../parts/ChartNoData.js';
import { format } from 'react-string-format';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 300,
    paddingTop: 40,
  },
  chartTitle: {
    height: 125,
  },
  chartTitleNoData: {
    height: 70,
  },
}));

export default function PieChart(props) {
  const classes = useStyles();
  const { title, chartData, isLoading, chartId, tooltipValueFormat } = props;
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShowNoData(false);
    } else {
      if (chartData && chartData.length > 0) {
        setShowNoData(false);
      } else {
        setShowNoData(true);
      }
    }
  }, [chartData, isLoading]);

  useLayoutEffect(() => {
    var chart;

    if (chartData) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      // Create chart instance
      chart = am4core.create(chartId, am4charts.PieChart);
      chart.logo.disabled = true;

      chart.data = chartData;

      // Add label
      chart.innerRadius = 50;
      chart.radius = 100;
      let label = chart.seriesContainer.createChild(am4core.Label);

      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = 11;

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.colors.list = colorList;
      pieSeries.dataFields.value = 'amount';
      pieSeries.dataFields.category = 'name';
      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);
      pieSeries.labels.template.adapter.add('text', function (text, target) {
        if (target.dataItem && target.dataItem.value == 0) {
          return '';
        } else {
          return text;
        }
      });
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.stroke = am4core.color('#fff');

      if (tooltipValueFormat) {
        pieSeries.tooltip.numberFormatter.numberFormat = format(tooltipValueFormat, '#,###.##');
      } else {
        pieSeries.tooltip.numberFormatter.numberFormat = '#,###.##';
      }

      pieSeries.ticks.template.disabled = true;

      pieSeries.fontSize = 11;
    }
    return () => {
      if (chart) chart.dispose();
    };
  }, [chartData, showNoData]);

  return (
    <Card chart>
      <LoadingOverlay IsLoading={isLoading} />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>
          {showNoData ? <ChartNoData text="There is no shipment data on this Product." /> : <div id={chartId} className={classes.chart}></div>}
        </ContentFont>
      </CardHeader>
      <CardBody className={clsx(classes.chartTitle, 'dashboard-chart-title-area', { [classes.chartTitleNoData]: showNoData })}>
        <h4>{title}</h4>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

PieChart.propTypes = {
  chartId: PropTypes.string,
  title: PropTypes.string,
  chartData: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, amount: PropTypes.number })),
  isLoading: PropTypes.bool,
  tooltipValueFormat: PropTypes.string,
};
