import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import DataTableFilter from 'src/components/parts/DataTableFilter/DataTableFilter';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

const defaultHeadCells = [
  {
    id: 'ShipmentNumbersText',
    numeric: false,
    disablePadding: true,
    label: 'Shipment No.(s)',
  },
  {
    id: 'Origin',
    numeric: false,
    disablePadding: false,
    label: 'Origin',
  },
  {
    id: 'Destination',
    numeric: false,
    disablePadding: false,
    label: 'Destination',
  },
  {
    id: 'Trans',
    numeric: false,
    disablePadding: false,
    label: 'Trans',
  },
  {
    id: 'Mode',
    numeric: false,
    disablePadding: false,
    label: 'Mode',
  },
  {
    id: 'OriginETD',
    numeric: false,
    disablePadding: false,
    label: 'Origin ETD',
  },
  {
    id: 'OriginATD',
    numeric: false,
    disablePadding: false,
    label: 'Origin ATD',
  },
  {
    id: 'DestinationETA',
    numeric: false,
    disablePadding: false,
    label: 'Destination ETA',
  },
  {
    id: 'DestinationATA',
    numeric: false,
    disablePadding: false,
    label: 'Destination ATA',
  },
  {
    id: 'CarrierName',
    numeric: false,
    disablePadding: false,
    label: 'Carrier Name',
  },
  {
    id: 'CarrierBookingReference',
    numeric: false,
    disablePadding: false,
    label: 'Carrier Booking Ref.',
  },
  {
    id: 'TotalFreightSpend',
    numeric: true,
    disablePadding: false,
    label: 'Freight Spend ($)',
  },
  {
    id: 'CommercialValue',
    numeric: true,
    disablePadding: false,
    label: 'Commercial Value ($)',
  },
  {
    id: 'TotalSpends',
    numeric: true,
    disablePadding: false,
    label: 'Total Spends ($)',
  },
  {
    id: 'Teu',
    numeric: true,
    disablePadding: false,
    label: 'TEU',
  },
  {
    id: 'ContainerNumbersText',
    numeric: false,
    disablePadding: false,
    label: 'Container Numbers',
  },
];

function ConsolidatedShipmentsSpendsTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('ShipmentNumbersText');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [baseQueryString, setBaseQueryString] = React.useState();

  const { enabledConsolidatedShipmentsSpendsFeature } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [headCellsSelect, setHeadCellsSelect] = useState();

  useEffect(() => {
    if (filter) {
      startProgress();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getConsolidatedShipmentsSpendsTable(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers
      )
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    var otherQueryString = baseQueryString;
    if (otherQueryString) {
      otherQueryString = '&' + otherQueryString;
    }

    items.map((item, index) => {
      let etd = moment(item.originETD);
      let atd = moment(item.originATD);
      let eta = moment(item.destinationETA);
      let ata = moment(item.destinationATA);

      var shipmentNumberLinks = { component: 'linkList', links: [] };
      if (item.shipmentNumbers) {
        item.shipmentNumbers.map((number) => {
          shipmentNumberLinks.links.push({
            text: number,
            path: '/shipments?s=' + number + otherQueryString,
          });
        });
      }

      var containerNumbers = { component: 'tags', texts: [] };
      if (item.containerNumbers) {
        item.containerNumbers.map((number) => {
          containerNumbers.texts.push({ text: number });
        });
      }

      var supplierNames = { component: 'textList', texts: [] };
      if (item.supplierNames) {
        item.supplierNames.map((supplierName) => {
          supplierNames.texts.push({ text: supplierName });
        });
      }

      let cells = [
        index,
        shipmentNumberLinks,
        item.origin,
        item.destination,
        item.trans,
        item.mode,
        etd.isValid() ? etd.format('L') : '',
        atd.isValid() ? atd.format('L') : '',
        eta.isValid() ? eta.format('L') : '',
        ata.isValid() ? ata.format('L') : '',
        item.carrierName,
        item.carrierBookingReference,
        item.totalFreightSpend && item.totalFreightSpend.toLocaleString(),
        item.commercialValue && item.commercialValue.toLocaleString(),
        item.totalSpends && item.totalSpends.toLocaleString(),
        item.teu,
        containerNumbers,
      ];

      let tmpCell = {};
      tmpCell['id'] = cells[0];
      defaultHeadCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });
    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    //getData();
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };
  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getConsolidatedShipmentsSpendsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generateConsolidatedShipmentsSpendsPDF(
            result.data.items,
            'Shipments Spends     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Shipments Spends Report'
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getConsolidatedShipmentsSpendsTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateConsolidatedShipmentsSpendsCsvData(result.data.items, filter.accountName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleColumnSelected = (headCells) => {
    setHeadCellsSelect(headCells);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <EntityListFrame>
        {enabledConsolidatedShipmentsSpendsFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Shipments Spends</h4>
              </Grid>
              <Grid item className={'align-self-center '}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} />
              </Grid>
              <Grid item className={'align-self-center '}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableFilter sendFilterComponentData={filterCallback} sendQueryStringsNoFreeTextSearch={setBaseQueryString} showSuppliers={false} />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCellsSelect}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(ConsolidatedShipmentsSpendsTable);
