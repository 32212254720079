import React, { useEffect, useState } from 'react';
import AmountBox from './ChartTemplates/AmountBox';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function AverageUnitFreightSpendBox(props) {
  const { accountId, productCode } = props;
  const [value, setValue] = useState('-');
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData = () => {
      apiService
        .getAverageUnitFreightSpendProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            setValue(result.data.value);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (accountId && productCode) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  return <AmountBox title="Avg. Unit Freight Spend" valueText={`$ ${value.toLocaleString()} `} color="danger" iconComponent={<MonetizationOnIcon />} />;
}
