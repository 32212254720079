import React, { useEffect, useState } from 'react';
import AmountBox from './ChartTemplates/AmountBox';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';

export default function AverageTransitBox(props) {
  const { productCode, accountId } = props;
  const [value, setValue] = useState('-');
  const history = useHistory();

  useEffect(() => {
    let isCancelled = false;

    const loadData =  () => {
      apiService
        .getAverageTransitTimeProductOverviewReport(accountId, productCode)
        .then((result) => {
          if (!isCancelled) {
            result.data.value ? setValue(result.data.value) : setValue('-');
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    };

    if (productCode && accountId) {
      loadData();
    }

    return () => {
      isCancelled = true;
    };
  }, [accountId, productCode]);

  return (
    <AmountBox
      title="Avg. Transit Time"
      valueText={`${value.toLocaleString(undefined, {
        maximumFractionDigits: 1,
      })} Days`}
      color="warning"
      iconComponent={<AccessTimeIcon />}
    />
  );
}
