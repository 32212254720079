import apiHelperService from './api.helper.service';

class PublicShipmentTrackingApiService {
  constructor() {
    this.urlPrefix = '/api/v1/public-shipment-tracking';
  }

  searchPublicShipmentTrackings = (accountId, searchText, skip, take) => {
    return apiHelperService.anonymousGet(`${this.urlPrefix}/query/search`, {
      params: {
        accountId: accountId,
        searchText: searchText,
        skip: skip,
        take: take,
      },
    });
  };

  getPublicShipmentTrackingById = (accountId, shipmentTrackingId) => {
    return apiHelperService.anonymousGet(`${this.urlPrefix}/query/get-shipment-tracking-by-id`, {
      params: {
        accountId: accountId,
        shipmentTrackingId: shipmentTrackingId,
      },
    });
  };

  hasPublicTrackAndTraceEnabled = (accountId) => {
    return apiHelperService.anonymousGet(`${this.urlPrefix}/query/has-public-track-and-trace-enabled` + '?accountId=' + accountId);
  };
}

export default new PublicShipmentTrackingApiService();
