import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';

import ConfirmDialog from 'src/components/parts/ConfirmDialog';
import DeleteDialog from 'src/components/parts/DeleteDialog';
import LoadingOverlay from 'src/components/parts/LoadingOverlay';
import useAccounts from 'src/hooks/useAccounts';
import useQuery from 'src/hooks/useQuery';
import shipmentBookingStatus from 'src/models/ShipmentBookingStatus';
import MyButton from 'src/wrappers/Button';

import { AddressSelection } from './AddressSelection';
import { AttachedOrdersSection } from './AttachedOrdersSection/AttachedOrdersSection';
import { ImportantNoticeSection } from './ImportantNoticeSection';
import { ShippingDetailsEntrySection } from './ShippingDetailsEntrySection';
import { StatesSection } from './StatesSection';
import useShipmentBookingEntry from './hooks/useShipmentBookingEntry';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  headerBox: {
    height: '100%',
    display: 'flex',
  },
  headerButton: {
    alignSelf: 'baseline',
    marginLeft: theme.spacing(2),
  },
}));

export default function ShipmentBookingEntry(props) {
  const classes = useStyles();
  const history = useHistory();
  const [confirmDialogAction, setConfirmDialogAction] = useState({});
  const [discardDialogAction, setDiscardDialogAction] = useState({});

  let { id } = useParams();
  const query = useQuery();
  const { accountId } = useAccounts();
  const {
    messages,
    shipperSelectionItems,
    receiverSelectionItems,
    shipper,
    receiver,
    initialized,
    readOnly,
    bookingNumber,
    isSubmitting,
    states,
    setShipper,
    setReceiver,
    initialize,
    saveDraft,
    loadExistingData,
    submit,
    changeFormToEditMode,
    discardDraft,
    addPurchaseOrder,
  } = useShipmentBookingEntry();

  useEffect(() => {
    if (accountId) {
      console.log('ShipmentBookingEntry initialize', accountId, 'shipment booking id', id);
      initialize(accountId, id);
    }
  }, [accountId]);

  useEffect(() => {
    if (initialized) {
      if (id) {
        console.log('ShipmentBookingEntry loadExistingData', accountId, 'shipment booking id', id);
        loadExistingData(accountId, id);
      } else {
        let orderNumber = query.get('orderNumber');
        if (orderNumber) {
          addPurchaseOrder(orderNumber);
        }
      }
    }
  }, [initialized]);

  const getTitle = useMemo(() => {
    if (readOnly) {
      return `Booking Number: ${bookingNumber}`;
    } else {
      if (id) {
        if (bookingNumber) {
          return `Update Shipment Booking (${bookingNumber})`;
        } else {
          return 'Update Shipment Booking';
        }
      } else {
        return 'Create new Shipment Booking';
      }
    }
  }, [id, readOnly, bookingNumber]);

  const canDiscardDraft = useMemo(() => {
    if (states) {
      let hasDraft = states.findIndex((x) => x.status == shipmentBookingStatus.draft) > -1;
      let hasOtherStatus = states.findIndex((x) => x.status != shipmentBookingStatus.draft) > -1;

      return hasDraft && hasOtherStatus;
    }
    return false;
  }, [states]);

  const handleSubmitRequest = (event) => {
    setConfirmDialogAction({ show: true, referenceId: '' });
  };
  const handleDiscardRequest = (event) => {
    setDiscardDialogAction({ show: true, referenceId: '' });
  };

  return (
    <>
      <LoadingOverlay IsLoading={isSubmitting} />
      <Grid container alignItems="center" spacing={2}>
        <Grid item container xs={12}>
          <Grid item xs="auto">
            <Box className={classes.headerBox}>
              <h4>{getTitle}</h4>
              {readOnly && (
                <MyButton
                  size="small"
                  type="submit"
                  className={classes.headerButton}
                  buttonType="secondary"
                  onClick={() => {
                    changeFormToEditMode();
                  }}
                >
                  Edit
                </MyButton>
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <Box className={classes.headerBox}></Box>
          </Grid>
          <Grid item xs="auto">
            <StatesSection />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ImportantNoticeSection />
        </Grid>
        <Grid item xs={12}>
          <AttachedOrdersSection />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AddressSelection
            id="shippers"
            title="Shipper"
            addressSelectItems={shipperSelectionItems}
            selectedAddress={shipper}
            onSelectedValue={setShipper}
            helperTextStatus={messages[`Shipper`]?.status}
            helperText={messages[`Shipper`]?.text}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AddressSelection
            id="receivers"
            title="Receiver"
            addressSelectItems={receiverSelectionItems}
            selectedAddress={receiver}
            onSelectedValue={setReceiver}
            helperTextStatus={messages[`Receiver`]?.status}
            helperText={messages[`Receiver`]?.text}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <ShippingDetailsEntrySection />
        </Grid>
        <Grid item xs={12}>
          {!readOnly ? (
            <Box className={classes.buttonsBox}>
              <MyButton
                type="submit"
                className={clsx(classes.buttons, classes.buttonRight)}
                buttonType="secondary"
                onClick={() => {
                  saveDraft();
                }}
                startIcon={<SaveAltOutlinedIcon />}
              >
                Save Draft
              </MyButton>
              {canDiscardDraft && (
                <MyButton
                  type="cancel"
                  className={clsx(classes.buttons, classes.buttonRight)}
                  buttonType="secondary"
                  onClick={handleDiscardRequest}
                  startIcon={<DeleteOutlinedIcon />}
                >
                  Discard Draft
                </MyButton>
              )}
              <MyButton type="submit" className={clsx(classes.buttons, classes.buttonRight)} buttonType="primary" onClick={handleSubmitRequest}>
                Submit
              </MyButton>
              <MyButton className={clsx(classes.buttons, classes.buttonRight)} buttonType="cancel" onClick={() => history.goBack()}>
                Cancel
              </MyButton>
            </Box>
          ) : (
            <Box className={classes.buttonsBox}>
              <MyButton className={clsx(classes.buttons, classes.buttonRight)} buttonType="cancel" onClick={() => history.goBack()}>
                Back
              </MyButton>
            </Box>
          )}
        </Grid>
      </Grid>
      <ConfirmDialog
        title="Submit Shipment Booking?"
        body="Once submitted the system will send an email to Verus Global and cc you to process the next step. Please confirm that you want to proceed."
        handleConfirm={() => {
          submit();
        }}
        action={confirmDialogAction}
      />
      <DeleteDialog
        action={discardDialogAction}
        handleDelete={() => {
          discardDraft();
        }}
        title="Discard Changes?"
        body="Are you sure you want to discard this changes?"
      />
    </>
  );
}
