import clsx from 'clsx';
import React, { useCallback, useContext, useEffect } from 'react';
import { useMediaLayout } from 'use-media';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloudIcon from '@material-ui/icons/Cloud';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import Group from '@material-ui/icons/Group';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MapIcon from '@material-ui/icons/Map';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PublicIcon from '@material-ui/icons/Public';
import StorageIcon from '@material-ui/icons/Storage';
import StoreIcon from '@material-ui/icons/Store';
import TimelineIcon from '@material-ui/icons/Timeline';
import TableChartIcon from '@material-ui/icons/TableChart';

import { DrawerContext } from 'src/contexts/DrawerContext';
import { UserContext } from 'src/contexts/UserContext';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';

import DrawerMenuItem from '../parts/DrawerMenuItem';

const drawerWidth = 250;

const useCustomerDrawerStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.menuDrawer.main,
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.menuDrawer.button,
  },
  menuButtonHidden: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.menuDrawer.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100vh',
    overflowX: 'hidden',
    '& ::-webkit-scrollbar': {
      width: 8,
    },
  },
  drawerPaperClose: {
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  text: {
    color: theme.palette.menuDrawer.text,
  },
  divider: {
    background: theme.palette.menuDrawer.divider,
  },
  bottom: {
    borderTop: '1px solid #ffffff33',
    marginTop: 'auto',
  },
  menuScroll: {
    overflowY: 'overlay',
    overflowX: 'hidden',
  },
}));

export default function CustomerDrawer() {
  const classes = useCustomerDrawerStyles();
  const [userContext] = useContext(UserContext);
  const [drawerContext, setDrawerContext] = useContext(DrawerContext);
  const customerAccountFeatures = useCustomerAccountFeatures();

  const isWide = useMediaLayout({ maxWidth: '1000px' });

  useEffect(() => {
    if (isWide) {
      setDrawerContext(false);
    }
  }, [isWide]);

  const handleDrawerClose = useCallback(() => {
    setDrawerContext(false);
  }, []);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !drawerContext && classes.drawerPaperClose),
      }}
      open={drawerContext}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose} className={clsx(classes.text, !drawerContext && classes.menuButtonHidden)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List className={clsx(classes.text, classes.menuScroll)}>
        <DrawerMenuItem title="News" linkTo="/news" iconComponent={<PublicIcon />} />
        <Divider className={classes.divider} />
        {customerAccountFeatures.enabledOverviewFeature && <DrawerMenuItem title="Overview" linkTo="/overview" iconComponent={<DonutLargeIcon />} />}
        {customerAccountFeatures.enabledTrackAndTrace && <DrawerMenuItem title="Track and Trace" linkTo="/trackandtrace" iconComponent={<MapIcon />} />}
        {customerAccountFeatures.enabledTrendsOverTime && <DrawerMenuItem title="Trends Over Time" linkTo="/trends" iconComponent={<TimelineIcon />} />}
        <Divider className={classes.divider} />
        {customerAccountFeatures.enabledShipmentsFeature && <DrawerMenuItem title="Shipments" linkTo="/shipments" iconComponent={<DirectionsBoatIcon />} />}
        {customerAccountFeatures.enabledPortPairDataFeature && <DrawerMenuItem title="Port Pair Data" linkTo="/portpairdata" iconComponent={<StorageIcon />} />}
        {customerAccountFeatures.enabledCommercialInvoices && (
          <DrawerMenuItem
            title="Commercial Invoices"
            tooltipTitle="Commercial Invoice Value Per Supplier"
            linkTo="/commercialInvoiceValuePerSupplier"
            iconComponent={<AttachMoneyIcon />}
          />
        )}
        {customerAccountFeatures.enabledConsolidatedShipmentsSpendsFeature && (
          <DrawerMenuItem title="Shipments Spends" linkTo="/consolidated-shipments-spends" iconComponent={<MonetizationOnIcon />} />
        )}
        {customerAccountFeatures.enabledShipmentSpendsFeature && (
          <DrawerMenuItem title="Shipment Spends" linkTo="/shipment-spends" iconComponent={<MonetizationOnIcon />} />
        )}
        {customerAccountFeatures.enabledSpendPerSuppliers && (
          <DrawerMenuItem title="Spend Per Supplier" linkTo="/spendpersupplier" iconComponent={<MonetizationOnIcon />} />
        )}
        {customerAccountFeatures.enabledProducts && <DrawerMenuItem title="Products" linkTo="/products" iconComponent={<StoreIcon />} />}
        {customerAccountFeatures.enabledPurchaseOrder && <DrawerMenuItem title="Purchase Orders" linkTo="/purchase-order" iconComponent={<ListAltIcon />} />}
        {customerAccountFeatures.enabledPurchaseOrderLinesFeature && (
          <DrawerMenuItem title="Purchase Order Lines" linkTo="/purchase-order-lines" iconComponent={<ListAltIcon />} />
        )}
        {customerAccountFeatures.enabledPurchaseOrderLineSpendsFeature && (
          <DrawerMenuItem title="Order Lines Spends" linkTo="/purchase-order-lines-spends" iconComponent={<ListAltIcon />} />
        )}
        {customerAccountFeatures.enabledPurchaseOrderLineMilestonesFeature && (
          <DrawerMenuItem title="Order Lines Milestones" linkTo="/purchase-order-lines-milestones" iconComponent={<ListAltIcon />} />
        )}
        {/* {customerAccountFeatures.enabledExceptionsFeature && <DrawerMenuItem title="Exceptions" linkTo="/exceptions" iconComponent={<InsertDriveFileIcon />} />} */}
        {customerAccountFeatures.enabledEDIReport && <DrawerMenuItem title="Integrations" linkTo="/integrations" iconComponent={<AssignmentIcon />} />}
        {customerAccountFeatures.enabledReportFilesFeature && (
          <DrawerMenuItem title="Report Files" linkTo="/report-files" iconComponent={<InsertDriveFileIcon />} />
        )}
        <DrawerMenuItem title="Deliveries" linkTo="/deliveries" iconComponent={<TableChartIcon />} />
        <DrawerMenuItem title="Open Orders" linkTo="/open-orders" iconComponent={<TableChartIcon />} />
        <DrawerMenuItem title="Billing Summaries" linkTo="/billing-summaries" iconComponent={<TableChartIcon />} />
        <DrawerMenuItem title="Billing Detailed" linkTo="/billing-detailed" iconComponent={<TableChartIcon />} />
        <DrawerMenuItem title="Exceptions" linkTo="/exceptions" iconComponent={<TableChartIcon />} />
      </List>

      <List className={classes.bottom}>
        {userContext && userContext.hasFullPermissions && <DrawerMenuItem title="Accounts" linkTo="/accounts" iconComponent={<AddBoxIcon />} />}
        {userContext && userContext.permissions?.canReadUsers && <DrawerMenuItem title="Users" linkTo="/users" iconComponent={<Group />} />}
        {userContext && userContext.permissions?.canManageExternalApplications && (
          <DrawerMenuItem title="External API" linkTo="/external-applications" iconComponent={<CloudIcon />} />
        )}
      </List>
    </Drawer>
  );
}
