import React, { useLayoutEffect, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../Card/Card.js';
import CardHeader from '../../Card/CardHeader.js';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import CardBody from '../../Card/CardBody';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import ChartLogo from '../../parts/ChartLogo';
import { lightTheme } from '../../../themes/lightTheme';
import ContentFont from '../../Typography/ContentFont';
import LoadingOverlay from '../../parts/LoadingOverlay.js';
import ChartNoData from '../../parts/ChartNoData.js';

const useStyles = makeStyles((theme) => ({
  chart: {
    height: 300,
    paddingTop: 40,
  },
  chartTitle: {
    height: 125,
  },
}));

function JobTypes(props) {
  const classes = useStyles();
  const [chartDataInput, setChartDataInput] = useState([]);
  const { FilterComponentData } = props;
  const [showNoData, setShowNoData] = useState(false);
  const [IsLoading, setIsLoading] = useState();

  const history = useHistory();
  useEffect(() => {
    let isCancelled = false;
    if (FilterComponentData.accountId && FilterComponentData.start && FilterComponentData.end) {
      setIsLoading(true);
      apiService
        .getJobTypesReport(
          FilterComponentData.accountId,
          FilterComponentData.dateBasedOn,
          FilterComponentData.start,
          FilterComponentData.end,
          FilterComponentData.suppliers
        )
        .then((result) => {
          if (!isCancelled) {
            if (result.data && result.data.jobTypesItems && result.data.jobTypesItems.length > 0) {
              setShowNoData(false);
              setChartDataInput(result.data.jobTypesItems);
            } else {
              setShowNoData(true);
              setChartDataInput([]);
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [FilterComponentData, history]);

  useLayoutEffect(() => {
    var chart;

    function hasChartData() {
      if (chartDataInput && chartDataInput.length > 0) {
        return true;
      } else {
        return false;
      }
    }

    if (hasChartData()) {
      var colorList = [];
      for (var i = 0; i < lightTheme.chart.colors.length; i++) {
        colorList.push(am4core.color(lightTheme.chart.colors[i]));
      }

      // Create chart instance
      chart = am4core.create('PieSeries', am4charts.PieChart);
      chart.logo.disabled = true;

      chart.data = prepareStuff(chartDataInput);

      // Add label
      chart.innerRadius = 50;
      chart.radius = 100;
      let label = chart.seriesContainer.createChild(am4core.Label);

      label.horizontalCenter = 'middle';
      label.verticalCenter = 'middle';
      label.fontSize = 11;

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.colors.list = colorList;
      pieSeries.dataFields.value = 'amount';
      pieSeries.dataFields.category = 'name';
      pieSeries.alignLabels = false;
      pieSeries.labels.template.bent = true;
      pieSeries.labels.template.radius = 3;
      pieSeries.labels.template.padding(0, 0, 0, 0);
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.stroke = am4core.color('#fff');

      pieSeries.ticks.template.disabled = true;

      pieSeries.fontSize = 11;
    }
    return () => {
      if (chart) chart.dispose();
    };
  }, [chartDataInput, showNoData]);

  const prepareStuff = (data) => {
    var tempArray = [];
    data.forEach((item) => {
      let itemObj = {
        name: item.name,
        amount: item.amount,
      };
      tempArray.push(itemObj);
    });

    return tempArray;
  };

  return (
    <Card chart>
      <LoadingOverlay IsLoading={IsLoading} />
      <CardHeader className={'dashboard-chart-area'}>
        <ContentFont>{showNoData ? <ChartNoData /> : <div id="PieSeries" className={classes.chart}></div>}</ContentFont>
      </CardHeader>
      <CardBody className={classes.chartTitle + ' ' + 'dashboard-chart-title-area'}>
        <h4>Job Types</h4>
        <ChartLogo />
      </CardBody>
    </Card>
  );
}

export default JobTypes;
