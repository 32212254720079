import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router';
import InputBase from '@material-ui/core/InputBase';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import apiService from '../../../services/api.service';
import LiveExchangeRateChart from './LiveExchangeRateChart';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    fontWeight: 500,
  },
  liveExchangeRates: {},
  inputTitle: {
    minHeight: 32,
    alignItems: 'center',
  },
  inputControl: {
    minWidth: '80%',
    '& div': {
      // backgroundColor: 'rgba(255,255,255,0.5)',
    },
    '& input': {
      color: 'white !important',
      padding: theme.spacing(1),
    },
  },
  movement: {
    '&.pos': {
      color: '#3dddbf',
    },
    '&.neg': {
      color: '#f22b2b',
    },
  },
  valueText: {
    maxWidth: 100,
    textAlign: 'right',
  },
  fromContainer: {
    backgroundColor: theme.palette.liveExchangeRates.fromContainerBackgroundColor,
    margin: 5,
    borderRadius: 5,
  },
  fromContainerValueText: {
    maxWidth: 100,
    textAlign: 'right',
  },
  fromContainerFlagImage: {
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
  flagImage: {
    marginLeft: 9,
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },

  exchangeRow: {
    height: 96,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(255,255,255,0.5)',
    padding: theme.spacing(1),
    paddingRight: '26px',
    border: '1px solid #c1c1c1',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
}))(InputBase);

function LiveExchangeRates(props) {
  const classes = useStyles();

  const availableCurrencies = [
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/US.svg',
      currencyName: 'United States dollar',
      currencySymbol: 'USD',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/AU.svg',
      currencyName: 'Australian dollar',
      currencySymbol: 'AUD',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/TH.svg',
      currencyName: 'Thai baht',
      currencySymbol: 'THB',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/CN.svg',
      currencyName: 'Renminbi',
      currencySymbol: 'CNY',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/JP.svg',
      currencyName: 'Japanese yen',
      currencySymbol: 'JPY',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/HK.svg',
      currencyName: 'Hong Hong dollar',
      currencySymbol: 'HKD',
    },
    {
      flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/GB.svg',
      currencyName: 'Pound sterling',
      currencySymbol: 'GBP',
    },
  ];

  const [selectedBaseCurrency, setSelectedBaseCurrency] = useState(availableCurrencies[0]);
  const [exchangeCurrencies, setExchangeCurrencies] = useState(availableCurrencies.filter((x) => x.currencySymbol !== availableCurrencies[0].currencySymbol));
  const [liveExchangeRates, setLiveExchangeRates] = useState();

  const handleBaseCurrencySymbolChange = (e) => {
    let selectedCurrency = availableCurrencies.find((x) => x.currencySymbol === e.target.value);
    let exchangeCurrencies = availableCurrencies.filter((x) => x.currencySymbol !== e.target.value);
    setSelectedBaseCurrency(selectedCurrency);
    setExchangeCurrencies(exchangeCurrencies);
  };

  useEffect(() => {
    const loadLiveExchangeRates = async (baseCurrencySymbol, currencySymbols) => {
      let result = await apiService.getLiveCurrencyExchangeRates(baseCurrencySymbol, currencySymbols);
      setLiveExchangeRates(result.data);
    };

    if (selectedBaseCurrency) {
      let baseCurrencySymbol = selectedBaseCurrency.currencySymbol;
      let currencySymbols = availableCurrencies.map((currency) => {
        return currency.currencySymbol;
      });
      currencySymbols = currencySymbols.filter((currencySymbol) => currencySymbol !== baseCurrencySymbol);

      loadLiveExchangeRates(baseCurrencySymbol, currencySymbols.join(','));
    }
  }, [selectedBaseCurrency]);

  const getChangePercentageClassName = (changePercentage) => {
    if (changePercentage > 0) {
      return classes.movement + ' pos';
    } else if (changePercentage < 0) {
      return classes.movement + ' neg';
    }
    return classes.movement;
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3}>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h6">Live Exchange Rate</Typography>
        </Grid>
        {/* From */}
        <Grid item xs={12} container direction="row" spacing={1} alignItems="center" className={classes.fromContainer}>
          <Grid item flexgrow={1}>
            <Avatar
              className={classes.fromContainerFlagImage}
              alt={selectedBaseCurrency ? selectedBaseCurrency.currencyName : ''}
              src={selectedBaseCurrency ? selectedBaseCurrency.flagImageUrl : ''}
            />
          </Grid>
          <Grid item xs md={4} lg={5}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              variant="outlined"
              value={selectedBaseCurrency ? selectedBaseCurrency.currencySymbol : ''}
              onChange={handleBaseCurrencySymbolChange}
              className={classes.inputControl}
              input={<CustomInput />}
            >
              {availableCurrencies.map((currency, index) => {
                return (
                  <MenuItem key={`baseCurrency${index}`} value={currency.currencySymbol}>
                    {currency.currencyName}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2" className={classes.fromContainerValueText}>
              1
            </Typography>
          </Grid>
        </Grid>
        {/* Exchange Rate items */}
        <Grid item xs={12} container direction="row">
          {exchangeCurrencies &&
            exchangeCurrencies.map((item, index) => {
              let liveExchangeRate = liveExchangeRates ? liveExchangeRates.find((x) => x.currencySymbol === item.currencySymbol) : {};
              if (!liveExchangeRate) {
                liveExchangeRate = {};
              }
              return (
                <Grid key={`rate${index}`} container item xs={12} direction="column">
                  <Grid item xs={12} container direction="row" spacing={1} alignItems="center" className={classes.exchangeRow}>
                    <Grid item flexgrow={1}>
                      <Avatar alt={item.currencyName} src={item.flagImageUrl} className={classes.flagImage} />
                    </Grid>
                    <Grid item xs={3} md={4} lg={5}>
                      <Typography variant="subtitle2">{item.currencyName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2" className={classes.valueText}>
                        {liveExchangeRate.currentPrice && liveExchangeRate.currentPrice.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <Typography variant="subtitle2" className={getChangePercentageClassName(liveExchangeRate.changePercentage)}>
                        {liveExchangeRate.changePercentage && liveExchangeRate.changePercentage.toLocaleString()}%
                      </Typography>
                    </Grid>
                    <Grid item xs className={classes.chartContainer}>
                      <LiveExchangeRateChart
                        ChartId={`chart${selectedBaseCurrency.currencySymbol}${item.currencySymbol}`}
                        ChartData={liveExchangeRate.historicalPrices}
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default LiveExchangeRates;
