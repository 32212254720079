const shipmentBookingEntryInitialState = {
  id: '',
  vendorAccountId: '',
  bookingNumber: '',
  statusName: '',
  containerSizeSelectionItems: [],
  shipperSelectionItems: [],
  receiverSelectionItems: [],
  purchaseOrderSelectItems: [],
  shipper: {
    id: '',
    name: '',
    address: '',
    postCode: '',
    city: '',
    state: '',
    country: '',
    phone: '',
    email: '',
  },
  receiver: {
    id: '',
    name: '',
    address: '',
    postCode: '',
    city: '',
    state: '',
    country: '',
    phone: '',
    email: '',
  },
  containerVolume: '',
  containerSize: '',
  containerSizeName: '',
  cargoReadyDate: null,
  incoterms: '',
  marksAndNumbers: '',
  goodsDescription: '',
  orders: [],
  overflowPercentageOnQty: 0,
  submittedDate: null,
  states: [],
  errors: {},
  messages: {},
  needValidate: null,
  initialized: false,
  isEdit: false,
  isSubmitting: false,
  isSubmitted: false,
  readOnly: false,
};

const shipmentBookingEntryReducer = (state, action) => {
  console.log('shipmentBookingEntryReducer', state, action);
  switch (action.type) {
    case 'initialize': {
      return {
        id: '',
        vendorAccountId: action.payload.vendorAccountId,
        bookingNumber: '',
        statusName: 'Draft',
        containerSizeSelectionItems: action.payload.containerSizeSelectionItems,
        shipperSelectionItems: action.payload.shipperSelectionItems,
        receiverSelectionItems: [],
        purchaseOrderSelectItems: action.payload.purchaseOrderSelectItems,
        shipper: {
          id: '',
          name: '',
          address: '',
          postCode: '',
          city: '',
          state: '',
          country: '',
          phone: '',
          email: '',
        },
        receiver: {
          id: '',
          name: '',
          address: '',
          postCode: '',
          city: '',
          state: '',
          country: '',
          phone: '',
          email: '',
        },
        containerVolume: '',
        containerSize: '',
        containerSizeName: '',
        cargoReadyDate: null,
        incoterms: '',
        marksAndNumbers: '',
        goodsDescription: '',
        orders: [],
        overflowPercentageOnQty: action.payload.overflowPercentageOnQty,
        submittedDate: null,
        states: [],
        errors: {},
        messages: {},
        needValidate: null,
        initialized: true,
        isEdit: false,
        isSubmitting: false,
        isSubmitted: false,
        readOnly: false,
      };
    }
    case 'field': {
      return {
        ...state,
        [action.fieldName]: action.payload,
      };
    }
    case 'loadReceiverSelection': {
      let updateReceiver = {};
      if (action.payload.findIndex((x) => x.id == state.receiver.id) < 0) {
        updateReceiver = {
          receiver: {
            id: '',
            name: '',
            address: '',
            postCode: '',
            city: '',
            state: '',
            country: '',
            contact: '',
            phone: '',
            fax: '',
            email: '',
          },
        };
      }
      return {
        ...state,
        ...updateReceiver,
        receiverSelectionItems: action.payload,
      };
    }
    case 'addOrder': {
      return {
        ...state,
        orders: [...state.orders, action.payload],
        messages: { ...state.messages, Orders: {} },
      };
    }
    case 'submit': {
      return {
        ...state,
        messages: {},
        isSubmitting: true,
      };
    }
    case 'success': {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case 'apiError': {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'message': {
      return {
        ...state,
        messages: { ...state.messages, [action.fieldName]: action.payload },
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'messages': {
      return {
        ...state,
        messages: { ...state.messages, ...action.payload.messages },
        isSubmitting: false,
        isSubmitted: false,
      };
    }
    case 'loadExistingBooking': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
export { shipmentBookingEntryInitialState, shipmentBookingEntryReducer };
