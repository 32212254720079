import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import DataTableFilter from 'src/components/parts/DataTableFilter/DataTableFilter';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
}));

const defaultHeadCells = [
  {
    id: 'Supplier',
    numeric: false,
    disablePadding: true,
    label: 'Supplier',
  },
  {
    id: 'Customs',
    numeric: true,
    disablePadding: false,
    label: 'Customs ($)',
  },
  {
    id: 'Duty',
    numeric: true,
    disablePadding: false,
    label: 'Duty ($)',
  },
  {
    id: 'FOB',
    numeric: true,
    disablePadding: false,
    label: 'FOB ($)',
    tooltip: 'Value of the goods in AUD. Duty is calculated from this value.',
  },
  {
    id: 'FOBCurrency',
    numeric: false,
    disablePadding: false,
    label: 'FOB Currency',
  },
  {
    id: 'CIF',
    numeric: true,
    disablePadding: false,
    label: 'CIF ($)',
    tooltip: 'Cost, insurance & freight value – GST payable calculated on this value + any duty value',
  },
  {
    id: 'CIFCurrency',
    numeric: false,
    disablePadding: false,
    label: 'CIF Currency',
  },
  {
    id: 'InvoiceValue',
    numeric: true,
    disablePadding: false,
    label: 'Commercial Invoice Value ($)',
  },
  {
    id: 'InvoiceCurrency',
    numeric: false,
    disablePadding: false,
    label: 'Invoice Currency',
  },
];

function CommercialInvoiceValuePerSupplierTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('Supplier');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [totalNumberOfRows, setTotalNumberOfRows] = React.useState(0);
  const [downloadCsv, setDownloadCsv] = React.useState({ headers: [], data: [], filename: '' });
  const { enabledCommercialInvoices } = useCustomerAccountFeatures();
  const csvLinkEl = useRef();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();

  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [headCellsSelect, setHeadCellsSelect] = useState();

  useEffect(() => {
    if (filter) {
      startProgress();
      getData();
    }
  }, [rowsPerPage, page, filter]);

  const filterCallback = (data) => {
    setPage(0);
    setFilter(data);
  };

  const getData = (inData) => {
    let data = inData !== undefined ? inData : filter;
    apiService
      .getCommercialInvoiceValuePerSupplierTable(
        data.accountId,
        data.dateBasedOn,
        data.start,
        data.end,
        order,
        orderBy,
        page * rowsPerPage,
        rowsPerPage,
        data.freeTextSearch,
        data.suppliers
      )
      .then((result) => {
        setRows(createTableListData(result.data.items));
        setTotalNumberOfRows(result.data.count);
        stopProgress();
      })
      .catch(catchApiError);
  };

  const createTableListData = (items) => {
    let newArray = [];
    items.map((item) => {
      let cells = [
        item.supplier,
        item.supplier,
        item.customs.toLocaleString(),
        item.duty.toLocaleString(),
        item.fob.toLocaleString(),
        'AUD', //item.fobCurrency,//Feedback from Nicole(2021-03-18) FOB value to always AUD
        item.cif.toLocaleString(),
        item.cifCurrency,
        item.invoiceValue.toLocaleString(),
        item.invoiceCurrency,
      ];

      let tmpCell = {};
      tmpCell['id'] = item.supplier;
      defaultHeadCells.forEach((x, index) => {
        tmpCell[x.id] = cells[index + 1];
      });

      newArray.push(tmpCell);
    });

    return newArray;
  };

  const handleRequestSort = (sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    getData();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
  };

  const generatePdf = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getCommercialInvoiceValuePerSupplierTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          pdfTableService.generateCommercialInvoiceValuePerSupplierPDF(
            result.data.items,
            'Commercial Invoice Value Per Supplier     Company:' + filter.accountName + '    Period:' + filter.periodName,
            'Commercial Invoice Value Per Supplier Report'
          );
        })
        .catch(catchApiError);
    }
  };

  const generateCSV = () => {
    if (totalNumberOfRows > 0) {
      apiService
        .getCommercialInvoiceValuePerSupplierTable(
          filter.accountId,
          filter.dateBasedOn,
          filter.start,
          filter.end,
          order,
          orderBy,
          0,
          totalNumberOfRows,
          filter.freeTextSearch,
          filter.suppliers
        )
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateCommercialInvoiceValuePerSupplierCsvData(result.data.items, filter.accountName + '_' + filter.periodName);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  };

  const handleColumnSelected = (headCells) => {
    setHeadCellsSelect(headCells);
  };

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <EntityListFrame>
        {enabledCommercialInvoices ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4 className={classes.titleText}>Commercial Invoice Value Per Supplier</h4>
              </Grid>
              <Grid item className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} />
              </Grid>
              <Grid item className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <DataTableFilter sendFilterComponentData={filterCallback} showSuppliers={true} />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={headCellsSelect}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(CommercialInvoiceValuePerSupplierTable);
