import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';

import { Grid, makeStyles } from '@material-ui/core';

import CustomerProfile from './CustomerProfile';

const useStyles = makeStyles((theme) => ({
  buttonRight: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
}));

export default function CustomerProfileContainer(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid item xs={12}>
      <CustomerProfile />
    </Grid>
  );
}
