import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router';

import { Backdrop, CircularProgress, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import ColumnSelector from 'src/components/parts/ColumnSelector';
import DataContentTable from 'src/components/parts/DataContentTable';
import FeatureDisabledMessage from 'src/components/parts/FeatureDisabledMessage';
import useCatchAPIError from 'src/hooks/useCatchAPIError';
import useCustomerAccountFeatures from 'src/hooks/useCustomerAccountFeatures';
import useGlobalLoadingBar from 'src/hooks/useGlobalLoadingBar';
import apiService from 'src/services/api.service';
import csvTableService from 'src/services/csv.table.service';
import pdfTableService from 'src/services/pdf.table.service';
import tableService from 'src/services/table.service';
import ExportMenu from 'src/wrappers/ExportMenu';

import BillingSummariesTableFilter from './BillingSummariesTableFilter';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginBottom: '28px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  ExportMenuContainer: {
    flexGrow: 0,
  },
}));

const defaultHeadCells = [
  { id: 'ShipmentNumber', numeric: false, disablePadding: true, label: 'Shipment No.' },
  { id: 'InvoiceNumber', numeric: false, disablePadding: false, label: 'Invoice No.' },
  { id: 'InvoiceType', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'InvoiceDate', numeric: false, disablePadding: false, label: 'Invoice Date' },
  { id: 'LocalExcludingTax', numeric: true, disablePadding: false, label: 'Local Excluding Tax' },
  { id: 'LocalTax', numeric: true, disablePadding: false, label: 'Local Tax' },
  { id: 'InvoiceAmount', numeric: true, disablePadding: false, label: 'Invoice Amount' },
  { id: 'Currency', numeric: false, disablePadding: false, label: 'Currency' },
  { id: 'Mode', numeric: false, disablePadding: false, label: 'Mode' },
  { id: 'ContainerNumbers', numeric: false, disablePadding: false, label: 'Container No.' },
  { id: 'OrderNumbers', numeric: false, disablePadding: false, label: 'Order No.' },
  { id: 'OriginCode', numeric: false, disablePadding: false, label: 'Origin' },
  { id: 'Destination', numeric: false, disablePadding: false, label: 'Destination' },
  { id: 'ETD', numeric: false, disablePadding: false, label: 'ETD' },
  { id: 'ETA', numeric: false, disablePadding: false, label: 'ETA' },
  { id: 'Vessel', numeric: false, disablePadding: false, label: 'Vessel' },
  { id: 'VoyageFlight', numeric: false, disablePadding: false, label: 'Voyage/Flight' },
];

function BillingSummariesTable(props) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('ShipmentNumber');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState('');
  const [filter, setFilter] = useState({});
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [downloadCsv, setDownloadCsv] = useState({ headers: [], data: [], filename: '' });
  const csvLinkEl = useRef();
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [newHeadCells, setNewHeadCells] = useState([]);
  const [hiddenColumnNames, setHiddenColumnNames] = useState();
  const { enabledShipmentsFeature, enabledTrackAndTrace } = useCustomerAccountFeatures();
  const { catchApiError } = useCatchAPIError();
  const { startProgress, stopProgress } = useGlobalLoadingBar();
  const [destinations, setDestinations] = useState([]);

  const previousReload = useRef();
  const [reload, setReload] = useState(new Date());

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const createBillingSummariesTableReportFilter = useCallback(() => {
    const billingSummariesTableReportFilter = {
      ...filter,
      skip: page * rowsPerPage,
      take: rowsPerPage,
      sortColumn: orderBy,
      sortDirection: order,
    };

    return billingSummariesTableReportFilter;
  }, [filter, order, orderBy, page, rowsPerPage]);

  const createTableListData = useCallback(
    (items) => {
      let newArray = [];

      items.forEach((item) => {
        const invoiceDate = moment(item.invoiceDate);
        const etd = moment(item.etd);
        const eta = moment(item.eta);

        var shipmentNumberLinks = item.shipmentNumber;

        if (enabledTrackAndTrace) {
          shipmentNumberLinks = { component: 'linkList', links: [] };
          shipmentNumberLinks.links.push({
            text: item.shipmentNumber,
            path: '/trackandtrace?s=' + item.shipmentNumber,
          });
        }

        var containerNumbers = { component: 'tags', texts: [] };
        if (item.containerNumbers) {
          item.containerNumbers.split(',').forEach((container) => {
            containerNumbers.texts.push({ text: container });
          });
        }

        var orderNumbers = { component: 'tags', texts: [] };
        if (item.orderNumbers) {
          item.orderNumbers.split(',').forEach((orderNumber) => {
            orderNumbers.texts.push({ text: orderNumber });
          });
        }

        const rowData = {
          id: item.shipmentNumber + item.invoiceNumber,
          ShipmentNumber: shipmentNumberLinks,
          InvoiceNumber: item.invoiceNumber,
          InvoiceType: item.invoiceType,
          InvoiceDate: invoiceDate.isValid() ? invoiceDate.format('L') : '',
          LocalExcludingTax: item.localExcludingTax.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          LocalTax: item.localTax.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          InvoiceAmount: item.invoiceAmount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          Currency: item.currency,
          Mode: item.mode,
          ContainerNumbers: containerNumbers,
          OrderNumbers: orderNumbers,
          OriginCode: item.originCode,
          Destination: item.destination,
          ETD: etd.isValid() ? etd.format('L') : '',
          ETA: eta.isValid() ? eta.format('L') : '',
          Vessel: item.vessel,
          VoyageFlight: item.voyageFlight,
        };

        newArray.push(rowData);
      });

      return newArray;
    },
    [enabledTrackAndTrace]
  );

  const handleOnFilterChange = useCallback((data) => {
    setPage(0);
    setFilter((f) => ({ ...f, ...data }));
    setReload(new Date());
  }, []);

  const handleRequestSort = useCallback((sortOrder, property) => {
    setOrder(sortOrder);
    setOrderBy(property);
    setReload(new Date());
  }, []);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
    setReload(new Date());
  }, []);

  const handleChangeRowsPerPage = useCallback((newValue) => {
    setPage(0);
    setRowsPerPage(newValue);
    setReload(new Date());
  }, []);

  const generatePdf = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const billingSummariesTableReportFilter = createBillingSummariesTableReportFilter();
      const filterForPdf = {
        ...billingSummariesTableReportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getBillingSummariesTableReport(filterForPdf)
        .then((result) => {
          stopProgress();
          pdfTableService.generateBillingSummariesPDF(result.data.items, 'BillingSummaries', 'Billing Summaries Report', result.data.hiddenColumnNames);
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createBillingSummariesTableReportFilter, stopProgress, totalNumberOfRows]);

  const generateCSV = useCallback(() => {
    if (totalNumberOfRows > 0) {
      const billingSummariesTableReportFilter = createBillingSummariesTableReportFilter();
      const filterForCsv = {
        ...billingSummariesTableReportFilter,
        skip: 0,
        take: totalNumberOfRows,
      };
      apiService
        .getBillingSummariesTableReport(filterForCsv)
        .then((result) => {
          stopProgress();
          var csvData = csvTableService.generateBillingSummariesCsvData(result.data.items, '', result.data.hiddenColumnNames);
          setDownloadCsv(csvData);
          csvLinkEl.current.link.click();
        })
        .catch(catchApiError);
    }
  }, [catchApiError, createBillingSummariesTableReportFilter, stopProgress, totalNumberOfRows]);

  const handleColumnSelected = useCallback((headCells) => {
    setNewHeadCells(headCells);
  }, []);

  const handleBackdropClose = useCallback(() => {
    setOpenBackdrop(false);
  }, []);

  useEffect(() => {
    const getData = (billingSummariesTableReportFilter) => {
      apiService
        .getBillingSummariesTableReport(billingSummariesTableReportFilter)
        .then((result) => {
          let contentRows = createTableListData(result.data.items);
          let filteredTable = tableService.filterHiddenTableColumns(defaultHeadCells, contentRows, result.data.hiddenColumnNames);
          setHeadCells(filteredTable.headCells);
          setRows(filteredTable.contentRows);
          setTotalNumberOfRows(result.data.count);
          setHiddenColumnNames(result.data.hiddenColumnNames);
          setDestinations(result.data.destinations);
          stopProgress();
          handleBackdropClose();
        })
        .catch(catchApiError);
    };

    if (previousReload.current !== reload) {
      previousReload.current = reload;
      startProgress();
      const billingSummariesTableReportFilter = createBillingSummariesTableReportFilter();
      getData(billingSummariesTableReportFilter);
    }
  }, [reload, createBillingSummariesTableReportFilter, startProgress, catchApiError, createTableListData, stopProgress, handleBackdropClose]);

  return (
    <>
      <CSVLink headers={downloadCsv.headers} filename={downloadCsv.filename} data={downloadCsv.data} ref={csvLinkEl} />
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <EntityListFrame>
        {enabledShipmentsFeature ? (
          <>
            <Grid container className={classes.titleArea}>
              <Grid item xs={12} sm className="align-self-center">
                <h4>Billing Summaries</h4>
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ColumnSelector headCells={headCells} handleColumnSelected={handleColumnSelected} hiddenColumnNames={hiddenColumnNames} />
              </Grid>
              <Grid item xs className={'align-self-center ' + classes.ExportMenuContainer}>
                <ExportMenu onPdfClick={generatePdf} onCsvClick={generateCSV}></ExportMenu>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <BillingSummariesTableFilter onChange={handleOnFilterChange} destinations={destinations} />
            </Grid>
            <Divider />

            <Grid item xs={12}>
              {rows && (
                <DataContentTable
                  title=""
                  headCells={newHeadCells}
                  totalNumberOfRows={totalNumberOfRows}
                  rows={rows}
                  page={page}
                  handleRequestSort={handleRequestSort}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </>
        ) : (
          <FeatureDisabledMessage />
        )}
      </EntityListFrame>
    </>
  );
}

export default withRouter(BillingSummariesTable);
