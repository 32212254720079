import { Box, makeStyles, Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 5,
    color: '#888888',
    zIndex: 100,
  },
}));

export default function ChartTooltip(props) {
  const { contentHtml } = props;
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <HtmlTooltip
        title={
          <React.Fragment>
            {contentHtml ? (
              <>{contentHtml}</>
            ) : (
              <>
                <b>Show/Hide line:</b> Click on a chart legend. <br />
                <b>Show/Hide values above bullets:</b> Click on a bullet.
              </>
            )}
          </React.Fragment>
        }
      >
        <HelpOutlineIcon />
      </HtmlTooltip>
    </Box>
  );
}

ChartTooltip.propTypes = {
  contentHtml: PropTypes.element,
};
