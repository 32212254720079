import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Accordion, AccordionDetails, AccordionSummary, Chip, Fade, Grid, Hidden, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

import TitleFont from 'src/components/Typography/TitleFont';
import { ReloadContext } from 'src/contexts/ReloadContext';
import { UserContext } from 'src/contexts/UserContext';
import useAccounts from 'src/hooks/useAccounts';
import useFilterQuery from 'src/hooks/useFilterQuery';
import apiService from 'src/services/api.service';
import helpService from 'src/services/help.service';
import ExportToPdfButton from 'src/wrappers/ExportToPdfButton';

import PeriodsControl from './PeriodsControl';
import SuppliersTrendFilter from './SuppliersTrendFilter';
import dateBaseOn from 'src/models/DateBaseOn';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flexGrow: 1,
  },
  checkBlockControl: {
    paddingTop: '6px',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  checkBox: {
    marginRight: theme.spacing(1),
  },
  icon: {
    color: '#888888',
  },
  pdfButton: {
    marginTop: '10px',
  },
  enabledCompare: {
    backgroundColor: theme.palette.trendsOverTime.enabledComparePeriodBackgroundColor,
    borderRadius: 5,
  },
  supplierTags: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  moreFilterSection: {
    backgroundColor: theme.palette.trendsOverTime.moreFilterSection,
    borderRadius: 5,
    boxShadow: 'none',
  },
}));

const groupBys = { month: 'Month', year: 'Year' };
const defaultPeriodMonths = 6;
const allCarrierContractNumberValue = 'All';

function TrendsFilters(props) {
  const history = useHistory();
  const classes = useStyles();
  const [userContext] = useContext(UserContext);
  const [reloadContext] = useContext(ReloadContext);
  const { filterQuery, queryString, updateAddressUrl } = useFilterQuery();
  const { accountId, accountName } = useAccounts();
  const [periods, setPeriods] = useState();
  const [suppliers, setSuppliers] = useState();

  const [selectedStartPeriodValue, setSelectedStartPeriodValue] = useState(filterQuery.startPeriod);
  const [selectedEndPeriodValue, setSelectedEndPeriodValue] = useState(filterQuery.endPeriod);
  const [selectedShowSuppliersIndividually, setSelectedShowSuppliersIndividually] = useState(false);
  const [selectedGroupBy, setSelectedGroupBy] = useState(groupBys.month);
  const [selectedDateBasedOn, setSelectedDateBasedOn] = useState(filterQuery.dateBasedOn || dateBaseOn.eta);
  const [selectedAllSuppliers, setSelectedAllSuppliers] = useState(true);
  const [selectedSupplierFilterValue, setSelectedSupplierFilterValue] = useState();

  const [postBackFilterDataCounter, setPostBackFilterDataCounter] = useState(1);
  const [selectedComparePeriodValues, setSelectedComparePeriodValues] = useState({
    enabledCompare: false,
    startPeriodValue: '',
    endPeriodValue: '',
  });

  const [suppliersBySpecialFilterOption, setSuppliersBySpecialFilterOption] = useState([]);

  const [carrierContractNumberFilterItems, setCarrierContractNumberFilterItems] = useState();
  const [selectedCarrierContractNumber, setSelectedCarrierContractNumber] = useState(allCarrierContractNumberValue);

  useEffect(() => {
    function setDefaultPeriodValue(data) {
      if (data && data.length > 0) {
        //Set Start Period
        let startPeriodValue = data.find((x) => x.id === selectedStartPeriodValue);
        if (startPeriodValue == null) {
          var startPeriodIndex = defaultPeriodMonths - 1;
          while (startPeriodIndex > data.length - 1) {
            startPeriodIndex--;
          }
          setSelectedStartPeriodValue(data[startPeriodIndex].id);
        } else {
          setSelectedStartPeriodValue(startPeriodValue.id);
        }

        //Set End Period
        let endPeriodValue = data.find((x) => x.id === selectedEndPeriodValue);
        if (endPeriodValue == null) {
          setSelectedEndPeriodValue(data[0].id);
        } else {
          setSelectedEndPeriodValue(endPeriodValue.id);
        }

        //Set Compare Period
        var endPeriodIndex = data.length - defaultPeriodMonths;
        if (endPeriodIndex < 0) endPeriodIndex = 0;
        setSelectedComparePeriodValues({ ...selectedComparePeriodValues, startPeriodValue: data[data.length - 1].id, endPeriodValue: data[endPeriodIndex].id });
      } else {
        setSelectedStartPeriodValue('');
        setSelectedEndPeriodValue('');

        setSelectedComparePeriodValues({ ...selectedComparePeriodValues, startPeriodValue: '', endPeriodValue: '' });
      }
    }

    async function resetFilters(selectedAccountId, selectedDateBasedOn) {
      try {
        var dashboardFilters = await apiService.getDashboardFilters(selectedAccountId, selectedDateBasedOn);

        var carrierContractNumberFilterItemsResult = await apiService.getCarrierContractNumberFilterItems(selectedAccountId);
        setCarrierContractNumberFilterItems(carrierContractNumberFilterItemsResult.data);

        if (dashboardFilters.data.filterPeriods) {
          setDefaultPeriodValue(null);
          setPeriods(dashboardFilters.data.filterPeriods);
          setDefaultPeriodValue(dashboardFilters.data.filterPeriods);
        } else {
          setDefaultPeriodValue(null);
          setPeriods(null);
        }

        //Suppliers
        if (dashboardFilters.data.reportSuppliers) {
          let tempSuppliers = dashboardFilters.data.reportSuppliers.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

          tempSuppliers.forEach((item) => {
            item.name = helpService.truncateString(item.name, 40);
          });

          setSuppliers(tempSuppliers);
        } else {
          setSuppliers(null);
        }

        //Reset filter values
        setSelectedCarrierContractNumber(allCarrierContractNumberValue);
        setSelectedAllSuppliers(true);
        setSelectedShowSuppliersIndividually(false);
        setSelectedGroupBy(groupBys.month);

        setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
      } catch (err) {
        if (err.response && err.response.status === 401) history.push('/login');
      }
    }

    if (accountId && selectedDateBasedOn) {
      resetFilters(accountId, selectedDateBasedOn);
    }
  }, [reloadContext, userContext, accountId, selectedDateBasedOn]);

  useEffect(() => {
    if (props.sendQueryStrings) {
      props.sendQueryStrings(queryString);
    }
  }, [queryString]);

  useEffect(() => {
    const createFilterQueryModel = () => {
      return {
        dateBasedOn: selectedDateBasedOn,
        startPeriod: selectedStartPeriodValue,
        endPeriod: selectedEndPeriodValue,
        accountId: accountId,
      };
    };

    if (periods != null) {
      let filterQueryModel = createFilterQueryModel();
      updateAddressUrl(filterQueryModel);
      ResendFilterComponentData();
    }
  }, [postBackFilterDataCounter]);

  const handleStartPeriodChange = (e) => {
    setSelectedStartPeriodValue(e.target.value);

    if (selectedSupplierFilterValue.specialFilterOption !== undefined) {
      var selectedStartPeriod = periods.find((x) => x.id === e.target.value);
      var selectedEndPeriod = periods.find((x) => x.id === selectedEndPeriodValue);
      loadSuppliersBySpecialFilterOption(
        accountId,
        selectedDateBasedOn,
        selectedStartPeriod?.periodStart,
        selectedEndPeriod?.periodEnd,
        selectedSupplierFilterValue
      );
    } else {
      setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
    }
  };

  const handleEndPeriodChange = (e) => {
    setSelectedEndPeriodValue(e.target.value);

    if (selectedSupplierFilterValue.specialFilterOption !== undefined) {
      var selectedStartPeriod = periods.find((x) => x.id === selectedStartPeriodValue);
      var selectedEndPeriod = periods.find((x) => x.id === e.target.value);
      loadSuppliersBySpecialFilterOption(
        accountId,
        selectedDateBasedOn,
        selectedStartPeriod?.periodStart,
        selectedEndPeriod?.periodEnd,
        selectedSupplierFilterValue
      );
    } else {
      setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
    }
  };

  const handleShowSuppliersIndividuallyChange = (e) => {
    setSelectedShowSuppliersIndividually(e.target.checked);
    if (e.target.checked) {
      setSelectedComparePeriodValues({ ...selectedComparePeriodValues, enabledCompare: false });
    }
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const handleGroupByChange = (e) => {
    setSelectedGroupBy(e.target.value);
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const handleDateBasedOnChange = (e) => {
    setSelectedDateBasedOn(e.target.value);
  };

  const handleCarrierContractNumberChange = (e) => {
    setSelectedCarrierContractNumber(e.target.value);
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  function ResendFilterComponentData() {
    var selectedStartPeriod = periods.find((x) => x.id === selectedStartPeriodValue);
    var selectedEndPeriod = periods.find((x) => x.id === selectedEndPeriodValue);
    var companyName = accountName;

    var periodName = '';
    if (selectedStartPeriod === '' && selectedEndPeriod === '') {
      periodName = 'All';
    } else {
      var startPeriodText = selectedStartPeriod == null ? 'Past' : selectedStartPeriod.displayName;
      var endPeriodText = selectedEndPeriod == null ? 'Present' : selectedEndPeriod.displayName;
      periodName = startPeriodText + ' to ' + endPeriodText;
    }

    let comparePeriodFilter = {};
    if (selectedComparePeriodValues.enabledCompare) {
      var startPeriod = periods.find((x) => x.id === selectedComparePeriodValues.startPeriodValue);
      var endPeriod = periods.find((x) => x.id === selectedComparePeriodValues.endPeriodValue);

      comparePeriodFilter = { enabledCompare: true, start: startPeriod?.periodStart, end: endPeriod?.periodEnd };
    } else {
      comparePeriodFilter = { enabledCompare: false };
    }

    let carrierContractNumber;
    if (selectedCarrierContractNumber === allCarrierContractNumberValue) {
      carrierContractNumber = null;
    } else {
      carrierContractNumber = selectedCarrierContractNumber;
    }

    const filterComponentData = {
      companyName: companyName,
      periodName: periodName,
      supplierName: selectedSupplierFilterValue.supplierNames,
      accountId: accountId,
      start: selectedStartPeriod?.periodStart,
      end: selectedEndPeriod?.periodEnd,
      suppliers: selectedSupplierFilterValue.supplierIds,
      showSuppliersIndividually: selectedShowSuppliersIndividually,
      groupBy: selectedGroupBy,
      showAllSuppliers: selectedAllSuppliers,
      comparePeriodFilter: comparePeriodFilter,
      dateBasedOn: selectedDateBasedOn,
      carrierContractNumber: carrierContractNumber,
    };

    props.sendFilterComponentData(filterComponentData);
  }

  function disableStartPeriodMenuItem(periodItem, endPeriodValue) {
    if (endPeriodValue) {
      let selectedEndPeriod = periods.find((x) => x.id === endPeriodValue);
      if (selectedEndPeriod) {
        let endDate = new Date(selectedEndPeriod.periodEnd);
        let startDate = new Date(periodItem.periodStart);
        return startDate > endDate;
      }
    }
    return false;
  }

  function disableEndPeriodMenuItem(periodItem, startPeriodValue) {
    if (startPeriodValue) {
      let selectedStartPeriod = periods.find((x) => x.id === startPeriodValue);
      if (selectedStartPeriod) {
        let startDate = new Date(selectedStartPeriod.periodStart);
        let endDate = new Date(periodItem.periodEnd);
        return startDate > endDate;
      }
    }
    return false;
  }

  const handleEnabledCompareChange = (e) => {
    setSelectedComparePeriodValues({ ...selectedComparePeriodValues, enabledCompare: e.target.checked });
    setTimeout(() => {
      setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
    }, 300);
  };

  const handleCompareStartPeriodChange = (e) => {
    setSelectedComparePeriodValues({ ...selectedComparePeriodValues, startPeriodValue: e.target.value });
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const handleCompareEndPeriodChange = (e) => {
    setSelectedComparePeriodValues({ ...selectedComparePeriodValues, endPeriodValue: e.target.value });
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const loadSuppliersBySpecialFilterOption = async (accountId, dateBasedOn, periodStart, periodEnd, selectedSupplierFilterValue) => {
    var supplierResult = await apiService.getSuppliersBySpecialSuppliersTrendFilterOption(
      accountId,
      dateBasedOn,
      periodStart,
      periodEnd,
      selectedSupplierFilterValue.specialFilterOption
    );

    if (supplierResult.data) {
      let supplierIds = supplierResult.data.map((x) => x.id);
      let supplierNames = supplierResult.data.map((x) => x.name);
      setSuppliersBySpecialFilterOption(supplierResult.data);
      setSelectedSupplierFilterValue({
        ...selectedSupplierFilterValue,
        supplierIds: supplierIds.join(', '),
        supplierNames: supplierNames.join(', '),
      });
    } else {
      setSelectedSupplierFilterValue({
        ...selectedSupplierFilterValue,
        supplierIds: '',
        supplierNames: '',
      });
    }
    setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
  };

  const handleSelectedSuppliers = (value) => {
    setSuppliersBySpecialFilterOption([]);
    setSelectedAllSuppliers(value.showAllSuppliers);
    if (value.specialFilterOption !== undefined) {
      var selectedStartPeriod = periods.find((x) => x.id === selectedStartPeriodValue);
      var selectedEndPeriod = periods.find((x) => x.id === selectedEndPeriodValue);
      loadSuppliersBySpecialFilterOption(accountId, selectedDateBasedOn, selectedStartPeriod?.periodStart, selectedEndPeriod?.periodEnd, value);
    } else {
      setSuppliersBySpecialFilterOption([]);
      setSelectedSupplierFilterValue(value);
      if (value.showAllSuppliers) {
        setSelectedShowSuppliersIndividually(false);
      }
      setPostBackFilterDataCounter(postBackFilterDataCounter + 1);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Hidden lgUp>
          <Grid item xs={12} sm={12} md={12}>
            <ExportToPdfButton className={classes.pdfButton} onClick={props.onClickGeneratePdf}></ExportToPdfButton>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={6}>
          <SuppliersTrendFilter
            Suppliers={suppliers}
            RootClassName={classes.formControl + ' exclude'}
            Title="Suppliers"
            OnSelectedSuppliers={handleSelectedSuppliers}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <FormControl variant="outlined" className={classes.formControl + ' ' + classes.checkBlockControl + ' exclude'}>
            <FormControlLabel
              className={classes.checkBox}
              checked={selectedShowSuppliersIndividually}
              onChange={handleShowSuppliersIndividuallyChange}
              control={<Checkbox name="checkedB" color="primary" />}
              label="Show suppliers individually"
              disabled={selectedAllSuppliers}
            />
            <Tooltip arrow title="To show suppliers individually, please select specific suppliers from the list.">
              <InfoIcon className={classes.icon} />
            </Tooltip>
          </FormControl>
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12} lg={3}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <ExportToPdfButton className={classes.pdfButton} onClick={props.onClickGeneratePdf}></ExportToPdfButton>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={1}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <InputLabel id="groupByLabel">
              <TitleFont> Group By</TitleFont>
            </InputLabel>
            <Select labelId="groupByLabel" id="demo-simple-select-outlined" label="Group By" value={selectedGroupBy} onChange={handleGroupByChange}>
              <MenuItem value={groupBys.month}>{groupBys.month}</MenuItem>
              <MenuItem value={groupBys.year}>{groupBys.year}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={1}>
          <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
            <InputLabel id="basedOnLabel">
              <TitleFont> Based On</TitleFont>
            </InputLabel>
            <Select labelId="groupByLabel" id="demo-simple-select-outlined" label="Based On" value={selectedDateBasedOn} onChange={handleDateBasedOnChange}>
              <MenuItem value={dateBaseOn.eta}>{dateBaseOn.eta}</MenuItem>
              <MenuItem value={dateBaseOn.etd}>{dateBaseOn.etd}</MenuItem>
              <MenuItem value={dateBaseOn.ata}>{dateBaseOn.ata}</MenuItem>
              <MenuItem value={dateBaseOn.atd}>{dateBaseOn.atd}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} lg={4} className={clsx(classes.periods, 'exclude')}>
          <Grid container item xs={6}>
            <PeriodsControl
              id={'period-start'}
              title={'Start'}
              selectedPeriodValue={selectedStartPeriodValue}
              handlePeriodChange={handleStartPeriodChange}
              periods={periods}
              disablePeriodMenuItem={disableStartPeriodMenuItem}
              limitPeriodValue={selectedEndPeriodValue}
            />
          </Grid>
          <Grid container item xs={6}>
            <PeriodsControl
              id={'period-end'}
              title={'End'}
              selectedPeriodValue={selectedEndPeriodValue}
              handlePeriodChange={handleEndPeriodChange}
              periods={periods}
              disablePeriodMenuItem={disableEndPeriodMenuItem}
              limitPeriodValue={selectedStartPeriodValue}
            />
          </Grid>
        </Grid>
        {/* Compare */}
        <Grid container item xs={12} lg={6} className={selectedComparePeriodValues.enabledCompare ? classes.enabledCompare : ''}>
          <Grid item xs={12} lg={4}>
            <FormControl variant="outlined" className={classes.formControl + ' ' + classes.checkBlockControl + ' exclude'}>
              <FormControlLabel
                className={classes.checkBox}
                checked={selectedComparePeriodValues.enabledCompare}
                onChange={handleEnabledCompareChange}
                control={<Checkbox name="checkedBoxCompare" color="primary" />}
                label="Compare Period"
                disabled={selectedShowSuppliersIndividually}
              />
            </FormControl>
          </Grid>
          <Fade in={selectedComparePeriodValues.enabledCompare} mountOnEnter unmountOnExit>
            <Grid container item xs={12} lg={8} className={clsx(classes.periods, 'exclude')}>
              <Grid container item xs={6}>
                <PeriodsControl
                  id={'compare-period-start'}
                  title={'Start'}
                  selectedPeriodValue={selectedComparePeriodValues.startPeriodValue}
                  handlePeriodChange={handleCompareStartPeriodChange}
                  periods={periods}
                  disablePeriodMenuItem={disableStartPeriodMenuItem}
                  limitPeriodValue={selectedComparePeriodValues.endPeriodValue}
                  disabled={!selectedComparePeriodValues.enabledCompare}
                />
              </Grid>
              <Grid container item xs={6}>
                <PeriodsControl
                  id={'compare-period-end'}
                  title={'End'}
                  selectedPeriodValue={selectedComparePeriodValues.endPeriodValue}
                  handlePeriodChange={handleCompareEndPeriodChange}
                  periods={periods}
                  disablePeriodMenuItem={disableEndPeriodMenuItem}
                  limitPeriodValue={selectedComparePeriodValues.startPeriodValue}
                  disabled={!selectedComparePeriodValues.enabledCompare}
                />
              </Grid>
            </Grid>
          </Fade>
        </Grid>

        <Grid item xs={12}>
          <Accordion className={classes.moreFilterSection}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
              <Typography className={classes.heading}>More Filters</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <FormControl variant="outlined" className={classes.formControl + ' exclude'}>
                <InputLabel id="carrier-select-outlined-label">
                  <TitleFont> Carrier Contact No.</TitleFont>
                </InputLabel>
                <Select
                  labelId="carrier-select-outlined-label"
                  id="carrier-select"
                  label="Carrier Contact No."
                  value={(carrierContractNumberFilterItems && selectedCarrierContractNumber) || allCarrierContractNumberValue}
                  onChange={(e) => handleCarrierContractNumberChange(e)}
                  displayEmpty={true}
                >
                  <MenuItem value={allCarrierContractNumberValue}>All</MenuItem>
                  {carrierContractNumberFilterItems &&
                    carrierContractNumberFilterItems.map((item) => {
                      return (
                        <MenuItem key={item.carrierContractNumber} value={item.carrierContractNumberValue}>
                          {item.carrierContractNumber}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {suppliersBySpecialFilterOption && suppliersBySpecialFilterOption.length > 0 && (
          <Grid item xs={12} className={classes.supplierTags}>
            <strong>Suppliers:</strong>
            {suppliersBySpecialFilterOption.map((supplier) => {
              return <Chip size="small" key={'chip' + supplier.id} label={`${supplier.rank}. ${supplier.name}`} />;
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default TrendsFilters;
