import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { lightThemeAdmin } from 'src/themes/lightTheme';

import TopRedBar from './TopRedBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflowY: 'auto',
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // maxWidth: '1600px',
    minHeight: 'calc( 100vh - 120px )',
  },
  paper: {
    width: '100%',
    padding: '50px',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  nonePaper: {
    padding: 0,
    boxShadow: 'none',
    background: 'none',
    transition: 'none',
  },
  childrenGridContainer: {
    position: 'relative',
  },
  copyRightBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: 38,
    },
  },
  copyrightTypography: {
    paddingTop: 10,
    marginLeft: theme.spacing(1),
    color: '#666',
  },
}));

function PublicPageFrame(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={lightThemeAdmin}>
      <CssBaseline />
      <div className={classes.root}>
        <main className={classes.content}>
          <TopRedBar size="small" darkMode={false} />
          <Container maxWidth="xl" className={classes.container}>
            <Grid container spacing={3} className={classes.childrenGridContainer}>
              {props.children}
            </Grid>
          </Container>
          <Box className={classes.copyRightBox}>
            <img src="src/../images/Fickle-Black.webp" />
            <Typography variant="caption" display="block" className={classes.copyrightTypography}>
              Copyright © 2023 Fickle Tech.
            </Typography>
          </Box>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default PublicPageFrame;
