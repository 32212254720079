import { Box, Chip, makeStyles, Paper, Typography, withStyles } from '@material-ui/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import React, { useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { format } from 'react-string-format';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import OrderPlacedIcon from '@material-ui/icons/ShoppingCartOutlined';
import InTransitIcon from '@material-ui/icons/SyncAltOutlined';
import ArrivalIcon from '@material-ui/icons/ArchiveOutlined';
import CompletedIcon from '@material-ui/icons/DoneOutlined';
import CustomClearIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

const useTimeLineItemStyles = makeStyles((theme) => ({
    oppositeContentRoot: {
        flexBasis: 0,
        flexGrow: 0,
    },
    separatorRoot: {
        minWidth: 36,
    },
    highlightTextColor: {
        color: theme.palette.shipmentTracking.trackingEvents.highlightColor,
    },
    unHighlightTextColor: {
        color: theme.palette.shipmentTracking.trackingEvents.unHighlightColor,
    },
    titleText: {
        fontSize: '1rem',
    },
    descriptionText: {
        fontSize: '0.75rem',
    }
}));

const InfoDot = withStyles((theme) => ({
    root: {
        alignSelf: 'unset',
    },
    defaultSecondary: {
        backgroundColor: theme.palette.shipmentTracking.trackingEvents.highlightColor,
    }
}))((props) => (<TimelineDot {...props}>
    {props.children}
</TimelineDot>
));

const NormalDot = withStyles((theme) => ({
    root: {
        // padding: 8,
        alignSelf: 'unset',
        '& svg': {
            fontSize: '1rem',
            color: '#bdbdbd'
        }
    },
    outlinedSecondary: {
        borderColor: theme.palette.shipmentTracking.trackingEvents.highlightColor,
        background: 'transparent',
        '& svg': {
            color: theme.palette.shipmentTracking.trackingEvents.highlightColor,
        }
    }
}))((props) => (<TimelineDot variant="outlined" {...props}>
    {props.icon}
</TimelineDot>
));

const IconDot = withStyles((theme) => ({
    root: {
        alignSelf: 'unset',
        '& svg': {
            fontSize: '1.25rem',
            color: theme.palette.shipmentTracking.trackingEvents.iconColor
        }
    },
    defaultSecondary: {
        backgroundColor: theme.palette.shipmentTracking.trackingEvents.highlightColor,
        '& svg': {
            color: '#ffffff'
        }
    }
}))((props) => (<TimelineDot {...props}>
    {props.icon}
</TimelineDot>
));


function getDateTimeText(dateTimeValue, showTime) {
    if (dateTimeValue) {
        var momentTime = moment(dateTimeValue);

        if (showTime) {
            return momentTime.format('L LT');
        } else {
            return momentTime.format('L');
        }
    } else {
        return '';
    }
}

const Icon = (props) => {
    switch (props.code) {
        case "WTA":
            return <OrderPlacedIcon />;
        case "DEP":
            return <InTransitIcon />;
        case "DCF":
            return <CompletedIcon />;
        case "ARV":
            return <ArrivalIcon />;
        case "CLR":
            return <CustomClearIcon />;
        default:
            return <></>;
    }
}

const Dot = (props) => {
    // color={props.latest ? 'secondary' : 'grey'}
    switch (props.trackingType) {
        case 'important':
            return <IconDot color={props.isLatest ? 'secondary' : 'grey'} icon={<Icon code={props.code} />} />
        case 'normal':
            return <NormalDot color={props.isLatest ? 'secondary' : 'grey'} icon={<CircleIcon />} />
        default:
            return <InfoDot color={props.isLatest ? 'secondary' : 'grey'} />;
    }
}

function TimelineEventItem(props) {
    const { model, index, isFirst } = props;
    const classes = useTimeLineItemStyles();

    function getDescription(model) {
        return format(model.title, getDateTimeText(model.eventDate, true), getDateTimeText(model.eventDate2, true));
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent classes={{ root: classes.oppositeContentRoot }}>
                <Typography variant='body2' color="textSecondary">{getDateTimeText(model.createdDate, true)}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator classes={{ root: classes.separatorRoot }}>
                <Dot trackingType={model.trackingType} code={model.code} isLatest={index === 0} />
                {isFirst ? <></> : <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Paper elevation={0}>
                    {model.trackingType !== 'info' ? <Typography variant={'subtitle2'} className={clsx(index === 0 ? classes.highlightTextColor : classes.unHighlightTextColor, classes.titleText)}>{getDescription(model)}</Typography> :
                        <Typography variant={'body2'} className={clsx(index === 0 ? classes.highlightTextColor : classes.unHighlightTextColor, classes.descriptionText)}>{getDescription(model)}</Typography>}
                    <Typography variant={'body2'} className={clsx(index === 0 ? classes.highlightTextColor : classes.unHighlightTextColor, classes.descriptionText)}>{model.description}</Typography>
                </Paper>
            </TimelineContent>
        </TimelineItem>
    )
}

const useTimeLineStyle = makeStyles((theme) => ({
    root: {
        overflowY: 'auto',
    },
}));

function TrackingEvents(props) {
    const { shipmentTrackingModel } = props;
    const classes = useTimeLineStyle();

    // useEffect(() => {
    //     console.log("shipmentTrackingModel", shipmentTrackingModel);
    // }, [shipmentTrackingModel])

    return (
        <Box className={clsx(props.className, classes.root)}>
            <Timeline>
                {shipmentTrackingModel &&
                    (shipmentTrackingModel.trackingEvents && shipmentTrackingModel.trackingEvents.length > 0 ? (
                        shipmentTrackingModel.trackingEvents.slice(0).reverse().map((item, index) => {
                            return <TimelineEventItem key={'timelineItem' + index} model={item} index={index} isFirst={index === shipmentTrackingModel.trackingEvents.length - 1} />;
                        })
                    ) : (
                        <Typography align='center' variant='body2' >There is no tracking event on this shipment yet.</Typography>
                    ))}

            </Timeline>
        </Box>
    );
}

export default TrackingEvents;