import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import LoadingOverlay from '../../parts/LoadingOverlay';
import apiService from '../../../services/api.service';
import moment from 'moment';
import clamp from 'clamp-js-main';
import { format } from 'react-string-format';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
  },
  articles: {
    padding: theme.spacing(2),
    minHeight: 682,
  },
  articlesItems: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 12,
    overflowY: 'auto',
    height: '100%',
    maxHeight: 582,
    '& a': {
      color: theme.palette.news.titleColor,
    },
  },
  articleItem: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  articlesImage: {
    backgroundSize: 'cover',
    height: 120,
  },
  articlesImageGrid: {
    paddingTop: theme.spacing(1) + 'px !important',
  },
  authorAndPublishText: {
    color: '#AAAAAA',
  },
  description: {
    color: theme.palette.news.subTitleColor,
  },
}));

function Articles(props) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [articleItems, setArticleItems] = useState([]);
  const [seeMoreUrl, setSeeMoreUr] = useState();
  const descriptionElementIdFormat = 'description{0}';

  useEffect(() => {
    setIsLoading(true);
    apiService
      .getWidgetArticle(10)
      .then((result) => {
        setIsLoading(false);

        if (result.data && result.data.items.length > 0) {
          setSeeMoreUr(result.data.seeMoreUrl);
          setArticleItems(result.data.items);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('error: ', err);
        if (err.response && err.response.status === 401) history.push('/login');
      });
  }, [history]);

  useEffect(() => {
    for (var i = 0; i < articleItems.length; i++) {
      var descriptionElementId = format(descriptionElementIdFormat, i);
      var clampElement = document.getElementById(descriptionElementId);
      clamp(clampElement, { clamp: 3 });
    }
  }, [articleItems]);

  function getRelativeTimeText(dateTimeValue) {
    //moment.now.;
    var compareDate = new Date();
    compareDate.setMonth(compareDate.getMonth() - 1);

    var momentTime = moment(dateTimeValue);
    var valueDate = new Date(dateTimeValue);
    if (compareDate.getTime() > valueDate.getTime()) {
      return momentTime.format('ll');
    } else {
      return momentTime.fromNow();
    }
  }

  return (
    <Paper className={classes.root}>
      <LoadingOverlay IsLoading={isLoading} />
      <Grid container spacing={3} direction="row" justify="flex-start" alignItems="flex-start" alignItems="stretch" className={classes.articles}>
        {/* Header */}
        <Grid item xs={12} container justify="space-between" alignItems="flex-end">
          <Grid item>
            <Typography variant="h6">Articles</Typography>
          </Grid>
          <Grid item>
            {seeMoreUrl ? (
              <a href={seeMoreUrl} target="_blank">
                <Typography variant="caption">See more</Typography>
              </a>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {/* Articles items */}
        <Box flexGrow={1} className={classes.articlesItems}>
          <Grid item xs={12} container direction="column">
            {articleItems &&
              articleItems.map((item, index) => {
                var descriptionElementId = format(descriptionElementIdFormat, index);
                return (
                  <Grid key={`article${index}`} item xs={12} container direction="row" spacing={1} className={classes.articleItem}>
                    <Grid item xs={5} className={classes.articlesImageGrid}>
                      <div className={classes.articlesImage} style={{ backgroundImage: 'url(' + item.imageUrl + ')' }}></div>
                    </Grid>
                    <Grid item xs={7} container direction="column" justify="flex-start" alignItems="flex-start">
                      <Grid item>
                        <a href={item.sourceUrl} target="_blank">
                          <Typography variant="subtitle2">{item.title}</Typography>
                        </a>
                      </Grid>
                      <Grid item>
                        <div id={descriptionElementId}>
                          <Typography variant="body2" className={classes.description}>
                            {item.description}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className={classes.authorAndPublishText}>
                          {item.author ? `by ${item.author} - ${getRelativeTimeText(item.publishedDate)}` : `${getRelativeTimeText(item.publishedDate)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}

export default Articles;
