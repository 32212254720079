import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.warning': {
      '& label': {
        color: theme.palette.inputControl.warning,
      },
      '& p': {
        color: theme.palette.inputControl.warning,
      },
      '& label + div:before': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 1,
      },
      '& label.Mui-focused + div:before, & label.Mui-focused + div:after': {
        borderColor: theme.palette.inputControl.warning,
        borderWidth: 2,
      },
    },
  },
}));

export const EntryFormSelect = (props) => {
  const classes = useStyles();
  const { id, label, value, onSelectionChange, items, required, helperTextStatus, helperText } = props;
  let isWarning = false;
  let isError = false;
  if (helperTextStatus == 'warning') {
    isWarning = true;
  } else if (helperTextStatus == 'error') {
    isError = true;
  }

  const onChange = (e) => {
    if (onSelectionChange) {
      onSelectionChange(e.target.value);
    }
  };

  return (
    <FormControl required={required} fullWidth className={clsx(classes.root, !isError && isWarning && 'warning')} error={isError}>
      <InputLabel id={'label' + id}>{label}</InputLabel>
      <Select labelId={'label' + id} value={value || ''} onChange={onChange}>
        {items &&
          items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

EntryFormSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
