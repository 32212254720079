import React, { useEffect } from 'react';

import { Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';

import { EntryFormSelect } from './components/EntryFormSelect';
import { EntryTextField } from './components/EntryTextField';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
  },
}));

export const AddressSelection = (props) => {
  const { id, title, addressSelectItems, selectedAddress, onSelectedValue, helperTextStatus, helperText, readOnly } = props;

  const classes = useStyles();

  const onSelectionChange = (selectedValue) => {
    if (onSelectedValue) {
      onSelectedValue(selectedValue);
    }
  };

  useEffect(() => {
    if (addressSelectItems && addressSelectItems.length == 1) {
      onSelectionChange(addressSelectItems[0].id);
    }
  }, [addressSelectItems]);

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {addressSelectItems && addressSelectItems.length > 1 && !readOnly ? (
              <EntryFormSelect
                id={id}
                items={addressSelectItems}
                label={'Name'}
                onSelectionChange={onSelectionChange}
                required={true}
                value={selectedAddress.id}
                helperTextStatus={helperTextStatus}
                helperText={helperText}
              />
            ) : (
              <EntryTextField
                label="Name"
                name="name"
                readOnly={true}
                value={selectedAddress.name}
                helperTextStatus={helperTextStatus}
                helperText={helperText}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <EntryTextField label="Address" name="address" readOnly={true} value={selectedAddress.address ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="Post Code" name="postCode" readOnly={true} value={selectedAddress.postCode ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="City" name="city" readOnly={true} value={selectedAddress.city ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="State" name="state" readOnly={true} value={selectedAddress.state ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="Country" name="country" readOnly={true} value={selectedAddress.country ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="Phone" name="phone" readOnly={true} value={selectedAddress.phone ?? ''} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntryTextField label="Email" name="email" readOnly={true} value={selectedAddress.email ?? ''} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
