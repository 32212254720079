import React from "react";
import StandardAlert from "@material-ui/lab/Alert";

function Alert(props) {
    const {theme} = props;

    return theme === "dark" ? (
      <StandardAlert variant="outlined" {...props}>
        {props.children}
      </StandardAlert>
    ) : (
      <StandardAlert variant="filled" {...props}>
        {props.children}
      </StandardAlert>
    );
}

export default Alert;
