import dateBaseOn from 'src/models/DateBaseOn';

class DataTableFilterModelFactory {
  createReducerStateFromFilterQueryModel = (filterQueryModel, showDateBasedOn) => {
    let dataTableFilterModel = {
      startPeriodValue: filterQueryModel.startPeriod,
      endPeriodValue: filterQueryModel.endPeriod,
      freeTextSearch: filterQueryModel.freeText,
      supplierId: filterQueryModel.supplierId || 'All',
      dateBasedOn: showDateBasedOn ? filterQueryModel.dateBasedOn || dateBaseOn.eta : dateBaseOn.eta,
      specialSearch: {
        title: filterQueryModel.specialSearchTitle,
        portOriginCode: filterQueryModel.portOriginCode,
        portDestinationCode: filterQueryModel.portDestinationCode,
        trans: filterQueryModel.trans,
        mode: filterQueryModel.mode,
      },
    };
    return dataTableFilterModel;
  };
  createFilterQueryModelFromReducerState = (reducerState, accountId) => {
    return {
      supplierId: reducerState.supplierId,
      dateBasedOn: reducerState.dateBasedOn,
      startPeriod: reducerState.startPeriodValue,
      endPeriod: reducerState.endPeriodValue,
      freeText: reducerState.freeTextSearch,
      specialSearchTitle: reducerState.specialSearch?.title,
      portOriginCode: reducerState.specialSearch?.portOriginCode,
      portDestinationCode: reducerState.specialSearch?.portDestinationCode,
      trans: reducerState.specialSearch?.trans,
      mode: reducerState.specialSearch?.mode,
      accountId: accountId,
    };
  };
  createReturnFilterComponentData = (reducerState, accountId, accountName) => {
    var selectedStartPeriod = reducerState.periods.find((x) => x.id === reducerState.startPeriodValue);
    var selectedEndPeriod = reducerState.periods.find((x) => x.id === reducerState.endPeriodValue);

    var supplierName;
    if (reducerState.supplierId === null || reducerState.supplierId === 'All') {
      supplierName = 'All';
    } else {
      supplierName = reducerState.suppliers.find((x) => x.id === reducerState.supplierId).name;
    }

    var periodName = '';
    if (selectedStartPeriod === '' && selectedEndPeriod === '') {
      periodName = 'All';
    } else {
      var startPeriodText = selectedStartPeriod == null ? 'Past' : selectedStartPeriod.displayName;
      var endPeriodText = selectedEndPeriod == null ? 'Present' : selectedEndPeriod.displayName;
      periodName = startPeriodText + ' to ' + endPeriodText;
    }

    const filterComponentData = {
      companyName: accountName,
      accountId: accountId,
      accountName: accountName,
      periodName: periodName,
      start: selectedStartPeriod?.periodStart,
      end: selectedEndPeriod?.periodEnd,
      freeTextSearch: reducerState.freeTextSearch,
      supplierName: supplierName,
      suppliers: reducerState.supplierId,
      dateBasedOn: reducerState.dateBasedOn,
      specialSearch: { ...reducerState.specialSearch },
      showFocusedShipment: reducerState.showFocusedShipment,
    };

    return filterComponentData;
  };
}

export default new DataTableFilterModelFactory();
