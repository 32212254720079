import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { format } from 'react-string-format';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.currentLocalTimes.backgroundColor,
  },
  localtimes: {
    padding: theme.spacing(2),
    minHeight: 682,
  },
  localtimesItems: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 12,
    overflowY: 'auto',
    maxHeight: 582,
  },
  localtimesItem: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: 'White',
  },
  placeAndUtcContainer: {
    width: 'auto',
  },
  placeName: {
    fontSize: '0.8rem',
    fontWeight: 600,
  },
  utcText: {
    fontSize: '0.65rem',
  },
  timeText: {
    color: '#4a95ed',
  },
  flagImage: {
    boxShadow: '1px 0px 9px -2px rgb(0 0 0 / 50%); -webkit-box-shadow: 1px 0px 9px -2px rgb(0 0 0 / 50%); -moz-box-shadow: 1px 0px 9px -2px rgba(0,0,0,0.5);',
  },
}));

function CurrentLocalTimes(props) {
  const classes = useStyles();
  const history = useHistory();
  const [time, setTime] = useState();
  const [localTimes, setLocalTimes] = useState([]);

  const contryCodeWithTimeZone = [
    { code: 'AU', country: 'Australia', city: 'Sydney', timeZoneName: 'Australia/Sydney' },
    { code: 'AT', country: 'Austria', city: 'Vienna', timeZoneName: 'Europe/Vienna' },
    { code: 'BE', country: 'Belgium', city: 'Brussels', timeZoneName: 'Europe/Brussels' },
    { code: 'CN', country: 'China', city: 'Shanghai', timeZoneName: 'Asia/Shanghai' },
    { code: 'FR', country: 'France', city: 'Paris', timeZoneName: 'Europe/Paris' },
    { code: 'DE', country: 'Germany', city: 'Berlin', timeZoneName: 'Europe/Berlin' },
    { code: 'HK', country: 'Hong Kong', city: 'Hong Kong', timeZoneName: 'Asia/Hong_Kong' },
    { code: 'ID', country: 'Indonesia', city: 'Jakarta', timeZoneName: 'Asia/Jakarta' },
    { code: 'JP', country: 'Japan', city: 'Tokyo', timeZoneName: 'Asia/Tokyo' },
    { code: 'KR', country: 'Korea (Republic of)', city: 'Seoul', timeZoneName: 'Asia/Seoul' },
    { code: 'MY', country: 'Malaysia', city: 'Kuala Lumpur', timeZoneName: 'Asia/Kuala_Lumpur' },
    { code: 'NZ', country: 'New Zealand', city: 'Auckland', timeZoneName: 'Pacific/Auckland' },
    { code: 'PT', country: 'Portugal', city: 'Lisbon', timeZoneName: 'Europe/Lisbon' },
    { code: 'ES', country: 'Spain', city: 'Madrid', timeZoneName: 'Europe/Madrid' },
    { code: 'SE', country: 'Sweden', city: 'Stockholm', timeZoneName: 'Europe/Stockholm' },
    { code: 'TW', country: 'Taiwan', city: 'Taipei', timeZoneName: 'Asia/Taipei' },
    { code: 'TH', country: 'Thailand', city: 'Bangkok', timeZoneName: 'Asia/Bangkok' },
    { code: 'GB', country: 'United Kingdom', city: 'London', timeZoneName: 'Europe/London' },
    { code: 'US', country: 'United States', city: 'New York', timeZoneName: 'America/New_York' },
    { code: 'VN', country: 'Viet Nam', city: 'Ho Chi Minh City', timeZoneName: 'Asia/Ho_Chi_Minh' },
  ];

  useEffect(() => {
    initLocalTimes();

    const intervalId = setInterval(function () {
      setTime(new Date().getTime());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [history]);

  function initLocalTimes() {
    var localTimes = [];
    contryCodeWithTimeZone.forEach((countryCode) => {
      var abbr = moment.tz(countryCode.timeZoneName).format('z');
      var utcOffset = moment.tz(countryCode.timeZoneName).format('Z');
      var utcText = '';

      if (!abbr.includes('0') && !abbr.includes('-') && !abbr.includes('+')) {
        utcText = format('{0} (UTC {1})', abbr, utcOffset);
      } else {
        utcText = format('(UTC {0})', utcOffset);
      }

      localTimes.push({
        flagImageUrl: 'https://catamphetamine.gitlab.io/country-flag-icons/1x1/' + countryCode.code + '.svg',
        zoneName: countryCode.timeZoneName,
        utcText: utcText,
        placeName: format('{0}, {1}', countryCode.city, countryCode.country),
      });
    });
    setLocalTimes(localTimes);
  }

  function getTimeText(zone) {
    return moment(time).tz(zone).format('LT');
  }
  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} direction="column" justify="flex-start" alignItems="flex-start" alignItems="stretch" className={classes.localtimes}>
        {/* Header */}
        <Grid item>
          <Typography variant="h6">Current Local Times</Typography>
        </Grid>
        {/* LocalTimes items */}
        <Box flexGrow={1} className={classes.localtimesItems}>
          <Grid item xs={12} container direction="column">
            {localTimes &&
              localTimes.map((localTime, index) => {
                return (
                  <Grid key={`time${index}`} container item xs={12} direction="column">
                    <Grid item xs={12} container direction="row" spacing={1} alignItems="center" justify="space-between" className={classes.localtimesItem}>
                      <Grid item>
                        <Avatar className={classes.flagImage} alt={'localTime.placeName'} src={localTime.flagImageUrl} />
                      </Grid>
                      <Grid item container direction="column" justify="flex-start" alignItems="flex-start" xs>
                        <Grid item>
                          <Typography variant="body2" className={classes.placeName}>
                            {localTime.placeName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" className={classes.utcText}>
                            {localTime.utcText}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" className={classes.timeText}>
                          {getTimeText(localTime.zoneName)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Grid>
    </Paper>
  );
}

export default CurrentLocalTimes;
