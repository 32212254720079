import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Box, Grid, IconButton, Modal, makeStyles } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import apiService from '../../../services/api.service';
import PurchaseOrderDetails from './PurchaseOrderDetails';

const useShipmentDetailsItemStyles = makeStyles((theme) => ({
  hightlightRow: {
    backgroundColor: theme.palette.shipmentTracking.shipmentDetails.hightlightRowBackgroundColor,
  },
  title: {
    titleColor: theme.palette.shipmentTracking.shipmentDetails.hightlightRowBackgroundColor,
    fontSize: '0.75rem',
    paddingLeft: theme.spacing(1),
  },
  value: {
    fontWeight: 600,
    fontSize: '0.875rem',
    paddingLeft: theme.spacing(1),
  },
  routingPortItem: {
    paddingLeft: theme.spacing(1),
  },
  underLineValue: {
    fontWeight: 600,
    fontSize: '0.875rem',
    // paddingLeft: theme.spacing(1),
    borderBottom: '1px dotted',
    borderColor: theme.palette.shipmentTracking.shipmentDetails.titleColor,
    textAlign: 'center',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1.5),
  },
}));

function getDateTimeText(dateTimeValue) {
  if (dateTimeValue) {
    var momentTime = moment(dateTimeValue);

    if (momentTime.isValid()) {
      return momentTime.format('l');
    }
  }
  return dateTimeValue;
}

function ShipmentDetailsItem(props) {
  const classes = useShipmentDetailsItemStyles();
  const { model, index } = props;
  return (
    <Grid container item xs={12} className={clsx('p-1', { [classes.hightlightRow]: index % 2 == 0 })}>
      <Grid item xs={5} className={classes.title}>
        {model.title} :
      </Grid>
      <Grid item className={classes.value}>
        {model.valueType === 'date' ? getDateTimeText(model.value) : model.value}
      </Grid>
    </Grid>
  );
}

function ShipmentDetailsRoutingPortItem(props) {
  const classes = useShipmentDetailsItemStyles();
  const { model, index, samePort } = props;
  return (
    <Grid container item xs={12} className={clsx('p-1', { [classes.hightlightRow]: index % 2 == 0 })}>
      <Grid item xs={5} className={classes.routingPortItem}>
        {samePort ? (
          ''
        ) : (
          <>
            {model.portNumber}.{model.country},{` `}
            {model.title} :
          </>
        )}
      </Grid>
      <Grid item container xs={7} className={classes.routingPortItem}>
        <Grid item xs={2}>
          {model.state}
        </Grid>
        <Grid item xs={3} className={classes.underLineValue}>
          {model.actualValue ? getDateTimeText(model.actualValue) : ' - '}{' '}
        </Grid>
        <Grid item>Est.</Grid>
        <Grid item xs={3} className={classes.underLineValue}>
          {model.estimateValue ? getDateTimeText(model.estimateValue) : ' - '}{' '}
        </Grid>
      </Grid>
    </Grid>
  );
}

const useShipmentDetailsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  scrollableList: {
    overflowY: 'auto',
  },
  lastUpdated: {
    position: 'absolute',
    color: '#666',
    fontSize: '0.75rem',
    bottom: -20,
    right: -20,
  },
  groupItemTitle: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
    fontWeight: 600,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContentContainer: {
    margin: 60,
    maxHeight: 'calc(100% - 40px)',
    display: 'flex',
  },
  buttonIcon: {
    color: theme.palette.primaryButton.backgroundColor,
  },
}));

export default function ShipmentDetails(props) {
  const { shipmentTrackingModel } = props;
  const classes = useShipmentDetailsStyles();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState('');
  const [purchaseOrderNumbersToShowDetails, setPurchaseOrderNumbersToShowDetails] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (shipmentTrackingModel && shipmentTrackingModel.shipmentTrackingId) {
      setPurchaseOrderNumbersToShowDetails([]);
      apiService
        .getNotEmptyPurchaseNumbersInShipmentTracking(shipmentTrackingModel.shipmentTrackingId)
        .then((result) => {
          if (!isCancelled && result.data) {
            setPurchaseOrderNumbersToShowDetails(result.data);
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) history.push('/login');
        });
    } else {
      setPurchaseOrderNumbersToShowDetails([]);
    }

    return () => {
      isCancelled = true;
    };
  }, [shipmentTrackingModel]);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handlePurchaseOrderDetailsClick = (event) => {
    let selectedOrderNumber = event.currentTarget.parentNode.dataset.tag;
    setSelectedOrderNumber(selectedOrderNumber);
    setModalOpen(true);
  };

  return (
    <>
      <Box className={clsx(props.className, classes.root)}>
        <h6>
          {shipmentTrackingModel && (
            <>
              {shipmentTrackingModel.originFullname} <TrendingFlatIcon /> {shipmentTrackingModel.destinationFullname}
            </>
          )}
        </h6>
        <Grid container className={classes.scrollableList}>
          {shipmentTrackingModel && shipmentTrackingModel.shipmentItems && shipmentTrackingModel.shipmentItems.length > 0 && (
            <>
              {shipmentTrackingModel.shipmentItems.map((item, index) => {
                return <ShipmentDetailsItem key={'shipmentItems' + index} model={item} index={index} />;
              })}
              <Grid item xs={12}>
                <hr />
              </Grid>
            </>
          )}
          {shipmentTrackingModel && shipmentTrackingModel.eventItems && shipmentTrackingModel.eventItems.length > 0 && (
            <>
              {shipmentTrackingModel.eventItems.map((item, index) => {
                return <ShipmentDetailsItem key={'eventItems' + index} model={item} index={index} />;
              })}
              <Grid item xs={12}>
                <hr />
              </Grid>
            </>
          )}
          {shipmentTrackingModel && shipmentTrackingModel.routingPortItems && shipmentTrackingModel.routingPortItems.length > 0 && (
            <>
              <Grid item xs={12} className={classes.groupItemTitle}>
                Routing Ports
              </Grid>
              {shipmentTrackingModel.routingPortItems.map((item, index) => {
                let samePort = false;
                if (index > 0) {
                  let a = shipmentTrackingModel.routingPortItems[index - 1];
                  if (item.portNumber === a.portNumber) {
                    samePort = true;
                  }
                }
                return <ShipmentDetailsRoutingPortItem key={'routingPortItems' + index} samePort={samePort} model={item} index={index} />;
              })}
              <Grid item xs={12}>
                <hr />
              </Grid>
            </>
          )}
          {shipmentTrackingModel && shipmentTrackingModel.orderGroupItems && shipmentTrackingModel.orderGroupItems.length > 0 && (
            <>
              {shipmentTrackingModel.orderGroupItems.map((groupItem, index) => {
                return (
                  <React.Fragment key={'orderGroup' + index}>
                    <Grid item xs={12} className={classes.groupItemTitle} data-tag={groupItem.title}>
                      Order: {groupItem.title}
                      {purchaseOrderNumbersToShowDetails.indexOf(groupItem.title) > -1 && (
                        <IconButton aria-label="edit" onClick={handlePurchaseOrderDetailsClick}>
                          <ListAltIcon size="small" className={classes.buttonIcon} />
                        </IconButton>
                      )}
                    </Grid>
                    {groupItem.items.map((item, index) => {
                      return <ShipmentDetailsItem key={groupItem.title + 'item' + index} model={item} index={index} />;
                    })}
                  </React.Fragment>
                );
              })}
              <Grid item xs={12}>
                <hr />
              </Grid>
            </>
          )}
          {shipmentTrackingModel && shipmentTrackingModel.containerGroupItems.length > 0 && (
            <>
              {shipmentTrackingModel.containerGroupItems.map((groupItem, index) => {
                return (
                  <React.Fragment key={'containerGroup' + index}>
                    <Grid item xs={12} className={classes.groupItemTitle}>
                      Container: {groupItem.title}
                    </Grid>
                    {groupItem.items.map((item, index) => {
                      return <ShipmentDetailsItem key={groupItem.title + 'item' + index} model={item} index={index} />;
                    })}
                  </React.Fragment>
                );
              })}
              <Grid item xs={12}>
                <hr />
              </Grid>
            </>
          )}
        </Grid>
        {/* <Box className={classes.lastUpdated}>Last updated: {shipmentTrackingModel && getDateTimeText(shipmentTrackingModel.lastUpdatedDate)}</Box> */}
      </Box>

      <Modal className={classes.modalContainer} open={modalOpen} onClose={handleModalClose}>
        <div className={classes.modalContentContainer}>
          <PurchaseOrderDetails orderNumber={selectedOrderNumber} onClose={handleModalClose} />
        </div>
      </Modal>
    </>
  );
}
