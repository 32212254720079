import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import EntityListFrame from 'src/components/layouts/EntityListFrame';
import { ProtectedRoute } from 'src/protected.route';

import SetUserPassword from './SetPassword';
import UserEntry from './UserEntry';
import Users from './Users';

export function UsersContainer(props) {
  let match = useRouteMatch();
  let location = useLocation();
  const history = useHistory();
  const [showFrameAsPaper, setShowFrameAsPaper] = useState(false);

  useEffect(() => {
    if (location.pathname === '/users/add' || location.pathname.indexOf('edit') > -1 || location.pathname.indexOf('set-password') > -1) {
      setShowFrameAsPaper(false);
    } else {
      setShowFrameAsPaper(true);
    }
  }, [location]);

  return (
    <>
      <EntityListFrame inPaper={showFrameAsPaper}>
        <Grid item xs={12}>
          <Switch>
            <ProtectedRoute exact path={`${match.path}/add`}>
              <UserEntry action="add" history={history} />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}/:id/edit`}>
              <UserEntry action="edit" history={history} />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}/:id/set-password`}>
              <SetUserPassword history={history} />
            </ProtectedRoute>
            <ProtectedRoute exact path={`${match.path}`}>
              <Users />
            </ProtectedRoute>
            <Redirect to="/dashboard" />
            <ProtectedRoute />
          </Switch>
        </Grid>
      </EntityListFrame>
    </>
  );
}
