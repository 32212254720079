import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router';
import moment from 'moment';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& h6': {
      fontWeight: 600,
    },
    backgroundColor: theme.palette.modal.backgroundColor,
    position: 'relative',
    padding: theme.spacing(3),
    flexDirection: 'column',
    transition: 'visibility 0.1s linear,opacity 0.1s linear',
    opacity: 0,
    maxWidth: '100%',
  },
  ready: {
    opacity: 1,
  },
  contentContainer: {
    overflowY: 'auto',
    marginTop: '0px !important',
  },
  closeButton: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  divider: {
    marginRight: 12,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
  },
  dataRow: {
    display: 'inline-flex',
  },
  headerLabel: {
    width: 200,
    fontWeight: 'bold',
  },
  addressLabel: {
    width: 120,
    fontWeight: 'bold',
  },
  tableHead: {
    '& th': {
      fontWeight: 'bold',
    },
  },
}));

function PurchaseOrderDetailsAddress(props) {
  const { classes, model, title } = props;
  const [addressText, setAddressText] = useState();
  const [telephonesText, setTelephonesText] = useState();

  useEffect(() => {
    if (model) {
      let addressItems = [];
      if (model.companyName) {
        addressItems.push(model.companyName);
      }
      if (model.streetAddress) {
        addressItems.push(model.streetAddress);
      }
      if (model.city) {
        addressItems.push(model.city);
      }
      if (model.country) {
        addressItems.push(model.country);
      }
      if (model.postCode) {
        addressItems.push(model.postCode);
      }
      setAddressText(addressItems.join(' '));

      let telephoneItems = [];
      if (model.telephone) {
        telephoneItems.push(model.telephone);
      }
      if (model.telephone2) {
        telephoneItems.push(model.telephone2);
      }
      setTelephonesText(telephoneItems.join(', '));
    }
  }, [model]);

  return (
    <Paper className={classes.paper}>
      <h6>{title}</h6>
      <Grid container>
        <Grid item xs={12} className={classes.dataRow}>
          <Box className={classes.addressLabel}>Name:</Box>
          {model.name}
        </Grid>
        <Grid item xs={12} className={classes.dataRow}>
          <Box className={classes.addressLabel}>Address:</Box>
          {addressText}
        </Grid>
        <Grid item xs={12} className={classes.dataRow}>
          <Box className={classes.addressLabel}>Telephones:</Box>
          {telephonesText}
        </Grid>
        <Grid item xs={12} className={classes.dataRow}>
          <Box className={classes.addressLabel}>Fax:</Box>
          {model.fax}
        </Grid>
      </Grid>
    </Paper>
  );
}

const PurchaseOrderDetailsTableCell = withStyles({
  root: {
    fontSize: '0.8rem',
  },
})(TableCell);

export default function PurchaseOrderDetails(props) {
  const classes = useStyles();
  const history = useHistory();
  const { orderNumber, accountId, onClose } = props;
  const [model, setModel] = useState();
  const [isReady, setIsReady] = useState(false);
  const [customFieldHeaders, setCustomFieldHeaders] = useState([]);
  const hidableMainColumnNames = ['requiredInStoreDate'];
  const [mainColumnVisibilities, setMainColumnVisibilities] = useState({ requiredInStoreDate: false });

  useEffect(() => {
    let isCancelled = false;
    if (orderNumber && accountId) {
      apiService
        .getPurchaseOrderReport(accountId, orderNumber)
        .then((result) => {
          if (!isCancelled) {
            setModel(result.data);
            setIsReady(true);
          }
        })
        .catch((err) => {
          console.log('error: ', err);
          if (err.response && err.response.status === 401) history.push('/login');
        });
    }

    return () => {
      isCancelled = true;
      setIsReady(false);
    };
  }, [orderNumber, accountId]);

  useEffect(() => {
    const generateCustomFieldHeaders = () => {
      if (model && model.lines && model.lines.length > 0 && model.lines[0].customFields && model.lines[0].customFields.length > 0) {
        var headers = model.lines[0].customFields.map((customField) => {
          return { name: customField.name, align: customField.type === 'DateTime' || customField.type === 'Decimal' ? 'right' : 'left' };
        });
        setCustomFieldHeaders(headers);
      } else {
        setCustomFieldHeaders([]);
      }
    };

    generateCustomFieldHeaders();
    if (model && model.lines) {
      initMainColumnVisibilities(model.lines);
    }
  }, [model]);

  const handleCloseModalClick = () => {
    setIsReady(false);
    onClose();
  };

  const getDateTimeText = (dateTime) => {
    if (dateTime) {
      var momentTime = moment(dateTime);
      return momentTime.format('l');
    }
    return '';
  };

  const getUnitPrice = (line) => {
    if (line.unitPrice) {
      if (line.unitQty > 1) {
        return `$${line.unitPrice} per ${line.unitQty}${line.unitUOM ?? ''}`;
      } else {
        return `$${line.unitPrice} per ${line.unitUOM ?? ''}`;
      }
    }
    return '';
  };

  const initMainColumnVisibilities = (orderLines) => {
    let tempMainColumnVisibilities = { ...mainColumnVisibilities };
    if (orderLines && orderLines.length > 0) {
      hidableMainColumnNames.forEach((hidableMainColumnName) => {
        let value = orderLines.find((x) => x[hidableMainColumnName] != null);
        if (value) {
          tempMainColumnVisibilities[hidableMainColumnName] = true;
        }
      });
    }

    setMainColumnVisibilities(tempMainColumnVisibilities);
  };

  return (
    <Paper className={clsx(classes.root, isReady && classes.ready)}>
      <Box>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <h4 style={{ fontWeight: 'normal' }}>Order Number: {orderNumber}</h4>
          </Grid>
        </Grid>
        <IconButton aria-label="edit" onClick={handleCloseModalClick} className={classes.closeButton}>
          <CloseIcon size="small" />
        </IconButton>
        <Divider className={classes.divider} />
      </Box>
      {model && (
        <Grid container spacing={2} className={classes.contentContainer}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12} lg={6} className={classes.dataRow}>
                  <Box className={classes.headerLabel}>Order Number:</Box> {orderNumber}
                </Grid>
                <Grid item xs={12} lg={6} className={classes.dataRow}>
                  <Box className={classes.headerLabel}>Order Date:</Box> {getDateTimeText(model.orderDate)}
                </Grid>
                <Grid item xs={12} lg={6} className={classes.dataRow}>
                  <Box className={classes.headerLabel}>Vendor Number:</Box> {model.vendorNumber}
                </Grid>
                <Grid item xs={12} lg={6} className={classes.dataRow}>
                  <Box className={classes.headerLabel}>Created By:</Box> {model.createdBy}
                </Grid>
                <Grid item xs={12} lg={6} className={classes.dataRow}>
                  <Box className={classes.headerLabel}>Status:</Box> {model.statusText}
                </Grid>
                {model.customFields &&
                  model.customFields.map((customField) => {
                    let value = customField.value;
                    if (customField.type === 'DateTime') {
                      value = getDateTimeText(customField.value);
                    } else if (customField.type === 'Decimal') {
                      value = customField.value.toLocaleString();
                    }
                    return (
                      <Grid item xs={12} lg={6} className={classes.dataRow}>
                        <Box className={classes.headerLabel}>{customField.name}:</Box> {value}
                      </Grid>
                    );
                  })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <PurchaseOrderDetailsAddress title="Supplier" model={model.supplier} classes={classes} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <PurchaseOrderDetailsAddress title="Deliver To" model={model.deliverTo} classes={classes} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Paper className={classes.paper}>
              <h6>Shipments Status</h6>
              <Grid container>
                {model.shipmentStatuses && model.shipmentStatuses.length > 0 ? (
                  model.shipmentStatuses.map((shipmentStatus) => {
                    return (
                      <Grid item xs={12} className={classes.dataRow}>
                        {shipmentStatus.shipmentNumber ? (
                          <Link to={'/trackandtrace?s=' + shipmentStatus.shipmentNumber} className={classes.dataRow}>
                            <Box className={classes.addressLabel}>{shipmentStatus.shipmentNumber}:</Box> {shipmentStatus.status}
                          </Link>
                        ) : (
                          shipmentStatus.status
                        )}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12} className={classes.dataRow}>
                    Pending
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <PurchaseOrderDetailsTableCell>Line No.</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell>Code</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell>Description</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell>Status</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell align="right">Qty</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell>UOM</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell align="right">{'Price($)'}</PurchaseOrderDetailsTableCell>
                    <PurchaseOrderDetailsTableCell>Unit Price</PurchaseOrderDetailsTableCell>
                    {mainColumnVisibilities.requiredInStoreDate && (
                      <PurchaseOrderDetailsTableCell align="right">Required In Store</PurchaseOrderDetailsTableCell>
                    )}
                    {customFieldHeaders &&
                      customFieldHeaders.map((header, index) => {
                        return (
                          <PurchaseOrderDetailsTableCell key={'lineHeader' + index} align={header.align}>
                            {header.name}
                          </PurchaseOrderDetailsTableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {model.lines &&
                    model.lines.map((line) => (
                      <TableRow key={line.lineId}>
                        <PurchaseOrderDetailsTableCell component="th" scope="row">
                          {line.lineId}
                        </PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell>{line.code}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell>{line.description}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell>{line.lineStatusText}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell align="right">{line.qty}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell>{line.qtyUOM}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell align="right">{line.linePrice}</PurchaseOrderDetailsTableCell>
                        <PurchaseOrderDetailsTableCell>{getUnitPrice(line)}</PurchaseOrderDetailsTableCell>
                        {mainColumnVisibilities.requiredInStoreDate && (
                          <PurchaseOrderDetailsTableCell align="right">{getDateTimeText(line.requiredInStoreDate)}</PurchaseOrderDetailsTableCell>
                        )}
                        {customFieldHeaders &&
                          customFieldHeaders.map((header, index) => {
                            let lineCustomField = line.customFields.find((x) => x.name === header.name);
                            let customFieldValue = '';
                            if (lineCustomField) {
                              if (lineCustomField.type === 'DateTime') {
                                customFieldValue = getDateTimeText(lineCustomField.value);
                              } else if (lineCustomField.type === 'Decimal') {
                                customFieldValue = lineCustomField.value.toLocaleString();
                              } else {
                                customFieldValue = lineCustomField.value;
                              }
                            }

                            return (
                              <PurchaseOrderDetailsTableCell key={line.lineId + 'lineCf' + index} align={header.align}>
                                {customFieldValue}
                              </PurchaseOrderDetailsTableCell>
                            );
                          })}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

PurchaseOrderDetails.propTypes = {
  orderNumber: PropTypes.string,
  accountId: PropTypes.string,
  onClose: PropTypes.func,
};
