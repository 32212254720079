import PropTypes from 'prop-types';
import React from 'react';

import StandardButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primaryButton.fontColor,
    backgroundColor: theme.palette.primaryButton.backgroundColor,
    '&:hover': {
      backgroundColor: theme.palette.primaryButton.hoverBackgroundColor,
    },
  },
}))(Button);

const ColorButtonGrey = withStyles((theme) => ({
  root: {
    color: '#fff',
    backgroundColor: '#000000',
    '&:hover': {
      backgroundColor: '#444444',
    },
  },
}))(Button);

const OutlinedColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primaryButton.backgroundColor,
    border: `1px solid ${theme.palette.primaryButton.backgroundColor}`,
    '&:hover': {
      color: theme.palette.primaryButton.hoverBackgroundColor,
      border: `1px solid ${theme.palette.primaryButton.hoverBackgroundColor}`,
    },
  },
}))(StandardButton);

const useMyButtonStyles = makeStyles((theme) => ({
  circularProgress: {
    height: '20px !important',
    width: '20px !important',
  },
}));

function MyButton(props) {
  const { buttonType = 'primary', isLoading, disabled, children, ...remainProps } = props;
  const classes = useMyButtonStyles();

  const theme = parseInt(localStorage.getItem('theme')) === 1 ? 'light' : 'dark';

  const darkThemePrimaryButton = (
    <ColorButton variant="contained" color="primary" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButton>
  );
  const darkThemeSecondaryButton = (
    <OutlinedColorButton variant="outlined" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </OutlinedColorButton>
  );
  const darkThemeCancelButton = (
    <ColorButtonGrey variant="outlined" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButtonGrey>
  );

  const lightThemePrimaryButton = (
    <ColorButton variant="contained" color="primary" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButton>
  );
  const lightThemeSecondaryButton = (
    <OutlinedColorButton variant="outlined" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </OutlinedColorButton>
  );
  const lightThemeCancelButton = (
    <ColorButtonGrey variant="contained" color="primary" {...remainProps} disabled={disabled || isLoading}>
      {isLoading ? <CircularProgress className={classes.circularProgress} /> : children}
    </ColorButtonGrey>
  );

  if (theme === 'light') {
    if (buttonType === 'cancel') {
      return lightThemeCancelButton;
    } else if (buttonType === 'secondary') {
      return lightThemeSecondaryButton;
    } else {
      return lightThemePrimaryButton;
    }
  } else {
    if (buttonType === 'cancel') {
      return darkThemeCancelButton;
    } else if (buttonType === 'secondary') {
      return darkThemeSecondaryButton;
    } else {
      return darkThemePrimaryButton;
    }
  }
}

MyButton.propTypes = {
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'cancel']),
};

export default MyButton;
